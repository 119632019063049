import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react';
import axios from 'axios'
import { APIContext } from 'src/contexts/api'
import { logError } from 'src/utils/Error';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import useFieldStyles from 'src/assets/css/components/ParameterFieldStyles';


/*
 * A custom Data Plugin field for Google Analytics 'tap-google-analytics.view_id'
 * 
 * Fetches and displays a list of views for the user to choose.
 */

const GoogleAnalyticsViewsField: FunctionComponent<any> = (props: PropsWithChildren<any>) => {
    const { 
      properties,
      setting,
      prefix,
      handleSettingsChange, 
      handleSettingsChangeEvent,
      handleSettingsUpdate,
      currentValue, 
      error
    } = props
    const propertyName = prefix ? `${prefix}.${setting.name}` : setting.name
    const accessToken = properties[prefix ? `${prefix}.oauth_credentials.access_token` : 'oauth_credentials.access_token']
    const refreshToken = properties[prefix ? `${prefix}.oauth_credentials.refresh_token` : 'oauth_credentials.refresh_token']
    const { label, required } = setting;
    const { catalogAPI } = useContext(APIContext);
    const classes = useFieldStyles();
    const isRequired = required == undefined ? true : (required.toString().toLowerCase() !== 'false')
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentOption, setCurrentOption] = useState({});

    const settingsChange = (event:any, newValue:any) => {
      handleSettingsChangeEvent ? handleSettingsChangeEvent(event) : null
      handleSettingsChange(propertyName, newValue ? newValue.id : null)
    }

    /*
     * Refresh the access_token used to GET Google Analytics Views. 
     */
    const refreshAccessToken = async (refreshToken:any) => {
      const exchangeBody = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }

      await catalogAPI
          .post(encodeURI(`/tokens/oauth2-google/token`), exchangeBody, { errorHandler: false })
          .then((resp: any) => {
            const data = resp.data;
            properties[prefix ? `${prefix}.oauth_credentials.access_token` : 'oauth_credentials.access_token'] = data.access_token
            handleSettingsUpdate(properties)
          })
          .catch((e: any) => {
            logError(e);
          });
    }

    useEffect(() => {
      let active = true;

      if (!accessToken)
        return

      (async () => {
        const url = 'https://www.googleapis.com/analytics/v3/management/accounts/~all/webproperties/~all/profiles'
        axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(resp => {
          const profiles = resp.data
          if (active && profiles && profiles.items) {
            setOptions(profiles.items);
            setIsLoading(false)
          }
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            refreshAccessToken(refreshToken)
          } else {
            logError(error)
            setIsLoading(false)
          }
        })

      })();

      return () => {
        active = false;
      };
    }, [isLoading, accessToken]);

    useEffect(() => {
      const selectedOption = options.find((o:any) => o.id == currentValue)
      setCurrentOption(selectedOption || {})
    }, [isLoading, options, currentValue]);

    const input = (
      <Autocomplete
        data-testid="tap-google-analytics.view_id"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={settingsChange}
        getOptionSelected={(option:any, value:any) => option.id === value.id}
        getOptionLabel={(option:any) => option.name ? `(${option.webPropertyId}) ${option.name}` : ''}
        options={isLoading ? [] : [{}, ...options]}
        loading={isLoading}
        ListboxProps={{ 'data-testid': 'tap-google-analytics.views' }}
        value={currentOption}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error?.defaultMessage || ''}
            label={label}
            name={propertyName}
            variant="outlined"
            required={isRequired}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )

    return <div className={classes.root}>{input}</div>;
};

export default GoogleAnalyticsViewsField;

import { AxiosInstance } from 'axios'
import * as React from 'react'
import { Toast } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import reactStringReplace from 'react-string-replace'
import Dataset from 'src/components/Dataset'
import Workbench from 'src/components/Workbench'
import Back from 'src/components/Back';
import datasetPlaceholder from '../components/placeholders/DatasetPlaceholder';
import DatasetAssistant from '../components/DatasetAssistant'
import { logError } from 'src/utils/Error'
// -----------------------------------------------------
import {
  APIContext,
  createDatasetCommentURI,
  deleteDatasetCommentURI,
  deleteDatasetURI,
  deleteLikeCommentURI,
  deleteLikeDatasetURI,
  getDatasetURI,
  intializeCommentReplyURI,
  intializeDatasetCommentURI,
  recordLikeCommentURI,
  recordLikeDatasetURI,
  recordViewDatasetURI,
  subscribeDatasetURI,
  updateDatasetDescriptionURI,
  viewAllCommentReplyURI,
  viewAllDatasetCommentURI
} from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { IDatasetOverviewProps, IDatasetOverviewState } from '../models/views/IDatasetOverviewView'
import { bindContexts } from '../utils/bindContexts'
// -----------------------------------------------------
import './../styles.css'

class DatasetOverview extends React.Component<IDatasetOverviewProps, IDatasetOverviewState> {
    public isThisMounted = false;
    public prev = 0;
    static contextType = MergedContexts;
    constructor (props: IDatasetOverviewProps) {
      super(props)
      this.state = {
        ready: false,
        currentDataset: null,
        likeLoading: false,
        subscribeLoading: false,
        addCommentLoading: false,
        commentLoading: false,
        comment: '',
        commentData: [],
        addReplyLoading: false,
        commentLikeLoading: false,
        nextPageUrl: '',
        showBackRow: true,
        message: '',
        deleteLoading: false
      }
      this.handleDatasetView = this.handleDatasetView.bind(this)
      this.handleRecordDatasetLike = this.handleRecordDatasetLike.bind(this)
      this.handleDeleteDatasetLike = this.handleDeleteDatasetLike.bind(this)
      this.handleAddDatasetComment = this.handleAddDatasetComment.bind(this)
      this.handleAddCommentReply = this.handleAddCommentReply.bind(this)
      this.handleRecordCommentLike = this.handleRecordCommentLike.bind(this)
      this.handleDeleteCommentLike = this.handleDeleteCommentLike.bind(this)
      this.hideCommentsReply = this.hideCommentsReply.bind(this)
      this.getAllCommentsReply = this.getAllCommentsReply.bind(this)
      this.handleReplyChanges = this.handleReplyChanges.bind(this)
      this.loadMore = this.loadMore.bind(this)
      this.getCurrentDataset = this.getCurrentDataset.bind(this)

      this.handleUpdateDatasetComment = this.handleUpdateDatasetComment.bind(this)
      this.handleDeleteComment = this.handleDeleteComment.bind(this)
      this.handleUpdateCommentReply = this.handleUpdateCommentReply.bind(this)
      this.handleDeleteDataset = this.handleDeleteDataset.bind(this)
      this.handleUpdateDatasetDescription = this.handleUpdateDatasetDescription.bind(this)
      this.handleSubscribeDataset = this.handleSubscribeDataset.bind(this)
      this.handleDeleteSubscribeDataset = this.handleDeleteSubscribeDataset.bind(this)
    }

    public componentDidMount () {
      this.isThisMounted = true
      const { currentDataset } = this.context
      if (currentDataset) {
        if (this.isThisMounted) {
          this.props.setViewName && currentDataset && this.props.setViewName(currentDataset.title)
          this.props.setDatasetViewName && this.props.setDatasetViewName()
          this.setState({ currentDataset, ready: true })
        }
      } else {
        // loads dataset from url
        this.getCurrentDataset()
      }
      window.addEventListener('scroll', this.loadMore, false)
    }

    public componentDidUpdate (prevProps: IDatasetOverviewProps, prevState: IDatasetOverviewState) {
      if (prevState.currentDataset !== this.state.currentDataset) {
        this.props.setViewName && this.state.currentDataset && this.props.setViewName(this.state.currentDataset.title)
      }
      this.prev = window.scrollY
      const { searchResults } = this.context
      const { datasets } = searchResults
      if (prevProps.location.key !== this.props.location.key) {
        if (datasets && datasets.length > 0) {
          const datasetIdOrAlias = this.props.match.params.datasetIdOrAlias
          const dataset = datasets.find((d: any) => d.alias === datasetIdOrAlias)
          if (dataset) {
            this.setState({ currentDataset: dataset, commentData: [] })
            this.recordViewDataset(dataset)
            this.getAllDatasetComments('', dataset.id)
          } else {
            // not in current datasets, try to load from catalog
            this.getCurrentDataset()
          }
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }, 200)
        } else {
          this.getCurrentDataset()
        }
      }
    }

    componentWillUnmount () {
      this.isThisMounted = false
      if (this.state.currentDataset && this.state.currentDataset.description) {
        this.handleEditDescription(false)
      }
      window.removeEventListener('scroll', this.loadMore, false)
    }

    private loadMore (e: any) {
      const targetWindow = e.currentTarget
      // load more  data on scroll bottom

      if (document) {
        const scrollTop = window.innerHeight + document.documentElement.scrollTop + 1
        const scrollHeight =
                document &&
                document !== null &&
                document.documentElement &&
                document.documentElement !== null
                  ? document.documentElement.scrollHeight
                  : 0

        if (scrollTop < scrollHeight) {
          if (targetWindow.scrollY >= 1) {
            if (this.prev > targetWindow.scrollY) {
              if (this.isThisMounted && !this.state.showBackRow) {
                this.setState({ showBackRow: true })
              }
            } else if (this.prev < targetWindow.scrollY) {
              if (this.isThisMounted && this.state.showBackRow) {
                this.setState({ showBackRow: false })
              }
            }
          } else {
            if (this.isThisMounted && !this.state.showBackRow) {
              this.setState({ showBackRow: true })
            }
          }
        }

        if (scrollTop === scrollHeight) {
          if (this.state.nextPageUrl && !this.state.commentLoading) {
            this.getAllDatasetComments(this.state.nextPageUrl)
          }
        }
      }
    }

    private async getCurrentDataset () {
      const { catalogAPI } = this.context
      this.setState({ currentDataset: null, ready: false })

      await catalogAPI
        .get(
          getDatasetURI(
            this.props.match.params.workspaceId,
            this.props.match.params.datasetIdOrAlias
          ),
          { errorHandler: false }
        )
        .then((resp: any) => {
          if (this.isThisMounted) {
            if (resp.data && this.isThisMounted) {
              const dataset = resp.data
              this.setState({ currentDataset: dataset, ready: true })
              this.recordViewDataset(dataset)
              this.getAllDatasetComments('', dataset.id)
            } else {
              this.setState({ currentDataset: null, ready: true })
            }
          }
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }, 200)
        })
        .catch((error: any) => {
          // we might have search results or user link to deleted dataset
          if (this.isThisMounted && error.response && error.response.status === 404) {
            // not found
            this.setState({ currentDataset: null, ready: true })
          }
        })
    }

    private async recordViewDataset (dataset: any) {
      const { catalogAPI } = this.context
      catalogAPI
        .put(recordViewDatasetURI(dataset.id), null, { errorHandler: false })
        .then(() => {
          dataset.viewCount = dataset.viewCount + 1
          if (this.isThisMounted) {
            this.setState({ currentDataset: dataset })
          }
        })
        .catch((error:any) => {
          debugger
          if (this.isThisMounted && error.response && error.response.status !== 404) {
            logError(error)
          }
        })
    }

    private async handleRecordDatasetLike () {
      this.setState({ likeLoading: true })
      const { catalogAPI, user } = this.context
      const api = catalogAPI as AxiosInstance
      await api
        .put(recordLikeDatasetURI(this.state.currentDataset.id))
        .then(() => {
          const dataset = this.state.currentDataset
          dataset.likeCount = dataset.likeCount + 1
          dataset.likedByProfiles.push({ id: user.sub, email: user.email })
          this.setState({ currentDataset: dataset })
        })
        .catch(e => {
          logError(e)
        })
      this.setState({ likeLoading: false })
    }

    private async handleDeleteDatasetLike () {
      this.setState({ likeLoading: true })
      const { catalogAPI, user } = this.context
      const api = catalogAPI as AxiosInstance
      await api
        .delete(deleteLikeDatasetURI(this.state.currentDataset.id))
        .then(() => {
          const dataset = this.state.currentDataset
          dataset.likeCount = dataset.likeCount - 1
          dataset.likedByProfiles = dataset.likedByProfiles.filter(
            (p: any) => p.id !== user.sub
          )
          this.setState({ currentDataset: dataset })
        })
        .catch(e => {
          logError(e)
        })
      this.setState({ likeLoading: false })
    }

    private async handleAddDatasetComment () {
      this.setState({ addCommentLoading: true })
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      const commentBody = {
        message: this.state.comment,
        datasetId: this.state.currentDataset.id
      }
      await api
        .post(intializeDatasetCommentURI(this.state.currentDataset.id))
        .then(async newResponse => {
          if (newResponse.data.id) {
            await api
              .put(
                createDatasetCommentURI(
                  this.state.currentDataset.id,
                  newResponse.data.id
                ),
                commentBody
              )
              .then(resp => {
                this.setState({ comment: '' })
                const comment = this.state.commentData
                comment.unshift(resp.data)
                this.setState({ commentData: comment })
              })
              .catch(e => {
                logError(e)
              })
          }
        })
        .catch(e => {
          logError(e)
        })
      this.setState({ addCommentLoading: false })
    }

    private async handleUpdateDatasetComment (comment: string, index: number) {
      const commentData = this.state.commentData
      commentData[index].loading = true
      this.setState({ commentData })
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      const commentBody = { message: comment, datasetId: this.state.currentDataset.id }
      await api
        .put(
          createDatasetCommentURI(this.state.currentDataset.id, commentData[index].id),
          commentBody
        )
        .then(() => {
          commentData[index].message = comment
          commentData[index].loading = false
          commentData[index].isEdit = false
          this.setState({ commentData })
        })
        .catch(e => {
          logError(e)
        })
    }

    private async handleUpdateCommentReply (index: number, replyIndex: number) {
      const commentData = this.state.commentData
      commentData[index].reply[replyIndex].loading = true
      this.setState({ commentData })
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      const commentBody = {
        message: commentData[index].reply[replyIndex].replyEditMessage,
        datasetId: this.state.currentDataset.id
      }
      await api
        .put(
          createDatasetCommentURI(
            this.state.currentDataset.id,
            commentData[index].reply[replyIndex].id
          ),
          commentBody
        )
        .then(() => {
          commentData[index].reply[replyIndex].message =
                    commentData[index].reply[replyIndex].replyEditMessage
          commentData[index].reply[replyIndex].loading = false
          commentData[index].reply[replyIndex].isEdit = false
          this.setState({ commentData })
        })
        .catch(e => {
          logError(e)
        })
    }

    private async handleDeleteComment (index: number, replyIndex: number) {
      const commentData = this.state.commentData
      let id = commentData[index].id
      if (replyIndex !== null) {
        commentData[index].reply[replyIndex].loadingDelete = true
        id = commentData[index].reply[replyIndex].id
      } else {
        commentData[index].loadingDelete = true
      }
      this.setState({ commentData })
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance

      await api
        .delete(deleteDatasetCommentURI(id))
        .then(() => {
          if (replyIndex !== null) {
            commentData[index].reply.splice(replyIndex, 1)
            commentData[index].commentCount = commentData[index].commentCount - 1
          } else {
            commentData.splice(index, 1)
          }
          this.setState({ commentData })
        })
        .catch(e => {
          logError(e)
        })
      this.setState({ addCommentLoading: false })
    }

    private async handleAddCommentReply (message: string, commentId: number, index: number) {
      this.setState({ addReplyLoading: true })
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      const comment = this.state.commentData
      const commentBody = {
        message,
        datasetId: this.state.currentDataset.id,
        parentId: commentId
      }
      await api
        .post(intializeCommentReplyURI(commentId))
        .then(async newResponse => {
          if (newResponse.data.id) {
            await api
              .put(
                createDatasetCommentURI(
                  this.state.currentDataset.id,
                  newResponse.data.id
                ),
                commentBody
              )
              .then(resp => {
                if (!comment[index].reply) {
                  comment[index].reply = []
                }
                comment[index].reply.unshift(resp.data)
                comment[index].reply_message = ''
                comment[index].commentCount = comment[index].commentCount + 1
                this.setState({ commentData: comment })
                this.getAllCommentsReply(index)
              })
              .catch(e => {
                logError(e)
              })
          }
        })
        .catch(e => {
          logError(e)
        })
      this.setState({ addReplyLoading: false })
    }

    private async handleRecordCommentLike (index: number, replyIndex: number) {
      const { catalogAPI, user } = this.context
      const api = catalogAPI as AxiosInstance
      const comment = this.state.commentData
      let commentId = comment[index].id
      if (replyIndex || replyIndex === 0) {
        commentId = comment[index].reply[replyIndex].id
        comment[index].reply[replyIndex].likeLoading = true
        this.setState({ commentData: comment })
      } else {
        this.setState({ commentLikeLoading: true })
      }
      await api
        .put(recordLikeCommentURI(commentId))
        .then(() => {
          if (replyIndex || replyIndex === 0) {
            comment[index].reply[replyIndex].likeCount =
                        comment[index].reply[replyIndex].likeCount + 1
            comment[index].reply[replyIndex].likedByProfiles.push({
              id: user.sub,
              email: user.email
            })
            comment[index].reply[replyIndex].likeLoading = false
            this.setState({ commentData: comment })
          } else {
            comment[index].likeCount = comment[index].likeCount + 1
            comment[index].likedByProfiles.push({ id: user.sub, email: user.email })
            this.setState({ commentData: comment, commentLikeLoading: false })
          }
        })
        .catch(e => {
          logError(e)
        })
    }

    private async handleDeleteCommentLike (index: number, replyIndex: number) {
      const { catalogAPI, user } = this.context
      const api = catalogAPI as AxiosInstance
      const comment = this.state.commentData
      let commentId = comment[index].id
      if (replyIndex || replyIndex === 0) {
        commentId = comment[index].reply[replyIndex].id
        comment[index].reply[replyIndex].likeLoading = true
        this.setState({ commentData: comment })
      } else {
        this.setState({ commentLikeLoading: true })
      }

      await api
        .delete(deleteLikeCommentURI(commentId))
        .then(() => {
          if (replyIndex || replyIndex === 0) {
            comment[index].reply[replyIndex].likeCount =
                        comment[index].reply[replyIndex].likeCount - 1
            comment[index].reply[replyIndex].likedByProfiles = comment[index].reply[
              replyIndex
            ].likedByProfiles.filter((p: any) => p.id !== user.sub)
            comment[index].reply[replyIndex].likeLoading = false
            this.setState({ commentData: comment })
          } else {
            comment[index].likeCount = comment[index].likeCount - 1
            comment[index].likedByProfiles = comment[index].likedByProfiles.filter(
              (p: any) => p.id !== user.sub
            )
            this.setState({ commentData: comment, commentLikeLoading: false })
          }
        })
        .catch(e => {
          logError(e)
        })
    }

    private async getAllDatasetComments (pageUrl = '', datasetId = '') {
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance

      this.setState({ commentLoading: true })
      let url = viewAllDatasetCommentURI(datasetId)
      if (pageUrl) {
        url = encodeURI(pageUrl)
      }

      if (datasetId) {
        url = viewAllDatasetCommentURI(datasetId)
      }

      await api
        .get(url)
        .then(resp => {
          if (this.isThisMounted) {
            if (resp.data._embedded && resp.data._embedded.datasetComments) {
              let comment = this.state.commentData
              comment = comment.concat(resp.data._embedded.datasetComments)
              this.setState({ commentData: comment })
            }

            if (resp.data && resp.data._links && resp.data._links.next) {
              this.setState({ nextPageUrl: resp.data._links.next.href })
            } else {
              this.setState({ nextPageUrl: '' })
            }
          }
        })
        .catch(e => {
          logError(e)
        })

      if (this.isThisMounted) {
        this.setState({ commentLoading: false })
      }
    }

    private async getAllCommentsReply (index: number) {
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      const comment = this.state.commentData
      comment[index].replyLoading = true
      this.setState({ commentData: comment })
      comment[index].showReply = true
      if (!comment[index].reply || comment[index].reply.length < comment[index].commentCount) {
        await api
          .get(viewAllCommentReplyURI(this.state.currentDataset.id, comment[index].id))
          .then(resp => {
            if (resp.data) {
              comment[index].reply = resp.data._embedded.datasetComments
            }
          })
          .catch(e => {
            logError(e)
          })
      }
      comment[index].replyLoading = false
      this.setState({ commentData: comment })
    }

    private async hideCommentsReply (index: number) {
      const comment = this.state.commentData
      comment[index].showReply = false
      this.setState({ commentData: comment })
    }

    handleDatasetView (dataset: any) {
      const { workspace } = this.context
      this.props.history.push(
        '/l/' + workspace.id + '/dataset/' + (dataset.alias ? dataset.alias : dataset.id)
      )
    }

    private handleCommentChanges = (e: any) => {
      e.preventDefault()
      const value = e.target.value
      this.setState({ comment: value })
    };

    private handleReplyChanges = (e: any, index: number) => {
      e.preventDefault()
      const value = e.target.value
      const comment = this.state.commentData
      comment[index].reply_message = value
      this.setState({ commentData: comment })
    };

    private setCommentEdit = (type: boolean, index: number) => {
      const commentData = this.state.commentData
      commentData[index].isEdit = type
      this.setState({ commentData })
    };

    private setCommentReplyEdit = (type: boolean, index: number, replyIndex: number) => {
      const commentData = this.state.commentData
      commentData[index].reply[replyIndex].isEdit = type
      commentData[index].reply[replyIndex].replyEditMessage =
            commentData[index].reply[replyIndex].message
      this.setState({ commentData })
    };

    private handleReplyEditChanges = (e: any, index: number, replyIndex: number) => {
      e.preventDefault()
      const value = e.target.value
      const comment = this.state.commentData
      comment[index].reply[replyIndex].replyEditMessage = value
      this.setState({ commentData: comment })
    };

    private async handleDeleteDataset () {
      const currentDataset = this.state.currentDataset
      currentDataset.deleteLoading = true
      this.setState({ currentDataset })
      const { catalogAPI, removeDataset, searchResults, handleSearchResults } = this.context
      const api = catalogAPI as AxiosInstance
      await api
        .delete(deleteDatasetURI(this.state.currentDataset.id))
        .then(() => {
          removeDataset(searchResults, handleSearchResults, currentDataset)
          if (this.isThisMounted) {
            this.setState({ message: 'Dataset deleted successfully.' })
            setTimeout(() => {
              this.props.history.goBack()
            }, 2000)
          }
        })
        .catch(e => {
          logError(e)
        })

      currentDataset.deleteLoading = false

      if (this.isThisMounted) {
        this.setState({ currentDataset })
      }
    }

    private async handleUpdateDatasetDescription () {
      const currentDataset = this.state.currentDataset
      currentDataset.loadingSaveDescription = true
      this.setState({ currentDataset })
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      const datasetBody = { description: currentDataset.changedDescription }

      await api
        .patch(updateDatasetDescriptionURI(currentDataset.id), datasetBody)
        .then(() => {
          currentDataset.description = currentDataset.changedDescription
          currentDataset.loadingSaveDescription = false
          currentDataset.isEditDescription = false
          if (this.isThisMounted) {
            this.setState({ currentDataset })
          }
        })
        .catch(e => {
          logError(e)
        })

      currentDataset.description = currentDataset.changedDescription
      currentDataset.loadingSaveDescription = false
      currentDataset.isEditDescription = false

      if (this.isThisMounted) {
        this.setState({ currentDataset })
      }
    }

    private handleEditDescription = (type: boolean) => {
      const currentDataset = this.state.currentDataset
      currentDataset.changedDescription = currentDataset.description
      currentDataset.isEditDescription = type

      if (this.isThisMounted) {
        this.setState({ currentDataset })
      }
    };

    private handleChangeDescription = (value: any) => {
      const currentDataset = this.state.currentDataset
      currentDataset.changedDescription = value
      this.setState({ currentDataset })
    };

    private async handleSubscribeDataset (type: any) {
      const postData = { type: type }
      this.setState({ subscribeLoading: true })
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      await api
        .post(subscribeDatasetURI(this.state.currentDataset.id), postData)
        .then((res: any) => {
          const dataset = this.state.currentDataset
          dataset._links['delete subscription'] = res.data._links['delete subscription']
          this.setState({ currentDataset: dataset })
        })
        .catch(e => {
          logError(e)
        })
      this.setState({ subscribeLoading: false })
    }

    private async handleDeleteSubscribeDataset () {
      this.setState({ subscribeLoading: true })
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      await api
        .delete(this.state.currentDataset._links['delete subscription'].href)
        .then(() => {
          const dataset = this.state.currentDataset
          dataset._links['delete subscription'] = null
          this.setState({ currentDataset: dataset })
        })
        .catch(e => {
          logError(e)
        })
      this.setState({ subscribeLoading: false })
    }

    public render () {
      const { user, workspace } = this.context
      const { currentDataset, commentData } = this.state

      const datasetNotFound = <div className="dataset_page d-flex">Dataset not found. [{this.props.match.params.datasetIdOrAlias}]</div>

      let liked = false
      let datasetDisplay = null
      if (currentDataset) {
        if (currentDataset.likedByProfiles) {
          currentDataset.likedByProfiles.forEach((profile: any) => {
            if (profile.id === user.sub) {
              liked = true
            }
          })
        }

        currentDataset.title = reactStringReplace(
          currentDataset.title,
          /#(\S*)/g,
          (match: any, i: any) => (
                    <a href='#' onClick={(e:any) => {
                      e.preventDefault()
                      this.props.history.push({ pathname: '/l/' + workspace.id + '/explore', search: 'search="tag:' + match + '"'}) 
                    }} key={i}>
                        #{match}
                    </a>
          )
        )

        const loadMoreComments = () => this.getAllDatasetComments(this.state.nextPageUrl)
        datasetDisplay = (
                <div className="dataset_page" data-testid="resolved">
                    <Back show={this.state.showBackRow} />
                    <hr
                      className='position-absolute w-100 m-0'
                      style={{ left: 0 }}/>
                    { !!this.state.message && (<div className="pad_tb15 content-center">
                      <Toast show={!!this.state.message} delay={500}>
                          <Toast.Body id="workspace-settings.submit-msg">
                              {this.state.message}
                          </Toast.Body>
                      </Toast>
                    </div>)}
                    <div className="pad_tb15 datasetContent custom_container mobview_dataset clearfix">
                        <div className="pad_0 datasetContent-Dataset">
                            <div className="clearfix">
                                {currentDataset && (
                                    <Dataset
                                        dataset={currentDataset}
                                        handleRecordDatasetLike={this.handleRecordDatasetLike}
                                        handleDeleteDatasetLike={this.handleDeleteDatasetLike}
                                        likeLoading={this.state.likeLoading}
                                        liked={liked}
                                        handleDeleteDataset={this.handleDeleteDataset}
                                        handleUpdateDatasetDescription={
                                            this.handleUpdateDatasetDescription
                                        }
                                        handleEditDescription={this.handleEditDescription}
                                        handleChangeDescription={this.handleChangeDescription}
                                        handleSubscribeDataset={this.handleSubscribeDataset}
                                        handleDeleteSubscribeDataset={
                                            this.handleDeleteSubscribeDataset
                                        }
                                        subscribeLoading={this.state.subscribeLoading}
                                        workspace={workspace}
                                    />
                                )}
                            </div>
                        </div>
                        { (!workspace.appProperties 
                          || (workspace.appProperties && workspace.appProperties.DATASET_VIEW_TABS == undefined)
                          || (workspace.appProperties && workspace.appProperties.DATASET_VIEW_TABS.length > 0)) &&
                        <div className="pad_0 datasetContent-DatasetAssistant">
                            <DatasetAssistant 
                              handleDatasetView={this.handleDatasetView} 
                              currentDataset={this.state.currentDataset} 
                              commentData={commentData}
                              commentsLoading={this.state.commentLoading}
                              handleRecordCommentLike={this.handleRecordCommentLike}
                              handleDeleteCommentLike={this.handleDeleteCommentLike}
                              handleAddCommentReply={this.handleAddCommentReply}
                              getAllCommentsReply={this.getAllCommentsReply}
                              hideCommentsReply={this.hideCommentsReply}
                              commentLikeLoading={this.state.commentLikeLoading}
                              comment={this.state.comment}
                              addCommentLoading={this.state.addReplyLoading}
                              handleReplyChanges={this.handleReplyChanges}
                              afterCommentAnimatedIn={this.props.afterCommentAnimatedIn}
                              handleUpdateDatasetComment={this.handleUpdateDatasetComment}
                              setCommentEdit={this.setCommentEdit}
                              handleDeleteComment={this.handleDeleteComment}
                              setCommentReplyEdit={this.setCommentReplyEdit}
                              handleReplyEditChanges={this.handleReplyEditChanges}
                              handleUpdateCommentReply={this.handleUpdateCommentReply}
                              handleAddDatasetComment={this.handleAddDatasetComment}
                              handleCommentChanges={this.handleCommentChanges}
                              loadMoreComments={loadMoreComments}
                              moreCommentsUrl={this.state.nextPageUrl}
                            />
                        </div> }
                    </div>
                </div>
        )
      }

      return (
            <Workbench
                menu='app'
                isExpandedSidebarActive={this.props.isExpandedSidebarActive}
                isSidebarLayout={this.props.isSidebarLayout}
                noHeader={true}
                toggleSidebar={this.props.toggleSidebar}>
                {/* START for dataset page */}
                <div className="rightside_pageContent">
                    {!this.state.ready ? datasetPlaceholder : null}
                    {this.state.ready && this.state.currentDataset == null
                      ? datasetNotFound
                      : datasetDisplay}
                </div>
                {/* END for dataset page */}
            </Workbench>
      )
    }
}

export const DatasetOverviewWithContext = bindContexts(DatasetOverview, [
  Auth0Context,
  APIContext,
  WorkspaceContext
])
export default withRouter(DatasetOverviewWithContext)

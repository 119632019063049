import { ITimestampedEntity } from 'src/models/IEntity'
import IPipeline from 'src/models/IPipeline'
import IProfile from 'src/models/IProfile'

export enum JobType {
    PipelineConfig = 'PIPELINE_CONFIG',
    PipelineEnv = 'PIPELINE_ENV',
    PipelineRun = 'PIPELINE_RUN',
    PipelineVerify = 'PIPELINE_VERIFY',
    WorkspaceInit = 'WORKSPACE_INIT'
}

export enum JobStatus {
    Skipped = 'SKIPPED',
    Complete = 'COMPLETE',
    Error = 'ERROR',
    Queued = 'QUEUED',
    Running = 'RUNNING',
    Stopped = 'STOPPED'
}

export default interface IJob extends ITimestampedEntity {
    type: JobType
    triggeredBy?: string
    maxAttempts: number
    attempt: number
    exitCode?: number
    status: JobStatus
    commitId?: string
    startTime?: string
    endTime?: string
    _embedded: {
        pipeline?: IPipeline
        profile?: IProfile
    }
}

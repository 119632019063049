import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { AxiosResponse } from 'axios'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField } from '@material-ui/core'
import { APIContext } from 'src/contexts/api'
import { WorkspaceContext } from 'src/contexts/workspace'
import { logError } from 'src/utils/Error'
import Spinner from 'src/components/Spinner'
import { INewQuestionDialogProps } from 'src/models/components/INewQuestionDialog'
import IDataset from 'src/models/IDataset'

const NewQuestionDialog: FunctionComponent<INewQuestionDialogProps> = (props: PropsWithChildren<INewQuestionDialogProps>) => {

    const isMounted = useRef(true)

    const [isReady, setReady] = useState(false)
    const [question, setQuestion] = useState<string>({} as any)
    const [message, setMessage] = useState<string | null>(null)

    const { catalogAPI } = useContext(APIContext)
    const { workspace } = useContext(WorkspaceContext)

    const createQuestion = async () => {
        await catalogAPI.post(workspace._links['questions'].href, { question: question })
            .then((r: AxiosResponse<IDataset>)  => {
                if (isMounted.current) {
                    if (r.status == 204) {
                        // not information
                        setMessage("We found no information related to your request.")
                    } else {
                        // switch to dataset?
                        setQuestion(r.data.description)
                        props.handleAnswer(r.data)
                    }
                    setReady(true)
                }
            })
            .catch(logError)
    }

    const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion( e.target.value )
    }

    const handleCreate = () => {
        setReady(false)
        createQuestion()
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') return
        const target = (e.target as HTMLInputElement)
        target.blur()
        if (target.value) handleCreate()
    }

    useEffect(() => {
        setReady(true)
        return () => { isMounted.current = false }
    }, [])

    useEffect(() => {
        setMessage(null)
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth="md"
            onClose={props.handleClose}>
            <DialogTitle>AI-Powered Research Assistant</DialogTitle>
            <DialogContent>
                { message && <div>{message}</div> }
                <TextField
                    id='new-question'
                    fullWidth
                    autoFocus
                    required
                    variant="outlined"
                    margin="dense"
                    label="Ask a question"
                    placeholder="Ask a question, or describe what you need to know."
                    onKeyPress={handleKeyPress}
                    onChange={handleQuestionChange}
                    multiline
                    minRows={4}
                />
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={!isReady}
                        onClick={handleCreate}>
                        <Spinner active={!isReady}>
                            Ask
                        </Spinner>
                    </Button>
                    <Button
                        onClick={props.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog >
    )

}

export default NewQuestionDialog

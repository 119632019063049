import * as React from 'react'
import { Link } from 'react-router-dom'

/*
 * This method processes the markdown to improve display.
 * 1. Strip new lines from <style> html blocks.
 *    Why?  It seems the ReactMarkdown library thinks a new line in the html
 *    is a markdown new block and turns the rest of that block into code.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function processMarkdown (markdown: string) {
  if (markdown) {
    let trimmedHtmlMarkdown = markdown
    const STYLE_BLOCK = /<style\b[^>]*>([\s\S]*?)<\/style>/gm
    while (true) {
      const match = STYLE_BLOCK.exec(trimmedHtmlMarkdown)
      if (!match) break
      // remove blank lines in style block
      const trimmedBlock = match[0].replace(/^\s*[\r\n]/gm, '')
      trimmedHtmlMarkdown = trimmedHtmlMarkdown.replace(match[0], trimmedBlock)
    }

    return trimmedHtmlMarkdown
  }
  return markdown
}

// add the links to workspace tag search
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function processMarkdownWithTags(markdown: string | undefined, workspace: any) {
  if (!markdown)
    return;

  markdown = processMarkdown(markdown)
  const TAG = /#([^\s#]+)/gm

  return markdown.replace(TAG, (tag, tagText : string) => `[${tag}](/l/${workspace.id}/explore?search="tag:${tagText}")`);
}

const imageRenderer = ({ alt, src, title }: { alt?: string; src?: string; title?: string }) : any => (
  <img alt={alt} src={src} title={title} style={{ maxWidth: '100%' }} />
)

const canvasRenderer = ({ id }: { id?: string }) : any => <canvas id={id} style={{ maxWidth: '100%' }} />

const linkRenderer = ({ href, title, children }: { href?: string; title?: string; children?: any }): any => {
  if (!href)
    return "Link error: expected `href`";

  try {
    const url = new URL(href);
    return <a href={url.toString()} title={title}>{children}</a>;
  } catch {
    return <Link to={href} onClick={e => e.stopPropagation()}>{children}</Link>;
  }
}

const tableRenderer = (props: React.TableHTMLAttributes<HTMLTableElement>): JSX.Element => (
  <div style={{ overflowX: 'auto' }}>
    <table {...props} />
  </div>
)

export const renderers = {
  image: imageRenderer,
  canvas: canvasRenderer,
  link: linkRenderer,
  table: tableRenderer,
}

// escapes regex for special characters: \ => \\, $ => \$
export function escapeRegExp(string:string) : string {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function highlight(textToHighlight:string, searchWords:string[], autoEscape:boolean) : any {
  const modSearchWords = searchWords
    .map((word:string) => (autoEscape ? escapeRegExp(word) : word))
    .filter((word:string) => word)
  const stringToReplace = modSearchWords.join('|')
  return (
    <span
      className="highlight"
      dangerouslySetInnerHTML={{
        __html: textToHighlight.replace(
            new RegExp(stringToReplace, 'ig'),
            (matched:any) => `<mark class="highlight">${matched}</mark>`,
          ),
        }}
    />
  )
}

/*
 * Function that returns a new textRenderer with keywords to be highlighted
 * const textRenderer = ({ value }: { value?: string; }) : any => {
 *   return value ? highlight(value, ["keyword"], false) : null
 * }
 */
export function keywordHighligherTextRenderer( keywords: string[]) {
  return ({ value }: { value?: string; }) : any => {
    return value ? highlight(value, keywords, false) : null
  }
}

import IDataComponent from 'src/models/IDataComponent'
import { INamedEntity, ITimestampedEntity } from 'src/models/IEntity'
import IJob from 'src/models/IJob'

export enum PipelineStatus {
    Draft = 'DRAFT',
    Failed = 'FAILED',
    Provisioning = 'PROVISIONING',
    Ready = 'READY'
}

interface IPipelineEmbedded {
    dataComponents: IDataComponent[]
    'latest job'?: IJob
}

export default interface IPipeline extends INamedEntity, ITimestampedEntity {
    status: PipelineStatus
    schedule?: string
    timeout: number
    maxRetries: number
    script?: string
    actions: string[]
    dataComponents: string[]
    properties: { [property: string]: string }
    triggeredBy: string[]
    _embedded?: IPipelineEmbedded
}

export interface IPipelineEnvironment {
    [key: string]: {
        value: string
        sensitive: boolean
    }
}

import React, { FunctionComponent, PropsWithChildren } from 'react';
import history from 'src/utils/history'
import leftAngleGrey from './../assets/images/left_angle_grey.png'
import ScrollAnimation from 'src/utils/ScrollAnimation'

//
// The back row - back to workspace or browser back.
//

const Back: FunctionComponent<any> = (props: PropsWithChildren<any>) => {
    const { workspace, show } = props;

    const handleBackToWorkspace= () => {
        // route user to workspace home
        workspace ? history.push('/l/' + workspace.id + '/feed') : history.goBack();
    }


    return (
        <div
            id="back"
            className={
                'backbar cursor-pointer custom_container clearfix'
                + (!!show ? ' show' : ' hide')
            }
            onClick={handleBackToWorkspace}
        >
            <ScrollAnimation animateIn="fadeIn" offset={10}>
                <img src={leftAngleGrey} alt="Back" /> <span>{workspace ? "Back to workspace" : "Back"}</span>
            </ScrollAnimation>
        </div>
    );
};

export default Back;

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Chart } from 'react-google-charts'
import Metadata from 'src/components/charts/metadata/Metadata'
import { useAPIContext } from 'src/contexts/api'
import { GoogleChartProps } from 'src/models/charts/google/GoogleChart'
import { parseJsonSwallowError } from 'src/utils/charts/chartUtils'
import '../../../assets/css/charts/GoogleChart.css'

export const updateData = (
  props: GoogleChartProps,
  results: Record<string, unknown>[],
  componentIsMounted: React.MutableRefObject<boolean>,
  setData: React.Dispatch<React.SetStateAction<any>>
) : void => {
  let dataArray: any = []
  if (results && results.length > 0) {
    if (props.metadata) {
      const metadataObj = new Metadata(props.metadata)
      const columns: string[] = []
      const rows: any[] = []
      Object.keys(results).forEach(i => {
        const row: any[] = []
        Object.keys(results[i]).forEach(key => {
          if (!columns.includes(key)) {
            columns.push(key)
          }
          row.push(results[i][key])
        })
        rows.push(row)
      })
      const columnsWithLabels: string[] = []
      columns.forEach(columnKey => {
        columnsWithLabels.push(metadataObj.getColumn(columnKey)?.label || columnKey)
      })
      dataArray[0] = columnsWithLabels
      dataArray = dataArray.concat(rows)
    }
  }
  if (props.dataCallback) {
    props.dataCallback(dataArray)
  }
  if (!componentIsMounted.current) return
  setData(dataArray)
}

const GoogleChart: FunctionComponent<GoogleChartProps> = props => {
  const { catalogAPI } = useAPIContext()
  const [data, setData] = useState()

  const {
    getData,
    rawData,
    metadata,
  } = props

  // ensure component is still mounted before updating chart
  const componentIsMounted = useRef<boolean>(true)

  useEffect(() => {
    componentIsMounted.current = true
    if (props.rawData) {
      updateData(props, parseJsonSwallowError(props.rawData), componentIsMounted, setData)
    } else {
      getData?.().then(data => updateData(props, data, componentIsMounted, setData));
    }

    // return a cleanup function
    return () => {
      componentIsMounted.current = false
    }
  }, [catalogAPI, rawData, metadata])

  return data
    ? <div data-testid="google-chart" className="google-chart">
      <Chart
          chartType={props.chartType}
          options={props.options}
          data={data}
          height="100%"
      />
    </div>
    : null
}

export default GoogleChart

import { createStyles, makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles(() =>
    createStyles({
        largeIcon: {
            width: 30,
            height: 30,
        },
        button: {
            padding: '6px',
            '&:focus': {
                border: 0,
            }
        },
        dropdownMenu: {
            padding: '6px 0',
            minWidth: '100px',
            '-webkit-box-shadow': '0px 2px 7px 2px rgba(0, 0, 0, 0.15)',
            '-moz-box-shadow': '0px 2px 7px 2px rgba(0, 0, 0, 0.15)',
            boxShadow: '0px 2px 7px 2px rgba(0, 0, 0, 0.15)',
        },
        dropdownItem: {
            '& i': {
                color: 'rgba(0, 0, 0, 0.54)',
                minWidth: 32,
            },
            '& span': {
                color: '#000',
                fontWeight: 300,
                fontSize: '12px',
                lineHeight: '14px',
                letterSpacing: '0.5px',
                height: 'auto',
                width: 'auto',
                background: 'none',
            },
        }
    })
);

export default useRowStyles

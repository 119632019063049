import { AxiosInstance, AxiosResponse } from 'axios';
import { Tab, Tabs } from '@material-ui/core'
import React, { useContext, useEffect, useState, FunctionComponent, PropsWithChildren } from 'react'
import { APIContext } from '../contexts/api';
import { WorkspaceContext } from '../contexts/workspace';
import { Auth0Context } from '../contexts/auth0'
import RelatedDatasetCard from '../components/RelatedDatasetCard'
import Comment from 'src/components/Comment'
import IAlert from 'src/models/IAlert'
import IPage from 'src/models/IPage'
import Moment from 'react-moment'
import Spinner from 'react-bootstrap/Spinner'
import ReactMarkdown from 'react-markdown'
import * as markdownUtils from 'src/utils/markdownUtils'
import ScrollAnimation from 'src/utils/ScrollAnimation'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import '@webscopeio/react-textarea-autocomplete/style.css'
import { IDatasetAssistantProps } from 'src/models/components/IDatasetAssistant';
import { logError } from 'src/utils/Error';

enum Options {
  Discussion = "Discussion",
  Alerts = "Alerts",
  Related = "Related",
}

const DatasetAssistant: FunctionComponent<IDatasetAssistantProps> = (props: PropsWithChildren<IDatasetAssistantProps>) => {

    const { workspace, searchResults, workspaceMembers } = useContext(WorkspaceContext);
    const { user } = useContext(Auth0Context);
    const { catalogAPI } = useContext(APIContext);
    const api = catalogAPI as AxiosInstance;
    const datasetViewTabOrderProperty: string | undefined = workspace.appProperties?.DATASET_VIEW_TABS
    let datasetViewTabOrder = datasetViewTabOrderProperty?.toLowerCase().split(",").slice(0, 3).map(s => {
      if (!s) return s
      const tabName = s.trim()
      return Options[tabName[0].toUpperCase() + tabName.substring(1)]
    }).filter(s => !!s)

    datasetViewTabOrder = datasetViewTabOrder?.length ? Array.from(new Set(datasetViewTabOrder)) : Object.values(Options)

    const [activeTab, setActiveTab] = useState(0)
    const [alertsLoading, setAlertsLoading] = useState(false)
    const [alertList, setAlertList] = useState<IAlert[]>()

    const COMMENTS_TAB = datasetViewTabOrder.indexOf(Options.Discussion)
    const ALERTS_TAB = datasetViewTabOrder.indexOf(Options.Alerts)
    const RELATED_TAB = datasetViewTabOrder.indexOf(Options.Related)



    const getTabTxt = (tabKey: string) => {
      if (tabKey == Options.Alerts) {
        return workspace.appProperties && workspace.appProperties.DATASET_ASSISTANT_TAB_ALERTS_ALT_TEXT ? 
          workspace.appProperties.DATASET_ASSISTANT_TAB_ALERTS_ALT_TEXT : Options[Options.Alerts]
      }
      return Options[tabKey]
    }
    
    const onTabChange = (event: React.ChangeEvent<unknown>, tab: number) => setActiveTab(tab)

    const getAlerts = async () => {
      if (props.currentDataset._links && props.currentDataset._links.alerts) {
        setAlertsLoading(true)
        await api.get(props.currentDataset._links.alerts.href)
            .then((response: AxiosResponse<IPage<IAlert>>) => {
              const page = response.data
              setAlertList(page._embedded?.alerts)
              setAlertsLoading(false)
            })
            .catch(logError)
            .finally(() => setAlertsLoading(false))
      }
    }

    useEffect(() => {
      activeTab == ALERTS_TAB && !alertList && !alertsLoading && getAlerts()
    }, [activeTab])

    //
    // Discussion
    //
    const loadingMessage = () => <span>Loading</span>
    const commentOnChange = (e: any) => {
      props.handleCommentChanges(e)
    }

    const canComment = props.currentDataset._links['new comment']
    const memberAutoComplete = (
          <div className="container">
              <ReactTextareaAutocomplete
                  className="form-control d-block mt-0 disable_resize pad_tb8"
                  data-testid="comment-input"
                  placeholder={canComment ? "Start a discussion…" : workspace.appProperties?.MEMBER_COMMENTS_READ_ONLY_MESSAGE || "Comments are disabled for this workspace"}
                  disabled={!canComment}
                  value={props.comment}
                  onChange={commentOnChange}
                  loadingComponent={loadingMessage}
                  trigger={{
                    '@': {
                      dataProvider: (token: any) => {
                        let members = []
                        members = workspaceMembers.filter((item: any) => {
                          return (
                            item.name.toLowerCase().search(token.toLowerCase()) !== -1
                          )
                        })
                        return members
                      },
                      component: (items: any) => {
                        return <div>{items.entity.name}</div>
                      },
                      output: (item: any) => item.handle
                    }
                  }}
              />
          </div>
    )
    
    let viewComments = null
    const rows: any[] = []
    if (props.commentData && props.commentData.length > 0) {
      props.commentData.forEach((comment: any, index: any) => {
        let commentLiked = false
        if (comment.likedByProfiles) {
          comment.likedByProfiles.forEach((profile: any) => {
            if (profile.id === user.sub) {
              commentLiked = true
            }
          })
        }
        rows.push(<React.Fragment key={'frag' + index}>
          <Comment
            handleRecordCommentLike={props.handleRecordCommentLike}
            handleDeleteCommentLike={props.handleDeleteCommentLike}
            handleAddCommentReply={props.handleAddCommentReply}
            getAllCommentsReply={props.getAllCommentsReply}
            hideCommentsReply={props.hideCommentsReply}
            likeLoading={props.commentLikeLoading}
            liked={commentLiked}
            addCommentLoading={props.addCommentLoading}
            comment={comment}
            readOnly={!canComment}
            key={index}
            index={index}
            handleReplyChanges={props.handleReplyChanges}
            afterAnimatedIn={props.afterCommentAnimatedIn}
            handleUpdateDatasetComment={props.handleUpdateDatasetComment}
            setCommentEdit={props.setCommentEdit}
            handleDeleteComment={props.handleDeleteComment}
            setCommentReplyEdit={props.setCommentReplyEdit}
            handleReplyEditChanges={props.handleReplyEditChanges}
            handleUpdateCommentReply={props.handleUpdateCommentReply}
          />
          <hr key={'hr' + index} className='m-0' />
        </React.Fragment>)
      })
    }

    viewComments =
          rows.length > 0
            ? rows
            : props.commentsLoading ? (
                <div className='pad_15'>Loading...</div>
              ) : (
                <div className='pad_15'></div>
              )

    //
    // Alerts
    //
    const viewAlerts =
      alertList && alertList.length > 0
        ? (<>
            {alertList.map(alert =>
                <div key={alert.id} className="alert pad_15">
                  <p className="raised"><Moment fromNow={true} ago={true} utc={true} local={true}>{alert.raised}</Moment>&nbsp;ago.</p>
                  <ReactMarkdown
                      className="detail"
                      renderers={markdownUtils.renderers}
                      escapeHtml={false}
                      source={markdownUtils.processMarkdownWithTags(alert.detail, workspace)}
                  />
                </div>)}
          </>)
        : alertsLoading ? (
            <div className='pad_15'>Loading...</div>
          ) : (
            <div className='pad_15'>{ workspace.appProperties && workspace.appProperties.DATASET_ASSISTANT_NO_ALERTS_ALT_TEXT ? 
                                        workspace.appProperties.DATASET_ASSISTANT_NO_ALERTS_ALT_TEXT : 'No alerts.' }</div>
          )


    //
    // Related
    //
    const { datasets } = searchResults
    let viewDatasets = null
    if (props.currentDataset && datasets && datasets.length > 0) {
      const renderRows: any[] = []
      datasets.forEach((dataset: any, index: any) => {
        if (props.currentDataset.id !== dataset.id) {
          renderRows.push(
                        <RelatedDatasetCard
                            dataset={dataset}
                            key={index}
                            handleDatasetView={props.handleDatasetView}
                        />
          )
        }
      })
      viewDatasets = renderRows.length > 0 ? renderRows : ''
    }

    return <>
        <div className='dataset-assistant w-100'>
            <Tabs
                indicatorColor='primary'
                variant='scrollable'
                value={activeTab}
                onChange={onTabChange}>
                {datasetViewTabOrder.map(k =>
                    <Tab
                        key={k}
                        label={getTabTxt(k)} />)}
            </Tabs>
            
            {activeTab === COMMENTS_TAB && 
              <div data-testid="comments-container" className="comments">
                {workspace.appProperties && workspace.appProperties.DISCUSSION_HELP_TEXT ?
                <div className='pad_15 markdown-content'>
                  <ReactMarkdown
                    renderers={markdownUtils.renderers}
                    source={ workspace.appProperties.DISCUSSION_HELP_TEXT }
                    escapeHtml={false}
                  />
                </div> : <></>}
                  <ScrollAnimation
                      afterAnimatedIn={props.afterCommentsAnimatedIn}
                      animateIn="fadeIn"
                      offset={10}
                      className="add_comment pad_15 m_top10 d-flex align-items-start"
                  >
                      <img src={user.picture} alt="person" />
                      <div className="form-group d-inline-block">
                          {memberAutoComplete}
                          {props.comment && (
                              <>
                                  {props.addCommentLoading
                                    ? (
                                      <div className="m_top10 d-inline-block float-right spinner_btnHolder">
                                          <Spinner animation="border" />
                                      </div>
                                      )
                                    : (
                                      <button
                                          type="button"
                                          className={
                                              'float-right btn btn-warning'
                                          }
                                          data-testid="add-comment"
                                          onClick={props.handleAddDatasetComment}
                                          disabled={props.addCommentLoading}
                                      >
                                          Comment
                                      </button>
                                      )}
                              </>
                          )}
                      </div>
                  </ScrollAnimation>
                  <hr className='m-0' />
                  {viewComments}
                  {(props.moreCommentsUrl || props.commentsLoading) && (
                      <div className={'loadMoreDatasetButton '}>
                          <button
                              type="button"
                              className={
                                  'float-center btn btn-warning' +
                                  (props.commentsLoading
                                    ? ' spinner-border'
                                    : '')
                              }
                              onClick={props.loadMoreComments}
                              disabled={props.commentsLoading}
                          >
                              {props.commentsLoading ? ' ' : ' Load More'}
                          </button>
                      </div>
                  )}
              </div>}
            {activeTab === ALERTS_TAB && 
              <div className='alerts'>
                {workspace.appProperties && workspace.appProperties.ALERTS_HELP_TEXT ?
                <div className='pad_15 markdown-content'>
                  <ReactMarkdown
                      renderers={markdownUtils.renderers}
                      escapeHtml={false}
                      source={ workspace.appProperties.ALERTS_HELP_TEXT }
                  />
                </div> : <></>}
                {viewAlerts}
              </div>}
            {activeTab === RELATED_TAB && 
              <div className='related'>
                {workspace.appProperties && workspace.appProperties.RELATED_HELP_TEXT ?
                <div className='pad_lr15 pad_bot15 markdown-content'>
                  <ReactMarkdown
                      renderers={markdownUtils.renderers}
                      escapeHtml={false}
                      source={ workspace.appProperties.RELATED_HELP_TEXT }
                  />
                </div> : <></>}
                {viewDatasets}
              </div>}
        </div>
    </>

}

export default DatasetAssistant

import React, { FunctionComponent, PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown'
import { IHelpPanelProps } from 'src/models/components/IHelpPanel';
import 'src/assets/css/components/helpPanel.css';
import Button from '@material-ui/core/Button';
import { useContext } from 'react';
import { WindowContext } from 'src/contexts/window';

//
// A generic component to display help text.
//

const HelpPanel: FunctionComponent<IHelpPanelProps> = (props: PropsWithChildren<IHelpPanelProps>) => {
    const { title, overview, dataPlugin, closeHelpPanel, rowView } = props;
    const { size } = useContext(WindowContext);

    const learnMoreDescription = () => {
      if (dataPlugin && dataPlugin.fullDescription && dataPlugin.docs) {
        const docsUrl = (() => {
          try {
            return new URL(dataPlugin.docs)
          } catch (e) {
            if (e instanceof TypeError)
              return undefined
            throw e
          }
        })()

        if (!docsUrl)
          return

        const allowedDomains = [
          "localhost",
          "www-localhost.matatika.com",
          "www-staging.matatika.com",
          "www.matatika.com",
        ]

        if (allowedDomains.some(domain => domain === docsUrl.hostname)) {
          const learnMoreHeading = "\n\n## Learn more\n\n"
          const docLink = `[Matatika ${dataPlugin.label || dataPlugin.name} Data Source Documentation](${dataPlugin.docs})`
          return dataPlugin.fullDescription + learnMoreHeading + docLink
        }
      }
      return
    }

    return (
      <div className={`${!size.smallScreen ? "help-container help-big-screen" : "help-container"} ${rowView ? 'help-big-screen-row': ''}`} >
        { size.smallScreen && !rowView &&
          <>
            <div className="close-help-panel">
              <h1>{title}</h1>
              <Button title="Close" style={{marginTop: '-0.5rem'}} onClick={closeHelpPanel}>Close</Button>
            </div>
            <hr />
          </>
        }
        {dataPlugin && dataPlugin.description ? (
        <>
          <h1>{title}</h1>
          <ReactMarkdown
              linkTarget="_blank"
              escapeHtml={true}
              source={learnMoreDescription() || dataPlugin.fullDescription || dataPlugin.description}
          />
        </>
      ) : overview}
      </div>
    );
};

export default HelpPanel;

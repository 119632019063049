import { Backdrop, CircularProgress } from '@material-ui/core'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import 'src/assets/css/components/Spinner.css'
import { ISpinnerProps } from 'src/models/components/ISpinner'

//
// A full page Spinner
//
const Spinner: FunctionComponent<ISpinnerProps> = (props: PropsWithChildren<ISpinnerProps>) => {

    const { active } = props

    const classes = [
        'spinner',
        ...props.active ? ['active'] : [],
    ]

    return <>
        <Backdrop
            open={!!active}
            invisible
            className={classes.join(' ')}>
            <CircularProgress data-testid={props['data-testid']} size='1em' />
        </Backdrop>
        <div>
            {props.children}
        </div>
    </>

}


export default Spinner
import * as assert from 'assert-plus'
import React, { useEffect, useRef, useState } from 'react'
import { logError } from 'src/utils/Error'
import {
  createUpdateProfileURI,
  getNotificationURI,
  getProfileURI,
  getUserInvitationsURI,
  getWorkspaceMembersURI,
  getWorkspaceURI,
  useAPIContext,
  getAllJobs
} from './api'
import { useAuth0 } from './auth0'

//
// Context for Workspace state
// The main purpose of this class is to hold app state specific details such
// as profile, workspace, selected items, and datasets.
//

const WorkspaceContext = React.createContext()
WorkspaceContext.displayName = 'WorkspaceContext'

function WorkspaceProvider ({ children }) {
  const [profile, setProfile] = useState()
  const [workspaces, setWorkspaces] = useState()
  // current workspace
  const [workspaceId, setWorkspaceId] = useState()
  const [workspace, setWorkspace] = useState()
  const [channel, setChannel] = useState({
    channelList: [],
    selectedChannel: null
  })
  const [tag, setTag] = useState({ tagList: null, selectedTag: null })
  const [currentDataset, setCurrentDataset] = useState()
  const [currentDatasetComment, setCurrentDatasetComment] = useState()
  const [workspaceMembers, setWorkspaceMembers] = useState()
  const [unreadNotifications, setUnreadNotifications] = useState('')
  const [workspaceJobs, setWorkspaceJobs] = useState()
  const [invitationsList, setInvitationsList] = useState()

  const [pipelines, setPipelines] = useState([])
  const [isLoadingPipelines, setLoadingPipelines] = useState(false)

  // current search for datasets
  const [searchResults, setSearchResults] = useState({
    isLoading: false,
    datasets: null,
    fetchtime: -1,
    hits: -1,
    nextPageUrl: null,
    isMoreLoading: false,
    query: ''
  })

  const isUnMounted = useRef()
  const checkNotifications = useRef(0)
  useEffect(() => {
    isUnMounted.current = false

    return () => {
      isUnMounted.current = true
    }
  }, [])

  const handleSearchResults = (datasets, fetchtime, hits, nextPageUrl, query) => {
    setSearchResults({
      datasets: datasets,
      fetchtime: fetchtime,
      hits: hits,
      nextPageUrl: nextPageUrl,
      isLoading: false,
      isMoreLoading: false,
      query: query
    })
  }

  const isOwner = () => profile && profile._links && profile._links['new workspace'] != null
  const isWorkspaceAdmin = () => workspace && workspace._links && workspace._links['update delete workspace'] != null

  const { catalogAPI, error } = useAPIContext()
  const { user, getTokenSilently, loading, isAuthenticated } = useAuth0()

  const updateWorkspace = updatedWorkspace => {
    if (workspaces) {
      if (!workspaces.find(element => element.id === updatedWorkspace?.id)) {
        setWorkspaces([...workspaces, updatedWorkspace])
      }
      setWorkspace(updatedWorkspace)
    }
  }

  const loadWorkspace = id => {
    // changing workspace id loads the new id as current workspace
    setWorkspace(null)
    setTag({tagList: [], selectedTag: null})
    setWorkspaceId(id)
  }

  const getAllWorkspaceJobs = async (id) => {
    await catalogAPI
      .get(getAllJobs(id))
      .then(r => setWorkspaceJobs(r.data._embedded?.jobs || []))
      .catch(logError)
  }

  const getDataComponents = async (type) => {
    if (workspace && type) {

      const params = new URLSearchParams({
        environment: workspace.defaultEnvironment,
        size: 99
      })

      const result = catalogAPI.get(`${workspace._links.datacomponents.href}?${params}`)
        .then(r => r.data._embedded?.datacomponents.filter(dc => [ ...type ? [type.toUpperCase()] : ['EXTRACTOR', 'LOADER']].includes(dc._embedded.dataplugin.pluginType)))
        .catch(logError)

      if (result)
        return result
    }
    return []
  }

  const getDataStores = async() => catalogAPI.get(workspace._links.datastores.href)
      .then(r => r.data._embedded?.datastores || [])
      .catch(logError)

  const getChannels = async (type, datasetId) =>
    catalogAPI.get(workspace._links['channels'].href + encodeURI('?sort=lastModified,desc') + encodeURI(type ? '&type='+type : '') + encodeURI(datasetId ? '&containsDataset='+datasetId : ''))
      .then(r => r.data)
      .catch(logError)

  const initialiseChannel = async () =>
    catalogAPI.post(workspace._links['channels'].href)
      .then(r => r.data)
      .catch(logError)

  const initialiseDataStore = async () =>
    catalogAPI.post(workspace._links['new datastore'].href)
      .then(r => r.data)
      .catch(logError)

  const getDataStore = async () =>
    catalogAPI.get(workspace._links.datastore.href)
      .then(r => r.data)
      .catch(logError)

  const initialisePipeline = async () =>
    catalogAPI.post(workspace._links['new pipeline'].href)
      .then(r => r.data)
      .catch(logError)

  const switchToWorkspace = id => {
    catalogAPI
      .patch(createUpdateProfileURI(profile.id), {
        defaultWorkspace: {
          id
        }
      })
      .then(() => {
        if (!isUnMounted.current) {
          setWorkspaceId(id)
          window.location.href = '/'
        }
      })
  }

  const fetchWorkspace = async () => {
    await catalogAPI
      .get(workspace._links.self.href)
      .then(resp => setWorkspace(resp.data))
      .catch(e => {
        logError(e)
      })
  }

  const fetchPipelines = async () => {
    setLoadingPipelines(true)

    await catalogAPI
      .get(workspace._links.pipelines.href)
      .then(r => setPipelines(r.data._embedded?.pipelines || []))
      .catch(e => {
        setPipelines([])
        logError(e)
      })

    setLoadingPipelines(false)
  }

  useEffect(() => {
    const switchWorkspace = (id, wrkspaces) => {
      if (wrkspaces) {
        const workspace = id && wrkspaces.find(w => w.id === id) || wrkspaces.find(w => w.defaultWorkspace) || wrkspaces[0]
        setWorkspace(workspace)

        if (workspace) {
          fetchWorkspaceMembers(workspace.id)
          getAllWorkspaceJobs(workspace.id)
        }
      }
    }

    const fetchProfileWorkspaces = async () => {
      assert.object(profile, 'Profile must be loaded before fetching workspaces')
      let nextWorkspaceId = null

      let url = window !== undefined ? window.location.pathname : ''
      if (url) {
        url = url.split('/')
        if (url[2]) {
          nextWorkspaceId = url[2]
        }
      }

      // use state only if no invitation was accepted
      if (!nextWorkspaceId && workspaceId) {
        nextWorkspaceId = workspaceId
      }

      let fetchedWorkspaces = []
      await catalogAPI
        .get(getWorkspaceURI())
        .then(resp => {
          if (resp.data._embedded && resp.data._embedded.workspaces) {
            fetchedWorkspaces = resp.data._embedded.workspaces
          }
        })
        .catch(e => {
          logError(e)
        })
      setWorkspaces(fetchedWorkspaces)
      switchWorkspace(nextWorkspaceId, fetchedWorkspaces)
    }

    const fetchWorkspaceMembers = async workspaceId => {
      await catalogAPI
        .get(getWorkspaceMembersURI(workspaceId))
        .then(resp => {
          if (resp.data._embedded && resp.data._embedded.members) {
            setWorkspaceMembers(resp.data._embedded.members)
          }
        })
        .catch(e => {
          logError(e)
        })
    }

    const fetchNotifications = async () => {
      // update the unread notifications, do not error on network issues
      await catalogAPI
        .get(getNotificationURI('', '', '', ''), { errorHandler: false })
        .then(resp => {
          if (resp.data._embedded && resp.data._embedded.notifications) {
            if (resp.data._links && resp.data.page && resp.data.page.totalElements) {
              setUnreadNotifications(resp.data.page.totalElements)
            } else {
              setUnreadNotifications('')
            }
          }
        })
        .catch(e => {
          logError(e)
        })
    }

    const acceptInvitations = async () => {
      assert.object(user, 'Auth0 user must be loaded before check for accept invitations')

      const invitations = []
      await catalogAPI.get(getUserInvitationsURI(user.email))
        .then(resp => {
          if (resp.data._embedded && resp.data._embedded.invitations.length > 0) {
            resp.data._embedded.invitations.forEach(function (invitation) {
              invitations.push(invitation)
            })
          }
        })
        .catch(e => {
          logError(e)
        })

      let acceptedInvitation = null
      for (let index = 0; index < invitations.length; index++) {
        const invitation = invitations[index]
        if (invitation._links && invitation._links['accept invitation']) {
          let accepted = false
          await catalogAPI
            .patch(encodeURI(invitation._links['accept invitation'].href))
            .then(resp => {
              if (resp && resp.data) {
                accepted = true
              }
            })
            .catch(e => {
              logError(e)
            })
          if (accepted) acceptedInvitation = invitation
        }
      }
      if (acceptedInvitation) {
        return acceptedInvitation
      }
    }

    const initialiseProfile = async () => {
      await acceptInvitations().then(invitation => {
        if (invitation) {
          setWorkspaceId(invitation.workspace.id)
        }
      })

      const profileData = {
        id: user.sub,
        name: user.name,
        email: user.email
      }

      await catalogAPI
        .post(getProfileURI(user.sub)+'/login', profileData)
        .then(resp => {
          if (resp && resp.data) {
            setProfile(resp.data)
          }
        })
        .catch(async error => {
          logError(error)
        })
    }

    if (!loading && !error) {
      if (isAuthenticated && user && catalogAPI) {
        if (!profile) {
          initialiseProfile()
        } else {
          fetchProfileWorkspaces()
          // terminate any existing notifications check
          if (checkNotifications.current > 0) {
            clearInterval(checkNotifications.current)
            checkNotifications.current = 0
          }
          // check for notifications now and at 90s interval
          fetchNotifications()
          checkNotifications.current = setInterval(() => fetchNotifications(), 90000)
        }
      }
    }
  }, [loading, isAuthenticated, getTokenSilently, user, catalogAPI, error, workspaceId, profile])

  return (
        <WorkspaceContext.Provider
            value={{
              profile,
              setProfile,
              workspace,
              workspaces,
              loadWorkspace,
              switchToWorkspace,
              getAllWorkspaceJobs,
              getDataComponents,
              initialiseDataStore,
              getDataStores,
              getDataStore,
              initialisePipeline,
              workspaceJobs,
              updateWorkspace,
              searchResults,
              setSearchResults,
              handleSearchResults,
              isOwner,
              isWorkspaceAdmin,
              channel,
              setChannel,
              getChannels,
              initialiseChannel,
              tag,
              setTag,
              currentDataset,
              setCurrentDataset,
              workspaceMembers,
              unreadNotifications,
              setUnreadNotifications,
              currentDatasetComment,
              setCurrentDatasetComment,
              invitationsList,
              setInvitationsList,
              pipelines,
              setPipelines,
              isLoadingPipelines,
              fetchWorkspace,
              fetchPipelines,
            }}
        >
            {children}
        </WorkspaceContext.Provider>
  )
}

function WorkspaceConsumer ({ children }) {
  return (
        <WorkspaceContext.Consumer>
            {context => {
              if (context === undefined) {
                throw new Error('WorkspaceConsumer must be used within a WorkspaceProvider')
              }
              return children(context)
            }}
        </WorkspaceContext.Consumer>
  )
}

function useWorkspaceContext () {
  const context = React.useContext(WorkspaceContext)
  if (context === undefined) {
    throw new Error('WorkspaceConsumer must be used within a WorkspaceProvider')
  }
  return context
}

export { WorkspaceContext, WorkspaceConsumer, WorkspaceProvider, useWorkspaceContext }

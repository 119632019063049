import { RouteComponentProps } from 'react-router';
import EntityRel from 'src/models/EntityRel'

export type Menu = 'app' | 'lab'

export interface IMenuProps extends RouteComponentProps<any> {
    isExpandedSidebarActive?: boolean;
    isSidebarLayout?: boolean;
    toggleSidebar?: (event: React.MouseEvent<HTMLDivElement>) => void;
    menu: Menu
}

export interface MenuViewOptions {
    name: string
    faIcon?: string
    label?: string
    path?: string
}

export const AppMenuViewOptions: MenuViewOptions[] = [
    {
        name: 'home',
        path: 'news'
    },
    {
        name: 'explore',
        faIcon: 'hashtag'
    },
    {
        name: 'channels',
        faIcon: 'database'
    },
    {
        name: 'library',
        faIcon: 'list'
    },
    {
        name: 'starred',
        faIcon: 'star'
    },
    {
        name: 'help',
        faIcon: 'question-circle'
    }
]

export const LabMenuViewOptions: MenuViewOptions[] = [
    {
        name: EntityRel.Pipeline.collection,
        faIcon: 'stream',
        label: EntityRel.Pipeline.collectionLabel
    },
    {
        name: 'project',
        faIcon: 'folder-open'
    },
    {
        name: EntityRel.Dataset.collection,
        faIcon: 'chart-bar',
        label: EntityRel.Dataset.collectionLabel
    },
    {
        name: EntityRel.Channel.collection,
        faIcon: 'tag',
        label: EntityRel.Channel.collectionLabel
    },
    {
        name: EntityRel.DataStore.collection,
        faIcon: 'database',
        label: EntityRel.DataStore.collectionLabel
    },
    {
        name: EntityRel.DataPlugin.collection,
        faIcon: 'plug',
        label: EntityRel.DataPlugin.collectionLabel,
    },
    {
        name: 'settings',
        faIcon: 'cogs'
    },
    {
        name: 'help',
        faIcon: 'question-circle'
    }
]

import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import '@webscopeio/react-textarea-autocomplete/style.css'
import * as React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import Moment from 'react-moment'
import { Link, withRouter } from 'react-router-dom'
import ScrollAnimation from 'src/utils/ScrollAnimation'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { ICommentProps, ICommentState } from '../models/components/IComment'
import '../styles.css'
import { bindContexts } from '../utils/bindContexts'
import closeIcon from './../assets/images/close.svg'
import CommentActions from 'src/components/CommentActions'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const reactStringReplace = require('react-string-replace')

class Comment extends React.Component<ICommentProps, ICommentState> {
    static contextType = MergedContexts;
    private currentCommentRef: any;
    constructor (props: ICommentProps) {
      super(props)
      this.state = {
        showReply: false,
        comment: '',
        isEdit: false,
        replyIndex: null
      }
      this.currentCommentRef = React.createRef()
    }

    public componentDidMount () {
      const { currentDatasetComment } = this.context
      if (
        currentDatasetComment &&
            (currentDatasetComment.id === this.props.comment.id ||
                currentDatasetComment.parentId === this.props.comment.id)
      ) {
        this.currentCommentRef.current.scrollIntoView()
        if (currentDatasetComment.parentId) this.props.getAllCommentsReply(this.props.index)
      }
    }

    private handleCommentChanges = (e: any) => {
      e.preventDefault()
      const value = e.target.value
      this.setState({ comment: value })
    };

    private handleEdit = () => {
      this.props.setCommentEdit(true, this.props.index)
      this.setState({ comment: this.props.comment.message })
    };

    private handleEditReply = (replyIndex: number) => {
      this.props.setCommentReplyEdit(true, this.props.index, replyIndex)
      this.setState({ comment: this.props.comment.message })
    };

    private handleCancelUpdatComment = () => {
      this.props.setCommentEdit(false, this.props.index)
      this.setState({ comment: '' })
    };

    private handleCancelEditReply = (replyIndex: number) => {
      this.props.setCommentReplyEdit(false, this.props.index, replyIndex)
    };

    public render () {
      const comment = this.props.comment
      const { user, workspace, workspaceMembers } = this.context
      let viewReply = null
      const handleAutoCompleteTrigger = {
        '@': {
          dataProvider: (token: any) => {
            let members = []
            members = workspaceMembers.filter((item: any) => {
              return item.name.toLowerCase().search(token.toLowerCase()) !== -1
            })
            return members
          },
          component: (items: any) => {
            return <div>{items.entity.name}</div>
          },
          output: (item: any) => item.handle
        }
      }

      const memberAutoComplete = (
            <div className="container">
                <ReactTextareaAutocomplete
                    className="form-control d-block"
                    data-testid="comment-input"
                    placeholder="Your comment…"
                    value={this.state.comment}
                    onChange={this.handleCommentChanges}
                    loadingComponent={() => <span>Loading</span>}
                    trigger={handleAutoCompleteTrigger}
                />
            </div>
      )

      if (comment.reply && comment.reply) {
        const rows: any[] = []
        comment.reply.forEach((reply: any, index: any) => {
          let liked = false
          if (reply.likedByProfiles) {
            reply.likedByProfiles.forEach((profile: any) => {
              if (profile.id === user.sub) {
                liked = true
              }
            })
          }

          if (reply.isEdit) {
            rows.push(
                        <div className="sub_comment" key={index}>
                            <ScrollAnimation
                                animateIn="fadeIn"
                                offset={10}
                                className="add_comment "
                            >
                                <div className="form-group d-inline-block w-100">
                                    {/* <input className="form-control d-block autoHeight pad_0" data-testid={"reply-input-"+comment.id} placeholder="Add a reply…"
									onChange={(e) => this.props.handleReplyEditChanges(e, this.props.index, index)}
									value={reply.replyEditMessage || ""} /> */}
                                    <ReactTextareaAutocomplete
                                        className="form-control d-block autoHeight pad_0 disable_resize min_width300"
                                        data-testid={'reply-input-' + comment.id}
                                        placeholder="Add a reply…"
                                        value={reply.replyEditMessage || ''}
                                        onChange={e =>
                                          this.props.handleReplyEditChanges(
                                            e,
                                            this.props.index,
                                            index
                                          )
                                        }
                                        loadingComponent={() => <span>Loading</span>}
                                        trigger={handleAutoCompleteTrigger}
                                    />

                                    {reply.replyEditMessage && (
                                        <div className="clearfix">
                                            {reply.loading
                                              ? (
                                                <div className="pad_top10 d-inline-block spinner_btnHolder pad_lr10 mw-auto float-right">
                                                    <Spinner animation="border" />
                                                </div>
                                                )
                                              : (
                                                <div className="editorActionButtons float-right">
                                                    <button
                                                        type="button"
                                                        className={' btn btn-warning'}
                                                        onClick={() => {
                                                          this.props.handleUpdateCommentReply(
                                                            this.props.index,
                                                            index
                                                          )
                                                        }}
                                                        disabled={reply.loading}
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={' btn btn-secondary'}
                                                        onClick={() =>
                                                          this.handleCancelEditReply(index)
                                                        }
                                                        disabled={reply.loading}
                                                    >
                                                        <img src={closeIcon} alt="close" />
                                                    </button>
                                                </div>
                                                )}
                                        </div>
                                    )}
                                </div>
                            </ScrollAnimation>
                        </div>
            )
          } else if (reply.loadingDelete) {
            rows.push(
                        <div className="sub_comment" key={index}>
                            <ScrollAnimation
                                animateIn="fadeIn"
                                offset={10}
                                className="add_comment "
                            >
                                <Spinner animation="border" />
                            </ScrollAnimation>
                        </div>
            )
          } else {
            let replacedText = reactStringReplace(
              reply.message,
              /#(\S*)/g,
              (match: any, i: any) => (
                            <Link
                                to={'/l/' + workspace.id + '/feed?q=tag:' + match}
                                key={'tag' + comment.id + i}
                            >
                                #{match}
                            </Link>
              )
            )
            replacedText = reactStringReplace(
              replacedText,
              /[^\w]@(\S*)/g,
              (match: any, i: any) => {
                const member = workspaceMembers.find((o: any) => o.handle === '@' + match)
                return (<b key={'member' + comment.id + i}> @{member ? member.name : match}</b>)
              }
            )

            rows.push(
                        <div className="sub_comment" key={index}>
                            <div className="clearfix">
                                <p className="m_0 comment_content float-left">
                                    <span className="font-weight-bold">{reply.from.name}</span>
                                    &nbsp;
                                    {replacedText}
                                </p>
                                <p className="m_0 duration float-left text-right">
                                    {reply.created && (
                                        <Moment fromNow={true} ago={true} utc={true} local={true}>
                                            {reply.created}
                                        </Moment>
                                    )}
                                </p>
                                
                                {!this.props.readOnly &&
                                  <CommentActions
                                    isLoading={reply.loading}
                                    comment={reply}
                                    handleDelete={ () => this.props.handleDeleteComment(this.props.index, index) }
                                    handleEdit={ () => this.handleEditReply(index) }
                                  />}

                            </div>
                            <div className="comment_action  action_list d-flex align-items-center">
                                <div
                                    className={
                                        reply.likeLoading
                                          ? 'cursor-pointer d-flex align-items-center disable'
                                          : 'cursor-pointer d-flex align-items-center'
                                    }
                                    onClick={() => {
                                      liked
                                        ? this.props.handleDeleteCommentLike(
                                          this.props.index,
                                          index
                                        )
                                        : this.props.handleRecordCommentLike(
                                          this.props.index,
                                          index
                                        )
                                    }}
                                >
                                    {liked
                                      ? (
                                        <>
                                            <i className="fas fa-thumbs-up active" />
                                            <span className="active">{reply.likeCount}</span>
                                        </>
                                        )
                                      : (
                                        <>
                                            <i className="fas fa-thumbs-up" />
                                            <span className="">{reply.likeCount}</span>
                                        </>
                                        )}
                                </div>
                            </div>
                        </div>
            )
          }
        })
        viewReply = rows.length > 0 ? rows : ''
      }

      let commentReplaceText = reactStringReplace(
        comment.message,
        /#(\S*)/g,
        (match: any, i: any) => (
                <a href='#' onClick={(e:any) => {
                  e.preventDefault()
                  this.props.history.push({ pathname: '/l/' + workspace.id + '/explore', search: 'search="tag:' + match + '"'}) 
                }} key={'tag' + comment.id + i}>
                    #{match}
                </a>
        )
      )

      commentReplaceText = reactStringReplace(
        commentReplaceText,
        /[^\w]@(\S*)/g,
        (match: any, i: any) => {
          const member = workspaceMembers.find((o: any) => o.handle === '@' + match)
          return <b key={'member' + comment.id + i}> @{member ? member.name : match}</b>
        }
      )

      let render = (
            <ScrollAnimation
                afterAnimatedIn={this.props.afterAnimatedIn}
                animateIn="fadeIn"
                offset={10}
                className="comment pad_15"
            >
                <div
                    data-testid={'comment-container-' + comment.id}
                    className="clearfix"
                    ref={this.currentCommentRef}
                >
                    <p className="m_0 comment_content float-left">
                        <span className="font-weight-bold">{comment.from.name}</span>
                        &nbsp;
                        {commentReplaceText}
                    </p>
                    <p className="m_0 duration float-left text-right">
                        {comment.created && (
                            <Moment fromNow={true} ago={true} utc={true} local={true}>
                                {comment.created}
                            </Moment>
                        )}
                    </p>

                    {!this.props.readOnly &&
                      <CommentActions
                        isLoading={comment.loading}
                        comment={comment}
                        handleDelete={ () => this.props.handleDeleteComment(this.props.index, null) }
                        handleEdit={ () => this.handleEdit() }
                      />}

                </div>

                <div className="comment_action  action_list d-flex align-items-start">
                    <div
                        className={
                            this.props.likeLoading
                              ? 'cursor-pointer d-flex align-items-center disable'
                              : 'cursor-pointer d-flex align-items-center'
                        }
                        onClick={() => {
                          this.props.liked
                            ? this.props.handleDeleteCommentLike(this.props.index)
                            : this.props.handleRecordCommentLike(this.props.index)
                        }}
                    >
                        {this.props.liked
                          ? (
                            <>
                                <i
                                    className="fas fa-thumbs-up active"
                                    data-testid={'datasetLiked-' + comment.id}
                                />
                                <span
                                    className="m_top4"
                                    data-testid={'datasetLikeCnt-' + comment.id}
                                >
                                    {comment.likeCount}
                                </span>
                            </>
                            )
                          : (
                            <>
                                <i
                                    className="fas fa-thumbs-up"
                                    data-testid={'datasetLike-' + comment.id}
                                />
                                <span
                                    className="m_top4"
                                    data-testid={'datasetLikeCnt-' + comment.id}
                                >
                                    {comment.likeCount}
                                </span>
                            </>
                            )}
                    </div>
                    <div className={this.state.showReply ? 'w-100' : 'align-self-center m_top4'}>
                        {this.state.showReply ? (
                            <ScrollAnimation
                                animateIn="fadeIn"
                                offset={10}
                                className="add_comment "
                            >
                                <div className="form-group d-inline-block w-100">
                                    {/* <input className="form-control d-block autoHeight pad_0"
											data-testid={"reply-input-"+comment.id}
											placeholder="Add a reply…"
											onChange={(e) => this.props.handleReplyChanges(e, this.props.index)}
											value={comment.reply_message || ""} /> */}
                                    <ReactTextareaAutocomplete
                                        className="form-control d-block autoHeight pad_0 disable_resize min_width300"
                                        data-testid={'reply-input-' + comment.id}
                                        placeholder="Add a reply…"
                                        value={comment.reply_message || ''}
                                        onChange={e =>
                                          this.props.handleReplyChanges(e, this.props.index)
                                        }
                                        loadingComponent={() => <span>Loading</span>}
                                        trigger={handleAutoCompleteTrigger}
                                    />

                                    {comment.reply_message && (
                                        <div className="m_top10 clearfix">
                                            {this.props.addCommentLoading
                                              ? (
                                                <div className="d-inline-block float-right width100 text-center">
                                                    <Spinner animation="border" />
                                                </div>
                                                )
                                              : (
                                                <button
                                                    type="button"
                                                    data-testid={'reply-button-' + comment.id}
                                                    className={
                                                        'width100 float-right btn btn-warning'
                                                    }
                                                    onClick={() => {
                                                      this.props.handleAddCommentReply(
                                                        comment.reply_message,
                                                        this.props.comment.id,
                                                        this.props.index
                                                      )
                                                    }}
                                                    disabled={this.props.addCommentLoading}
                                                >
                                                    Reply
                                                </button>
                                                )}
                                        </div>
                                    )}
                                </div>
                            </ScrollAnimation>
                        ) : !this.props.readOnly &&
                            <span
                                className="cursor-pointer"
                                data-testid={'reply-view-' + comment.id}
                                onClick={() => this.setState({ showReply: true })}>
                                REPLY
                            </span>
                        }
                    </div>
                </div>

                {comment.commentCount > 0 && (
                    <>
                        {!comment.showReply
                          ? (
                            <button
                                className="viewmore_replies d-inline-block m_top10"
                                data-testid={'view-reply-' + comment.id}
                                onClick={() => {
                                  this.props.getAllCommentsReply(this.props.index)
                                }}
                            >
                                <span className="triangle" /> View {comment.commentCount} replies
                            </button>
                            )
                          : (
                            <div className="m_top10 clearfix">
                                {comment.replyLoading
                                  ? (
                                    <div className="d-inline-block width100 text-center">
                                        <Spinner animation="border" />
                                    </div>
                                    )
                                  : (
                                    <>
                                        <button
                                            className="viewmore_replies hide d-inline-block"
                                            onClick={() => {
                                              this.props.hideCommentsReply(this.props.index)
                                            }}
                                        >
                                            <span className="triangle" /> Hide{' '}
                                            {comment.commentCount} replies
                                        </button>
                                        {viewReply}
                                    </>
                                    )}
                            </div>
                            )}
                    </>
                )}
            </ScrollAnimation>
      )
      if (comment.isEdit) {
        render = (
                <ScrollAnimation
                    afterAnimatedIn={this.props.afterAnimatedIn}
                    animateIn="fadeIn"
                    offset={10}
                    className="add_comment pad_15 m_top10 d-flex align-items-start"
                >
                    <img src={user.picture} alt="person" />
                    <div className="form-group d-inline-block">
                        {memberAutoComplete}
                        {this.state.comment && (
                            <div className="clearfix">
                                {comment.loading
                                  ? (
                                    <div className="pad_top10 d-inline-block spinner_btnHolder pad_lr10 mw-auto float-right">
                                        <Spinner animation="border" />
                                    </div>
                                    )
                                  : (
                                    <div className={'editorActionButtons float-right'}>
                                        <button
                                            type="button"
                                            className={' btn btn-warning'}
                                            onClick={() =>
                                              this.props.handleUpdateDatasetComment(
                                                this.state.comment,
                                                this.props.index
                                              )
                                            }
                                            disabled={comment.loading}
                                            data-testid={'saveCommentBtn'}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className={' btn btn-secondary'}
                                            onClick={this.handleCancelUpdatComment}
                                            disabled={comment.loading}
                                        >
                                            <img src={closeIcon} alt={''} />
                                        </button>
                                    </div>
                                    )}
                            </div>
                        )}
                    </div>
                </ScrollAnimation>
        )
      } else if (comment.loadingDelete) {
        render = (
                <ScrollAnimation
                    afterAnimatedIn={this.props.afterAnimatedIn}
                    animateIn="fadeIn"
                    offset={10}
                    className="add_comment pad_15 m_top10 d-flex align-items-start"
                >
                    <Spinner animation="border" />
                </ScrollAnimation>
        )
      }

      return render
    }
}

export const CommentWithContext = bindContexts(Comment, [Auth0Context, WorkspaceContext])
export default withRouter(CommentWithContext)

// import { AxiosInstance } from 'axios';
import React, { useContext, useState, FunctionComponent, PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import '../assets/css/components/dataRow.css';
import arrowRight from '../assets/images/svgs/arrow_left.svg';
import dataIcon from '../assets/images/svgs/data.svg';
import { APIContext } from '../contexts/api';
import { WorkspaceContext } from '../contexts/workspace';
import { bindContexts } from '../utils/bindContexts';
import { IDataStoresRowProps } from 'src/models/components/IDataStoresRow';
import { WindowContext } from 'src/contexts/window';
import DataPluginSettings from './DataPluginSettings';
import useRowStyles from 'src/assets/css/components/RowComponentsStyles';
import DataStoreImage from './DataStoreImage';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'


const DataStoresRow: FunctionComponent<IDataStoresRowProps> = (props: PropsWithChildren<IDataStoresRowProps>) => {
    const classes = useRowStyles();
    const { size } = useContext(WindowContext);
    const { datastore, handleMakeDefaultDatastore, handleOpenDatastoreConfirmDialog, handleCopyProp, loaders } = props;
    const { id } = datastore;
    const dataStoreName = datastore.name;
    const dataStoreJdbcUrl = datastore.jdbcUrl;
    const dataStoreImg = datastore.logoUrl || dataIcon;
    const [state, setState] = useState({
        datastoreReady: true,
        shouldDisplay: false,
        isMenuOpen: false,
        menuAnchorEl: null as null | EventTarget & HTMLButtonElement,
    });
    const loader = loaders.find(plugin => 'loaders/'+plugin.name+'--'+plugin.variant == datastore.dataPlugin)
    const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setState({ ...state, isMenuOpen: true, menuAnchorEl: e.currentTarget })
    const handleCloseMenu = () => setState({ ...state, isMenuOpen: false })

    const showDatastoreInfo = () => {
        state.shouldDisplay
            ? setState({
                ...state,
                shouldDisplay: false,
            })
            : setState({
                ...state,
                shouldDisplay: true,
            });
    }

    const arrowButton =
        <div
            className={`button-wrapper ${(size.smallScreen || size.mediumScreen) ? 'mobile' : ''} ${state.shouldDisplay ? 'collapse-center' : 'expand-center'}`}
            onClick={showDatastoreInfo}
            title={`${state.shouldDisplay ? 'Collapse' : 'Expand'}`}>
            <span className={`info-action ${state.shouldDisplay ? 'expanded' : ''}`}>
                <img src={arrowRight} />
            </span>
        </div>

    const iconButton = (
        <div className={`${(size.smallScreen || size.mediumScreen) ? 'actions-menu-mobile' : ''}`}>
            <IconButton
                className={classes.button}
                aria-label='actions-menu'
                onClick={handleOpenMenu}
                title="More">
                <MoreVert className={classes.largeIcon} />
            </IconButton>
        </div>
    );

    const handleEdit = () => props.onEdit(datastore)
    const handleMakeDefault = () => {
        handleMakeDefaultDatastore(datastore)
        handleCloseMenu()
    }
    
    const handleDeleteDatastoreConfirm = () => {
        handleCloseMenu()
        handleOpenDatastoreConfirmDialog(datastore)
    }

    const buttons = (
        <div className='inline-vertical-center'>
            {!(size.smallScreen || size.mediumScreen) ? arrowButton : null}
            {!(size.smallScreen || size.mediumScreen) ? iconButton : null}
            <Menu
                className={classes.dropdownMenu}
                open={state.isMenuOpen}
                anchorEl={state.menuAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseMenu}>
                <MenuItem
                    className={classes.dropdownItem}
                    disabled={!datastore._links['update datastore']}
                    dense
                    onClick={handleEdit}>
                    <i className='fas fa-edit' />
                    <span>Edit</span>
                </MenuItem>
                <MenuItem
                    className={classes.dropdownItem}
                    disabled={!datastore._links['make-default']}
                    dense
                    onClick={handleMakeDefault}>
                    <i className='fas fa-pen' />
                    <span>Make default</span>
                </MenuItem>
                <MenuItem
                    className={classes.dropdownItem}
                    disabled={!datastore._links['delete datastore']}
                    dense
                    onClick={handleDeleteDatastoreConfirm}>
                    <i className='fas fa-trash' />
                    <span>Delete</span>
                </MenuItem>
            </Menu>
        </div>
    )

    return (
        <>
            <div
                key={id}
                aria-label={datastore.name}
                className="dataRow-container">
                <div className={`${state.shouldDisplay ? "row-info-container" : "row-info-container collapse-pipeline-datastore"}`}>
                    <div className='row-data'>
                        <DataStoreImage
                            dataStoreImg={dataStoreImg}
                            dataStoreLabel={loader?.label || loader?.name}
                        />
                        <div className="row-content">
                            <div className='pipeline-datastore-info'>
                                <h2 className='pipeline-datastore-name'>{dataStoreName}</h2>
                                {state.datastoreReady ? (
                                  buttons
                                ) : (
                                  <Spinner animation="border" />
                                )}
                                {(size.smallScreen || size.mediumScreen) ? iconButton : null}
                            </div>
                            <div className='inline-vertical-center row-labels'>
                                {datastore.managed &&
                                    <span className='inline-vertical-center datastore-date'>
                                        <LockOutlinedIcon style={{ fontSize: 12 }} />
                                        MANAGED
                                    </span>}
                                {!datastore._links['make-default'] && !datastore.draft &&
                                    <span className='inline-vertical-center datastore-date'>
                                        <VerifiedUserOutlinedIcon style={{ fontSize: 12 }} />
                                        DEFAULT
                                    </span>}
                                {datastore.draft
                                    ?
                                    <span className='inline-vertical-center datastore-date'>
                                        DRAFT
                                    </span>
                                    : (!state.shouldDisplay && !size.smallScreen) && !datastore.draft &&
                                    <span className='inline-vertical-center datastore-date'>
                                        {dataStoreJdbcUrl.replace(/[&]/g, '&\u200B').replace(/[=]/g, '=\u200B')}
                                    </span>}
                            </div>
                        </div>
                    </div>
                    {(size.smallScreen || size.mediumScreen) ? arrowButton : null}
                </div>
                <div
                    id={id}
                    data-testid="loader-dataplugin"
                    className={`data-jobs-container ${state.shouldDisplay ? 'd-block' : 'd-none'}`} >
                    {loader &&
                        <DataPluginSettings
                            selectedDataplugin={loader}
                            properties={datastore.properties}
                            handleCopyProp={handleCopyProp} />}
                </div>
            </div>
        </>
    );
};

export const DataRowWithContext = bindContexts(DataStoresRow, [APIContext, WorkspaceContext]);

export default DataStoresRow;
import React, { FunctionComponent, PropsWithChildren, useState, useRef } from 'react';
import 'src/assets/css/components/dataRow.css';
import { ILibraryRowProps } from 'src/models/components/ILibraryRow';
import Moment from 'react-moment'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import ConfirmDialog from './ConfirmDialog'
import { MoreVert } from '@material-ui/icons'
import EntityRel from 'src/models/EntityRel'
import useCRUDEntity from 'src/hooks/useCRUDEntity'
import { useAPIContext } from 'src/contexts/api'
import history from '../utils/history'
import IChannel from 'src/models/IChannel'
import useRowStyles from 'src/assets/css/components/RowComponentsStyles';
import { useLocation } from 'react-router-dom'
import ImageHolder from 'src/components/ImageHolder'
import chainIcon from 'src/assets/images/svgs/chain-icon.svg'
import { useWorkspaceContext } from '../contexts/workspace'


const LibraryRow: FunctionComponent<ILibraryRowProps> = (props: PropsWithChildren<ILibraryRowProps>) => {

    const { workspace } = useWorkspaceContext();

    const isWorkspaceAdmin = () => workspace && workspace._links && workspace._links['update delete workspace'] != null

    const { catalogAPI: api } = useAPIContext()

    const classes = useRowStyles();

    const channel = useCRUDEntity(api, EntityRel.Channel, props.channel)
    const { id } = channel;

    const location = useLocation()

    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const menuAnchorRef = useRef<Element>()

    const onCloseMenu = (e: React.MouseEvent) => {
        e.stopPropagation()
        setMenuOpen(false)
    }

    const onDeleteDialogClose = () => setDeleteDialogOpen(false)

    const onMenuDelete = (e: React.MouseEvent) => {
        e.stopPropagation()
        setMenuOpen(false)
        setDeleteDialogOpen(true)
    }

    const onOpenMenu = (e: React.MouseEvent) => {
        e.preventDefault()
        menuAnchorRef.current = e.currentTarget
        setMenuOpen(true)
        e.stopPropagation()
    }

    const onMenuEdit = (e: React.MouseEvent) => {
        e.stopPropagation()
        setMenuOpen(false)
        props.handleEdit!(channel)
    }

    const channelVisibility = (channel: IChannel) => {
        if (channel.scope == 'WORKSPACE') return 'Public'
        return 'Private'
    }

    const channelVisibilityInverse = (channel: IChannel) => {
        if (channel.scope == 'WORKSPACE') return 'Private'
        return 'Public'
    }

    const handleChannelVisability = (e: React.MouseEvent) => {
        e.stopPropagation()
        setMenuOpen(false)
        props.handleVisability(channel)
    }

    let menuOptions = [
        {
            label: "Edit",
            onClick: onMenuEdit
        },
        {
            label: "Delete",
            onClick: onMenuDelete
        }
    ]

    const visabilityMenuOption = {label: "Make " + channelVisibilityInverse(channel), onClick: handleChannelVisability}


    const viewChannel = () => {
        props.handleClick(channel)
        history.push(`${location.pathname}/${channel.id}`)
    }

    const handleConfirmDelete = async () => {
        await channel.delete_()
        props.handleDelete()
    }

    if (channel._links['withdraw workspace-scope'] || channel._links['add workspace-scope']) {
        menuOptions.push(visabilityMenuOption)
    } else {
        menuOptions = menuOptions
    }


    return (
        <>
            <div
                key={id}
                aria-label={channel.name}
                className="dataRow-container library-row-hover">
                <div className="row-info-container source-search flex-wrap collapse-pipeline-datastore"
                    onClick={viewChannel}>
                    <div className={'pipeline-row-data'}>
                        {channel.type == 'SOURCE' &&
                        <div className="library-image-holder">
                            <ImageHolder
                            imageUrl={channel.picture || chainIcon} />
                        </div>}
                        <div className="pipeline-row-info">
                            <h2 className={'pipeline-row-name'}>{channel.name}</h2>
                            {channel.canDelete &&
                                <div>
                                    {channel.type == 'LIST' &&
                                    <><IconButton
                                        className={classes.button}
                                        data-testid='actions-menu'
                                        aria-label='More'
                                        onClick={onOpenMenu}>
                                        <MoreVert className={classes.largeIcon} />
                                    </IconButton>
                                    <Menu
                                        aria-label='menu'
                                        open={isMenuOpen}
                                        anchorEl={menuAnchorRef.current}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        onClose={onCloseMenu}>
                                        {menuOptions.map(o =>
                                            <MenuItem
                                                key={o.label}
                                                aria-label={o.label}
                                                dense
                                                onClick={o.onClick}>
                                                {o.label}
                                            </MenuItem>
                                        )}
                                    </Menu></>}
                                </div>}
                        </div>
                        <div className="channel-row-info">
                            <h2 className={'channel-description'}>{channel.description}</h2>
                            <p className={'text-muted channel-info'}>{channelVisibility(channel)} | {channel.datasetCount}
                            <> {channel.type == 'LIST' && workspace.appProperties?.LIBRARY_LIST_INFO_ITEM_TEXT || 'Dataset(s)'}</> |
                            <>{' '}
                                <Moment fromNow={true} utc={true} local={true}>
                                    {channel.lastModified}
                                </Moment>
                            </>
                            <>
                            {isWorkspaceAdmin() && ' | ' + channel.creator["name"]}
                            </>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDialog
                isOpen={isDeleteDialogOpen}
                title={`Delete '${channel.name}'?`}
                message={`Remove this list from the workspace. This action cannot be undone.`}
                confirmLabel="Delete"
                handleConfirm={handleConfirmDelete}
                handleClose={onDeleteDialogClose} />
        </>
    );

};

export default LibraryRow;
import React, { FunctionComponent, PropsWithChildren, useState } from 'react'
import { IConfirmDialogProps } from 'src/models/components/IConfirmDialogProps'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import Spinner from 'src/components/Spinner'

enum ActionState {
    AwaitingInput,
    Processing,
    Completed
}

const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props: PropsWithChildren<IConfirmDialogProps>) => {

    const [cancelState, setCancelState] = useState(ActionState.AwaitingInput)
    const [confirmState, setConfirmState] = useState(ActionState.AwaitingInput)

    const { isOpen, title, handleClose, handleCancel, handleConfirm, handleClosed } = props
    const message = props.message || ' '
    const cancelLabel = props.cancelLabel || "Cancel"
    const confirmLabel = props.confirmLabel || "Confirm"

    const handleActioning = async (setActionState: React.Dispatch<React.SetStateAction<ActionState>>, handleAction?: () => Promise<void>) => {
        setActionState(ActionState.Processing)
        await handleAction?.()
        setActionState(ActionState.Completed)
        handleClose()
        setActionState(ActionState.AwaitingInput)
    }

    const handleCancelling = () => handleActioning(setCancelState, handleCancel)
    const handleConfirming = () => handleActioning(setConfirmState, handleConfirm)

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            TransitionProps={{ onExited: handleClosed }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={cancelState !== ActionState.AwaitingInput}
                    onClick={handleCancelling}>
                    <Spinner active={cancelState === ActionState.Processing}>
                        {cancelLabel}
                    </Spinner>
                </Button>
                <Button
                    variant='contained'
                    disabled={confirmState !== ActionState.AwaitingInput}
                    onClick={handleConfirming}>
                    <Spinner active={confirmState === ActionState.Processing}>
                        {confirmLabel}
                    </Spinner>
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default ConfirmDialog

import React, { FunctionComponent, PropsWithChildren } from 'react'
import Slider from 'react-slick'
import { APIContext } from '../contexts/api'
import { bindContexts } from 'src/utils/bindContexts'
import { INewsChannelsFilterProps } from 'src/models/components/INewsChannelsFilter'
import ScrollAnimation from 'react-animate-on-scroll'


const NewsChannelsFilter: FunctionComponent<INewsChannelsFilterProps> = (props: PropsWithChildren<INewsChannelsFilterProps>) => {


    return (
        <ScrollAnimation
            animateIn="fadeIn"
            offset={10}
            className="pad_tb10 topbar clearfix"
        >
            <div className="roundedListItemSlider_holder position-relative">
                <Slider {...props.settings}>{props.channelView}</Slider>
            </div>
        </ScrollAnimation>
    )

}

export const NewsChannelsDialogWithContext = bindContexts(NewsChannelsFilter, [
    APIContext
])

export default NewsChannelsFilter

import React, { useContext, useRef, FunctionComponent, PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'
import reactStringReplace from 'react-string-replace'
import DatasetChart from 'src/components/DatasetChart'
import { WorkspaceContext } from 'src/contexts/workspace'
import 'src/styles.css'
import history from 'src/utils/history'
import * as markdownUtils from 'src/utils/markdownUtils'
import ScrollAnimation from 'src/utils/ScrollAnimation'
import DatasetActions from 'src/components/DatasetActions'
import { IDatasetCardProps } from 'src/models/components/IDatasetCard'

const DatasetCard: FunctionComponent<IDatasetCardProps> = (props: PropsWithChildren<IDatasetCardProps>) => {

    const { workspace, setCurrentDatasetComment, workspaceMembers, searchResults } = useContext(WorkspaceContext);
    const commentDivElement = useRef<HTMLDivElement>(null)

    const handleDatasetView = () => {
      if (setCurrentDatasetComment) {
        if (props.isNews) {
          setCurrentDatasetComment(props.dataset._embedded.datasetComment)
        } else {
          setCurrentDatasetComment(null)
        }
      }
      history.push(
            `/l/${workspace.id}/dataset/${
                props.dataset.alias ? props.dataset.alias : props.dataset.id
            }`
      )
    }

    const { dataset } = props
    const channelIcon = dataset._embedded && dataset._embedded.source.picture 
                          ? dataset._embedded.source.picture : require('./../assets/images/default_channel.png')
    const channelIconStyle = {
      backgroundImage: `url(${channelIcon})`
    }

    const userIconStyle = {
      backgroundImage: "none",
      paddingLeft: "0px"
    }

    const chart = <DatasetChart dataset={dataset} compact={true} />
    const datasetDescription = !dataset.descriptionContentType || dataset.descriptionContentType === 'text/plain' 
        ? markdownUtils.processMarkdownWithTags(dataset.description, workspace) : dataset.description;
    const textHighlighter = searchResults != null && searchResults.query != null && searchResults.query.length > 0 
        ? { text: markdownUtils.keywordHighligherTextRenderer([searchResults.query]) } : null
    const markdown = (
          <ReactMarkdown
              renderers={{...markdownUtils.renderers, ...textHighlighter}}
              escapeHtml={false}
              source={datasetDescription}
          />
    )

    let detail = null
    if (props.isNews && dataset._embedded.datasetComment) {
      detail = reactStringReplace(
        dataset._embedded.datasetComment.message,
        /[^\w]@(\S*)/g,
        (match: any, i: any) => {
          const member = workspaceMembers.find((o: any) => o.handle === '@' + match)
          return (<b key={i}> @{member ? member.name : match}</b>)
        }
      )
    }
    if (props.isNews && dataset._embedded && dataset._embedded.notification
        && dataset._embedded.notification._embedded && dataset._embedded.notification._embedded.alert) {
      detail = dataset._embedded.notification._embedded.alert ? dataset._embedded.notification._embedded.alert.detail : ''
    }

    let showMore = false
    if (props.isNews) {
      const height = commentDivElement?.current?.offsetHeight
      if (height && height > 38) {
        showMore = true
      }
    }
    
    const contextMenu = (
        <div className="rightSide_verticalDots">
            {(dataset._links && dataset._links['delete dataset'] != null) ||
                (dataset._links && dataset._links['edit dataset'] != null)
                ? <DatasetActions
                    dataset={dataset}
                    handleDeleteDataset={props.handleDeleteDataset!}
                    handleEditDescription={props.handleEditDescription!}
                />
                : null}
        </div>
    )
    
    let card = (
          <ScrollAnimation
              afterAnimatedIn={props.afterDescriptionAnimatedIn}
              animateIn="fadeIn"
              offset={150}
              style={{ height: '100%' }}
          >
              <div
                  data-testid="dataset-card"
                  className="dataset-card detailslink position-relative cursor-pointer"
              >
                  {props.viewClass != 'listView' ? (
                  <div className="graph_holder" onClick={ handleDatasetView }>
                     {dataset.visualisation == null ? markdown : chart}
                  </div>
                  ) : null}

                  { contextMenu }
                  
                  <div className="dataset-card-details">
                      <div
                          className="graph_detail"
                          style={channelIconStyle}
                          onClick={ handleDatasetView }
                      >
                          <div className="position-relative">
                              <h2>{dataset.title}</h2>
                              {props.viewClass == 'listView' ? (
                                <ReactMarkdown
                                    renderers={markdownUtils.renderers}
                                    escapeHtml={false}
                                    source={markdownUtils.processMarkdownWithTags(dataset.questions, workspace)}
                                />
                              ) : null}
                              <div className="text-muted position-relative">
                                  <p>{dataset._embedded.source.description}</p>
                                  <p>
                                      {dataset.viewCount} {dataset.viewCount === 1 ? 'view' : 'views'}{' '}
                                      | {dataset.likeCount}{' '}
                                      {dataset.likeCount === 1 ? 'star' : 'stars'}{' '}
                                      {dataset.published && (
                                          <>
                                              |{' '}
                                              <Moment fromNow={true} utc={true} local={true}>
                                                  {dataset.published}
                                              </Moment>
                                          </>
                                      )}
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </ScrollAnimation>
    )
    if (props.isNews && dataset._embedded && dataset._embedded.notification) {
      card = (
              <ScrollAnimation
                  afterAnimatedIn={props.afterDescriptionAnimatedIn}
                  animateIn="fadeIn"
                  offset={150}
              >
                  <div
                      data-testid="news-card"
                      className="news-card detailslink clearfix cursor-pointer"
                      onClick={handleDatasetView}
                  >
                      <div className="graph_detail clearfix" style={userIconStyle}>
                          <div className="usercomment position-relative pad_r20">
                            {dataset._embedded.notification.type === 'DATASET_LIKE' 
                              || dataset._embedded.notification.type === 'DATASET_COMMENT'
                              ? (
                                <div>
                                    <b>{dataset._embedded.notification && dataset._embedded.notification.actor 
                                        ? dataset._embedded.notification.actor.name : ''}</b>{' '}
                                    {dataset._embedded.notification.type === 'DATASET_LIKE'
                                      ? 'starred '
                                      : dataset._embedded.notification.type === 'DATASET_COMMENT'
                                        ? 'commented on '
                                        : ' '}
                                    dataset.
                                </div>
                              )
                              : dataset._embedded.notification.type === 'WORKSPACE_INVITATION' ? (
                                  <div>
                                        {workspace.appProperties.WELCOME_MESSAGE || 'You joined the ' + workspace.name + ' workspace'}
                                  </div>
                              )
                              : (
                                <div>
                                   {dataset._embedded.notification.type === 'DATASET_ALERT'
                                      ? workspace.appProperties && workspace.appProperties.NEWS_DATASET_ALERT_ALT_TEXT 
                                        ? workspace.appProperties.NEWS_DATASET_ALERT_ALT_TEXT : 'Alert'
                                      : 'Activity'}
                                </div>
                              )}
                              <div className="text-muted position-relative clearfix">
                                  <p>
                                      <Moment fromNow={true} utc={true} local={true}>
                                          {dataset._embedded.notification.created}
                                      </Moment>
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div
                          className="usercomment toggleHeight pad_top10"
                          ref={commentDivElement}
                      >
                          {detail}
                          {showMore && <span className="d-inline-block readmore">Read More</span>}
                      </div>
                      <div className="borderedSection">
                          <div className="position-relative d-flex pad_15 clearfix">
                              <div className="col p-0 float-left">
                                  <div className="graph_detail" style={channelIconStyle}>
                                      <div className="usercomment position-relative">
                                          <div>{dataset.title}</div>
                                          <div className="text-muted position-relative clearfix">
                                              <p>{dataset._embedded.source.description}</p>
                                              <p>
                                                  {dataset.viewCount}{' '}
                                                  {dataset.viewCount === 1 ? 'view' : 'views'} |{' '}
                                                  {dataset.likeCount}{' '}
                                                  {dataset.likeCount === 1 ? 'star' : 'stars'}{' '}
                                                  {dataset.published && (
                                                      <>
                                                          |{' '}
                                                          <Moment
                                                              fromNow={true}
                                                              utc={true}
                                                              local={true}
                                                          >
                                                              {dataset.published}
                                                          </Moment>
                                                      </>
                                                  )}
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>
                          <div className="pad_l30 pad_r30">{dataset.visualisation == null ? markdown : chart}</div>
                      </div>
                  </div>
                  <div className="divider" />
              </ScrollAnimation>
      )
    }

    return (
        <div className="gridbox">
            {card}
        </div>
    )
}

export default DatasetCard

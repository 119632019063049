import React, { FunctionComponent, PropsWithChildren } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ReUnixCron } from '@sbzen/re-cron';

// default schedule to top of every hour
const DEFAULT_SCHEDULE = "0 0 * * * *"
export const HOURLY_SCHEDULE = "0 0 * * * *"
export const DAILY_SCHEDULE = "0 0 0 * * *"

const scheduleWithoutSeconds = (schedule:any) => {
  return schedule.substring(2, schedule.length)
}

const DataScheduleComponent: FunctionComponent<any> = (props: PropsWithChildren<any>) => {
    const { selectedSchedule, onChangeSchedule, schedule, handleScheduleChange } = props;

    const handleChooseCustomSchedule = (newSchedule:any) => {
        // prepend seconds field required by our scheduling service
        handleScheduleChange('0 ' + newSchedule)
    }

    const theScheduleValue = (!selectedSchedule ? !schedule ? 'manual-schedule'
                                : schedule == HOURLY_SCHEDULE || schedule == DAILY_SCHEDULE ? schedule : 'custom-schedule'
                              : selectedSchedule)

    return (
        <>
            <FormControl component="fieldset">
                <RadioGroup 
                  aria-label="Schedule" 
                  name="schedule" 
                  onChange={onChangeSchedule} 
                  value={theScheduleValue}
                >
                    <FormControlLabel
                        value="manual-schedule"
                        control={<Radio />}
                        label="Manual"
                    />
                    <FormControlLabel value={HOURLY_SCHEDULE} control={<Radio />} label="Hourly" />
                    <FormControlLabel value={DAILY_SCHEDULE} control={<Radio />} label="Daily" />
                    <FormControlLabel
                        value="custom-schedule"
                        control={<Radio />}
                        label="Custom"
                    />
                </RadioGroup>
            </FormControl>
            {theScheduleValue == "custom-schedule" ? (
                <div data-testid="cron-input">
                    <ReUnixCron
                      onChange={handleChooseCustomSchedule}
                      value={scheduleWithoutSeconds(schedule ? schedule : DEFAULT_SCHEDULE)}
                    />
                </div>
            ) : null}
        </>
    );
};

export default DataScheduleComponent;

import React, { ReactNode } from 'react'
import loader from 'src/assets/images/loader.png'

class Loading extends React.Component {
  public render () : ReactNode {
    return (
            <div className="loader_parent" data-testid="loader">
                <img className="loader" src={loader} alt="loading.." />
            </div>
    )
  }
}

export default Loading

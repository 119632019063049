import React, { FunctionComponent } from 'react'
import { Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import inviteUserIcon from '../assets/images/invite_user_icon.svg'
import { useAuth0 } from '../contexts/auth0'
import { useWorkspaceContext } from '../contexts/workspace'
import { AppEnv, AppFeatures, isFeatureEnabled } from '../utils/appenv'
import closeIcon from './../assets/images/close.svg'
import 'src/assets/css/components/ProfileDropdown.css';

const ProfileDropdown: FunctionComponent<any> = () => {
  const {
    workspace,
    workspaces,
    switchToWorkspace,
    isOwner,
    profile,
    unreadNotifications
  } = useWorkspaceContext()
  const { isAuthenticated, logout, user } = useAuth0()
  const logoutWithRedirect = () => {
    logout({
      returnTo: window.location.origin
    })
  }
  let workspaceList = null
  if (workspaces && workspaces.length > 0 && workspaces !== null) {
    workspaceList = workspaces.map((workspaceItem: any, i: any) => {
      const switchWorkspaceFn = () => switchToWorkspace(workspaceItem.id)
      return (
                <NavDropdown.Item key={i} onClick={switchWorkspaceFn}>
                    {workspaceItem.name}
                </NavDropdown.Item>
      )
    })
  }

  const profileIcon = (
        <Navbar.Text data-testid="profiledropdown-icon" className="nav-button pad_0">
            <div className="user_image d-inline-block">
                <img src={user.picture} alt="User" />
            </div>
        </Navbar.Text>
  )


  let dropdown = null
  if (isAuthenticated === true) {
    let changePasswordLink = (
            <LinkContainer
                data-rb-event-key="change-password"
                to={'/change-password'}
                className="nav-link dropdown-item"
            >
                <NavDropdown.Item>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
                        <path
                            id="Path_200"
                            data-name="Path 200"
                            d="M31,10.656a10.662,10.662,0,0,1-12.644,10.47L16.9,22.762a1.453,1.453,0,0,1-1.086.488H13.562v2.422a1.453,1.453,0,0,1-1.453,1.453H9.688v2.422A1.453,1.453,0,0,1,8.234,31H1.453A1.453,1.453,0,0,1,0,29.547V24.821a1.453,1.453,0,0,1,.426-1.028l9.8-9.8A10.657,10.657,0,1,1,31,10.656ZM20.344,7.75A2.906,2.906,0,1,0,23.25,4.844,2.906,2.906,0,0,0,20.344,7.75Z"
                        />
                    </svg>
                    Change Password
                </NavDropdown.Item>
            </LinkContainer>
    )
    if (user.sub.startsWith('google')) {
      changePasswordLink = (
                <a
                    target={'_blank'}
                    rel="noopener noreferrer"
                    href={'https://myaccount.google.com/signinoptions/password'}
                    className="nav-link dropdown-item"
                >
                    {/* <FontAwesomeIcon icon="user" className="mr-3" /> */}
                    {/* <span className="fa fa-key"></span> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
                        <path
                            id="Path_200"
                            data-name="Path 200"
                            d="M31,10.656a10.662,10.662,0,0,1-12.644,10.47L16.9,22.762a1.453,1.453,0,0,1-1.086.488H13.562v2.422a1.453,1.453,0,0,1-1.453,1.453H9.688v2.422A1.453,1.453,0,0,1,8.234,31H1.453A1.453,1.453,0,0,1,0,29.547V24.821a1.453,1.453,0,0,1,.426-1.028l9.8-9.8A10.657,10.657,0,1,1,31,10.656ZM20.344,7.75A2.906,2.906,0,1,0,23.25,4.844,2.906,2.906,0,0,0,20.344,7.75Z"
                        />
                    </svg>
                    Change Password
                </a>
      )
    } else if (user.sub.startsWith('microsoft') || user.sub.startsWith('windows')) {
      changePasswordLink = (
                <a
                    target={'_blank'}
                    rel="noopener noreferrer"
                    href={'https://account.live.com/password/reset'}
                    className="nav-link dropdown-item"
                >
                    {/* <FontAwesomeIcon icon="user" className="mr-3" /> */}
                    {/* <i className="fa fa-key"></i> */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
                        <path
                            id="Path_200"
                            data-name="Path 200"
                            d="M31,10.656a10.662,10.662,0,0,1-12.644,10.47L16.9,22.762a1.453,1.453,0,0,1-1.086.488H13.562v2.422a1.453,1.453,0,0,1-1.453,1.453H9.688v2.422A1.453,1.453,0,0,1,8.234,31H1.453A1.453,1.453,0,0,1,0,29.547V24.821a1.453,1.453,0,0,1,.426-1.028l9.8-9.8A10.657,10.657,0,1,1,31,10.656ZM20.344,7.75A2.906,2.906,0,1,0,23.25,4.844,2.906,2.906,0,0,0,20.344,7.75Z"
                        />
                    </svg>
                    Change Password
                </a>
      )
    }

    const apiKeysLink = (
            <LinkContainer
                data-rb-event-key="api-keys"
                to={'/api-key'}
                className="nav-link dropdown-item"
            >
                <NavDropdown.Item>
                    {/* <FontAwesomeIcon icon="user" /> */}
                    <svg viewBox="0 0 33.794998 38.641082">
                        <path
                            d="m 16.9,19.331084 c 8.603176,0 12.910103,-10.4008501 6.827476,-16.4834764 C 17.64485,-3.2350188 7.244,1.0719083 7.244,9.6750838 7.2434476,15.008174 11.56691,19.331636 16.9,19.331084 Z m 6.759,2.414 H 22.4 c -3.490003,1.60968 -7.509997,1.60968 -11,0 H 10.14 C 4.5404518,21.745636 0.00110431,26.284536 0,31.884084 v 3.136 c 5.5199296e-4,1.999594 1.6214056,3.620448 3.621,3.621 h 26.553 c 1.999594,-5.52e-4 3.620448,-1.621406 3.621,-3.621 v -3.138 c -0.0022,-5.598377 -4.540623,-10.135896 -10.139,-10.137 z"
                            id="path56"
                        />
                    </svg>
                    API Keys
                </NavDropdown.Item>
            </LinkContainer>
    )

    const manageAccount = (
            <a
                target={'_blank'}
                rel="noopener noreferrer"
                href={AppEnv.APP_MANAGE_ACCOUNT_URL}
                className="nav-link dropdown-item"
            >
                {/* <FontAwesomeIcon icon="user" className="mr-3" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.857 31">
                    <path
                        id="Path_215"
                        data-name="Path 215"
                        d="M0,59.679A3.322,3.322,0,0,0,3.321,63H36.536a3.322,3.322,0,0,0,3.321-3.321V47.5H0Zm13.286-4.705a.833.833,0,0,1,.83-.83h9.411a.833.833,0,0,1,.83.83v2.768a.833.833,0,0,1-.83.83H14.116a.833.833,0,0,1-.83-.83Zm-8.857,0a.833.833,0,0,1,.83-.83h4.982a.833.833,0,0,1,.83.83v2.768a.833.833,0,0,1-.83.83H5.259a.833.833,0,0,1-.83-.83ZM39.857,35.321v3.321H0V35.321A3.322,3.322,0,0,1,3.321,32H36.536A3.322,3.322,0,0,1,39.857,35.321Z"
                        transform="translate(0 -32)"
                    />
                </svg>
                Manage Account
            </a>
    )

    const truncate = (str: any) => {
      if (str) {
        return str.length > 27 ? str.substring(0, 24) + '...' : str
      } else {
        return ''
      }
    }

    dropdown = (
            <>
            <div className="toolbar-profile-items">
                <div className="toolbar-user d-inline-flex">
                    {workspace && (
                        <Link
                            className="toolbar-notification"
                            to={'/l/' + workspace.id + '/notifications'}
                        >
                            {/* <img src={inviteUserIcon} data-testid={"notifications"} alt="notifications" />  */}
                            <svg
                                id="bell_1_"
                                data-name="bell (1)"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="19"
                                viewBox="0 0 31.878 38.254"
                            >
                                <path
                                    id="Path_523"
                                    data-name="Path 523"
                                    d="M30.888,26.958A10.677,10.677,0,0,1,27.1,18.789V14.345a11.166,11.166,0,0,0-9.563-11.03V1.594a1.594,1.594,0,0,0-3.188,0V3.315a11.165,11.165,0,0,0-9.563,11.03v4.444A10.687,10.687,0,0,1,.975,26.971a2.789,2.789,0,0,0,1.814,4.908h26.3a2.789,2.789,0,0,0,1.8-4.92Zm0,0"
                                    fill="#a7a7a7"
                                />
                                <path
                                    id="Path_524"
                                    data-name="Path 524"
                                    d="M140.809,452.782A5.987,5.987,0,0,0,146.665,448H134.953A5.987,5.987,0,0,0,140.809,452.782Zm0,0"
                                    transform="translate(-124.87 -414.528)"
                                    fill="#a7a7a7"
                                />
                            </svg>
                            {/* <span className="redcount">2</span> */}
                            {unreadNotifications && unreadNotifications !== '0' && (
                                <span className="redcount">{unreadNotifications}</span>
                            )}
                        </Link>
                    )}
                    {workspace && !profile?.featuresDisabled?.includes("INVITATIONS") &&
                        <Link to={'/l/' + workspace.id + '/invitations'}>
                            <img
                                src={inviteUserIcon}
                                data-testid={'invitationIcon'}
                                alt="Invite others"
                            />
                        </Link>
                    }
                </div>
                <NavDropdown
                    title={profileIcon}
                    id="profile-dropdown"
                    className="profile_dropdown"
                    renderMenuOnMount={true}
                    alignRight={true}
                >
                    <NavDropdown.Item className="d-md-none close_profiledropdown d-block text-right">
                        <img src={closeIcon} alt="close dropdown" />
                    </NavDropdown.Item>
                    <div className="profile_dropdown_head topbar d-flex align-items-center clearfix">
                        {' '}
                        {/* ------------------ To close popup in mobile view */}
                        <img
                            className="profile_image pad_0 d-inline-block float-left"
                            src={user.picture}
                            alt="User"
                        />
                        <div className="d-inline-block float-left pad_l10">
                            <NavDropdown.Item
                                active={true}
                                disabled={true}
                                className="profile_name d-block"
                                data-testid="profileName"
                            >
                                {truncate(profile.name)}
                            </NavDropdown.Item>
                            <span>
                                <LinkContainer
                                    data-rb-event-key="profile"
                                    to={'/p/profile'}
                                    className="edit_profile"
                                >
                                    <NavDropdown.Item>Edit Profile</NavDropdown.Item>
                                </LinkContainer>
                            </span>
                        </div>
                    </div>

                    {changePasswordLink}
                    {apiKeysLink}
                    {workspaceList &&
                        isFeatureEnabled(AppFeatures.MANAGE_ACCOUNT) &&
                        isOwner() &&
                        manageAccount}

                    <NavDropdown.Divider className="m_0" />
                    <NavDropdown.Item onClick={logoutWithRedirect} className="nav-link">
                        {/* <FontAwesomeIcon icon="power-off" className="mr-3" />  */}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.805 24">
                            <path
                                id="Path_204"
                                data-name="Path 204"
                                d="M28.406,77.04l-9.6,10.5a1.363,1.363,0,0,1-2.343-1.062v-6H8.688a1.437,1.437,0,0,1-1.372-1.5v-6a1.437,1.437,0,0,1,1.372-1.5h7.773v-6A1.363,1.363,0,0,1,18.8,64.417l9.6,10.5A1.615,1.615,0,0,1,28.406,77.04ZM10.974,87.226v-2.5a.722.722,0,0,0-.686-.75h-4.8a1.918,1.918,0,0,1-1.829-2v-12a1.918,1.918,0,0,1,1.829-2h4.8a.722.722,0,0,0,.686-.75v-2.5a.722.722,0,0,0-.686-.75h-4.8a5.762,5.762,0,0,0-5.487,6v12a5.762,5.762,0,0,0,5.487,6h4.8A.722.722,0,0,0,10.974,87.226Z"
                                transform="translate(0 -63.979)"
                            />
                        </svg>
                        Logout
                    </NavDropdown.Item>
                </NavDropdown>
            </div>
            </>
    )
  } else {
    dropdown = (
            <NavDropdown title={profileIcon} id="profile-dropdown" alignRight={true}>
                <NavDropdown.Item>Not logged in.</NavDropdown.Item>
            </NavDropdown>
    )
  }

  return dropdown
}

export default ProfileDropdown

import React from 'react'
import Col from 'react-bootstrap/Col'
import ContentLoader from 'react-content-loader'

const datasetsFeedPlaceholder = (
    <>
        <div className="dataLoader_parent d-flex" data-testid="loader">
            <Col className="dataLoader">
                <Col className="text-right clearfix">
                    <ContentLoader
                        className="boxload "
                        height="50"
                        viewBox="0 0 160 50"
                        speed={2}
                    >
                        <rect x="10" y="10" width="25" height="25" />
                        <rect x="45" y="10" width="25" height="25" />
                        <rect x="80" y="10" width="25" height="25" />
                        <rect x="115" y="10" width="25" height="25" />
                    </ContentLoader>
                </Col>
                <ContentLoader
                    className="col-12 col-md-6 col-lg-4 float-left m_top30"
                    viewBox="0 0 400 320"
                    speed={2}
                >
                    <circle cx="30" cy="238" r="30" />
                    <rect x="75" y="215" rx="4" ry="4" width="100" height="13" />
                    <rect x="75" y="235" rx="4" ry="4" width="50" height="8" />
                    <rect x="0" y="280" rx="5" ry="5" width="400" height="10" />
                    <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
                </ContentLoader>
                <ContentLoader
                    className="col-12 col-md-6 col-lg-4 float-left m_top30"
                    viewBox="0 0 400 320"
                    speed={2}
                >
                    <circle cx="30" cy="238" r="30" />
                    <rect x="75" y="215" rx="4" ry="4" width="100" height="13" />
                    <rect x="75" y="235" rx="4" ry="4" width="50" height="8" />
                    <rect x="0" y="280" rx="5" ry="5" width="400" height="10" />
                    <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
                </ContentLoader>
                <ContentLoader
                    className="col-12 col-md-6 col-lg-4 float-left m_top30"
                    viewBox="0 0 400 320"
                    speed={2}
                >
                    <circle cx="30" cy="238" r="30" />
                    <rect x="75" y="215" rx="4" ry="4" width="100" height="13" />
                    <rect x="75" y="235" rx="4" ry="4" width="50" height="8" />
                    <rect x="0" y="280" rx="5" ry="5" width="400" height="10" />
                    <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
                </ContentLoader>
                <ContentLoader
                    className="col-12 col-md-6 col-lg-4 float-left m_top30"
                    viewBox="0 0 400 320"
                    speed={2}
                >
                    <circle cx="30" cy="238" r="30" />
                    <rect x="75" y="215" rx="4" ry="4" width="100" height="13" />
                    <rect x="75" y="235" rx="4" ry="4" width="50" height="8" />
                    <rect x="0" y="280" rx="5" ry="5" width="400" height="10" />
                    <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
                </ContentLoader>
                <ContentLoader
                    className="col-12 col-md-6 col-lg-4 float-left m_top30"
                    viewBox="0 0 400 320"
                    speed={2}
                >
                    <circle cx="30" cy="238" r="30" />
                    <rect x="75" y="215" rx="4" ry="4" width="100" height="13" />
                    <rect x="75" y="235" rx="4" ry="4" width="50" height="8" />
                    <rect x="0" y="280" rx="5" ry="5" width="400" height="10" />
                    <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
                </ContentLoader>
            </Col>
        </div>
    </>
)

export default datasetsFeedPlaceholder

import React, { useContext, useState, FunctionComponent, PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';
import 'src/assets/css/components/dataRow.css';
import arrowRight from 'src/assets/images/svgs/arrow_left.svg';
import dataIcon from 'src/assets/images/svgs/data.svg';
import { IDataComponentRowProps } from 'src/models/components/IDataComponentRow';
import { WindowContext } from 'src/contexts/window';
import DataPluginSettings from './DataPluginSettings';
import DataStoreImage from './DataStoreImage';


const DataComponentRow: FunctionComponent<IDataComponentRowProps> = (props: PropsWithChildren<IDataComponentRowProps>) => {
    const { size } = useContext(WindowContext);
    const { datacomponent, handleCopyProp } = props;
    const { id, _embedded } = datacomponent;
    const { dataplugin } = _embedded;
    const rowImg = dataplugin.logoUrl || dataIcon;
    const [state, setState] = useState({
        datastoreReady: true,
        shouldDisplay: false,
        isMenuOpen: false,
        menuAnchorEl: null as null | EventTarget & HTMLButtonElement,
    });

    const showInfo = () => {
        state.shouldDisplay
            ? setState({
                ...state,
                shouldDisplay: false,
            })
            : setState({
                ...state,
                shouldDisplay: true,
            });
    }

    const arrowButton =
        <div
            className={`button-wrapper ${(size.smallScreen || size.mediumScreen) ? 'mobile' : ''} ${state.shouldDisplay ? 'collapse-center' : 'expand-center'}`}
            onClick={showInfo}
            title={`${state.shouldDisplay ? 'Collapse' : 'Expand'}`}>
            <span className={`info-action ${state.shouldDisplay ? 'expanded' : ''}`}>
                <img src={arrowRight} />
            </span>
        </div>

    return (
        <>
            <div
                key={id}
                aria-label={datacomponent.name}
                className="dataRow-container">
                <div className={`${state.shouldDisplay ? "row-info-container" : "row-info-container collapse-pipeline-datastore"}`}>
                    <div className='row-data'>
                        <DataStoreImage
                            dataStoreImg={rowImg}
                            dataStoreLabel={dataplugin.label || dataplugin.name}
                        />
                        <div className="row-content">
                            <div className='pipeline-datastore-info'>
                                <h3>{datacomponent.name}</h3>
                                {state.datastoreReady ? (
                                    <div className='inline-vertical-center'>
                                        {!(size.smallScreen || size.mediumScreen) ? arrowButton : null}
                                    </div>
                                ) : (
                                    <Spinner animation="border" />
                                )}
                            </div>
                            <div className='row-labels'>
                                {datacomponent.dataPlugin}
                            </div>
                        </div>
                    </div>
                    {(size.smallScreen || size.mediumScreen) ? arrowButton : null}
                </div>
                <div
                    id={id}
                    data-testid="loader-dataplugin"
                    className={`data-jobs-container ${state.shouldDisplay ? 'd-block' : 'd-none'}`} >
                    {datacomponent &&
                        <DataPluginSettings
                            selectedDataplugin={dataplugin}
                            properties={datacomponent.properties}
                            handleCopyProp={handleCopyProp} />}
                </div>
            </div>
        </>
    );
};

export default DataComponentRow;
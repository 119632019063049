import { IconButton, Tooltip } from '@material-ui/core'
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import React, { FunctionComponent, PropsWithChildren, useState } from 'react'
import { ReactComponent as DownloadIcon } from 'src/assets/images/svgs/download-icon.svg'
import { useAPIContext } from 'src/contexts/api'
import { useWorkspaceContext } from 'src/contexts/workspace'
import IWorkspace from 'src/models/IWorkspace'
import { logError } from 'src/utils/Error'

interface IDownloadResource {
  path: string
  tooltip?: string
}

const b64EncodeUnicode = (data: string) => btoa(encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, (match, group) => String.fromCharCode(parseInt(group, 16))))

const DownloadResource: FunctionComponent<IDownloadResource> = (props: PropsWithChildren<IDownloadResource>) => {

  const { catalogAPI: api }: { catalogAPI: AxiosInstance } = useAPIContext()
  const { workspace }: { workspace: IWorkspace } = useWorkspaceContext()

  const [disabled, setDisabled] = useState(false)

  const resourceHref = `${workspace._links.resources.href}/${props.path}`
  const resourceName = props.path.split("/").pop() || props.path

  const download = () => {
    setDisabled(true)

    api.get(resourceHref, { errorHandler: false } as AxiosRequestConfig)
      .then((r: AxiosResponse<string>) => {
        const contentType = r.headers["content-type"] || "text/plain"

        const b64Data = b64EncodeUnicode(r.data);

        const link = document.createElement("a")
        link.href = `data:${contentType};base64,${(b64Data)}`
        link.target = '_blank'
        link.download = resourceName

        link.click()

        setDisabled(false)
      }).catch(logError)
  }

  return <>
    <Tooltip
      arrow
      title={props.tooltip || `Download ${resourceName}`}
      enterDelay={700}
      enterNextDelay={700}>
      <IconButton
        disabled={disabled}
        onClick={download}>
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  </>

}

export default DownloadResource

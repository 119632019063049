import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { useAuth0 } from '../contexts/auth0'

const PrivateRoute = props => {
  const { component: Component, path, children, render, component, ...rest } = props
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      const url = window !== undefined ? window.location.pathname + window.location.search : ''
      const searchParams = new URLSearchParams(window.location.search);
      const emailAddress = searchParams.get("email")
      await loginWithRedirect({
        appState: { targetUrl: url },
        login_hint: emailAddress ? emailAddress : "",
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect])

  const renderFn = () => {
    let target = children
    if (render) target = render
    if (component) target = component
    return isAuthenticated === true ? target : null
  }
  rest.children = null
  return <Route path={path} {...rest} render={renderFn} />
}

PrivateRoute.propTypes = {
  render: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.any]),
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
}

export default withRouter(PrivateRoute)

import createAuth0Client from '@auth0/auth0-spa-js'
import * as assert from 'assert-plus'
import Cookies from 'universal-cookie'
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'

const DEFAULT_REDIRECT_CALLBACK = (appState = {}) => {
  if (!appState) return
  window.history.replaceState({}, document.title, window.location.pathname)
}
const DEFAULT_EMAILNOTVERIFIED_CALLBACK = (user = {}) => {
  if (!user) return
  throw new Error('Please verify your email before logging in.')
}

const Auth0Context = React.createContext()
Auth0Context.displayName = 'Auth0Context'

const cookies = new Cookies();

const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  onEmailNotVerifiedCallback = DEFAULT_EMAILNOTVERIFIED_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const updateAuthCookie = async () => {
      if (!isAuthenticated) {
        cookies.remove('MBEARERTOKEN')
      } else if (auth0Client) {
        const token = await auth0Client.getTokenSilently()
        const decoded = jwtDecode(token)
        if (decoded) {
          const expires_in = decoded.exp - decoded.iat
          cookies.set('MBEARERTOKEN', token, {
            path: '/',
            sameSite: 'strict',
            secure: true,
            maxAge: expires_in,
          });
        }
      }
    }
    updateAuthCookie()
  }, [isAuthenticated, user])

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes('code=') &&
                window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        if (!user.email_verified) {
          onEmailNotVerifiedCallback()
        }
        setUser(user)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
    }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    if (!user.email_verified) {
      onEmailNotVerifiedCallback()
    }
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async (appState = {}) => {
    if (!appState) return
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    if (!user.email_verified) {
      onEmailNotVerifiedCallback()
    }

    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  return (
        <Auth0Context.Provider
            value={{
              isAuthenticated,
              user,
              loading,
              popupOpen,
              loginWithPopup,
              handleRedirectCallback,
              getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
              loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
              getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
              getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
              logout: (...p) => auth0Client.logout(...p)
            }}
        >
            {children}
        </Auth0Context.Provider>
  )
}

function Auth0Consumer ({ children }) {
  return (
        <Auth0Context.Consumer>
            {context => {
              if (context === undefined) {
                throw new Error('Auth0Consumer must be used within a Auth0Provider')
              }
              return children(context)
            }}
        </Auth0Context.Consumer>
  )
}

const useAuth0 = () => {
  const context = React.useContext(Auth0Context)
  assert.object(context, 'useAuth0 must be used within a Auth0Provider')
  return context
}

export { Auth0Context, Auth0Consumer, Auth0Provider, useAuth0 }

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '100%',
            },
            '.MuiInputBase-input': {
                width: '100%',
            },
        },
    })
);

export default useFieldStyles
import React, { useLayoutEffect } from 'react'

//
// Context for Window dimensions and other state about the window
// The main purpose of this class is to smooth out / 'debounce' the layout re-rendering
//

const WindowContext = React.createContext()
WindowContext.displayName = 'WindowContext'

function WindowProvider ({ children }) {
  const [size, setSize] = React.useState(() => {
    const wbProps = getWorkbenchProps(window.innerWidth, window.innerHeight)
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      workbenchWidth: wbProps.wbWidth,
      workbenchHeight: wbProps.wbHeight,
      smallScreen: wbProps.smallScreen,
      mediumScreen: wbProps.mediumScreen
    }
  })

  useLayoutEffect(() => {
    function handleResize () {
      const wbProps = getWorkbenchProps(window.innerWidth, window.innerHeight)
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        workbenchWidth: wbProps.wbWidth,
        workbenchHeight: wbProps.wbHeight,
        smallScreen: wbProps.smallScreen,
        mediumScreen: wbProps.mediumScreen
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return <WindowContext.Provider value={{size}}>{children}</WindowContext.Provider>
}

function WindowConsumer ({ children }) {
  return (
        <WindowContext.Consumer>
            {context => {
              if (context === undefined) {
                throw new Error('WindowConsumer must be used within a WindowProvider')
              }
              return children(context)
            }}
        </WindowContext.Consumer>
  )
}

function useWindowContext () {
  const context = React.useContext(WindowContext)
  if (context === undefined) {
    throw new Error('useWindowContext must be used within a WindowProvider')
  }
  return context
}

function getWorkbenchProps (innerWidth, innerHeight) {
  const smallScreen = innerWidth < 768
  const mediumScreen = innerWidth >= 768 && innerWidth < 992

  let wbWidth = innerWidth
  let wbHeight = innerHeight
  if (smallScreen) {
    wbHeight = wbHeight - 68 // toolbar height
    wbHeight = wbHeight - 50 // footer menu, footer-navbar height
  } else if (mediumScreen) {
    wbWidth = wbWidth - 80 // side sidebar-narrow
    wbHeight = wbHeight - 68 // toolbar height
  } else {
    wbWidth = wbWidth - 180 // side sidebar-expanded
    wbHeight = wbHeight - 68 // toolbar height
  }
  return {
    smallScreen: smallScreen,
    mediumScreen: mediumScreen,
    wbWidth: wbWidth,
    wbHeight: wbHeight
  }
}

export { WindowContext, WindowProvider, WindowConsumer, useWindowContext }

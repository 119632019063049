import React, { useState, useEffect } from 'react'
import type { MDXComponents } from 'mdx/types.js'
import * as mdx from '@mdx-js/mdx'
import * as jsxRuntime from 'react/jsx-runtime'
import { VFile, VFileCompatible } from 'vfile'
import Workbench from 'src/components/Workbench'
import PageTitle from 'src/components/PageTitle'
import 'src/assets/css/charts/HtmlMetric.css'

// components available to mdx
import Back from 'src/components/Back'
import DatasetChart from 'src/components/DatasetChart'
import DatasetData from 'src/components/DatasetData'
import DatasetLink from 'src/components/DatasetLink'
import DownloadResource from 'src/components/DownloadResource'

import { useWorkspaceContext } from 'src/contexts/workspace'
import 'src/assets/css/views/dashboard.css'


interface IDashboardProps {
  viewName: string
  setViewName?: (name: string) => void;
  isSidebarLayout: boolean
  isExpandedSidebarActive: boolean
  toggleSidebar?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export type CompileMDXResult = {
  compiledContent: JSX.Element
}

export type MDXProps = {
  source: VFileCompatible
  /**
   * An object mapping names to React components.
   * The key used will be the name accessible to MDX.
   *
   * For example: `{ ComponentName: Component }` will be accessible in the MDX as `<ComponentName/>`.
   */
  components?: MDXComponents
}

export async function compileMDX({
  source,
  components = {},
}: MDXProps): Promise<CompileMDXResult> {

  const vfile = new VFile(source)

  // mdx disabled in tests
  if (!mdx.compile)
    return { compiledContent: <>mdx not enabled</> }

  const compiledMdx = await mdx.compile(vfile, { outputFormat: 'function-body' })
  const compiledSource = String(compiledMdx)

  // assemble all the components for React and our content
  const fullScope = Object.assign(
    {
      opts: jsxRuntime,
    },
    components
  )
  const keys = Object.keys(fullScope)
  const values = Object.values(fullScope)

  // eval the source code using a function constructor
  // in order for this to work we need to have React, the mdx createElement,
  // and all our components in scope for the function, which is the case here
  // we pass the names (via keys) in as the function's args, and execute the
  // function with the actual values.
  const hydrateFn = Reflect.construct(
    Function,
    keys.concat(`${compiledSource}`)
  )

  const Content: any = hydrateFn.apply(hydrateFn, values).default

  return {
    compiledContent: <Content components={components} />
  }
}

/**
 * Renders compiled source from next-mdx-remote/serialize.
 */
const Dashboard = (props: IDashboardProps): JSX.Element => {

  const [content, setContent] = useState<JSX.Element>()

  const { workspace } = useWorkspaceContext()
  const source = workspace.appProperties ? workspace.appProperties.DASHBOARD_CONTENT : null

  useEffect(() => {
    const components = {
      Back,
      DatasetChart,
      DatasetData,
      DatasetLink,
      DownloadResource,
    } as MDXComponents
  
    async function update() {
      const { compiledContent } = await compileMDX({ source, components })
      setContent(compiledContent)
    }
    update();
  }, [])

  useEffect(() => {
    const viewName = workspace.appProperties?.DASHBOARD_PAGE_TITLE || props.viewName
    props.setViewName && props.setViewName(viewName)
  }, [props.viewName])
  
  return (
    <Workbench
      menu='app'
      isExpandedSidebarActive={props.isExpandedSidebarActive}
      isSidebarLayout={props.isSidebarLayout}
      toggleSidebar={props.toggleSidebar}>
      <div className="rightside_pageContent">
        {props.isSidebarLayout && 
          <PageTitle title={props.viewName} /> }
        {content}
      </div>
    </Workbench>
  )
}
export default Dashboard
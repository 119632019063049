import { AxiosInstance } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Feed from 'src/components/Feed'
import Workbench from 'src/components/Workbench'
import { logError } from 'src/utils/Error'
import { APIContext, getWorkspaceLikedURI } from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { IStarredProps, IStarredState } from '../models/views/IStarred'
import { bindContexts } from '../utils/bindContexts'
import 'src/assets/css/views/starred.css'
import 'src/styles.css'
import PageTitle from 'src/components/PageTitle'

class Starred extends React.Component<IStarredProps, IStarredState> {
  static contextType = MergedContexts;
  public isThisMounted = false;
  constructor (props: IStarredProps) {
    super(props)
    this.refreshData = this.refreshData.bind(this)
  }

  public componentDidMount () {
    this.isThisMounted = true
    this.props.setViewName && this.props.setViewName("Starred")
    this.getStarredDatasets()

    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
  }

  private refreshData () {
    this.getStarredDatasets()
  }

  private async getStarredDatasets () {
    const { workspace, catalogAPI, handleSearchResults, setSearchResults } = this.context
    const api = catalogAPI as AxiosInstance
    if (workspace) {
      setSearchResults({ isLoading: true })
      await api
        .get(getWorkspaceLikedURI(workspace.id))
        .then(resp => {
          let results:any = []
          if (resp.data._embedded) {
            results = resp.data._embedded.datasets
          }
          handleSearchResults(results, 0, 0, null, null)
          this.setState({})
        })
        .catch(e => {
          logError(e)
          handleSearchResults([], 0, 0, null, null)
        })
    }
  }

  public render () {
    const { searchResults } = this.context
    const { isLoading, datasets, fetchtime, hits } = searchResults

    const noStarredContent = (
        <div className="no-starred content-center" data-testid="no-starred">
            <i className="fas fa-star" />
            <h1>No starred datasets.</h1>
        </div>
    )

    return (
          <Workbench
              menu='app'
              isExpandedSidebarActive={this.props.isExpandedSidebarActive}
              isSidebarLayout={this.props.isSidebarLayout}
              toggleSidebar={this.props.toggleSidebar}>
              <div className="rightside_pageContent">
                  {this.props.isSidebarLayout && 
                  <PageTitle title={this.context.workspace.appProperties?.STARRED_PAGE_TITLE || 'Starred'} /> }
                  <Feed
                      key="starred"
                      summaryMessage={null}
                      noResultsMessage={noStarredContent}
                      datasets={datasets}
                      isLoading={isLoading}
                      hits={hits}
                      fetchtime={fetchtime}
                      refreshData={this.refreshData}
                  />
                  {this.props.children}
              </div>
          </Workbench>
    )
  }
}

export const StarredWithContext = bindContexts(withRouter(Starred), [
  Auth0Context,
  APIContext,
  WorkspaceContext
])
export default StarredWithContext

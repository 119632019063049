import React from 'react'
import Col from 'react-bootstrap/Col'
import ContentLoader from 'react-content-loader'

const newsPlaceholder = (
    <>
        <div className="dataLoader_parent d-flex" data-testid="loader">
            <Col className="dataLoader pt-4">
                <Col className="col-12 col-md-6 offset-md-3 p-0">
                    <ContentLoader viewBox="0 0 400 450" speed={2}>
                        <rect x="0" y="0" width="35" height="35" />
                        <rect x="45" y="0" width="200" height="10" />
                        <rect x="45" y="25" width="50" height="5" />

                        <rect x="0" y="45" width="400" height="6" />
                        <rect x="0" y="55" width="300" height="6" />

                        <rect x="0" y="75" rx="5" ry="5" width="400" height="250" />

                        <rect x="15" y="345" width="35" height="35" />
                        <rect x="60" y="345" width="150" height="10" />
                        <rect x="60" y="365" width="50" height="5" />

                        <rect x="0" y="400" width="400" height="1" />
                    </ContentLoader>
                    <ContentLoader viewBox="0 0 400 450" speed={2}>
                        <rect x="0" y="0" width="35" height="35" />
                        <rect x="45" y="0" width="200" height="10" />
                        <rect x="45" y="25" width="50" height="5" />

                        <rect x="0" y="45" width="400" height="6" />
                        <rect x="0" y="55" width="300" height="6" />

                        <rect x="0" y="75" rx="5" ry="5" width="400" height="250" />

                        <rect x="15" y="345" width="35" height="35" />
                        <rect x="60" y="345" width="150" height="10" />
                        <rect x="60" y="365" width="50" height="5" />

                        <rect x="0" y="400" width="400" height="1" />
                    </ContentLoader>
                    <ContentLoader viewBox="0 0 400 450" speed={2}>
                        <rect x="0" y="0" width="35" height="35" />
                        <rect x="45" y="0" width="200" height="10" />
                        <rect x="45" y="25" width="50" height="5" />

                        <rect x="0" y="45" width="400" height="6" />
                        <rect x="0" y="55" width="300" height="6" />

                        <rect x="0" y="75" rx="5" ry="5" width="400" height="250" />

                        <rect x="15" y="345" width="35" height="35" />
                        <rect x="60" y="345" width="150" height="10" />
                        <rect x="60" y="365" width="50" height="5" />

                        <rect x="0" y="400" width="400" height="1" />
                    </ContentLoader>
                </Col>
            </Col>
        </div>
    </>
)


export default newsPlaceholder

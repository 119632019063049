import React, { ReactNode } from 'react'
import { withRouter } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'
import { IToolbarProps, IToolbarState } from 'src/models/components/IToolbar'
import ProfileDropdown from 'src/components/ProfileDropdown'
import { APIContext, getSingleWorkspaceURI } from 'src/contexts/api'
import { Auth0Context } from 'src/contexts/auth0'
import { WorkspaceContext } from 'src/contexts/workspace'
import { MergedContexts } from 'src/contexts/merged'
import { bindContexts } from 'src/utils/bindContexts'
import AppEnv from 'src/utils/appenv'
import { ReactiveBase, DataSearch } from '@appbaseio/reactivesearch';
import '../assets/css/components/Toolbar.css'
import searchIcon from '../assets/images/search_icon.svg'
import logo from '../assets/images/logo.svg'
import rightArrow from '../assets/images/Vector.png'
import SwitchMenu from 'src/components/SwitchMenu'

class Toolbar extends React.Component<IToolbarProps, IToolbarState> {
  static contextType = MergedContexts;
  public isThisMounted = false;
  constructor (props: IToolbarProps) {
    super(props)
    this.state = {
      token: '',
      ready: false,
      suggestions: [],
      suggestionsShown: false,
      searchInputValue: null,
      isMenuOpen: false,
    }
  }  

  public async componentDidMount() {
    const { getTokenSilently, loading } = this.context
    this.isThisMounted = true
    if (!loading && AppEnv.APP_SERVER_URI) {
        const token = await getTokenSilently()
        this.setState({ token, ready: true })
    }
  }

  public componentWillUnmount() {
    this.isThisMounted = false
  }

  public render () : ReactNode {
    const { workspace, search } = this.context
    const { ready, token } = this.state
    const viewName = this.props.viewName
    const handleSearch = () => {
      const { searchResults, setSearchResults, handleSearchResults } = this.context
      if (this.isThisMounted && searchResults && !searchResults.isLoading
          && this.state.searchInputValue != null && this.state.searchInputValue.length > 0) {
        setSearchResults({ isLoading: true })
        this.props.history.push('/l/' + workspace.id + '/explore?search="'+this.state.searchInputValue+'"');
        search(workspace.id, handleSearchResults, null, null, this.state.searchInputValue)
      }
    }
    const searchBox = this.props.isSearchEnabled && (
      <>
        <ReactiveBase
          app="datasets"
          url={AppEnv.APP_SERVER_URI + getSingleWorkspaceURI(workspace.id)}
          headers={{
            Authorization: `Bearer ${token}`
          }}
        >
          <DataSearch
            componentId="search"
            className={ (this.props.isSidebarLayout && this.props.isExpandedSidebarActive ? 'toolbar-search' : 'toolbar-search')
                        + (this.state.suggestionsShown ? ' suggestions' : '') }
            innerClass={{
              input: 'toolbar-search-input',
              list: 'toolbar-search-suggestionlist',
            }}
            dataField={[
              'title'
            ]}
            fieldWeights={[]}
            fuzziness={2}
            highlight={true}
            highlightField={[
              'title'
            ]}
            URLParams={true}
            placeholder={workspace && workspace.appProperties && workspace.appProperties.TOOLBAR_SEARCH_ALT_TEXT ? workspace.appProperties.TOOLBAR_SEARCH_ALT_TEXT : 'Search all datasets'}
            queryFormat="and"
            icon={
              <span onClick={handleSearch} className="toolbar-search-icon button-wrapper">
                <img src={searchIcon} alt="Search" />
              </span>
            }
            onSuggestions={(suggestions) => {
              this.isThisMounted && this.setState({
                suggestions: suggestions,
                suggestionsShown: this.state.suggestionsShown 
                  && this.state.searchInputValue != null && this.state.searchInputValue.length > 0
                  && suggestions && suggestions.length > 0,
              })
            }}
            onBlur={() => {
                this.setState({ suggestionsShown: false })
            }}
            onFocus={() => {
              this.setState({
                suggestionsShown: this.state.searchInputValue != null && this.state.searchInputValue.length > 0
                  && this.state.suggestions && this.state.suggestions.length > 0,
              })
            }}
            onValueSelected={(value) => {
              this.isThisMounted && this.setState({ searchInputValue: value, suggestionsShown: false }, handleSearch)
            }}
            onValueChange={(value) => {
              if (this.state.searchInputValue != value) {
                this.isThisMounted && this.setState({ searchInputValue: value, suggestionsShown: value != null && value.length > 0 })  
              }
            }}
          />
        </ReactiveBase>
      </>
    )
    const handleNavToHome = () => workspace && this.props.history.push('/');
    const navigateToLab = () => location.href = '/'
    const title = (
      <>
        <Navbar.Collapse
            id="basic-navbar-nav"
            className={`${(this.props.isSidebarLayout && this.props.isExpandedSidebarActive) 
                            ? "toolbar-name d-inline-flex" : "toolbar-name-mobile d-inline-flex"} 
                ${(!this.props.isSidebarLayout) ? "toolbar-invite" : ""}`}
        >
            <h2
                id="workspace-context"
                data-testid="workspaceName"
                style={{ margin: 0, maxWidth: '50em', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
            >
                {workspace && !this.props.isHideWorkspaceName && (
                  <>
                    <a href="#" onClick={handleNavToHome}>{workspace.name}</a>
                  </>
                )}
            </h2>
            {workspace && !this.props.isHideWorkspaceName && (
              <>
                <img className="workspace-name-mobile" style = {{margin: '0 1rem'}} src={rightArrow} />
              </>
            )}
            <h2
                className="workspace-name-mobile"
                data-testid="viewName"
                style={{ margin: 0 }}
            >
                <span style={{fontWeight: 500}}>{viewName}</span>
            </h2>
        </Navbar.Collapse>
      </>
    )

    const toggleMenu = () => this.setState({ isMenuOpen: !this.state.isMenuOpen })

    // show matatika logo in lab, workspace logo in mobile view
    const imageUrl = this.props.menu === 'lab' ? logo : 
            !this.props.isSidebarLayout && !this.props.isHideWorkspaceName && workspace && workspace.imageUrl ? workspace.imageUrl : logo

    return (
            <>
                <Navbar 
                  className={ "toolbar" + (this.props.isSidebarLayout && this.props.isExpandedSidebarActive ? ' show_sidebar' : '') }
                  bg="white" expand="md" expanded={false} fixed="top">
                    { !this.props.isSidebarLayout && 
                    <div
                        id="brand"
                        className={ 'brand text-center d-inline-block position-relative' + (this.props.isHideWorkspaceName ? ' navigate-brand' : '') }
                        onClick={ this.props.isHideWorkspaceName ? navigateToLab : toggleMenu }
                    >
                        <img src={imageUrl} alt="Matatika" />
                    </div>}
                    <SwitchMenu menu={this.props.menu} handleClose={ toggleMenu } open={this.state.isMenuOpen} />
                    <div className={[
                      "rightside_pageContent",
                      ...!this.props.isSidebarLayout ? ["pl-0"] : [],
                    ].join(' ')}>
                      {ready && searchBox ? searchBox : (
                        <>
                          {title}
                        </>
                      )}
                    </div>
                    <ProfileDropdown />
                </Navbar>
            </>
    )
  }
}

export const ToolbarWithContext = bindContexts(Toolbar, [APIContext, Auth0Context, WorkspaceContext])
export default withRouter(ToolbarWithContext)

import { Tab, Tabs } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import { useWorkspaceContext } from 'src/contexts/workspace'
import { LibraryChannelSearch, LibraryListSearch } from 'src/components/Search'
import LibraryRow from 'src/components/LibraryRow'
import ListDialog from 'src/components/ListDialog'
import IChannel from 'src/models/IChannel'
import IPage from 'src/models/IPage'
import PageTitle from 'src/components/PageTitle'
import LibraryFeed from 'src/components/LibraryFeed'
import history from '../utils/history'
import { useParams } from 'react-router-dom'
import LibraryNavigation from 'src/components/LibraryNavigation'
import { APIContext } from 'src/contexts/api'


enum LibraryTabs {
    Lists = "Lists",
    Channels = "Channels"
}

interface ILibraryProps {
    isSidebarLayout: boolean;
}

const Library = (props: ILibraryProps): JSX.Element => {

    const LISTS_TAB = 0
    const CHANNELS_TAB = 1

    const { catalogAPI } = useContext(APIContext)

    const { type, channelId } = useParams<{ type: string, channelId: string }>()

    const { workspace, getChannels } = useWorkspaceContext()
    const [activeTab, setActiveTab] = useState(0)
    const [channels, setChannels] = useState<IChannel[]>([])

    const [isNewListDialogOpen, setIsNewListDialogOpen] = useState(false)
    const [isEditListDialogOpen, setIsEditListDialogOpen] = useState(false)
    const [channel, setChannel] = useState<IChannel>()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [isChannelSelected, setIsChannelSelected] = useState<boolean>(false)

    const noContent = (type: string) => {
        return (
            <div className="no-starred content-center" data-testid="no-starred">
              <i className="fas fa-folder-open" />
              <h1>No {type}.</h1>
            </div>
          )
    }

    const historyUpdate = (tabNumber: number) => {
        const tabName = tabNumber == 0 ? "lists" :
            "channels"
        history.push(`/l/${workspace.id}/library/${tabName}`)
    }

    const onTabChange = (event: React.ChangeEvent<unknown>, tab: number) => {
        historyUpdate(tab)
        setActiveTab(tab)
        setIsChannelSelected(false)
    }

    const handleChannelSelected = (channel: IChannel) => {
        setChannel(channel)
        setIsChannelSelected(true)
    }

    const handleEditDialog = (channel: IChannel) => {
        setChannel(channel)
        setIsEditListDialogOpen(true)
    }

    const getActiveType = () => {
        let activeTab = 0
        let activeType = activeTab == 0 ? "LIST" :
            "SOURCE"
        if (type) {
            activeType = type == "lists" ? "LIST" :
                "SOURCE"
            activeTab = type == "lists" ? 0 :
                1
        }
        return activeType
    }

    const _getChannels = (activeType: string) => {
        setIsLoading(true)
        getChannels(activeType).then((p: IPage<IChannel>) => {
            if (p._embedded && p._embedded.channels) {
                setChannels(p._embedded.channels)
            } else {
                setChannels([])
            }
            if (p._embedded && channelId) {
                const currentChannel = p._embedded?.channels.find(c => c.id === channelId)
                if (currentChannel) {
                    handleChannelSelected(currentChannel)
                }
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        setIsLoading(true)

        const activeType = getActiveType()

        setActiveTab(activeTab)

        workspace && _getChannels(activeType)

    }, [activeTab])

    const deleteChannel = (channel: IChannel) => setChannels(channels => channels?.filter(c => c.id !== channel.id))

    const addChannel = (channel: IChannel) => { channels && channel ? setChannels(channels => [channel, ...channels]) : setChannels([channel]) }

    const updateChannel = (channel: IChannel) => {
        deleteChannel(channel)
        const activeType = getActiveType()
        _getChannels(activeType)
    }

    const handleScopeChangeList = async (channel: IChannel, link: any) => {
        catalogAPI.put(link.href).then(() => {
            updateChannel(channel)
            setActiveTab(activeTab)
        });
    }

    const handleVisability = (channel: IChannel) => {
        setChannel(channel)
        let link = undefined
        if (channel.scope == 'WORKSPACE'){
            link = channel._links['withdraw workspace-scope']
        } else {
            link = channel._links['add workspace-scope']
        }
        handleScopeChangeList(channel, link)
    }

    return <>
        <div className='rightside_pageContent'>
            {props.isSidebarLayout && <PageTitle title={workspace?.appProperties?.LIBRARY_PAGE_TITLE || 'Library'} /> }
            <Tabs
                indicatorColor='primary'
                variant='scrollable'
                value={activeTab}
                onChange={onTabChange}>
                {Object.keys(LibraryTabs).map(k =>
                    <Tab
                        key={k}
                        label={k} />)}
            </Tabs>
            {isLoading && <div></div>}
            {activeTab === LISTS_TAB && !isLoading && <>{isChannelSelected ? <>
                    <LibraryNavigation channelName={channel!.name} allLink={setIsChannelSelected} />
                    <LibraryFeed channel={channel} /></> :
                <div className='lists'>
                    <LibraryListSearch
                        entities={channels}
                        new={
                            <div className="btn-add-data" onClick={() => setIsNewListDialogOpen(true)}>
                                Create List
                            </div>}
                        row={e =>
                            <LibraryRow
                                key={e.id}
                                channel={e}
                                handleDelete={() => deleteChannel(e)}
                                handleEdit={handleEditDialog}
                                handleClick={handleChannelSelected}
                                handleVisability={handleVisability}
                            />}
                    />
                {!channels[0] && noContent("lists")}
                </div>}</>}
            {activeTab === CHANNELS_TAB && !isLoading && <>{isChannelSelected ? <>
                    <LibraryNavigation channelName={channel!.name} allLink={setIsChannelSelected} />
                    <LibraryFeed channel={channel} /></> :
                <div className='channels'>
                    <LibraryChannelSearch
                        entities={channels}
                        row={e =>
                            <LibraryRow
                                key={e.id}
                                channel={e}
                                handleDelete={() => deleteChannel(e)}
                                handleEdit={handleEditDialog}
                                handleClick={handleChannelSelected}
                                handleVisability={handleVisability}
                            />}
                    />
                {!channels[0] && noContent("channels")}
                </div>}</>}
            {isNewListDialogOpen &&
                <ListDialog
                    handleClose={() => setIsNewListDialogOpen(false)}
                    addChannel={addChannel} />}
            {isEditListDialogOpen &&
                <ListDialog
                    handleClose={() => setIsEditListDialogOpen(false)}
                    updateChannel={updateChannel}
                    channel={channel!} />}
        </div>
    </>

}

export default Library

import { AxiosInstance, AxiosResponse } from 'axios'
import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react'
import Spinner from 'src/components/Spinner'
import Chart from 'src/components/charts/Chart'
import {
  APIContext,
  getDatasetURI,
} from 'src/contexts/api'
import { WorkspaceContext } from 'src/contexts/workspace'
import IDataset from 'src/models/IDataset'
import { logError } from 'src/utils/Error'

interface IDatasetChartProps {
  alias?: string
  dataset?: IDataset
  compact?: boolean
  showTable?: boolean
}

//
// Wraps the Chart component, loading the dataset if required
//
const DatasetChart: FunctionComponent<IDatasetChartProps> = (props: PropsWithChildren<IDatasetChartProps>) => {

  const { workspace } = useContext(WorkspaceContext)
  const { catalogAPI: api }: { catalogAPI: AxiosInstance } = useContext(APIContext)

  const [dataset, setDataset] = useState<IDataset | undefined>(props.dataset)
  const [loading, setLoading] = useState(props.alias !== undefined && props.alias != null)

  useEffect(() => {
    (async () => {
      if (props.alias) {
        setLoading(true)

        await api.get(getDatasetURI(workspace.id, props.alias))
          .then((r: AxiosResponse<IDataset>) => setDataset(r.data))
          .catch(logError)
      }

      setLoading(false)
    })()
  }, [props.alias])

  return <>
    <div style={{
      position: 'relative',
      height: '100%',
      minHeight: 'inherit',
      ...loading && {
        minHeight: 200,
        minWidth: 300,
      }
    }}>
      <Spinner data-testid={'dataset-spinner-'+dataset?.id} active={loading}>
        {dataset &&
          <Chart
            dataset={dataset}
            showTable={props.showTable}
            onGetData={(data: Record<string, unknown>[] | undefined) => setLoading(!data)} />}
      </Spinner>
    </div>
  </>

}

export default DatasetChart

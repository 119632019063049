import React, { useContext, FunctionComponent, PropsWithChildren } from 'react'
import { WorkspaceContext } from '../contexts/workspace';
import history from 'src/utils/history'

interface IDatasetLinkProps {
  alias: string
}

//
// Wraps any component with any dataset link
//

const DatasetLink: FunctionComponent<IDatasetLinkProps> = (props: PropsWithChildren<IDatasetLinkProps>) => {

  const { workspace } = useContext(WorkspaceContext);

  const handleOnClick= () => {
    // route user to dataset
    history.push('/l/' + workspace.id + '/dataset/' + props.alias);
  }

  return <div id="dataset-link" onClick={handleOnClick}>{props.children}</div>

}

export default DatasetLink

type Options = {
    collection: string
    appUrlComponent: string
    label: string
    collectionLabel: string
}

export default class EntityRel {
    static readonly Workspace = new EntityRel('workspace')
    static readonly DataPlugin = new EntityRel('dataplugin', { label: "plugin" })
    static readonly DataSource = new EntityRel('datasource', { label: "source" })
    static readonly DataComponent = new EntityRel('datacomponent', { label: "component" })
    static readonly DataStore = new EntityRel('datastore', { label: "store" })
    static readonly Pipeline = new EntityRel('pipeline', { appUrlComponent: 'data' })
    static readonly Channel = new EntityRel('channel')
    static readonly Dataset = new EntityRel('dataset')

    readonly collection: string
    readonly appUrlComponent: string
    readonly label: string
    readonly collectionLabel: string

    private constructor(readonly item: string, options?: Partial<Options>) {
        this.collection = options?.collection || `${item}s`
        this.appUrlComponent = options?.appUrlComponent || this.collection
        this.label = options?.label || this.item
        this.collectionLabel = options?.label ? `${options.label}s` : options?.collectionLabel || this.collection
    }

}

import { AxiosInstance, AxiosResponse } from 'axios';
import React, { useContext, useState, FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import { IconButton, Menu, MenuItem, Snackbar, Tab, Tabs, TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { MoreVert } from '@material-ui/icons'
import Moment from 'react-moment';
import { logError } from 'src/utils/Error';
import '../assets/css/components/dataRow.css';
import arrowRight from '../assets/images/svgs/arrow_left.svg';
import calendarIcon from '../assets/images/svgs/calendar.svg';
import dataIcon from '../assets/images/svgs/data.svg';
import startIcon from '../assets/images/svgs/play.svg';
import reloadIcon from '../assets/images/svgs/reload.svg';
import scheduleIcon from '../assets/images/svgs/schedule.svg';
import stopIcon from '../assets/images/svgs/square-icon.svg';
import manageIcon from '../assets/images/svgs/manage.svg';
import { APIContext } from '../contexts/api';
import { WorkspaceContext } from '../contexts/workspace';
import { bindContexts } from '../utils/bindContexts';
import { IDataRowProps } from 'src/models/components/IDataRow';
import { HOURLY_SCHEDULE, DAILY_SCHEDULE } from './DataScheduleComponent';
import JobRow from './JobRow';
import ConfirmDialog from 'src/components/ConfirmDialog'
import { WindowContext } from 'src/contexts/window';
import useRowStyles from 'src/assets/css/components/RowComponentsStyles';
import IPage from 'src/models/IPage'
import IJob, { JobStatus } from 'src/models/IJob'
import IPipeline, { IPipelineEnvironment, PipelineStatus } from 'src/models/IPipeline'
import DataPluginSettings from 'src/components/DataPluginSettings'
import copyIcon from 'src/assets/images/copyIcon.png'
import CopyToClipboard from 'react-copy-to-clipboard'
import chainIcon from '../assets/images/svgs/chain-icon.svg'
import IDataStore from 'src/models/IDataStore'
import Spinner from 'src/components/Spinner'
import CustomAccordion from './CustomAccordion';
import ImageHolder from 'src/components/ImageHolder'
import { ImageHolderSize } from 'src/models/components/IImageHolder'
import { Button, Modal } from 'react-bootstrap'
import Slider from 'react-slick'
import alerts from './../assets/images/sharewith/alerts.svg'
import allactivity from './../assets/images/sharewith/all_activity.svg'
import { SubscriptionType } from 'src/models/ISubscription'
import Chart from 'src/components/charts/Chart'
import assert from 'assert'

const DataRow: FunctionComponent<IDataRowProps> = (props: PropsWithChildren<IDataRowProps>) => {
    const classes = useRowStyles();
    const { catalogAPI } = useContext(APIContext);
    const api = catalogAPI as AxiosInstance;
    const { workspace, profile } = useContext(WorkspaceContext);
    const { size } = useContext(WindowContext);
    const { renderLogs, openLog, downloadLog, handleOpenPipelineConfirmDialog } = props;

    // refresh pipeline to update status while job active
    const refreshReference = useRef<NodeJS.Timeout | null>(null)

    const [pipeline, setPipeline] = useState<IPipeline>(props.pipeline)
    const [latestJob, setLatestJob] = useState<IJob>()

    const isActiveJob = !!latestJob && [JobStatus.Queued, JobStatus.Running].includes(latestJob.status)
    const isDraft = pipeline.status === PipelineStatus.Draft
    const isRunnable = !!pipeline._links['create job']
    const hasSubscription = !!pipeline._links['delete subscription']
    const metricsLink = pipeline._links['metrics']

    const [state, setState] = useState({
        currentJobs: pipeline._links.jobs.href,
        nextJobs: '',
        prevJobs: '',
        jobsReady: false,
    });
    const [job, setJob] = useState<IJob>()
    
    // button state
    const [isChangingSubscription, setChangingSubscription] = useState(false)
    const [isRefreshing, setRefreshing] = useState(false)
    const [isStarting, setStarting] = useState(false)
    const [isStopping, setStopping] = useState(false)
    const [isExpanded, setExpanded] = useState(false)
    const [isMenuOpen, setMenuOpen] = useState(false)
    const menuAnchorEl = useRef<EventTarget & HTMLButtonElement>()
    const [isSubscribeDialogOpen, setSubscribeDialogOpen] = useState(false)
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)

    const [jobList, setJobList] = useState<IJob[]>()
    const [activeTab, setActiveTab] = useState(0)
    const [meltanoEnv, setMeltanoEnv] = useState({ display: "", copy: "" })
    const [isCopied, setCopied] = useState(false)

    const [managedDataStore, setManagedDataStore] = useState<IDataStore>()

    const toggleCopiedAlert = () => setCopied(c => !c)

    const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMenuOpen(true)
        menuAnchorEl.current = e.currentTarget
    }
    const handleCloseMenu = () => setMenuOpen(false)

    const handleOpenJobConfirmDialog = (job: IJob) => {
        setConfirmDialogOpen(true)
        setJob(job)
    }
    const handleCloseJobConfirmDialog = () => setConfirmDialogOpen(false)

    const scheduleText = (schedule: any) => {
        if (!schedule)
            return 'Manual'
        if (schedule == HOURLY_SCHEDULE)
            return 'Hourly'
        if (schedule == DAILY_SCHEDULE)
            return 'Daily'
        return schedule
    }

    const addMarkerForJobStatus = (status: JobStatus) => {
        switch (status) {
            case JobStatus.Skipped:
                return 'grey'
            case JobStatus.Complete:
                return 'green'
            case JobStatus.Error:
                return 'red'
            case JobStatus.Running:
                return 'runningGreen'
            case JobStatus.Stopped:
                return 'red'
            default:
                return 'orange'
        }
    }

    const setStateFromJobsResponse = (response: AxiosResponse<IPage<IJob>>) => {
        const page = response.data
        setState({
            ...state,
            currentJobs: page._links.self.href,
            nextJobs: page._links.next?.href || '',
            prevJobs: page._links.prev?.href || '',
            jobsReady: true,
        })
        setJobList(page._embedded?.jobs)
    }

    const getPipeline = async () => {
        await api.get(pipeline._links.self.href)
            .then((r: AxiosResponse<IPipeline>) => setPipeline(r.data))
            .catch(logError)
    }
    const verifyPipeline = async () => {
        await api.post(pipeline._links['verify pipeline'].href)
            .then(getPipeline)
            .catch(logError)
    }

    const getJobs = async () => {
        const [url, query] = state.currentJobs.split("?")

        const params = new URLSearchParams(query)
        params.set("size", "10")

        await api.get(`${url}?${params.toString()}`)
            .then(setStateFromJobsResponse)
            .catch(logError)
    }

    const createJob = async () => {
        await api.post(pipeline._links['create job'].href)
            .then(getPipeline)
            .catch(logError)
    }

    const withdrawJob = async () => {
        await api.put(pipeline._links['withdraw job'].href)
            .then(getPipeline)
            .catch(logError)
    }

    const getWorkspaceManagedDataStore = () => {
        api.get(workspace._links.datastores.href).then((response: AxiosResponse<IPage<IDataStore>>) => {
            setManagedDataStore(response.data._embedded?.datastores.find(ds => ds.managed))
        })
            .catch(logError)
    }

    const refreshHandler = async () => {
        setRefreshing(true)
        await getPipeline()
    }

    useEffect(() => {
        setLatestJob(pipeline._embedded?.['latest job'])
        isExpanded && getJobs()
        setChangingSubscription(false)
        setRefreshing(false)
    }, [pipeline])

    useEffect(() => {
        if (latestJob?.status === JobStatus.Stopped) {
            setStopping(false)
        } else if (isStopping) {
            getPipeline()
        }

        setStarting(false)
    }, [latestJob])

    useEffect(() => {
        isExpanded && getPipeline()
    }, [isExpanded])

    useEffect(() => {
      // terminate any existing refresh interval
      if (refreshReference.current != null) {
        clearInterval(refreshReference.current as NodeJS.Timeout)
        refreshReference.current = null
      }
      // poll for pipeline status at 5s interval if the job is active
      if (isActiveJob) {
        refreshReference.current = setInterval(() => getPipeline(), 5000)
      }

      // return a cleanup function
      return () => {
        if (refreshReference.current != null) {
          clearInterval(refreshReference.current as NodeJS.Timeout)
          refreshReference.current = null
        }
      }
    }, [isActiveJob])

    useEffect(() => {
        isConfirmDialogOpen && setMenuOpen(false)
    }, [isConfirmDialogOpen])

    useEffect(() => {
        if (activeTab !== 2) return

        if (!managedDataStore) {
            return getWorkspaceManagedDataStore()
        }

        !meltanoEnv.display && !meltanoEnv.copy && api.get(pipeline._links.environment.href).then((r: AxiosResponse<IPipelineEnvironment>) => {
            const parseEnvironment = (safe?: boolean) => Object.entries(r.data)
                .map(([k, v]) => `${k}=${safe && v.sensitive ? "***" : v.value}`)
                .join('\n')

            // generate display-safe and clipboard copy envs
            const display = parseEnvironment(true)
            const copy = parseEnvironment()

            setMeltanoEnv({ display: display, copy: copy })
        })

    }, [activeTab, managedDataStore])

    const startStopHandler = async () => {
        !jobList?.length && setState({ ...state, jobsReady: false })

        if (!isActiveJob) {
            setStarting(true)
            createJob()
        } else {
            setStopping(true)
            withdrawJob()
        }
    }

    const olderJobs = async () => {
        if (!state.nextJobs) return;

        await api.get(state.nextJobs)
            .then(setStateFromJobsResponse)
            .catch(logError)
    }

    const newerJobs = async () => {
        if (!state.prevJobs) return;

        await api.get(state.prevJobs)
            .then(setStateFromJobsResponse)
            .catch(logError)
    }

    useEffect(() => {
        if (!jobList)
            newerJobs()
    }, [jobList])

    const handleDeleteJob = async () => {
        if (!job || [JobStatus.Queued, JobStatus.Running].includes(job.status)) return

        await api.delete(job._links['delete job'].href)
            .then(getJobs)
            .catch(logError);
    };

    const closeSubscribeDialog = () => setSubscribeDialogOpen(false)

    const handleSubscribe = async (subscriptionType: SubscriptionType) => {
        const addSubscriptionLink = pipeline._links['add subscription']
        assert(addSubscriptionLink, "Expected 'add subscription' link")

        closeSubscribeDialog()
        setChangingSubscription(true)

        await api.post(addSubscriptionLink.href, { type: subscriptionType })
        await getPipeline()
    }

    const handleUnsubscribe = async () => {
        const deleteSubscriptionLink = pipeline._links['delete subscription']
        assert(deleteSubscriptionLink, "Expected 'delete subscription' link")

        closeSubscribeDialog()
        setChangingSubscription(true)

        await api.delete(deleteSubscriptionLink.href)
        await getPipeline()
    }

    const handleWatch = () => {
        if (isChangingSubscription)
            return

        if (hasSubscription) {
            handleUnsubscribe()
            return
        }

        setMenuOpen(false)
        setSubscribeDialogOpen(true)
    }

    const watchBtn =
        <div
            className={[
                'button-wrapper',
                ...isChangingSubscription ? ['disabled'] : []
            ].join(' ')}
            onClick={handleWatch}
            title={hasSubscription ? "Unwatch" : "Watch"}>
            <span className='watch-action'>
                <Spinner active={isChangingSubscription}>
                    <i className={'fas ' + (hasSubscription ? 'fa-eye-slash' : 'fa-eye')} />
                </Spinner>
            </span>
        </div>

    const refreshBtn =
        <div
            className='button-wrapper'
            onClick={refreshHandler}
            title="Refresh">
            <span className='refresh-action'>
                <Spinner active={isRefreshing}>
                    <img src={reloadIcon} />
                </Spinner>
            </span>
        </div>

    const stopButton =
        <div
            className='button-wrapper'
            onClick={startStopHandler}
            title="Stop job">
            <span className='run-action'>
                <Spinner active={isStopping}>
                    <img src={stopIcon} />
                </Spinner>
            </span>
        </div>

    const startButton =
        <div
            className={[
                'button-wrapper',
                ...!isRunnable ? ['disabled'] : []
            ].join(' ')}
            title={isRunnable ? "Start job" : isDraft ? "Draft imports disabled" : "Import configuration incomplete"}>
            <span
                className='run-action'
                aria-label="Start job"
                onClick={startStopHandler}>
                <Spinner active={isStarting}>
                    <img src={startIcon} />
                </Spinner>
            </span>
        </div>


    const arrowButton =
        <div
            className={`button-wrapper ${(size.smallScreen || size.mediumScreen) ? 'mobile' : ''} ${isExpanded ? 'collapse-center' : 'expand-center'}`}
            onClick={() => setExpanded(!isExpanded)}
            title={`${isExpanded ? 'Collapse' : 'Expand'}`}>
            <span className={`info-action ${isExpanded ? 'expanded' : ''}`}>
                <img src={arrowRight} />
            </span>
        </div>

    const labels = <>
        <span className='inline-vertical-center pipeline-date'>
            <img src={manageIcon} alt="pipeline status" />
            {pipeline.status}
        </span>
        <span className={'inline-vertical-center pipeline-date'}>
            <img src={calendarIcon} alt="calendar icon" />
            {scheduleText(pipeline.schedule)}
        </span>
        {latestJob && <>
            <span className={`inline-vertical-center pipeline-date circle ${addMarkerForJobStatus(latestJob.status)}`}>{latestJob.status}</span>
            <span className={'pipeline-date'}>
                <Moment format="YYYY-MM-DD HH:mm" date={latestJob.created} utc={true} local={true} />
                <img src={scheduleIcon} alt="schedule icon" />
                {latestJob.startTime && latestJob.endTime ?
                    <Moment from={latestJob.startTime}>{latestJob.endTime}</Moment> :
                    <Moment interval={1000} date={latestJob.startTime} utc={true} durationFromNow={true} trim={true} format="h[h]m[m]s[s] [elapsed]" />}
            </span></>}
    </>

    const iconButton = (
        <div className={`${(size.smallScreen || size.mediumScreen) ? 'actions-menu-mobile' : ''}`}>
            <IconButton
                className={classes.button}
                aria-label='actions-menu'
                onClick={handleOpenMenu}
                title="More">
                <MoreVert className={classes.largeIcon} />
            </IconButton>
        </div>
    );


    const handleEditPipeline = () => props.onEdit(pipeline)

    const handleVerifyPipeline = async () => {
        setStarting(true)
        handleCloseMenu()
        await verifyPipeline()
    }

    const handleDeletePipeline = () => {
        handleCloseMenu()
        handleOpenPipelineConfirmDialog(pipeline)
    }

    const handleChangeTab = (event: React.ChangeEvent<unknown>, tab: number) => setActiveTab(tab)

    const buttons = (
        <div className='inline-vertical-center'>
            {!(size.smallScreen || size.mediumScreen) && <>
                {watchBtn}
                {refreshBtn}
                {isActiveJob ? stopButton : startButton}
                {arrowButton}
                {iconButton}</>}
            <Menu
                className={classes.dropdownMenu}
                open={isMenuOpen}
                anchorEl={menuAnchorEl.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseMenu}>
                {(size.smallScreen || size.mediumScreen) &&
                    <MenuItem
                        className={classes.dropdownItem}
                        dense
                        onClick={handleWatch}>  
                        <Spinner active={isChangingSubscription}>
                            <i className={'fas ' + (hasSubscription ? 'fa-eye-slash' : 'fa-eye')} />
                            <span>{hasSubscription ? "Unwatch" : "Watch"}</span>
                        </Spinner>
                    </MenuItem>}
                {(size.smallScreen || size.mediumScreen) &&
                    <MenuItem
                        className={classes.dropdownItem}
                        dense
                        onClick={refreshHandler}>
                        <Spinner active={isRefreshing}>
                            <i className='fas fa-redo' />
                            <span>Refresh</span>
                        </Spinner>
                    </MenuItem>}
                {(size.smallScreen || size.mediumScreen) &&
                    <MenuItem
                        className={classes.dropdownItem}
                        dense
                        disabled={!isRunnable && !isActiveJob}
                        onClick={startStopHandler}>
                        <Spinner active={isStarting || isStopping}>
                            <i className={'fas ' + (isActiveJob ? 'fa-stop' : 'fa-play')} />
                            <span>{isActiveJob ? "Stop job" : "Start job"}</span>
                        </Spinner>
                    </MenuItem>}
                <MenuItem
                    className={classes.dropdownItem}
                    dense
                    disabled={!pipeline._links['update pipeline']}
                    onClick={handleEditPipeline}>
                    <i className='fas fa-edit' />
                    <span>Edit</span>
                </MenuItem>
                <MenuItem
                    className={classes.dropdownItem}
                    dense
                    disabled={!pipeline._links['verify pipeline'] || isStarting || isActiveJob}
                    onClick={handleVerifyPipeline}>
                    <i className='fas fa-play' />
                    <span>Verify</span>
                </MenuItem>
                {!profile?.featuresDisabled?.includes("MODIFY_PIPELINE") &&
                <MenuItem
                    className={classes.dropdownItem}
                    dense
                    disabled={!pipeline._links['delete pipeline']}
                    onClick={handleDeletePipeline}>
                    <i className='fas fa-trash' />
                    <span>Delete</span>
                </MenuItem>
                }
            </Menu>
        </div>
    )

    const metricsDataset = {
        visualisation: `
            {
                "chartjs-chart": {
                    "chartType": "bar",
                    "options": {
                        "responsive": true,
                        "maintainAspectRatio": false
                    }
                }
            }`,
        metadata: `
            {
                "name": "metrics",
                "label": "Job Metrics",
                "related_table": {
                    "columns": [
                        {
                            "name": "job-created",
                            "label": "Created",
                            "description": "Job Created"
                        }
                    ], 
                    "aggregates": [
                        {
                            "name": "value",
                            "label": "Rows",
                            "description": "Total Rows"
                        }
                    ]
                }
            }`,
        continuousPoll: isActiveJob && isExpanded,
        _links: {
            data: metricsLink
        },
    }

    return <>
        <Modal
            show={isSubscribeDialogOpen}
            onHide={closeSubscribeDialog}
            className='sharePopup watchPopup'>
            <Modal.Header closeButton>
                <Modal.Title>Watch for...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='clearfix'>
                    <Slider
                        className='slider_shareIcons'
                        centerPadding='60px'
                        slidesToShow={2}
                        swipeToSlide
                        responsive={[
                            {
                                breakpoint: 767,
                                settings: {
                                    variableWidth: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                }
                            }
                        ]}>
                        <div className='item'>
                            <Button
                                variant='link'
                                onClick={() => handleSubscribe(SubscriptionType.Alerts)}>
                                <img src={alerts} alt='icon' />
                                <div>Errors</div>
                            </Button>
                        </div>

                        <div className='item'>
                            <Button
                                variant='link'
                                onClick={() => handleSubscribe(SubscriptionType.All)}>
                                <img src={allactivity} alt='icon' />
                                <div>All activity</div>
                            </Button>
                        </div>
                    </Slider>
                </div>
            </Modal.Body>
        </Modal>
        <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            title="Delete pipeline job history?"
            message="Remove this pipeline's job history. This action cannot be undone."
            cancelLabel="Close"
            confirmLabel="Delete"
            handleClose={handleCloseJobConfirmDialog}
            handleConfirm={handleDeleteJob} />
        <Snackbar
            open={isCopied}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={toggleCopiedAlert}>
            <Alert severity='success'>Copied</Alert>
        </Snackbar>
        <div
            key={pipeline.id}
            aria-label={pipeline.name}
            className="dataRow-container">
            <div className={`${isExpanded ? "row-info-container" : "row-info-container collapse-pipeline-datastore"}`}>
                <div className='pipeline-row-data'>
                    <div className="pipeline-row-info">
                        <h2 className='pipeline-row-name'>{pipeline.name}</h2>
                        {buttons}
                {(size.smallScreen || size.mediumScreen) ? iconButton : null}
                    </div>
                    <div className="data-source-store image-holders">
                        {pipeline._embedded?.dataComponents.map(dc => {
                            const defaultIcon = dc._embedded.dataplugin.pluginType === 'LOADER' ? dataIcon : chainIcon

                            return <ImageHolder
                                key={dc.id}
                                imageUrl={dc._embedded.dataplugin.logoUrl || defaultIcon}
                                size={ImageHolderSize.Small}/>})}
                    </div>
                    <div className='row-labels'>
                        {(!isExpanded) && labels}
                    </div>
                </div>
                {(size.smallScreen || size.mediumScreen) ? arrowButton : null}
            </div>
            <div id={pipeline.id} className={`data-jobs-container ${isExpanded ? 'd-block' : 'd-none'}`} >
                {!state.jobsReady ?
                    <div className="text-center">
                        <BootstrapSpinner animation="border" className="m_top20" />
                    </div> : <>
                        <Tabs
                            indicatorColor='primary'
                            value={activeTab}
                            onChange={handleChangeTab}>
                            <Tab label="History" />
                            <Tab label="Settings" />
                            <Tab label="Environment" />
                        </Tabs>
                        {activeTab === 0 && <>
                            {metricsLink && <div data-testid="metrics-chart" className="metrics-chart">
                                <Chart dataset={isRefreshing ? null : metricsDataset} />
                            </div>}
                            {jobList && <>
                                {jobList.map(job =>
                                    <JobRow
                                        key={job.id}
                                        job={job}
                                        jobMarker={addMarkerForJobStatus}
                                        renderLogs={renderLogs}
                                        openLog={openLog}
                                        handleOpenJobConfirmDialog={handleOpenJobConfirmDialog}
                                        downloadLog={downloadLog}
                                    />)}
                                <div className="pagination-buttons">
                                    <button disabled={!state.prevJobs} onClick={newerJobs}>
                                        <img
                                            className="arrow-left"
                                            src={arrowRight}
                                            title="Newer jobs"
                                            alt="Newer jobs" />
                                        Newer
                                    </button>
                                    <button disabled={!state.nextJobs} onClick={olderJobs}>
                                        Older
                                        <img
                                            className="arrow-right"
                                            src={arrowRight}
                                            title="Older jobs"
                                            alt="Older jobs" />
                                    </button>
                                </div></>}</>}
                        {activeTab === 1 &&
                            <div className='datastore-properties'>
                                {pipeline._embedded?.dataComponents.map((dc, i) =>
                                    <CustomAccordion
                                        key={dc.id}
                                        title={dc.name}
                                        isLast={i === pipeline._embedded!.dataComponents.length  - 1}
                                        defaultExpanded={i === 0}>
                                        <DataPluginSettings
                                            selectedDataplugin={dc._embedded.dataplugin}
                                            prefix={dc.name}
                                            properties={pipeline.properties}
                                            readOnlyProperties={dc.properties}
                                            handleCopyProp={toggleCopiedAlert} />
                                    </CustomAccordion>)}
                            </div>}
                        {activeTab === 2 &&
                            <div className='datastore-properties'>
                                <CopyToClipboard
                                    text={meltanoEnv.copy}
                                    onCopy={toggleCopiedAlert}>
                                    <TextField
                                        id={`${pipeline.id}-env`}
                                        className='pointer'
                                        multiline
                                        fullWidth
                                        variant='outlined'
                                        value={meltanoEnv.display}
                                        label=".env"
                                        InputProps={{
                                            style: { alignItems: 'flex-start' },
                                            readOnly: true,
                                            endAdornment:
                                                <img src={copyIcon} width={20} height={20} />
                                        }} />
                                </CopyToClipboard>
                            </div>}
                    </>}
            </div>
        </div >
    </>

};

export const DataRowWithContext = bindContexts(DataRow, [APIContext, WorkspaceContext]);

export default DataRow;

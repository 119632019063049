import { AxiosInstance } from 'axios'
import dateFormat from 'dateformat'
import * as React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import { OverlayTrigger, Spinner } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import ContentLoader from 'react-content-loader'
import Moment from 'react-moment'
import { withRouter } from 'react-router-dom'
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType
} from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import defaultChannelIcon from 'src/assets/images/default_channel.png';
import Workbench from 'src/components/Workbench'
import Back from 'src/components/Back';
import { WindowContext } from 'src/contexts/window'
import { logError } from 'src/utils/Error'
import history from 'src/utils/history'
import { APIContext, deleteNotificationsURI, getNotificationURI } from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { INotificationsProps, INotificationsState } from '../models/views/INotifications'
import { bindContexts } from '../utils/bindContexts'
import 'src/styles.css'

class Notifications extends React.Component<INotificationsProps, INotificationsState> {
    public isThisMounted = false;
    static contextType = MergedContexts;
    constructor (props: INotificationsProps) {
      super(props)
      this.state = {
        ready: false,
        waited: 0,
        notifications: [],
        nextPageUrl: '',
        moreLoading: false
      }
      this.getNotificationsData = this.getNotificationsData.bind(this)
      this.handleDeleteNotification = this.handleDeleteNotification.bind(this)
    }

    public async componentDidMount () {
      // on first load, initialise words and default to 'curious'
      this.isThisMounted = true
      this.props.setViewName && this.props.setViewName("Notifications")
      if (this.isThisMounted) {
        await this.getNotificationsData()
        this.refreshNotificationsData()
      }
    }

    private async getNotificationsData () {
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance

      let url = getNotificationURI(true, '', '', '')
      if (this.state.nextPageUrl) {
        url = this.state.nextPageUrl + '&all=true'
        this.setState({ moreLoading: true })
      } else {
        this.setState({ ready: false })
      }
      await api
        .get(url)
        .then(resp => {
          if (resp.data._embedded) {
            if (this.state.nextPageUrl) {
              let values = [...this.state.notifications]
              values = values.concat(resp.data._embedded.notifications)
              this.setState({ ready: true, notifications: values })
            } else {
              this.setState({
                ready: true,
                notifications: resp.data._embedded.notifications
              })
            }
          } else {
            this.setState({ ready: true })
          }
          if (resp.data._links && resp.data._links.next) {
            this.setState({ nextPageUrl: resp.data._links.next.href })
          } else {
            this.setState({ nextPageUrl: '' })
          }

          this.setState({ moreLoading: false })
        })
        .catch(e => {
          if (this.isThisMounted) {
            this.setState({ ready: true })
            logError(e)
          }
        })
    }

    private async refreshNotificationsData () {
      const { catalogAPI, setUnreadNotifications } = this.context
      const api = catalogAPI as AxiosInstance
      let since = ''
      if (this.state.notifications && this.state.notifications.length > 0) {
        since = this.state.notifications[0].lastModified
      }
      if (this.isThisMounted) {
        await api
          .put(getNotificationURI('', '', since, true))
          .then(() => {
            setUnreadNotifications('')
          })
          .catch(e => {
            if (this.isThisMounted) {
              this.setState({ ready: true })
              logError(e)
            }
          })
      }
    }

    private async handleDeleteNotification (index: any) {
      const currentNotification = this.state.notifications[index]
      const { workspace, catalogAPI } = this.context
      const values = [...this.state.notifications]
      const api = catalogAPI as AxiosInstance
      values[index].deleteLoading = true
      this.setState({ notifications: values })
      if (this.isThisMounted && workspace) {
        await api
          .delete(deleteNotificationsURI(currentNotification.id))
          .then(() => {
            values.splice(index, 1)
            this.setState({ notifications: values })
          })
          .catch(e => {
            if (this.isThisMounted) {
              this.setState({ ready: true })
              logError(e)
            }
          })
        currentNotification.deleteLoading = false
      }
    }

    public handleDatasetView (link: any, comment: any, workspaceId: any) {
      const { loadWorkspace, setCurrentDatasetComment } = this.context
      loadWorkspace(workspaceId)
      setCurrentDatasetComment(comment)
      history.push(link)
    }

    public render () {
      const { width, workspace } = this.context
      const isMobile = width <= 768
      let notifications = null
      const mobile_notifications: any = []
      const trailingActions = (index: any) => (
            <TrailingActions>
                <SwipeAction
                    destructive={true}
                    onClick={() => this.handleDeleteNotification(index)}
                >
                    <div className="swipeDelete" data-testid="swipeDeleteAction">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="22"
                            viewBox="0 0 38.5 44"
                        >
                            <path
                                id="Path_171"
                                data-name="Path 171"
                                d="M207.5,273.875A4.125,4.125,0,0,0,211.625,278h24.75a4.125,4.125,0,0,0,4.125-4.125h0V245h-33Zm23.375-22a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm-8.25,0a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm-8.25,0a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm27.5-15.125H231.562l-.807-1.607A2.062,2.062,0,0,0,228.907,234h-9.823a2.04,2.04,0,0,0-1.839,1.143l-.807,1.607H206.125a1.375,1.375,0,0,0-1.375,1.375v2.75a1.375,1.375,0,0,0,1.375,1.375h35.75a1.375,1.375,0,0,0,1.375-1.375v-2.75A1.375,1.375,0,0,0,241.875,236.75Z"
                                transform="translate(-204.75 -234)"
                                fill="#fff"
                            />
                        </svg>
                    </div>
                </SwipeAction>
            </TrailingActions>
      )

      if (this.state.notifications && this.state.notifications.length > 0) {
        let prevDateString: any = null
        notifications = this.state.notifications.map((item: any, index: any) => {
          let groupDate: any = null
          const calendarStrings = {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[Last] dddd',
            nextWeek: 'dddd',
            sameElse: 'L'
          }
          const dateString = dateFormat(item.created, 'yyyy-mm-dd')
          if (prevDateString === null || prevDateString !== dateString) {
            groupDate = (
                        <Moment calendar={calendarStrings} utc={true} local={true}>
                            {dateString}
                        </Moment>
            )
            prevDateString = dateString
          }
          const memberName = item.actor ? item.actor.name : ''
          let icon = defaultChannelIcon
          let message = item.message
          let link = ''
          let comment: any = null
          let workspaceId: any = null
          if (item.type === 'DATASET_LIKE') {
            message = `${memberName} starred ${item._embedded.dataset.title}`
            link =
                        '/l/' +
                        item._embedded.dataset.workspaceId +
                        '/dataset/' +
                        (item._embedded.dataset.alias
                          ? item._embedded.dataset.alias
                          : item._embedded.dataset.id)
            workspaceId = item._embedded.dataset.workspaceId
          } else if (item.type === 'DATASET_ALERT') {
            const alertTxt = workspace.appProperties && workspace.appProperties.NEWS_DATASET_ALERT_ALT_TEXT ? workspace.appProperties.NEWS_DATASET_ALERT_ALT_TEXT : 'Alert'
            message = `${alertTxt} on ${item._embedded.dataset.title}`
            link =
                        '/l/' +
                        item._embedded.dataset.workspaceId +
                        '/dataset/' +
                        (item._embedded.dataset.alias
                          ? item._embedded.dataset.alias
                          : item._embedded.dataset.id)
            workspaceId = item._embedded.dataset.workspaceId
          } else if (item.type === 'DATASET_COMMENT') {
            message = `${memberName} commented on ${
                        item._embedded.dataset ? item._embedded.dataset.title : 'dataset'
                    }`
            link =
                        '/l/' +
                        (item._embedded.dataset
                          ? item._embedded.dataset.workspaceId
                          : workspace.id) +
                        '/dataset/' +
                        (item._embedded.dataset && item._embedded.dataset.alias
                          ? item._embedded.dataset.alias
                          : item._embedded.datasetComment.datasetId)
            comment = item._embedded.datasetComment
            workspaceId = item._embedded.dataset
              ? item._embedded.dataset.workspaceId
              : workspace.id
          } else if (item.type === 'DATASET_MESSAGE') {
            message = item.message
            icon = defaultChannelIcon
            link =
                        '/l/' +
                        item._embedded.dataset.workspaceId +
                        '/dataset/' +
                        (item._embedded.dataset.alias
                          ? item._embedded.dataset.alias
                          : item._embedded.dataset.id)
            workspaceId = item._embedded.dataset.workspaceId
          } else if (item.type === 'WORKSPACE_INVITATION'){
            message = 'You joined ' + (item._embedded.workspace ? 'the ' + item._embedded.workspace.name : 'a' ) + ' workspace'
            link = (item._embedded.dataset ? '/l/' +
                    item._embedded.dataset.workspaceId +
                    '/dataset/' +
                    (item._embedded.dataset.alias
                      ? item._embedded.dataset.alias
                      : item._embedded.dataset.id) : '')
            workspaceId = (item._embedded.dataset ? item._embedded.dataset.workspaceId : '')
          } else if (item.type === 'DATASET_ACTIVITY') {
            message = `New activity on ${item._embedded.dataset.title}`
            link =
                        '/l/' +
                        item._embedded.dataset.workspaceId +
                        '/dataset/' +
                        (item._embedded.dataset.alias
                          ? item._embedded.dataset.alias
                          : item._embedded.dataset.id)
            workspaceId = item._embedded.dataset.workspaceId
          } else if (item.type === 'JOB_STARTED') {
            const job = item._embedded.job
            message = <>
              {`Pipeline '${item._embedded.pipeline.name}' `}
              <code>{job.type}</code>
              {" job started at "}
              <Moment
                utc
                local
                format="YYYY-MM-DD HH:mm">
                {job.startTime}
              </Moment>
            </>
          } else if (item.type === 'JOB_ENDED') {
            const job = item._embedded.job
            message = <>
              {`Pipeline '${item._embedded.pipeline.name}' `}
              <code>{job.type}</code>
              {" job ended at "}
              <Moment
                utc
                local
                format="YYYY-MM-DD HH:mm">
                {job.endTime}
              </Moment>
              {" with status "}
              <code>{job.status}</code>
            </>
          }

          const handleClick = () => {
            if (!link)
              return

            this.handleDatasetView(link, comment, workspaceId)
          }

          /* eslint-disable @typescript-eslint/no-unused-vars */
          let itemElement = (
                    <ScrollAnimation
                        key={index}
                        animateIn="fadeIn"
                        offset={10}
                        className={
                            item.resolved
                              ? 'channel_row clearfix position-relative read'
                              : 'channel_row clearfix position-relative unread'
                        }
                    >
                        {groupDate && <div className="dayDivider">{groupDate}</div>}
                        <div className="pad_tb14 clearfix">
                            <div
                                className="channel_icon d-inline-block"
                                onClick={handleClick}
                            >
                                <img src={icon} alt="icon" />
                            </div>
                            <div
                                className="channel_details d-inline-block"
                                data-testid={'notificationItem' + index}
                                onClick={handleClick}
                            >
                                <p className="m_bot0">{message}</p>
                            </div>
                            <div className="channel_graph d-inline-block">
                                {item.deleteLoading
                                  ? (
                                    <Spinner animation="border" />
                                    )
                                  : (
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="left"
                                        overlay={({
                                          placement,
                                          scheduleUpdate,
                                          arrowProps,
                                          outOfBoundaries,
                                          show,
                                          ...props
                                        }: any) => (
                                            <div
                                                className="inbox_page rightSide_verticalDots_dropdownlist position-absolute"
                                                {...props}
                                            >
                                                <div className="list-item clearfix">
                                                    <div className="icon_holder d-inline-block float-left">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="38.5"
                                                            height="44"
                                                            viewBox="0 0 38.5 44"
                                                        >
                                                            <path
                                                                id="Path_168"
                                                                data-name="Path 168"
                                                                d="M207.5,273.875A4.125,4.125,0,0,0,211.625,278h24.75a4.125,4.125,0,0,0,4.125-4.125h0V245h-33Zm23.375-22a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm-8.25,0a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm-8.25,0a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm27.5-15.125H231.562l-.807-1.607A2.062,2.062,0,0,0,228.907,234h-9.823a2.04,2.04,0,0,0-1.839,1.143l-.807,1.607H206.125a1.375,1.375,0,0,0-1.375,1.375v2.75a1.375,1.375,0,0,0,1.375,1.375h35.75a1.375,1.375,0,0,0,1.375-1.375v-2.75A1.375,1.375,0,0,0,241.875,236.75Z"
                                                                transform="translate(-204.75 -234)"
                                                                fill="#a7a7a7"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <span
                                                        className="list-item-name d-inline-block float-left"
                                                        onClick={() =>
                                                          this.handleDeleteNotification(index)
                                                        }
                                                        data-testid={'delete' + index}
                                                    >
                                                        Delete notification
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        rootClose={true}
                                    >
                                        <div
                                            className="rightSide_verticalDots"
                                            data-testid={'optionDots' + index}
                                        >
                                            <span />
                                            <span />
                                            <span />{' '}
                                        </div>
                                    </OverlayTrigger>
                                    )}
                            </div>
                        </div>
                    </ScrollAnimation>
          )
          if (isMobile) {
            itemElement = (
                        <SwipeableListItem key={index} trailingActions={trailingActions(index)}>
                            <ScrollAnimation
                                key={index}
                                animateIn="fadeIn"
                                offset={10}
                                className={
                                    item.resolved
                                      ? 'channel_row clearfix position-relative read'
                                      : 'channel_row clearfix position-relative unread'
                                }
                            >
                                <div
                                    className="channel_icon d-inline-block"
                                    onClick={handleClick}
                                >
                                    <img src={icon} alt="icon" />
                                </div>
                                <div
                                    className="channel_details d-inline-block"
                                    data-testid={'notificationItem' + index}
                                    onClick={handleClick}
                                >
                                    <p className="m_bot0">{message}</p>
                                </div>
                                <div className="channel_graph d-inline-block">
                                    {item.deleteLoading
                                      ? (
                                        <Spinner animation="border" />
                                        )
                                      : (
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="left"
                                            overlay={({
                                              placement,
                                              scheduleUpdate,
                                              arrowProps,
                                              outOfBoundaries,
                                              show,
                                              ...props
                                            }: any) => (
                                                <div
                                                    className="inbox_page rightSide_verticalDots_dropdownlist position-absolute"
                                                    {...props}
                                                >
                                                    <div className="list-item clearfix">
                                                        <div className="icon_holder d-inline-block float-left">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="38.5"
                                                                height="44"
                                                                viewBox="0 0 38.5 44"
                                                            >
                                                                <path
                                                                    id="Path_168"
                                                                    data-name="Path 168"
                                                                    d="M207.5,273.875A4.125,4.125,0,0,0,211.625,278h24.75a4.125,4.125,0,0,0,4.125-4.125h0V245h-33Zm23.375-22a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm-8.25,0a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm-8.25,0a1.375,1.375,0,0,1,2.75,0v19.25a1.375,1.375,0,0,1-2.75,0Zm27.5-15.125H231.562l-.807-1.607A2.062,2.062,0,0,0,228.907,234h-9.823a2.04,2.04,0,0,0-1.839,1.143l-.807,1.607H206.125a1.375,1.375,0,0,0-1.375,1.375v2.75a1.375,1.375,0,0,0,1.375,1.375h35.75a1.375,1.375,0,0,0,1.375-1.375v-2.75A1.375,1.375,0,0,0,241.875,236.75Z"
                                                                    transform="translate(-204.75 -234)"
                                                                    fill="#a7a7a7"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <span
                                                            className="list-item-name d-inline-block float-left"
                                                            onClick={() =>
                                                              this.handleDeleteNotification(index)
                                                            }
                                                            data-testid={'delete' + index}
                                                        >
                                                            Delete notification
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            rootClose={true}
                                        >
                                            <div
                                                className="rightSide_verticalDots"
                                                data-testid={'optionDots' + index}
                                            >
                                                <span />
                                                <span />
                                                <span />{' '}
                                            </div>
                                        </OverlayTrigger>
                                        )}
                                </div>
                            </ScrollAnimation>
                        </SwipeableListItem>
            )
          }
          /* eslint-enable @typescript-eslint/no-unused-vars */
          if (groupDate) {
            mobile_notifications.push(
                        <SwipeableListItem key={'group-date' + index}>
                            <div className="dayDivider">{groupDate}</div>
                        </SwipeableListItem>
            )
          }
          mobile_notifications.push(itemElement)
          return itemElement
        })
        if (isMobile) {
          notifications = mobile_notifications.map((item: any) => {
            return item
          })
        }
      } else {
        notifications = <div className="pad_tb14 clearfix">You have no notifications</div>
      }

      const inboxPlaceholder = (
            <>
                {/* <Loading /> */}
                <div className="dataLoader_parent d-flex">
                    <Col className="dataLoader pr-4">
                        <Col className="clearfix mt-4 p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                        <Col className="clearfix p-2 hideOverflow">
                            <ContentLoader
                                className="boxload"
                                height="70"
                                viewBox="0 0 1600 70"
                                speed={2}
                            >
                                <rect x="0" y="0" rx="0" ry="0" width="50" height="50" />
                                <rect x="70" y="20" rx="0" ry="0" width="900" height="15" />
                            </ContentLoader>
                        </Col>
                    </Col>
                </div>
            </>
      )

      return (
            <Workbench>
                <div className="w-100">
                    {!this.state.ready && (
                        <Col>{inboxPlaceholder != null ? inboxPlaceholder : ''}</Col>
                    )}
                    {this.state.ready && (
                      <>
                        <div className="full_pageContent inbox_page">
                            <Back />
                            <div className="custom_container clearfix">
                                <div className="channel_list" data-testid="notificationList">
                                    {isMobile && notifications.length > 0
                                      ? (
                                        <SwipeableList type={ListType.IOS}>
                                            {notifications}
                                        </SwipeableList>
                                        )
                                      : (
                                          notifications
                                        )}
                                    {(this.state.nextPageUrl || this.state.moreLoading) && (
                                        <div className={'loadMoreDatasetButton '}>
                                            <button
                                                type="button"
                                                className={
                                                    'float-center btn btn-warning' +
                                                    (this.state.moreLoading
                                                      ? ' spinner-border'
                                                      : '')
                                                }
                                                onClick={this.getNotificationsData}
                                                disabled={this.state.moreLoading}
                                            >
                                                {this.state.moreLoading ? ' ' : ' Load More'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                      </>
                    )}
                </div>
            </Workbench>
      )
    }
}

export const NotificationsWithContext = bindContexts(Notifications, [
  Auth0Context,
  APIContext,
  WorkspaceContext,
  WindowContext
])
export default withRouter(NotificationsWithContext)

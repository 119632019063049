import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ILibraryNavigationProps } from 'src/models/components/ILibraryNavigation';


const LibraryNavigation: FunctionComponent<ILibraryNavigationProps> = (props: PropsWithChildren<ILibraryNavigationProps>) => {
    const { channelName, allLink } = props;

    return (
        <div className={'library-navigation'}><a href='#' className={'library-navigation-all-link'} onClick={() => allLink(false)}>All</a> {'>'} <span>{channelName}</span></div>
    )
}

export default LibraryNavigation;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useCallback, useState } from 'react'
import EntityRel from 'src/models/EntityRel'
import IDataPlugin from 'src/models/IDataPlugin'
import IDataStore from 'src/models/IDataStore'
import { INamedEntity } from 'src/models/IEntity'
import IPipeline from 'src/models/IPipeline'

const useCRUDEntity = <E extends INamedEntity>(api: AxiosInstance, rel: EntityRel, e: E | undefined, disableErrorHandler?: boolean) => {

    const [entity, setEntity] = useState(e || { _links: {} } as E)
    const [isProcessing, setProcessing] = useState(false)
    const [isDeleted, setDeleted] = useState(false)

    const successResponseCallback = useCallback((response: AxiosResponse<E>) => {
        if (response.data) setEntity(response.data)
        setProcessing(false)
        return response
    }, [])

    const errorResponseCallback = useCallback((error: AxiosError<E>) => {
        setProcessing(false)
        return Promise.reject(error)
    }, [])

    const createLink = entity._links[`create ${rel.item}`]
    const readLink = entity._links.self
    const updateLink = entity._links[`update ${rel.item}`]
    const deleteLink = entity._links[`delete ${rel.item}`]

    const request = async (config: AxiosRequestConfig) => {
        config['errorHandler'] = !disableErrorHandler
        setProcessing(true)

        return await api.request(config)
            .then(successResponseCallback)
            .catch(errorResponseCallback)

    }

    const rest = {
        get: (url: string) => request({
            method: 'GET',
            url: url
        }),
        put: (url: string, data: Partial<E>) => request({
            method: 'PUT',
            url: url,
            data: data
        }),
        delete_: (url: string) => request({
            method: 'DELETE',
            url: url
        })
    }

    const create = (data: Partial<E>) => {
        if (!createLink) throw Error(`Entity '${rel.item}' cannot be created`)
        return rest.put(createLink.href, data)
    }

    const read = () => {
        if (!readLink) throw Error(`Entity '${rel.item}' cannot be read`)
        return rest.get(readLink.href)
    }

    const update = (data: Partial<E>) => {
        if (!updateLink) throw Error(`Entity '${rel.item}' cannot be updated`)
        return rest.put(updateLink.href, data)
    }

    const delete_ = () => {
        if (!deleteLink) throw Error(`Entity '${rel.item}' cannot be deleted`)
        return rest.delete_(deleteLink.href).then(() => setDeleted(true))
    }

    return {
        ...entity,
        canCreate: !!createLink,
        canRead: !!readLink,
        canUpdate: !!updateLink,
        canDelete: !!deleteLink,
        isProcessing,
        isDeleted,
        rest,
        create,
        read,
        update,
        delete_
    }

}

export default useCRUDEntity

export const useDataPlugin = (api: AxiosInstance, e: IDataPlugin | undefined, disableErrorHandler?: boolean) => useCRUDEntity(api, EntityRel.DataPlugin, e, disableErrorHandler)
export const useDataStore = (api: AxiosInstance, e: IDataStore | undefined, disableErrorHandler?: boolean) => useCRUDEntity(api, EntityRel.DataStore, e, disableErrorHandler)

export const usePipeline = (api: AxiosInstance, e: IPipeline | undefined, disableErrorHandler?: boolean) => {

    const rel = EntityRel.Pipeline
    const { rest, ...pipeline } = useCRUDEntity(api, rel, e, disableErrorHandler)

    const draftLink = pipeline._links[`draft ${rel.item}`]

    const draft = async (data: Partial<IPipeline>) => {
        if (!draftLink) throw Error(`Entity '${rel.item}' cannot be drafted`)
        return rest.put(draftLink.href, data)
    }

    return {
        ...pipeline,
        draft
    }

}

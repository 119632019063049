import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react';
import axios from 'axios'
import { APIContext } from 'src/contexts/api'
import { logError } from 'src/utils/Error';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
      '.MuiInputBase-input': {
        width: '100%',
      },
    },
  })
);

/*
 * A custom Data Plugin field for Google Drive 'gdrive.file_id'
 * 
 * Fetches and displays a list of files and folders for the user to choose.
 */

const GoogleDriveFileIdField: FunctionComponent<any> = (props: PropsWithChildren<any>) => {
  const {
    properties,
    setting,
    prefix,
    handleSettingsChange,
    handleSettingsChangeEvent,
    handleSettingsUpdate,
    currentValue,
    error
  } = props
  const propertyName = prefix ? `${prefix}.${setting.name}` : setting.name
  const accessToken = properties[prefix ? `${prefix}.oauth_credentials.access_token` : 'oauth_credentials.access_token']
  const refreshToken = properties[prefix ? `${prefix}.oauth_credentials.refresh_token` : 'oauth_credentials.refresh_token']
  const { label, required } = setting;
  const { catalogAPI } = useContext(APIContext);
  const classes = useStyles();
  const isRequired = required == undefined ? true : (required.toString().toLowerCase() !== 'false')
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentOption, setCurrentOption] = useState<any>({});
  const [searchText, setSearchText] = useState('');
  const settingsChange = (event: any, newValue: any) => {
    handleSettingsChangeEvent ? handleSettingsChangeEvent(event) : null
    handleSettingsChange(propertyName, newValue ? newValue.id : newValue)
    setSearchText(event.target.value)
  }

  /*
   * Refresh the access_token used to GET Google Drive Files. 
   */
  const refreshAccessToken = async (refreshToken: any) => {
    const exchangeBody = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }

    await catalogAPI
      .post(encodeURI(`/tokens/oauth2-google/token`), exchangeBody, { errorHandler: false })
      .then((resp: any) => {
        const data = resp.data;
        properties[prefix ? `${prefix}.oauth_credentials.access_token` : 'oauth_credentials.access_token'] = data.access_token
        handleSettingsUpdate(properties)
      })
      .catch((e: any) => {
        logError(e);
      });
  }

  const getFiles = async () => {
    let params = {};

    if (searchText) {
      params = {q: `name contains '${searchText}'`}
    }
  
    const url = 'https://www.googleapis.com/drive/v3/files'
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })
      .then(resp => {
        const files = resp.data
        if (files) {
          setOptions(files.files);
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 401) {
          refreshAccessToken(refreshToken)
        } else {
          logError(error)
          setIsLoading(false)
        }
  })

  }

  useEffect(() => {
    if (!isLoading)
      return

    getFiles()

  }, [isLoading, accessToken]);

  useEffect(() => {
    if (!searchText) {
      return
    }
    getFiles()
  }, [searchText]);

  useEffect(() => {
    const selectedOption = options.find((o: any) => o.id == currentValue)
    setCurrentOption(selectedOption || currentValue || {})
  }, [isLoading, options, currentValue]);


  const input = (
    <Autocomplete
      data-testid="gdrive.file-id"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={settingsChange}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) => option.id ? `${option.name}` : ''}
      options={isLoading ? [] : [{}, ...options]}
      loading={isLoading}
      ListboxProps={{ 'data-testid': 'gdrive.file-id-listbox' }}
      value={currentOption}
      renderOption={(option: any) => {
        return (
            <React.Fragment>
                {option.mimeType == 'application/vnd.google-apps.folder' ? <span className={`fas fa-folder pad_lr10`} /> : <span className={`fas fa-file pad_lr10`} /> }
                <div className='pad_lr10'>{option.name}</div>
            </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error?.defaultMessage || ''}
          label={label}
          name={propertyName}
          variant="outlined"
          required={isRequired}
          InputProps={{
            ...params.InputProps,
            startAdornment: (<>{currentOption.mimeType == 'application/vnd.google-apps.folder' ? <span className={`fas fa-folder pad_lr10`} /> : <span className={`fas fa-file pad_lr10`} /> }</>),
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )

  return <div className={classes.root}>{input}</div>;
};

export default GoogleDriveFileIdField;

import React, { useContext, useRef, useState } from 'react'
import { IconButton, Link, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import ReactMarkdown from 'react-markdown'
import 'src/assets/css/components/entityRow.css'
import chainIcon from 'src/assets/images/svgs/chain-icon.svg'
import ImageHolder from 'src/components/ImageHolder'
import useCRUDEntity from 'src/hooks/useCRUDEntity'
import { IImageHolder } from 'src/models/components/IImageHolder'
import EntityRel from 'src/models/EntityRel'
import { INamedEntity } from 'src/models/IEntity'
import ConfirmDialog from './ConfirmDialog'
import { useAPIContext } from 'src/contexts/api'
import { WindowContext } from 'src/contexts/window';
import useRowStyles from 'src/assets/css/components/RowComponentsStyles';
import arrowRight from 'src/assets/images/svgs/arrow_left.svg'

interface IEntityRow<R extends EntityRel, E extends INamedEntity> {
    entityRel: R
    entity: E
    disableImage?: boolean
    disableMenu?: boolean
    ImageHolderProps?: Partial<IImageHolder>
    description?: string
    actions?: JSX.Element
    expandContent?: React.ReactNode
    onClosed?: () => void
}

export const EntityRow = <R extends EntityRel, E extends INamedEntity>(props: IEntityRow<R, E>): JSX.Element => {
    const classes = useRowStyles();
    const { size } = useContext(WindowContext);
    const { catalogAPI: api } = useAPIContext()

    const entity = useCRUDEntity(api, props.entityRel, props.entity)

    const [isExpanded, setExpanded] = useState(false)
    const toggleExpanded = () => setExpanded(!isExpanded)

    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const [more, setMore] = useState(false)
    const toggleMore = () => setMore(more => !more)

    const menuAnchorRef = useRef<Element>()

    const onOpenMenu = (e: React.MouseEvent) => {
        menuAnchorRef.current = e.currentTarget
        setMenuOpen(true)
    }

    const onCloseMenu = () => setMenuOpen(false)
    const onMenuDelete = () => setDeleteDialogOpen(true)
    const onDeleteDialogClose = () => setDeleteDialogOpen(false)

    const displayName = ((): string => {
        const matchingField = [
            'label',
            'alias',
            'name'
        ].find(f => entity[f])
        return matchingField ? entity[matchingField] : entity.id
    })()

    const menuOptions = [
        {
            label: "Delete",
            isDisabled: !entity.canDelete,
            onClick: onMenuDelete
        }
    ]

    const iconButton = (
        <div className={`${(size.smallScreen || size.mediumScreen) ? 'actions-menu-mobile' : ''}`}>
            <IconButton
                className={classes.button}
                aria-label='more'
                onClick={onOpenMenu}
                title="More">
                <MoreVert className={classes.largeIcon} />
            </IconButton>
        </div>
    );

    const buttons = (
        <>
            {iconButton}
            <Menu
                aria-label='menu'
                open={isMenuOpen}
                anchorEl={menuAnchorRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={onCloseMenu}>
                {menuOptions.map(o =>
                    <MenuItem
                        key={o.label}
                        aria-label={o.label}
                        dense
                        disabled={o.isDisabled}
                        onClick={o.onClick}>
                        {o.label}
                    </MenuItem>
                )}
            </Menu>
        </>
    )
    
    return <>
        <div
            aria-label={displayName}
            className='dataRow-container'
            style={{ backgroundColor: 'white' }}>
            <div className='row-info-container source-search flex-wrap collapse-pipeline-datastore'>
                <div className='row-data'>
                    {props.disableImage ||
                        <ImageHolder
                            {...props.ImageHolderProps}
                            imageUrl={props.ImageHolderProps?.imageUrl || chainIcon} />}
                    <div className="row-content">
                        <div className='pipeline-datastore-info'>
                            <div className='h3-container'>
                                <h3>{displayName}</h3>
                                {props.description && <>
                                    <ReactMarkdown
                                        className={[
                                            'description',
                                            more && 'more'
                                        ].filter(Boolean).join(' ')} >
                                        {props.description}
                                    </ReactMarkdown>
                                    <Link
                                        className='more'
                                        component='button'
                                        underline='always'
                                        onClick={toggleMore}>
                                        {more ? "Less": "More"}
                                    </Link>
                                </>}
                            </div>
                            <div style={{ display: 'flex' }}>
                                {props.expandContent &&
                                    <div
                                        className={`button-wrapper ${(size.smallScreen || size.mediumScreen) ? 'mobile' : ''} ${isExpanded ? 'collapse-center' : 'expand-center'}`}
                                        title={`${isExpanded ? 'Collapse' : 'Expand'}`}
                                        onClick={toggleExpanded}>
                                        <span className={`info-action ${isExpanded ? 'expanded' : ''}`}>
                                            <img src={arrowRight} />
                                        </span>
                                    </div>}
                                {props.disableMenu || buttons}
                                {props.actions && <div className='actions'>{props.actions}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                {isExpanded && props.expandContent}
            </div>
        </div>
        <ConfirmDialog
            isOpen={isDeleteDialogOpen}
            title={`Delete '${displayName}'?`}
            message={`Remove this ${props.entityRel.item} from the workspace. This action cannot be undone.`}
            confirmLabel="Delete"
            handleConfirm={entity.delete_}
            handleClose={onDeleteDialogClose}
            handleClosed={props.onClosed} />
    </>

}

export default EntityRow

import React, { useContext, useState, FunctionComponent, PropsWithChildren, useRef, useEffect } from 'react'
import Moment from 'react-moment'
import { Auth0Context, useAuth0 } from '../contexts/auth0'
import { bindContexts } from '../utils/bindContexts'
import 'src/assets/css/components/jobRow.css'
import calendarIcon from '../assets/images/svgs/calendar.svg';
import scheduleIcon from '../assets/images/svgs/schedule.svg';
import playIcon from '../assets/images/svgs/play.svg';
import deleteIcon from '../assets/images/svgs/delete.svg'
import downloadIcon from '../assets/images/svgs/download-icon.svg'
import { IJobRowProps } from 'src/models/components/IJobRow'
import ndjsonStream from 'src/utils/stream/ndjson-stream'
import stringStream from 'src/utils/stream/string-stream'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { WindowContext } from 'src/contexts/window';
import { logError } from 'src/utils/Error'
import useRowStyles from 'src/assets/css/components/RowComponentsStyles'
import IJob, { JobStatus } from 'src/models/IJob'
import ILog from 'src/models/ILog'


const JobRow: FunctionComponent<IJobRowProps> = (props: PropsWithChildren<IJobRowProps>) => {
  const classes = useRowStyles();
  const { size } = useContext(WindowContext)
  const { job, jobMarker, renderLogs, openLog, handleOpenJobConfirmDialog, downloadLog } = props
  const { getTokenSilently } = useAuth0()
  const [state, setState] = useState({
    isMenuOpen: false,
    menuAnchorEl: null as null | EventTarget & HTMLButtonElement,
  });
  const jobRef = useRef(job)

  useEffect(() => {
    jobRef.current = job
  }, [job])

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setState({ ...state, isMenuOpen: true, menuAnchorEl: e.currentTarget })
  const handleCloseMenu = () => setState({ ...state, isMenuOpen: false })

  const getLog = async (prevJob: IJob, sequence?: string) => {
    const job = jobRef.current
    const logsHref = new URL(job._links.logs.href)

    openLog(job)

    if (sequence !== undefined)
      logsHref.searchParams.set('sequence', sequence)

    try {
      const token = await getTokenSilently()

      await fetch(logsHref.toString(), {
        headers: {
          Accept: 'application/stream+json',
          Authorization: `Bearer ${token}`
        }
      })
        .then(async (response) => {
          if (response.status == 200) {
            if (typeof response.body !== 'undefined' && response.body != null) {
              return ndjsonStream(response.body)
            } else {
              const body = await response.text()
              return ndjsonStream(stringStream(body))
            }
          }
          return null
        })
        .then(async (jsonStream: ReadableStream<ILog> | null) => {
          if (!jsonStream)
            return

          const reader = jsonStream.getReader()
          const logs: ILog[] = []

          while (true) {
            const result = await reader.read()

            if (!result.done) {
              logs.push(result.value)
              continue
            }

            const promiseId = ([JobStatus.Queued, JobStatus.Running].includes(job.status) || job.status !== prevJob?.status)
              ? setTimeout(getLog, 5000, job, logs[logs.length - 1]?.sequence)
              : null

            return renderLogs(logs, promiseId)
          }
        })
        .catch(logError)
    } catch (e) {
      logError(e)
    }
  }

  const downloadLogs = () => {
    setState({ ...state, isMenuOpen: false })
    downloadLog(job);
  }

  const showLogs = () => {
    setState({ ...state, isMenuOpen: false })
    getLog(job)
  }

  const deleteJob = () => {
    setState({ ...state, isMenuOpen: false })
    handleOpenJobConfirmDialog(job)
  }

  return (
        <div
          id={job.id}
          className={'vertical-center data-job-row'}>
            <div className={'vertical-center info'}>
              <span>
                <img src={calendarIcon} alt="calendar icon" />
                <Moment format="YYYY-MM-DD HH:mm" date={job.created} utc={true} local={true} />
              </span>
              <span>
                <img src={playIcon} alt="play icon" />
                {`by ${job.attempt
                  ? `retry (attempt ${job.attempt}/${job.maxAttempts})`
                  : job.triggeredBy
                  || job._embedded.profile?.email
                  || "schedule"}`}
              </span>
              <span className={`vertical-center status circle ${jobMarker(job.status)}`}>
                {job.status}
              </span>
              <span>
                <img src={scheduleIcon} alt="schedule icon" />
                {job.startTime && job.endTime
                  ? <Moment from={job.startTime}>{job.endTime}</Moment>
                  : <Moment interval={1000} date={job.startTime} utc={true} durationFromNow={true} trim={true} format="h[h]m[m]s[s] [elapsed]" />}
              </span>
            </div>
            <div className="d-flex job-actions">
              {!size.smallScreen ? (
                <>
                  {job._links.logs && <>
                    <span
                      className="logs-button"
                      onClick={showLogs}>
                        View logs
                    </span>
                    <div className="button-wrapper" 
                      style={{ cursor: 'pointer' }}
                      title="Download logs"
                      onClick={downloadLogs}>
                      <img src={downloadIcon} />
                    </div>
                  </>}
                  {job._links['delete job'] &&
                    <div className="button-wrapper"
                      style={{ cursor: 'pointer' }}
                      onClick={deleteJob}
                      title="Delete job history">
                      <img className="delete-job" src={deleteIcon} />
                    </div>}
                </>
              ) : (
                <>
                  <Menu 
                    data-testid="context-menu"
                    className={classes.dropdownMenu}
                    open={state.isMenuOpen}
                    anchorEl={state.menuAnchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={handleCloseMenu}>
                    {job._links.logs &&
                      <MenuItem
                        className={classes.dropdownItem}
                        dense
                        onClick={downloadLogs}>
                        <img src={downloadIcon} />
                        <span>Download</span>
                      </MenuItem>}
                    {job._links.logs &&
                      <MenuItem
                        className={classes.dropdownItem}
                        dense
                        onClick={showLogs}>
                        <svg width="20" height="20" viewBox="0 0 20 20" 
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.6792 18.9623H0V0H18.9623V13.6792H17.6415V1.32075H1.32075V17.6415H13.6792V18.9623Z" fill="#5E677B"/>
                          <path d="M14.5283 13.5849C15.3774 12.5472 15.8491 11.2264 15.8491 9.90568C15.8491 8.39624 15.283 6.88681 14.1509 5.66039C11.7925 3.39624 8.01887 3.39624 5.66038 5.66039C3.30189 8.01888 3.30189 11.7925 5.66038 14.0566C6.79245 15.1887 8.30189 15.7547 9.90566 15.7547C11.2264 15.7547 12.5472 15.283 13.5849 14.434L19.0566 19.9057L20 18.9623L14.5283 13.5849ZM6.60377 13.2076C4.81132 11.3208 4.81132 8.39624 6.60377 6.60379C7.54717 5.66039 8.67925 5.28303 9.90566 5.28303C11.0377 5.28303 12.2642 5.75473 13.2075 6.60379C15 8.39624 15 11.3208 13.2075 13.1132C11.3208 15 8.39623 15 6.60377 13.2076Z" fill="#5E677B"/>
                        </svg>
                        <span>Logs</span>
                      </MenuItem>}
                    {job._links['delete job'] &&
                      <MenuItem
                        className={classes.dropdownItem}
                        dense
                        onClick={deleteJob}>
                        <img src={deleteIcon} />
                        <span>Delete</span>
                      </MenuItem>}
                  </Menu>
                  <IconButton
                    className={classes.button}
                    aria-label='actions-menu'
                    onClick={handleOpenMenu}
                    data-testid="actions-menu"
                    title="More">
                    <MoreVert className={classes.largeIcon} />
                </IconButton>
              </>)}
            </div>
        </div>
  )
}

export const JobRowWithContext = bindContexts(JobRow, [
  Auth0Context,
])

export default JobRow


import React, { FunctionComponent, useState, useEffect, PropsWithChildren } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { ILogModalProps } from 'src/models/components/ILogModal'
import downloadIcon from '../assets/images/svgs/download-icon.svg'
import Ansi from 'ansi-to-react'

const LogModal: FunctionComponent<ILogModalProps> = (props: PropsWithChildren<ILogModalProps>) => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (props.job.status !== 'RUNNING' && props.job.status !== 'QUEUED' && props.logArray.length) setReady(true)
    else if (props.logArray.length || props.taskId) setReady(true)
  }, [props.logArray.length, props.taskId, props.job.status])
  return (
        <>
            <Modal
                show={props.show}
                onHide={props.hideLog}
                dialogClassName="my-modal-style modal-90w"
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="log-modal-title">
                        {props.job?.status}
                        {props.job.status !== 'RUNNING' && props.job.status !== 'QUEUED'
                          ? (
                            <span className="log-download-button" onClick={() => props.downloadLog(props.job)}>
                                <img
                                    style={{ cursor: 'pointer' }}
                                    src={downloadIcon}
                                    alt="Download logs"
                                    title="Download logs"
                                />
                                Download
                            </span>
                            )
                          : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ready
                      ? (
                          props.logArray.length
                            ? (
                                props.logArray.map((log: any, index: number) => {
                                  return (
                                    <div className="log-data-row" key={index}>
                                        <code
                                            className="log-sequance"
                                            data-unselectable={`${log.sequence} |`} />
                                        <code
                                            className="log-timestamp"
                                            data-unselectable={`${log.timestamp} |`} />
                                        <Ansi>{log.message}</Ansi>
                                    </div>
                                  )
                                })
                              )
                            : (
                            <div>No logs yet</div>
                              )
                        )
                      : (
                        <div className="text-center">
                            <Spinner animation="border" className="m_top20" />
                        </div>
                        )}
                </Modal.Body>
            </Modal>
        </>
  )
}

export default LogModal

import React, { FunctionComponent, PropsWithChildren } from 'react';
import checkTick from '../assets/images/check.png'
import { Button } from '@material-ui/core'

//
// The task card - used on setup tasks.
//
const TaskCard: FunctionComponent<any> = (props: PropsWithChildren<any>) => {
    const { workspace, index, job, handleDismiss, handleLetsGo, title, message } = props;
    const userIcon = job && job.type && job.type.startsWith("PROFILE_") ? (<i className="fas fa-user" />) : null;
    const commitId = job.commitId?.substring(0, 7)

    return (
      <div
        key={index}
        aria-label={title}
        className="task-card task-card-mobile"
      >
        <div className="task-card-title">
          {job && job.status == "COMPLETE" && (<div className="tick-circle" style={{border: '1px solid #449569'}}><img src={checkTick} /></div>)}
          {job && job.status == "RUNNING" && (<div className="tick-circle tick-circle-running" style={{backgroundColor: '#454F66'}}></div>)}
          {job && job.status != "COMPLETE" && job.status != "RUNNING" && (<div className="tick-circle">{userIcon}</div>)}
          <span className="task-title-label">{title}</span>
          {commitId && <code>
            {workspace.repositoryUrl.includes("https://github.com/")
              ? <a
                href={`${workspace.repositoryUrl.replace(/\.git$/, '')}/tree/${job.commitId}`}
                target='_blank'
                rel='noreferrer'>
                {commitId}
              </a>
              : commitId}
          </code>}
        </div>
        <span className="onboarding-text-task long-text-div">{message}</span>
        { (handleDismiss || handleLetsGo) && (
        <div className="tasks-buttons">
          { handleDismiss && (
            <span className="onboarding-text-task taskcard-link-text" onClick={handleDismiss}>Dismiss</span>
          )}
          { handleLetsGo && (
            <Button
                variant="contained"
                color="primary"
                onClick={handleLetsGo}
                disabled={workspace == null}
            >
                Let&apos;s go
            </Button>
          )}
        </div>
        )}
      </div>
    );
};

export default TaskCard;

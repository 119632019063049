/**
 * WARNING - uses the generated globals, imported in index.html. See env.sh and .env
 */
export var AppEnv = {
  APP_ENVIRONMENT: window._env_.APP_ENVIRONMENT,
  APP_TITLE: window._env_.APP_TITLE,
  APP_VERSION: window._env_.APP_VERSION,
  APP_IDENTITY_DOMAIN: window._env_.APP_IDENTITY_DOMAIN,
  APP_IDENTITY_CLIENT_ID: window._env_.APP_IDENTITY_CLIENT_ID,
  APP_IDENTITY_CONNECTION: window._env_.APP_IDENTITY_CONNECTION,
  APP_IDENTITY_AUDIENCE: window._env_.APP_IDENTITY_AUDIENCE,
  APP_OAUTH_GOOGLE_CLIENT_ID: window._env_.APP_OAUTH_GOOGLE_CLIENT_ID,
  APP_SERVER_URI: window._env_.APP_SERVER_URI,
  APP_MANAGE_ACCOUNT_URL: window._env_.APP_MANAGE_ACCOUNT_URL,
  APP_FEATURES_DISABLED: window._env_.APP_FEATURES_DISABLED,
  APP_DISABLED_FEATURE_DEFAULT_TOOLTIP_TITLE: window._env_.APP_DISABLED_FEATURE_DEFAULT_TOOLTIP_TITLE,
  APP_PLACEHOLDER_MINIMUM_LOAD: window._env_.APP_PLACEHOLDER_MINIMUM_LOAD,
  APP_PLACEHOLDER_LOAD_TIMEOUT: window._env_.APP_PLACEHOLDER_LOAD_TIMEOUT,
  APP_SNACKBAR_TIMEOUT: window._env_.APP_SNACKBAR_TIMEOUT,
  APP_SENTRY_TRACES_SAMPLE_RATE: window._env_.APP_SENTRY_TRACES_SAMPLE_RATE
}

export var AppFeatures = {
  DATA: 'DATA',
  STARRED: 'STARRED',
  MANAGE_ACCOUNT: 'MANAGE_ACCOUNT',
  NEWS: 'NEWS',
  SHARE_TEAMS: 'SHARE_TEAMS',
  SHARE_SLACK: 'SHARE_SLACK',
  SHARE_MESSAGE: 'SHARE_MESSAGE',
  WORKSPACE_REPOSITORY: 'WORKSPACE_REPOSITORY',
  LAB_PROJECT: 'LAB_PROJECT',
  SAVE_LIST: 'SAVE_LIST',
  WORKSPACE_ADVANCED_CONFIG: 'WORKSPACE_ADVANCED_CONFIG',
  LIBRARY: 'LIBRARY'
}

export var WorkspaceFeatures = {
  QUESTIONS: 'QUESTIONS'
}

/*
 * Check globally disabled features
 */
export function isFeatureEnabled (feature) {
  if (AppEnv.APP_FEATURES_DISABLED) {
    const features = AppEnv.APP_FEATURES_DISABLED.split(',')
    return features.indexOf(feature) < 0
  }
  return true
}

/*
 * Use the workspace app properties to check if feature is enabled.
 */
export function isWorkspaceFeatureEnabled (workspace, feature) {
  if (typeof workspace !== 'undefined' && workspace) {
    if (typeof workspace.appProperties !== 'undefined' && workspace.appProperties) {
      const featureKey = 'FEATURE_' + feature
      return (/true/i).test(workspace.appProperties[featureKey]);
    }
  }
  return false
}

export default AppEnv

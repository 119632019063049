import * as React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import ContentLoader from 'react-content-loader'
import CopyToClipboard from 'react-copy-to-clipboard'
import { withRouter } from 'react-router-dom'
import Workbench from 'src/components/Workbench'
import Back from 'src/components/Back';
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { IAPIKeyState, IPropState } from '../models/views/IApiKey'
import { bindContexts } from '../utils/bindContexts'
import copyIcon from './../assets/images/copyIcon.png'
import { APIContext, getAllApiKeysURI } from '../contexts/api'
import { AxiosInstance } from 'axios'
import { logError } from 'src/utils/Error'
import * as Mui from '@material-ui/core'
import { ReactComponent as AddIcon } from '../assets/images/svgs/add.svg'
import ApiKeyRow from 'src/components/ApiKeyRow'
import NewApiKeyDialog from 'src/components/NewApiKeyDialog'
import 'src/assets/css/views/ApiKey.css'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import IApiKey from 'src/models/IApiKey'
import IRoot from 'src/models/components/IRoot'
import { AppEnv } from 'src/utils/appenv'

class APIKey extends React.Component<IPropState, IAPIKeyState> {
    static contextType = MergedContexts;

    public isThisMounted = false;
    public token_str = '';
    constructor(props: IPropState) {
        super(props)
        this.state = {
            ready: false,
            token: '',
            copied: false,
            apiKeys: [],
            isDialogOpen: false,
        }
    }

    public async componentDidMount() {
        const { getTokenSilently, loading, getRoot } = this.context
        this.isThisMounted = true
        this.props.setViewName && this.props.setViewName("API Keys")
        const token = !loading ? await getTokenSilently() : undefined
        this.getApiKeys()
        await getRoot?.()
        this.setState({ token, ready: true })
    }

    public componentWillUnmount() {
        this.isThisMounted = false
    }

    private async getApiKeys() {
        const { catalogAPI } = this.context
        const api = catalogAPI as AxiosInstance

        await api.get(getAllApiKeysURI())
            .then(resp => {
                if (this.isThisMounted) {
                    this.setState({ apiKeys: resp.data._embedded.apikeys })
                }
            })
            .catch(e => {
                if (this.isThisMounted) {
                    logError(e)
                }
            });
    }

    private async updateApiKey(apiKey: IApiKey) {

        const { catalogAPI } = this.context
        const api = catalogAPI as AxiosInstance

        const endpoint = apiKey._links['update apikey'].href
        await api.put(endpoint, apiKey)
            .catch(logError);

    }

    private async deleteApiKey(apiKey: IApiKey) {

        const { catalogAPI } = this.context
        const api = catalogAPI as AxiosInstance

        const endpoint = apiKey._links['delete apikey'].href
        await api.delete(endpoint, { data: apiKey })
            .catch(logError);

    }

    public render() {
        const { ready, token, copied, apiKeys, isDialogOpen } = this.state
        const root: IRoot | undefined = this.context.rootRef.current

        const contentLoader = (
            <Col className={ready ? 'feed-hidden loaderfadeOut' : 'feed'}>
                <div className="dataLoader_parent d-flex" data-testid="loader">
                    <Col className="dataLoader pad_0">
                        <ContentLoader className="placeholderList" viewBox="0 0 446 15" speed={2}>
                            <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                        </ContentLoader>
                        <ContentLoader className="placeholderDivider w-100" height={10} speed={2}>
                            <rect x="0" y="5" height="2" />
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 446 18" speed={2}>
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="6" />
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 446 50" speed={2}>
                            <rect x="5" y="8" rx="0" ry="0" width="220" height="3" />
                            <rect x="5" y="16" rx="0" ry="0" width="220" height="8" />
                        </ContentLoader>
                    </Col>
                </div>
            </Col>
        )

        const handleCopiedToastOpen = (text: string) => {
            if (text) this.setState({ copied: true })
        }
        const handleSnackbarClose = () => this.setState({ copied: false })
        const handleDialogOpen = () => this.setState({ isDialogOpen: true })
        const handleDialogClose = () => this.setState({ isDialogOpen: false })
        const handleAddApiKey = (apiKey: IApiKey) => this.setState({ apiKeys: [...apiKeys, apiKey] })
        const handleUpdateApiKey = (apiKey: IApiKey) => this.updateApiKey(apiKey)
        const handleRemoveApiKey = (apiKey: IApiKey) => this.deleteApiKey(apiKey).then(() => {
            if (apiKey.name === 'default') {
                this.getApiKeys()
                return
            }

            apiKeys.splice(apiKeys.findIndex(a => a.id === apiKey.id), 1);
            this.setState({ apiKeys: apiKeys })
        })

        const newTokenDisabled = !root?._links['new apikey']

        return (
            <>
                <Workbench>
                    <div className="w-100" data-testid="resolved">
                        {ready ? (
                            <div className="full_pageContent clearfix">
                                <Back />
                                <ScrollAnimation animateIn="fadeIn" offset={10}>
                                    <h1>Developer Token</h1>
                                    <Form.Group controlId="profileForm.name" className="form-group">
                                        <Form.Text>
                                            This is a short-lived API token for testing and development.
                                        </Form.Text>
                                        <CopyToClipboard
                                            onCopy={handleCopiedToastOpen}
                                            text={token}
                                        >
                                            <InputGroup className="input_with_copy_icon col-4">
                                                <Form.Control
                                                    type="text"
                                                    value={token || ''}
                                                    name="token"
                                                    onChange={() => { return }}
                                                />
                                                <InputGroup.Append
                                                    className="m_0"
                                                    data-testid="copyButton"
                                                >
                                                    <img id="copy-btn" src={copyIcon} alt="copy" />
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </CopyToClipboard>
                                    </Form.Group>
                                    <Mui.Grid
                                        container
                                        justifyContent='space-between'>
                                        <Mui.Grid
                                            item
                                            xs={6}
                                            container>
                                            <div>
                                                <h1>Personal Access Tokens</h1>
                                                <small className="form-text">Long-lived api clients for managing App or 3rd party access to your workspaces.</small>
                                            </div>
                                        </Mui.Grid>
                                        <Mui.Grid
                                            item
                                            xs={6}
                                            container
                                            direction='row-reverse'>
                                            <Mui.Tooltip

                                                arrow
                                                title={newTokenDisabled ? AppEnv.APP_DISABLED_FEATURE_DEFAULT_TOOLTIP_TITLE : ""}>
                                                {/* disabled MUI components must be wrapped by another element to display a tooltip on hover
                                                    https://v4.mui.com/components/tooltips/#disabled-elements */}
                                                <span>
                                                    <Mui.Button
                                                        variant='contained'
                                                        disabled={newTokenDisabled}
                                                        startIcon={(
                                                            <Mui.SvgIcon>
                                                                <AddIcon />
                                                            </Mui.SvgIcon>
                                                        )}
                                                        onClick={handleDialogOpen} >
                                                        New token
                                                    </Mui.Button>
                                                </span>
                                            </Mui.Tooltip>
                                        </Mui.Grid>
                                    </Mui.Grid>
                                    {isDialogOpen &&
                                        <NewApiKeyDialog
                                            handleClose={handleDialogClose}
                                            handleAddApiKey={handleAddApiKey}
                                            handleCopyClientCredentials={handleCopiedToastOpen} />}
                                    <div className="apikey-list">
                                        {apiKeys.length ? (
                                            <>
                                                {apiKeys.map(a => (
                                                    <ApiKeyRow
                                                        key={a.id}
                                                        apiKey={a}
                                                        handleUpdateApiKey={handleUpdateApiKey}
                                                        handleRemoveApiKey={handleRemoveApiKey}
                                                        handleCopyClientIdOrAccessToken={handleCopiedToastOpen}>
                                                    </ApiKeyRow>
                                                )
                                                )}
                                            </>
                                        ) : null }
                                    </div>
                                </ScrollAnimation>
                            </div>
                        ) : contentLoader}

                    </div>
                </Workbench>
                <Snackbar
                    open={copied}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}>
                    <Alert severity='success'>Copied</Alert>
                </Snackbar>
            </>
        )
    }
}

export const APIKeyWithContext = bindContexts(APIKey, [
    Auth0Context,
    APIContext
])
export default withRouter(APIKeyWithContext)

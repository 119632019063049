import * as assert from 'assert-plus';
import { useState, useEffect, useRef } from 'react';
import { logError } from 'src/utils/Error';
import IError from 'src/models/IError';
import IValidationError from 'src/models/IValidationError';
import { createNewWorkspaceURI } from 'src/contexts/api';

//
// Configures the add / edit workspace form
//

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useWorkspaceForm = (api: any,
    savedCallback?: (updatedWorkspace: any) => void,
    deletedCallback?: (deletedWorkspace: any) => void,
) => {

    const isMounted = useRef(false)
    useEffect(() => {
      isMounted.current = true;
      return () => { isMounted.current = false }
    }, []);

    const [workspace, setWorkspace] = useState({
      id: null,
      name: '',
      domains: '',
      imageUrl: '',
      defaultEnvironment: undefined,
      repositoryUrl: undefined,
      repositoryBranch: undefined,
      sshPrivateKey: undefined,
      githubInstallationId: undefined,
      appProperties: {},
      _links: {}
    });

    const [errors, setErrors] = useState<IError[]>([]);
    const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);

    const [name, setName] = useState<string>('');
    const [domains, setDomains] = useState<null | string>(null);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [defaultEnvironment, setDefaultEnvironment] = useState<string>()
    const [repositoryUrl, setRepositoryUrl] = useState<string>()
    const [repositoryBranch,  setRepositoryBranch] = useState<string>()
    const [sshPrivateKey,  setSshPrivateKey] = useState<string>()
    const [githubInstallationId,  setGithubInstallationId] = useState<string>()
    const [appProperties, setAppProperties] = useState({});

    const [isNew, setIsNew] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeploying, setDeploying] = useState(false);
    const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [disabledElements, setDisabledElements] = useState({
      isSubmitDisabled: false,
      isDeleteDisabled: false,
    });

    const defaultBranch = "main"
    const urlMatch = repositoryUrl?.match(/^(git@|https:\/\/)(?<host>\S+?)(:|\/)\S+/)
    const repostoryUrlHost = urlMatch?.groups?.["host"]
    const isRemoteGitRepo = !!(repositoryUrl?.endsWith(".git") || (repostoryUrlHost && ["github.com", "gitlab.com"].includes(repostoryUrlHost)))

    useEffect(() => {
      setIsNew(workspace && workspace._links && workspace._links['update delete workspace'] == null)
      // when editing, we have an existing workspace
      if (workspace) {
          workspace.name && setName(workspace.name);
          workspace.domains && setDomains(workspace.domains);
          workspace.imageUrl && setImageUrl(workspace.imageUrl);
          setDefaultEnvironment(workspace.defaultEnvironment);
          setRepositoryUrl(workspace.repositoryUrl);
          setRepositoryBranch(workspace.repositoryBranch);
          setSshPrivateKey(workspace.sshPrivateKey);
          setGithubInstallationId(workspace.githubInstallationId)
          setAppProperties(workspace.appProperties);
      }
    }, [workspace] )

    useEffect(() => {
        setDisabledElements({
            isSubmitDisabled: name == null || !name.length || isDeleting || isDeploying || isSaving,
            isDeleteDisabled: isDeleting || isDeploying || isSaving,
        })
    }, [name, isDeleting, isDeploying, isSaving] )

    async function initialiseWorkspace() {
        await api
          .post(createNewWorkspaceURI())
          .then((resp: any) => {
              isMounted.current && setWorkspace(resp.data)
          })
          .catch((e: any) => {
              isMounted.current && logError(e)
          })
    }

    function handleSubmit(event: any) {
        event.preventDefault();
        assert.object(workspace, 'workspace must be loaded before submit');
        assert.object(workspace._links, 'workspace links required');
        // create or update link
        const createLink = workspace._links['create workspace'];
        const updateLink = workspace._links['update delete workspace'];
        const link = updateLink ? updateLink : createLink;
        assert.object(link, 'workspace create or update link required');

        setIsSaving(true)

        // reset errors
        setErrors([]);
        // reset validation errors
        setValidationErrors([]);
        // define workspace to be created / updated
        const workspaceResource = {
            name: name,
            domains: domains,
            defaultEnvironment: defaultEnvironment || null,
            repositoryUrl: repositoryUrl,
            repositoryBranch: repositoryBranch || ((!repositoryUrl || isRemoteGitRepo) ? defaultBranch : undefined),
            sshPrivateKey: sshPrivateKey || undefined,
            githubInstallationId: githubInstallationId || undefined,
            ...(imageUrl && { imageUrl: imageUrl }),
            appProperties: appProperties
        }
        api.put(link.href, workspaceResource, { errorHandler: false })
            .then((resp: any) => {
                if (resp && (resp.status === 200 || resp.status === 201)) {
                    setIsSaving(false)
                    setWorkspace(resp.data)
                    savedCallback && savedCallback(resp.data)
                }
            })
            .catch((e: any) => {
                if (e.response && e.response.status === 400) {
                    const errors = e.response.data.errors
                    setValidationErrors(errors)
                } else {
                    if (e.response && e.response.data && e.response.data.message) {
                        const error = e.response.data
                        setErrors([error])
                    } else {
                        setErrors([{ message: "An unexpected error occurred" }])
                    }
                    logError(e);
                }
                setIsSaving(false)
            })
    }

    const handleDeploy = () => {
        setDeploying(true)

        const createDeploymentLink = workspace._links['create deployment'];
        assert.object(createDeploymentLink, '`create deployment` link not present')

        api.post(createDeploymentLink.href, { errorHandler: false })
            .catch(logError)
            .finally(() => setDeploying(false))
    }

    async function handleDelete() {
        assert.object(workspace, 'workspace must be loaded before delete');
        assert.object(workspace._links, 'workspace links required');

        setIsDeleting(true)

        // reset errors
        setErrors([]);
        // reset validation errors
        setValidationErrors([]);
        // delete link
        const deleteLink = workspace._links['update delete workspace'];
        api.delete(deleteLink.href, { errorHandler: false })
            .then(() => {
                setIsDeleting(false)
                deletedCallback && deletedCallback(workspace)
            })
            .catch((e: any) => {
                if (e.response && e.response.status === 400) {
                    const errors = e.response.data.errors
                    setValidationErrors(errors)
                } else {
                    if (e.response && e.response.data && e.response.data.message) {
                        const error = e.response.data
                        setErrors([error])
                    } else {
                        setErrors([{ message: "An unexpected error occurred" }])
                    }
                    logError(e);
                }
                setIsDeleting(false)
            })
            .finally(() => {
              setIsShowDeleteConfirmation(false)
            });
    }

    function handleRegenerateDeploymentSecret(event: any) {
        event.preventDefault();
        assert.object(workspace, 'workspace must be loaded before submit');
        assert.object(workspace._links, 'workspace links required');
        // create or update link
        const createLink = workspace._links['create workspace'];
        const updateLink = workspace._links['update delete workspace'];
        const link = updateLink ? updateLink : createLink;
        assert.object(link, 'workspace create or update link required');

        setIsSaving(true)

        // reset errors
        setErrors([]);
        // reset validation errors
        setValidationErrors([]);
        // define workspace to be created / updated
        const workspaceResource = {
            name: name,
            domains: domains,
            deploymentSecret: '',
            ...(imageUrl && { imageUrl: imageUrl }),
        }
        api.put(link.href, workspaceResource, { errorHandler: false })
            .then((resp: any) => {
                if (resp && (resp.status === 200 || resp.status === 201)) {
                    setIsSaving(false)
                    setWorkspace(resp.data)
                    savedCallback && savedCallback(resp.data)
                }
            })
            .catch((e: any) => {
                if (e.response && e.response.status === 400) {
                    const errors = e.response.data.errors
                    setValidationErrors(errors)
                } else {
                    if (e.response && e.response.data && e.response.data.message) {
                        const error = e.response.data
                        setErrors([error])
                    } else {
                        setErrors([{ message: "An unexpected error occurred" }])
                    }
                    logError(e);
                }
                setIsSaving(false)
            })
    }

    function onChangeName(event: any) {
      setName(event.target.value);
    }

    function onChangeDomains(event: any) {
      setDomains(event.target.value)
    }

    function onChangeImageUrl(imageUrl: any) {
        setImageUrl(imageUrl)
    }

    const onSetRepositoryUrl = (event: any) => setRepositoryUrl(event.target.value)
    const onSetRepositoryBranch = (event: any) => setRepositoryBranch(event.target.value)
    const onSetSshPrivateKey = (event: any) => setSshPrivateKey(event.target.value)
    const onSetGithubInstallationId = (event: any) => setGithubInstallationId(event.target.value)

    return {
        api,
        disabledElements,
        initialiseWorkspace,
        workspace,
        setWorkspace,
        name,
        onChangeName,
        domains,
        onChangeDomains,
        imageUrl,
        onChangeImageUrl,
        handleSubmit,
        handleDeploy,
        handleDelete,
        errors,
        validationErrors,
        isNew,
        setIsNew,
        isSaving,
        isDeploying,
        isShowDeleteConfirmation,
        setIsShowDeleteConfirmation,
        isDeleting,
        handleRegenerateDeploymentSecret,
        onSetRepositoryUrl,
        repositoryUrl,
        onSetRepositoryBranch,
        repositoryBranch,
        onSetSshPrivateKey,
        sshPrivateKey,
        onSetGithubInstallationId,
        githubInstallationId,
        defaultEnvironment,
        setDefaultEnvironment,
        defaultBranch,
        isRemoteGitRepo,
        repostoryUrlHost,
    };
};

export default useWorkspaceForm

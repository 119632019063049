import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as assert from 'assert-plus';
import { AxiosInstance, AxiosResponse } from 'axios';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'src/assets/css/views/stores.css';
import ConfirmDialog from 'src/components/ConfirmDialog';
import DataStoresRow from 'src/components/DataStoresRow';
import { DataStoreSearch } from 'src/components/Search';
import IDataPlugin from 'src/models/IDataPlugin';
import IDataStore from 'src/models/IDataStore';
import IPage from 'src/models/IPage';
import { logError } from 'src/utils/Error';
import history from 'src/utils/history';
import addIcon from '../assets/images/svgs/add.svg';
import dataIcon from '../assets/images/svgs/data.svg';
import pipelinesPlaceholder from '../components/placeholders/PipelinesPlaceholder';
import { APIContext } from '../contexts/api';
import { Auth0Context } from '../contexts/auth0';
import { MergedContexts } from '../contexts/merged';
import { WorkspaceContext } from '../contexts/workspace';
import { IDataStoresProps, IDataStoresState } from '../models/views/IDataStores';
import AppEnv from '../utils/appenv';
import { AsyncLoader, waitUntilReady } from '../utils/async';
import { bindContexts } from '../utils/bindContexts';

class DataStores extends React.Component<IDataStoresProps, IDataStoresState> implements AsyncLoader {
    public isThisMounted = false;
    static contextType = MergedContexts;
    constructor(props: IDataStoresProps) {
        super(props);
        this.state = {
            viewClass: 'listView',
            isLoading: true,
            waited: 0,
            ready: false,
            isConfirmDialogOpen: false,
            copied: false,
            dataStores: [],
            loaders: []
        };

        this.deleteDatastore = this.deleteDatastore.bind(this);
        this.handleMakeDefaultDatastore = this.handleMakeDefaultDatastore.bind(this);
        this.handleDeleteDatastore = this.handleDeleteDatastore.bind(this);
        this.handleOpenDatastoreConfirmDialog = this.handleOpenDatastoreConfirmDialog.bind(this)
        this.handleCloseDatastoreConfirmDialog = this.handleCloseDatastoreConfirmDialog.bind(this)

    }

    // implements AsyncLoader
    public isLoading(): boolean {
        return this.state.isLoading;
    }

    public componentDidMount() {
        this.isThisMounted = true;
        this.props.setViewName && this.props.setViewName("Data Stores")
        const { catalogAPI, workspace, getDataStores } = this.context;
        const api = catalogAPI as AxiosInstance;
        assert.func(api, 'APIContext must be initialise before this component is used');

        const isWorkspaceAdmin = () => workspace && workspace._links && workspace._links['update delete workspace'] != null

        if (!isWorkspaceAdmin()) history.push("/")

        getDataStores().then((datastores: IDataStore[]) => {
            this.setState({
                isLoading: false,
                dataStores: datastores
            })
        })

        this.initialiseLoadersList()
        // wait at least MINIMUM_LOAD, to show the placeholder smoothly on each load of this view
        waitUntilReady(
            parseInt(AppEnv.APP_PLACEHOLDER_MINIMUM_LOAD, 10),
            parseInt(AppEnv.APP_PLACEHOLDER_LOAD_TIMEOUT, 10),
            this.isThisMounted,
            this
        ).then(result => {
            if (this.isThisMounted) {
                this.setState({ ready: true, waited: result });
            }
        });
    }

    public componentWillUnmount() {
        this.isThisMounted = false;
    }

    private async initialiseLoadersList() {
        const { workspace, catalogAPI } = this.context;
        workspace._links && workspace._links['dataplugins'] && 
          await catalogAPI.get(workspace._links['dataplugins'].href + '?sort=label&size=9999')
            .then((r: AxiosResponse<IPage<IDataPlugin>>) => {
              const loaders = r.data._embedded?.dataplugins.filter(dp => !dp.matatikaHidden && dp.pluginType === 'LOADER')
              this.setState({ loaders: loaders })
            })
            .catch(logError)
    }

    public deleteDatastore(deletedDatastore: any) {
        this.setState({
            ready: true,
            dataStores: this.state.dataStores.filter(ds => ds.id !== deletedDatastore.id)
        })
    }

    public async handleMakeDefaultDatastore(datastore: IDataStore) {
        const { catalogAPI, workspace, fetchWorkspace } = this.context;
        await catalogAPI.put(datastore._links['make-default'].href)
            .then(async () => {
                await catalogAPI.get(workspace._links.datastores.href)
                    .then((r: AxiosResponse<IPage<IDataStore>>) => {
                        this.setState({ dataStores: r.data._embedded?.datastores || [] })
                        fetchWorkspace()
                    })
                    .catch(logError)
            })
            .catch(logError)
    }

    public async handleDeleteDatastore() {
        const { catalogAPI } = this.context;
        await catalogAPI.delete(this.state.datastore?._links['delete datastore'].href)
            .then(() => this.deleteDatastore(this.state.datastore))
            .catch(logError)
    }

    private handleOpenDatastoreConfirmDialog(datastore: any) {
        this.setState({ isConfirmDialogOpen: true, datastore: datastore })
    }
    private handleCloseDatastoreConfirmDialog() {
        this.setState({ isConfirmDialogOpen: false })
    }

    public render() {
        const { dataStores, ready, copied, loaders } = this.state;
        const { workspace } = this.context;
        const handleCopiedToastOpen = (text: string) => {
            if (text) this.setState({ copied: true })
        }
        const handleSnackbarClose = () => this.setState({ copied: false })
        assert.object(workspace, 'View must be used inside WorkspaceProvider');

        const onEdit = (dataStore: IDataStore) => history.push(`${location.pathname}/${dataStore.id}`)

        return (
            <>
                <ConfirmDialog
                    isOpen={this.state.isConfirmDialogOpen}
                    title={`Delete '${this.state.datastore?.name}'?`}
                    message="Remove this datastore from the workspace. This action cannot be undone."
                    confirmLabel="Delete"
                    handleClose={this.handleCloseDatastoreConfirmDialog}
                    handleConfirm={this.handleDeleteDatastore} />
                <div className='rightside_pageContent data-page'>
                    {ready ?
                        <DataStoreSearch
                            entities={dataStores}
                            new={
                                <div className="btn-add-data" onClick={this.props.onAdd}>
                                    <img
                                        className="data-add-icon"
                                        src={addIcon}
                                        alt="Import data" />
                                    Add
                                </div>}
                            row={e =>
                                <DataStoresRow
                                    loaders={loaders}
                                    datastore={e}
                                    key={e.id}
                                    handleMakeDefaultDatastore={this.handleMakeDefaultDatastore}
                                    handleOpenDatastoreConfirmDialog={this.handleOpenDatastoreConfirmDialog}
                                    handleCopyProp={handleCopiedToastOpen}
                                    onEdit={onEdit} />}
                            noEntities={
                                <div className="no-datasources content-center">
                                    <img
                                        alt="Data stores"
                                        src={dataIcon}>
                                    </img>
                                    <h1>No data stores yet.</h1>
                                </div>} /> :
                        pipelinesPlaceholder}
                </div>
                <Snackbar
                    open={copied}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}>
                    <Alert severity='success'>Copied</Alert>
                </Snackbar>
            </>
        );
    }
}

export const DataWithContext = bindContexts(DataStores, [Auth0Context, APIContext, WorkspaceContext]);

export default withRouter(DataWithContext);

import React, { FunctionComponent, PropsWithChildren } from 'react'

interface TestProps {
    name?: string;
}

const Test: FunctionComponent<TestProps> = (props: PropsWithChildren<TestProps>) => {
  let message = ''
  if (props.name) {
    message = 'Hello ' + props.name + '!'
  }
  return (
        <div className="workbench-container">
            <h2 data-testid="h2name">{message}</h2>
            {props.children}
        </div>
  )
}

export default Test

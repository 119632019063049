import React, { ReactNode } from 'react'
import ParentScrollAnimation, {
  ScrollAnimationProps as ParentProps
} from 'react-animate-on-scroll'

export interface ScrollAnimationProps extends ParentProps {
    // these props are not available in prop types on the current version
    afterAnimatedIn?: () => void;
    afterAnimatedOut?: () => void;
}

export default class ScrollAnimation extends React.Component<ScrollAnimationProps> {
  render () : ReactNode {
    return <ParentScrollAnimation {...this.props}>{this.props.children}</ParentScrollAnimation>
  }
}

import * as assert from 'assert-plus'
import * as React from 'react'
import ReactGA from 'react-ga4'
import { Redirect, Route, Switch } from 'react-router-dom'
import './App.css'
import './assets/css/global.css'
import Logout from './auth/Logout'
import PrivateRoute from './auth/PrivateRoute'
import { InstallPWA } from './components/InstallPWA'
import Toolbar from './components/Toolbar'
import { APIConsumer } from './contexts/api'
import { Auth0Consumer } from './contexts/auth0'
import { WindowConsumer } from './contexts/window'
import { WorkspaceConsumer } from './contexts/workspace'
import DebugApp from './debug/DebugApp'
import IRouteParams from './models/IRouteParams'
import { AppEnv, isFeatureEnabled, AppFeatures } from './utils/appenv'
import Error from './utils/Error'
import history from './utils/history'
import './utils/icons'
import initFontAwesome from './utils/initFontAwesome'
import Loading from './utils/Loading'
import APIKey from './views/APIKey'
import ChangePassword from './views/ChangePassword'
import Pipelines from './views/Pipelines'
import PipelineEdit from './views/PipelineEdit'
import DatasetOverview from './views/DatasetOverview'
import Signup from './views/Signup'
import EmailNotVerified from './views/EmailNotVerified'
import Explore from './views/Explore'
import Invitations from './views/Invitations'
import Starred from './views/Starred'
import Dashboard from './views/Dashboard'
import News from './views/News'
import Notifications from './views/Notifications'
import Profile from './views/Profile'
import Stores from './views/Stores'
import StoreEdit from './views/StoreEdit'
import WorkspaceEdit from './views/WorkspaceEdit'
import SetupTasks from './views/SetupTasks'
import OnboardingView from './views/OnboardingView'
import Plugins from 'src/views/Plugins'
import EntityRel from 'src/models/EntityRel'
import Workbench from 'src/components/Workbench'
import IEntity from 'src/models/IEntity'
import IDataStore from 'src/models/IDataStore'
import IPipeline from 'src/models/IPipeline'
import SearchEntities from 'src/views/SearchEntities'
import Project from 'src/views/Project'
import { DefaultAppHelp } from 'src/assets/help/views/defaultAppHelp'
import { LabHelp } from 'src/assets/help/views/labHelp'
import Help from './views/Help'
import Library from 'src/views/Library'
import IChannel from 'src/models/IChannel'
import Channels from './views/Channels'
import Dataset from './components/Dataset'
import IJob, { JobStatus } from './models/IJob'

initFontAwesome()

interface IAppState {
    isExpandedSidebarActive: boolean;
    prompt: boolean;
    viewName?: string;
}

interface IAppProps extends IRouteParams {
    appName?: string;
}

class App extends React.Component<IAppProps, IAppState> {
    public isThisMounted = false;

    constructor (props: IAppProps) {
      super(props)
      this.toggleSidebar = this.toggleSidebar.bind(this)
      this.setViewName = this.setViewName.bind(this)

      history.listen(location => {
        ReactGA.set({ page: location.pathname })
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "App Location" });
      })

      this.state = {
        isExpandedSidebarActive: !App.isRestrictedWidth() && App.getInitialExpanded(),
        prompt: false,
        viewName: 'Home',
      }
    }

    public static getInitialExpanded(): boolean {
        const storedValue = localStorage.getItem('isExpandedSidebarActive');
        return storedValue ? JSON.parse(storedValue) : true;
    }

    public static isRestrictedWidth(): boolean {
        return window.innerWidth < 1200
    }

    public checkForIOS () : any {
      const newVariable: any = window.navigator
      if (newVariable.standalone) {
        return false
      }

      const lastPrompt = localStorage.getItem('installPrompt')
      const ua = window.navigator.userAgent
      const isIPad = !!ua.match(/iPad/i)
      const isIPhone = !!ua.match(/iPhone/i)
      const isIOS = isIPad || isIPhone
      const prompt = !lastPrompt && isIOS
      return prompt
    }

    public shouldComponentUpdate(nextProps: Readonly<IAppProps>, nextState: Readonly<IAppState>): boolean {
        if (this.state.isExpandedSidebarActive !== nextState.isExpandedSidebarActive)
            return !App.isRestrictedWidth()

        return true
    }

    public componentDidUpdate(prevProps: IAppProps, prevState: IAppState): void {
        if (!App.isRestrictedWidth() && this.state.isExpandedSidebarActive !== prevState.isExpandedSidebarActive) {
            localStorage.setItem('isExpandedSidebarActive', JSON.stringify(this.state.isExpandedSidebarActive));
        }
    }

    public componentDidMount () : void {
      this.isThisMounted = true
      const checkprompt = this.checkForIOS()
      this.setState({ prompt: checkprompt })

      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "App Page" });
      document.title = AppEnv.APP_TITLE
      window.addEventListener('resize', () => {
        this.setState({ isExpandedSidebarActive: !App.isRestrictedWidth() && App.getInitialExpanded() })
      })
    }


    public componentWillUnmount () : void {
      // with thanks to - https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
      this.isThisMounted = false
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public renderApp (auth0: any, window: any, api: any, appContext: any) : any {
      if (auth0.loading) {
        return <Loading />
      }

      if (!auth0.isAuthenticated) {
        return (
                <div className="App" data-testid="login">
                    <PrivateRoute path="" />
                </div>
        )
      }

      if (api.error) {
        return <Error />
      }

      if (!api.catalogAPI || !appContext.profile || !appContext.workspaces) {
        return <Loading />
      }

      if (appContext.workspaces.length === 0) {
        return (
                <div className="App" data-testid="newWorkspace">
                    <Switch>
                        <Redirect
                            exact={true}
                            path="/"
                            to={'/setup/w/new'}
                        />
                        <Route exact={true} path="/debug">
                            <DebugApp />
                        </Route>
                        <Route exact={true} path="/logout">
                            <Logout />
                        </Route>
                        <PrivateRoute path="/signup">
                            <Toolbar isSidebarLayout={false} />
                            <Signup />
                        </PrivateRoute>
                        <PrivateRoute path="/notverified">
                            <EmailNotVerified />
                        </PrivateRoute>
                        <PrivateRoute path="/api-key">
                            <Toolbar isSidebarLayout={false} />
                            <APIKey />
                        </PrivateRoute>
                        <PrivateRoute path="/p/profile">
                            <Toolbar isSidebarLayout={false} />
                            <Profile setViewName={this.setViewName} />
                        </PrivateRoute>
                        <PrivateRoute path="/change-password">
                            <Toolbar isSidebarLayout={false} />
                            <ChangePassword />
                        </PrivateRoute>
                        <PrivateRoute path="/setup/:step/:workspaceId">
                            <Toolbar viewName={this.state.viewName} isSidebarLayout={false} isHideWorkspaceName={true} />
                            <OnboardingView setViewName={this.setViewName} />
                        </PrivateRoute>
                    </Switch>
                    {this.state.prompt && <InstallPWA />}
                </div>
        )
      }

      if (!appContext.workspace || !appContext.workspaceJobs) {
        return <Loading />
      }

      const toolbarNoSideMenuNoWorkspace = (
        <Toolbar
            viewName={this.state.viewName}
            isSidebarLayout={false}
            isHideWorkspaceName={true}
            isSearchEnabled={false}
        />
      )
      const toolbarNoSideMenu = (
        <Toolbar
            viewName={this.state.viewName}
            isSidebarLayout={false}
            isSearchEnabled={false}
        />
      )
      const toolbar = (
        <Toolbar
            viewName={this.state.viewName}
            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
            isSidebarLayout={!window.size.smallScreen}
            isSearchEnabled={true}
        />
      )

      const onAdd = (type: string) => {
          const params = new URLSearchParams({
            tab: "0",
            type: type
          })
          history.push(`/lab/${appContext.workspace.id}/${EntityRel.DataPlugin.collection}?${params}`)
      }

      const onAddDataStore = () => onAdd('LOADER')
      const onAddPipeline = () => onAdd('EXTRACTOR')

      const onSaved = (entity: IEntity, entityRel: EntityRel) => history.push(`/lab/${appContext.workspace.id}/${entityRel.collection}/${entity.id}`)
      const onSavedDataStore = (dataStore: IDataStore) => onSaved(dataStore, EntityRel.DataStore)
      const onSavedPipeline = (pipeline: IPipeline) => onSaved(pipeline, EntityRel.Pipeline)

      const onClose = (entityRel: EntityRel) => history.push(`/lab/${appContext.workspace.id}/${entityRel.collection}`)
      const onCloseDataStore = () => onClose(EntityRel.DataStore)
      const onClosePipeline = () => onClose(EntityRel.Pipeline)

      const isWorkspaceAdmin = () => appContext.workspace && appContext.workspace._links && appContext.workspace._links['update delete workspace'] != null

      const homePage = appContext.workspace.appProperties?.HOME_PAGE || 'feed'
      const homeUrl = (() => {
        const hasIncompleteProfileTasks = !!appContext.workspaceJobs?.filter((j: IJob) => j.type.startsWith('PROFILE_') && j.status !== JobStatus.Complete).length

        if (isWorkspaceAdmin())
          return hasIncompleteProfileTasks ? `/setup/tasks/${appContext.workspace.id}` : '/lab'

        return `/l/${appContext.workspace.id}/${homePage}`
      })()

      const app = (
            <div className="App">
                <Switch>
                    <Route exact={true} path="/debug">
                        <DebugApp />
                    </Route>
                    <Redirect
                        exact={true}
                        path="/"
                        to={homeUrl}
                    />
                    <Route exact={true} path="/logout">
                        <Logout />
                    </Route>
                    <PrivateRoute path="/notverified">
                        <EmailNotVerified />
                    </PrivateRoute>
                    <PrivateRoute path="/p/profile">
                        {toolbarNoSideMenuNoWorkspace}
                        <Profile setViewName={this.setViewName} />
                    </PrivateRoute>
                    <PrivateRoute path="/change-password">
                        {toolbarNoSideMenuNoWorkspace}
                        <ChangePassword setViewName={this.setViewName} />
                    </PrivateRoute>
                    <PrivateRoute path="/setup/:step/:workspaceId">
                        {toolbarNoSideMenuNoWorkspace}
                        <OnboardingView setViewName={this.setViewName} />
                    </PrivateRoute>
                    <PrivateRoute path="/w/:workspaceId">
                        {toolbarNoSideMenu}
                        <Workbench
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <WorkspaceEdit app={true} setViewName={this.setViewName} />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path="/api-key">
                        {toolbarNoSideMenuNoWorkspace}
                        <APIKey setViewName={this.setViewName}/>
                    </PrivateRoute>
                    <PrivateRoute path="/l/:workspaceId/invitations">
                        {toolbarNoSideMenu}
                        <Invitations setViewName={this.setViewName}/>
                    </PrivateRoute>
                    <PrivateRoute path="/l/:workspaceId/notifications">
                        {toolbarNoSideMenuNoWorkspace}
                        <Notifications
                            setViewName={this.setViewName}
                        />
                    </PrivateRoute>
                    <PrivateRoute path={'/l/:workspaceId/setupTasks'}>
                        {toolbar}
                        <SetupTasks
                            viewName="Setup Tasks"
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path={'/l/:workspaceId/dashboard'}>
                        {toolbar}
                        <Dashboard
                            viewName={this.state.viewName || 'Dashboard'}
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path={'/l/:workspaceId/news'}>
                        {toolbar}
                        <News
                            viewName="Home"
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path={'/l/:workspaceId/feed'}>
                        {toolbar}
                        <Explore
                            viewName="Home"
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path="/l/:workspaceId/explore">
                        {toolbar}
                        <Explore
                            viewName="Explore"
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path="/l/:workspaceId/channels">
                        {toolbar}
                        <Channels
                            viewName="Channels"
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    { isFeatureEnabled(AppFeatures.LIBRARY) &&
                    <PrivateRoute path="/l/:workspaceId/library/:type/:channelId">
                        {toolbar}
                        <Workbench
                            menu='app'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <Library isSidebarLayout={!window.size.smallScreen} />
                        </Workbench>
                    </PrivateRoute>}
                    { isFeatureEnabled(AppFeatures.LIBRARY) &&
                    <PrivateRoute path="/l/:workspaceId/library/:type">
                        {toolbar}
                        <Workbench
                            menu='app'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <Library isSidebarLayout={!window.size.smallScreen} />
                        </Workbench>
                    </PrivateRoute>}
                    { isFeatureEnabled(AppFeatures.LIBRARY) &&
                    <Redirect
                        path='/l/:workspaceId/library'
                        to={'/l/' + appContext.workspace.id + '/library/lists' }
                    />}
                    <PrivateRoute path="/l/:workspaceId/starred">
                        {toolbar}
                        <Starred
                            viewName="Starred"
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path="/l/:workspaceId/help">
                        {toolbar}
                        <Help
                            viewName='appHelp'
                            menu='app'
                            helpText={DefaultAppHelp}
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path="/l/:workspaceId/dataset/:datasetIdOrAlias+">
                        {toolbar}
                        <DatasetOverview
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <Redirect
                        path='/l/:workspaceId'
                        to={homeUrl}
                    />
                    <Redirect
                        path="/dataset/:datasetIdOrAlias"
                        to={`/l/${appContext.workspace.id}/dataset/:datasetIdOrAlias`} />
                    <Redirect
                        exact
                        path="/lab"
                        to={`lab/${appContext.workspace.id}/pipelines`} />
                    <PrivateRoute path={`/lab/:workspaceId/${EntityRel.DataPlugin.collection}`}>
                        <Toolbar
                            menu='lab'
                            viewName={EntityRel.DataPlugin.collectionLabel}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <Plugins />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path={`/lab/:workspaceId/${EntityRel.DataSource.collection}`}>
                        <Toolbar
                            menu='lab'
                            viewName={EntityRel.DataSource.collectionLabel}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            {/* <SearchEntities entityRel={EntityRel.DataSource} /> */}
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path={[
                        `/lab/:workspaceId/${EntityRel.DataStore.collection}/new`,
                        `/lab/:workspaceId/${EntityRel.DataStore.collection}/:datastoreId`
                    ]}>
                        <Toolbar
                            menu='lab'
                            viewName={EntityRel.DataStore.collectionLabel}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <StoreEdit
                                setViewName={this.setViewName}
                                isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                                isSidebarLayout={!window.size.smallScreen}
                                onSaved={onSavedDataStore}
                                onClose={onCloseDataStore} />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path={`/lab/:workspaceId/${EntityRel.DataStore.collection}`}>
                        <Toolbar
                            menu='lab'
                            viewName={EntityRel.DataStore.collectionLabel}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <Stores
                                setViewName={this.setViewName}
                                isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                                isSidebarLayout={!window.size.smallScreen}
                                onAdd={onAddDataStore} />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path={[
                        `/lab/:workspaceId/${EntityRel.Pipeline.collection}/new`,
                        `/lab/:workspaceId/${EntityRel.Pipeline.collection}/:pipelineId`
                    ]}>
                        <Toolbar
                            menu='lab'
                            viewName={EntityRel.Pipeline.collectionLabel}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <PipelineEdit
                                setViewName={this.setViewName}
                                isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                                isSidebarLayout={!window.size.smallScreen}
                                onSaved={onSavedPipeline}
                                onClose={onClosePipeline} />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path={`/lab/:workspaceId/${EntityRel.Pipeline.collection}`}>
                        <Toolbar
                            menu='lab'
                            viewName={EntityRel.Pipeline.collectionLabel}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <Pipelines
                                setViewName={this.setViewName}
                                isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                                isSidebarLayout={!window.size.smallScreen}
                                onAdd={onAddPipeline} />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path={`/lab/:workspaceId/${EntityRel.Channel.collection}`}>
                        <Toolbar
                            menu='lab'
                            viewName={EntityRel.Channel.collectionLabel}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <SearchEntities
                                key={EntityRel.Channel.collection}
                                entityRel={EntityRel.Channel}
                                getImageHolderProps={(channel: IChannel) => ({
                                    imageUrl: channel.picture,
                                    label: channel.description
                                })} />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path={`/lab/:workspaceId/${EntityRel.Dataset.collection}`}>
                        <Toolbar
                            menu='lab'
                            viewName={EntityRel.Dataset.collectionLabel}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar} >
                            <SearchEntities
                                key={EntityRel.Dataset.collection}
                                entityRel={EntityRel.Dataset}
                                getExpandContext={e =>
                                    <Dataset
                                        chartOnly
                                        dataset={e} />} />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path={'/lab/:workspaceId/setupTasks'}>
                        <Toolbar
                            menu='lab'
                            viewName='tasks'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <SetupTasks
                            isLab={true}
                            viewName="tasks"
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path='/lab/:workspaceId/settings'>
                        <Toolbar
                            menu='lab'
                            viewName='settings'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <WorkspaceEdit setViewName={this.setViewName} />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path='/lab/:workspaceId/project'>
                        <Toolbar
                            menu='lab'
                            viewName='project'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen} />
                        <Workbench
                            menu='lab'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}>
                            <Project />
                        </Workbench>
                    </PrivateRoute>
                    <PrivateRoute path="/lab/:workspaceId/help">
                        <Toolbar
                            menu='lab'
                            viewName='Help'
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                        />
                        <Help
                            menu='lab'
                            viewName='labHelp'
                            helpText={LabHelp}
                            setViewName={this.setViewName}
                            isExpandedSidebarActive={this.state.isExpandedSidebarActive}
                            isSidebarLayout={!window.size.smallScreen}
                            toggleSidebar={this.toggleSidebar}
                        />
                    </PrivateRoute>
                </Switch>
                {this.state.prompt && <InstallPWA />}
            </div>
      )
      return app
    }

    public render () : any {
      return (
            <Auth0Consumer>
                {(auth0: any) => {
                  assert.object(
                    auth0,
                    'App must always be rendered within Auth0Provider context'
                  )
                  return (
                        <WindowConsumer>
                            {(window: any) => {
                              assert.object(
                                window,
                                'App must always be rendered within WindowProvider context'
                              )
                              return (
                                    <APIConsumer>
                                        {(api: any) => {
                                          assert.object(
                                            api,
                                            'App must always be rendered within APIProvider context'
                                          )
                                          return (
                                                <WorkspaceConsumer>
                                                    {(wrkspcContext: any) => {
                                                      assert.object(
                                                        wrkspcContext,
                                                        'App must always be rendered within WorkspaceProvider context'
                                                      )
                                                      return this.renderApp(
                                                        auth0,
                                                        window,
                                                        api,
                                                        wrkspcContext
                                                      )
                                                    }}
                                                </WorkspaceConsumer>
                                          )
                                        }}
                                    </APIConsumer>
                              )
                            }}
                        </WindowConsumer>
                  )
                }}
            </Auth0Consumer>
      )
    }

    protected toggleSidebar () : void {
      this.setState({
        isExpandedSidebarActive: !this.state.isExpandedSidebarActive
      })
    }

    protected setViewName(name: string) : void {
        this.setState({
          viewName: name
        })
      }

}

export default App

import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link, withRouter } from 'react-router-dom'
import SwitchMenu from 'src/components/SwitchMenu'
import { AppMenuViewOptions, IMenuProps, LabMenuViewOptions, MenuViewOptions } from 'src/models/components/IMenu'
import '../assets/css/components/WorkspaceMenu.css'
import { useWorkspaceContext } from '../contexts/workspace'
import { AppFeatures, isFeatureEnabled } from '../utils/appenv'
// import elipse from '../assets/images/elipse.svg'
// import elipse_blue from '../assets/images/elipse-blue.svg'
import logo from 'src/assets/images/logo.svg'
import bottomArrow from '../assets/images/bottomArrow.svg'
import collapsearrow from '../assets/images/collapseArrow.svg'
import expandArrow from '../assets/images/expandArrow.svg'



const WorkspaceMenu: FunctionComponent<IMenuProps> = (props: PropsWithChildren<IMenuProps>) => {
  
  const { workspace, getAllWorkspaceJobs, workspaceJobs, profile } = useWorkspaceContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const isWorkspaceAdmin = () => workspace && workspace._links && workspace._links['update delete workspace'] != null
  useEffect(() => {
      isWorkspaceAdmin() && getAllWorkspaceJobs(workspace.id)
  }, [])

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const { location, toggleSidebar, menu } = props

  const navbarClasses = `${props.isSidebarLayout ? 'sidebar' : 'footer'}-navbar`
  const navClasses = [
    ...props.isSidebarLayout ? [
      'sidebar-nav',
      'flex-column',
      ...props.isExpandedSidebarActive ? [
        'sidebar-nav-expanded',
        'expanded-menu'
      ] : [
        'sidebar-nav-narrow'
      ]
    ] : [
      'footer-nav',
      'justify-content-between'
    ],    
    menu === 'lab' && 'lab'
  ].filter(Boolean).join(' ')

  const linkClasses = [
    'nav-link',
    `${props.isSidebarLayout ? 'sidebar' : 'footer'}-link`
  ].join(' ')

  const as = props.isSidebarLayout ? 'span' : 'div'
  const bg = props.isSidebarLayout ? 'none' : 'white'

  const imageUrl = workspace && workspace.imageUrl ? workspace.imageUrl : logo

  let activeRoute: any = location.pathname.substr(location.pathname.lastIndexOf('/') + 1)
  if (location.pathname.includes("library")) {
    activeRoute = 'library'
  }

  const handleNavHome = () => menu === 'lab' ? props.history.push('/lab') : props.history.push(`/l/${workspace.id}/news`)
  const workspaceItem = (
    <Link
        data-rb-event-key=""
        to={{}}
        className={[
          'workspace-name',
          'nav-link',
          menu === 'lab' && 'lab'
        ].filter(Boolean).join(' ')}
        style={!props.isSidebarLayout ? {borderRight: '1px solid #343F58', zIndex: 1} : {borderBottom: '1px solid #343F58'}}
    >
        <div 
            id="workspace-brand"
            className="linkIcon_holder"
            onClick={handleNavHome}
        >
            {<img className='workspace-url-image' src={imageUrl} />}
        </div>
        <Nav.Item
            id="workspace-menu-name"
            as={as} 
            className="d-xl-inline-block expandedName"
            onClick={handleNavHome}
        >
            {workspace.name}
        </Nav.Item>
        <div 
            id="workspace-menu-dropdown"
            data-testid="workspace-menu-dropdown"
            onClick={handleClick} 
            className='sidebar-arrow ml-auto'
            style={{
              ...!props.isExpandedSidebarActive && {
                position: 'absolute',
                right: '10px',
              },
            }}
        >
            <img className="expand-collapse-arrow" src={bottomArrow} />
        </div>
    </Link>
  )

  const tasks = (
      <Link
          data-rb-event-key="setupTasks"
          to={menu === 'lab' ? { pathname: '/lab/' + workspace.id + '/setupTasks', state: 'flushDeal' }
              : { pathname: '/l/' + workspace.id + '/setupTasks', state: 'flushDeal' }}
          className={linkClasses}
          data-testid="completed-tasks-link">
          <div className="linkIcon_holder">
            <div className='circle-border' 
                  style={(workspaceJobs && workspaceJobs.filter((job: any) => (job.status == 'RUNNING' || job.status == 'QUEUED')).length == 0) ? {background: 'linear-gradient(180deg, #009579 50%, transparent 50%), linear-gradient(360deg, #009579 50%, lightgray 50%)'} : {}}>
                <div className='tasks-in-progress-icon' data-testid="inprogress-task-no">{workspaceJobs && workspaceJobs.filter((job: any) => (job.status == 'RUNNING' || job.status == 'QUEUED')).length}</div>
            </div>
          </div>
          <Nav.Item as={as} className="d-xl-inline-block expandedName">
              Tasks
          </Nav.Item>
      </Link>
  )

  const appLab =
    <Link
      data-rb-event-key='appLab'
      to={menu !== 'lab' ? '/lab' : `/l/${workspace.id}/${workspace.appProperties?.HOME_PAGE || 'feed'}`}
      className={linkClasses}>
      <div className='linkIcon_holder'>
          <i className={`fas fa-${menu !== 'lab' ? 'magic' : 'door-open'}`} />
      </div>
      <Nav.Item
          className='d-xl-inline-block expandedName'
          as={as}>
          {`${menu !== 'lab' ? "Switch to Lab" : "Switch to App"}`}
      </Nav.Item>
    </Link>

  const collapseArrow = (
      <div className='expand-collapse'>
        <div
          className="linkIcon_holder"
          style={{cursor: 'pointer'}}
          title={!props.isExpandedSidebarActive ? "Expand" : "Collapse"}
          onClick={toggleSidebar}>
          <div className='sidebar-arrow'>
            <img className="expand-collapse-arrow" src={!props.isExpandedSidebarActive ? expandArrow : collapsearrow} />
          </div>
        </div>
        <span className='expandedName'>
          Collapse
        </span>
      </div>
  )

  const basePath = menu === 'lab' ? 'lab' : 'l'

  let customAppMenuViewOptions: MenuViewOptions[] = []
  if (workspace.appProperties && workspace.appProperties.APP_MENU_ITEMS) {
    customAppMenuViewOptions = JSON.parse(workspace.appProperties.APP_MENU_ITEMS)
  }

  const menuViewOptions = (() => {
      switch (menu) {
          case 'app':
            if (customAppMenuViewOptions.length > 0) {
                return customAppMenuViewOptions.map(o => {
                    if (o.name === "help") o.faIcon = workspace.appProperties && workspace.appProperties.HELP_CUSTOM_FA_MENU_ICON ?
                        workspace.appProperties.HELP_CUSTOM_FA_MENU_ICON : o.faIcon
                    return o
                })
            } else {
              return AppMenuViewOptions.map(o => {
                if (o.name === "help") o.faIcon = workspace.appProperties && workspace.appProperties.HELP_CUSTOM_FA_MENU_ICON ?
                    workspace.appProperties.HELP_CUSTOM_FA_MENU_ICON : o.faIcon
                return o
              })
            }
          case 'lab':
            if (profile?.featuresDisabled) {
                if (profile.featuresDisabled.includes(",")){
                    const featuresDisabled = profile.featuresDisabled.split(",").map((e: any) => e.trim())
                    return LabMenuViewOptions.filter(obj => !featuresDisabled.includes(obj.name.toUpperCase()))
                }  else {
                    return LabMenuViewOptions.filter(obj => !profile.featuresDisabled.includes(obj.name.toUpperCase()))
                }
            }
            return LabMenuViewOptions
          default:
              return
      }
  })()
  
  return (
      <>
          <Navbar
              bg={bg}
              // fixed={fixed}
              className={navbarClasses}
          >
              <Nav defaultActiveKey="home" className={navClasses}>
                  {props.isSidebarLayout && workspaceItem}
                  {menuViewOptions?.map(o => {
                      const feature = Object.values(AppFeatures).find(f => {
                          const feature = o.name.toUpperCase()
                          return f === (menu === 'lab' ? `LAB_${feature}` : feature)
                      })
                      if (feature && !isFeatureEnabled(feature)) return
                      
                      const altTextKey = 'MENU_ITEM_'+o.name.toUpperCase()+'_ALT_TEXT'
                      return <Link
                          key={o.name}
                          data-rb-event-key={o.name}
                          to={`/${basePath}/${workspace.id}/${o.path || o.name}`}
                          className={linkClasses + (activeRoute === o.name || activeRoute === o.path ? ' active' : '')}>
                          <div className='linkIcon_holder'>
                              <i className={`fas fa-${o.faIcon || o.name}`} />
                          </div>
                          <Nav.Item
                              className='d-xl-inline-block expandedName'
                              as={as}>
                              {workspace && workspace.appProperties && workspace.appProperties[altTextKey] 
                                ? workspace.appProperties[altTextKey]
                                : o.label || o.name}
                          </Nav.Item>
                      </Link>
                  })}
                  {props.isSidebarLayout && 
                    <div className='mt-auto'>
                      {isWorkspaceAdmin() && tasks}
                      {isWorkspaceAdmin() && !profile?.featuresDisabled?.includes("APP") && <>{appLab}</>}
                      {collapseArrow}
                    </div>}
              </Nav>
          </Navbar>
          <SwitchMenu menu={props.menu} isSidebarLayout={props.isSidebarLayout} handleClose={handleClose} open={open} anchorEl={anchorEl} />
      </>
  )
}

export default withRouter(WorkspaceMenu)

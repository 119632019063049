import React from 'react';

export const WorkspaceHelp = (props: { edit?: boolean }): JSX.Element =>
  <>
    <div className="help-field">
        <div className="help-title">
          <h4>Name</h4>  
        </div>
        <div>
            Give your workspace a name that your users will recognise.  Renaming the workspace will rename the repository and could affect any automation you may have configured.
        </div>
    </div>
    <div className="help-field">
        <div className="help-title">
          <h4>Approved Domains</h4>  
        </div>
        <div>
            You can control who can join your account by supplying a list of approved domains. Users of your account will only be able to 
            send invitations to other people in these domains. Leave empty for any domain.
        </div>
    </div>
    <div className="help-field">
        <div className="help-title">
          <h4>Image</h4>  
        </div>
        <div>
            Setting an image helps your members instantly know they are in the right workspace.
        </div>
    </div>
    {props.edit && <div className="help-field">
        <div className="help-title">
          <h4>Active environment</h4>  
        </div>
        <div>
            Set the name of the environment to use configuration from.
            The environment must exist in the <code>meltano.yml</code> under the <code>environments</code> section.
            See <a
              href='https://www.matatika.com/docs/how-to-guides/manage-workspaces/promoting-changes-with-dataops#what-are-environments'
              target='_blank'
              rel='noreferrer'>
              the docs
            </a> for more information.
        </div>
    </div>}
  </>


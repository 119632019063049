import React, { useRef, useState, FunctionComponent, PropsWithChildren } from 'react';
import useRowStyles from 'src/assets/css/components/RowComponentsStyles';
import 'src/styles.css'
import { IDatasetActionsProps } from 'src/models/components/IDatasetActions'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { MoreVert } from '@material-ui/icons'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import Spinner from 'src/components/Spinner'

const DatasetActions: FunctionComponent<IDatasetActionsProps> = (props: PropsWithChildren<IDatasetActionsProps>) => {

    const classes = useRowStyles();
    const menuAnchorEl = useRef<EventTarget & HTMLButtonElement>()
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [isMenuOpen, setMenuOpen] = useState(false)

    const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMenuOpen(true)
        menuAnchorEl.current = e.currentTarget
    }
    const handleCloseMenu = () => setMenuOpen(false)

    const handleDelete = async () => {
      setConfirmDialogOpen(false)
      setMenuOpen(false)
      await props.handleDeleteDataset(props.dataset)
    }

    const handleEdit = () => {
      setMenuOpen(false)
      props.handleEditDescription(props.dataset)
    }

    const { dataset } = props

    const iconButton = (
        <div>
            <IconButton
                className={classes.button}
                onClick={handleOpenMenu}
                data-testid='actions-menu'
                aria-label='More'
                title="More">
                <MoreVert className={classes.largeIcon} />
            </IconButton>
        </div>
    );
    
    const buttons = (
        <div className='inline-vertical-center'>
            {iconButton}
            <Menu
                className={classes.dropdownMenu}
                open={isMenuOpen}
                anchorEl={menuAnchorEl.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseMenu}
            >
                {dataset._links && dataset._links['edit dataset'] != null && (
                  <MenuItem
                    className={classes.dropdownItem}
                    dense
                    onClick={handleEdit}
                  >
                    <i className="fas fa-edit"></i>
                    <span>Edit</span>
                  </MenuItem>)}
                {dataset._links && dataset._links['delete dataset'] != null && (
                  <MenuItem
                    className={classes.dropdownItem}
                    dense
                    onClick={ () => setConfirmDialogOpen(true) }
                  >
                    <i className="fas fa-trash"></i>
                    <span>Delete</span>
                  </MenuItem>)}
            </Menu>
        </div>
    )
    
    const contextMenu = (
        <div>
            {(dataset._links && dataset._links['delete dataset'] != null) ||
            (dataset._links && dataset._links['edit dataset'] != null)
              ? (
                <Spinner active={dataset.deleteLoading}>
                    {buttons}
                </Spinner>
                )
              : null}
        </div>
    )

    return (
        <>
            {contextMenu}
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                title={`Delete '${dataset.alias}'?`}
                message="Remove this dataset from the workspace. This action cannot be undone."
                cancelLabel="Close"
                confirmLabel="Delete"
                handleClose={ () => setConfirmDialogOpen(false) }
                handleConfirm={ handleDelete } />
        </>
    )
}

export default DatasetActions

import { Chip, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Autocomplete } from '@material-ui/lab';
import { highlight, languages } from 'prismjs';
import "prismjs/themes/prism.css";
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Editor from 'react-simple-code-editor';
import { IDataTransformProps, TransformType } from 'src/models/components/IDataTransform';
require('prismjs/components/prism-bash')

const DataTransform: FunctionComponent<IDataTransformProps> = (props: PropsWithChildren<IDataTransformProps>) => {
    const { dataComponents, transformType, actions, onChangeActions, script, handleCustomScriptChange } = props;
    const onChangeTransform = (e: React.ChangeEvent<HTMLInputElement>) => props.onChangeTransform(e.target.value as TransformType)

    const meltanoRunArgs = dataComponents.length ? [
        ...dataComponents
            .filter(dc => ['EXTRACTOR', 'LOADER'].includes(dc._embedded.dataplugin.pluginType!))
            .map(dc => dc._embedded.dataplugin.name),
        ...dataComponents.find(dc => (dp => dp.pluginType === 'TRANSFORMER' && dp.name === 'dbt')(dc._embedded.dataplugin))
            ? ['dbt:deps', 'dbt:run']
            : []
    ] : []

    const defaultScript = meltanoRunArgs.length ? `meltano run ${meltanoRunArgs.join(" ")}` : "echo 'hello world'"

    const availableActions = dataComponents
        .map(dc => {
            const dataplugin = dc._embedded.dataplugin

            return [
                dataplugin.name,
                ...Object.keys(dc.commands).map(c => `${dataplugin.name}:${c}`),
            ]
        })
        .reduce((p, c) => [...p, ...c], [])

    return (
        <div>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="Actions"
                    name="actions"
                    onChange={onChangeTransform}
                    value={transformType}>
                    <FormControlLabel
                        value="default"
                        control={<Radio />}
                        label="Default"
                    />
                    <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label="Custom"
                    />
                    <FormControlLabel
                        value="script"
                        control={<Radio />}
                        label="Script"
                        onClick={() => handleCustomScriptChange(script !== undefined ? script : defaultScript)}
                    />
                </RadioGroup>
            </FormControl>
            {transformType === TransformType.Default &&
                <Autocomplete
                    multiple
                    disableClearable
                    disableCloseOnSelect
                    disablePortal
                    fullWidth
                    open={false}
                    forcePopupIcon={false}
                    disabled={!dataComponents.length}
                    options={meltanoRunArgs}
                    defaultValue={meltanoRunArgs}
                    renderInput={(params) =>
                        <TextField
                            variant='outlined'
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                readOnly: true
                            }} />}
                    renderTags={(value, getTagProps) => value.map((v, i) =>
                        <Chip
                            key={i}
                            label={v}
                            {...getTagProps({ index: i })}
                            onDelete={undefined} />)} />}
            {transformType === TransformType.Custom &&
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    disablePortal
                    fullWidth
                    autoHighlight
                    disabled={!dataComponents.length}
                    options={availableActions}
                    groupBy={o => o.split(':')[0]}
                    value={actions}
                    onChange={(e, actions) => onChangeActions(actions)}
                    renderInput={(params) =>
                        <TextField
                            variant='outlined'
                            {...params} />} />}
            {transformType === TransformType.Script && <>
                <h4>Custom <a target="_blank" rel="noreferrer" href="https://www.gnu.org/software/bash/">Bash</a> script</h4>
                <Editor
                    textareaId="custom-script"
                    name="custom-script"
                    value={script || ''}
                    onValueChange={handleCustomScriptChange}
                    highlight={code => highlight(code, languages.bash, 'bash')}
                    padding={10}
                    style={{ minHeight: '150px' }}
                    placeholder={defaultScript}
                    autoFocus />
            </>}
        </div>
    );
};

export default DataTransform;

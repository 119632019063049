import React from 'react'
import { IImageHolder, ImageHolderSize } from 'src/models/components/IImageHolder'


const ImageHolder = (props: IImageHolder): JSX.Element => {
    const { label, imageUrl, size, overlay } = props

    return <>
        <div
            className={[
                'image-holder',
                ...[size === ImageHolderSize.Small && ' image-holder-small'],
                ...[overlay && 'overlay']
            ].filter(Boolean).join(' ')}
            onClick={props.onClick}>
            <img
                src={imageUrl}
                alt={label} />
            {label && <span>{label}</span>}
            {overlay && <div className='overlay'>{overlay}</div>}
        </div>
    </>

}

export default ImageHolder

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { Router } from 'react-router-dom'
import App from './App'
import { APIProvider } from './contexts/api'
import { Auth0Provider } from './contexts/auth0'
import { WindowProvider } from './contexts/window'
import { WorkspaceProvider } from './contexts/workspace'
import './index.css'
import registerServiceWorker from './registerServiceWorker'
import { AppEnv } from './utils/appenv'
import history from './utils/history'
import { ThemeProvider } from '@material-ui/styles'
import theme from 'src/assets/css/AppTheme'
import CssBaseline from '@material-ui/core/CssBaseline'

const onRedirectCallback = (appState: any) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
}

const onEmailNotVerifiedCallback = () => {
  history.push('/notverified')
}

if (AppEnv.APP_SENTRY_TRACES_SAMPLE_RATE != null) {
  const tracesSampleRate = parseFloat(AppEnv.APP_SENTRY_TRACES_SAMPLE_RATE)
  if (tracesSampleRate > 0) {
    Sentry.init({
      dsn: 'https://7325cd9fcbec4621ae5bba01ed710cd3@o542113.ingest.sentry.io/5661320',
      integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: false
        })
      ],
      tracesSampleRate,
      environment: AppEnv.APP_ENVIRONMENT,
      release: "matatika-app@"+AppEnv.APP_VERSION,
    })
  }
}

ReactGA.initialize('G-9L5RK1KJR1')
ReactDOM.render(
    <Auth0Provider
        domain={AppEnv.APP_IDENTITY_DOMAIN}
        client_id={AppEnv.APP_IDENTITY_CLIENT_ID}
        audience={
            AppEnv.APP_IDENTITY_AUDIENCE ? AppEnv.APP_IDENTITY_AUDIENCE : AppEnv.APP_SERVER_URI
        }
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        onEmailNotVerifiedCallback={onEmailNotVerifiedCallback}
    >
        <Router history={history}>
            <WindowProvider>
                <APIProvider>
                    <WorkspaceProvider>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <App />
                        </ThemeProvider>
                    </WorkspaceProvider>
                </APIProvider>
            </WindowProvider>
        </Router>
    </Auth0Provider>,

    document.getElementById('root') as HTMLElement
)
registerServiceWorker()

import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { bindContexts } from '../utils/bindContexts'
import Workbench from 'src/components/Workbench'
import 'src/assets/css/views/setupTasks.css'
import { APIContext, getDatasetURI } from '../contexts/api'
import ReactMarkdown from 'react-markdown'
import * as markdownUtils from 'src/utils/markdownUtils'
import PageTitle from 'src/components/PageTitle'


class Help extends React.Component<any, any> {
  static contextType = MergedContexts;
  public isThisMounted = false;

  constructor(props: any) {
    super(props)
    this.state = {
      ready: false,
      currentDataset: null,
    }
    this.getCurrentDataset = this.getCurrentDataset.bind(this)
  }

  public componentDidMount() {
    this.isThisMounted = true;
    this.getCurrentDataset();
  }

  private async getCurrentDataset() {
    const { catalogAPI, workspace } = this.context
    this.setState({ currentDataset: null, ready: false })


    if (!workspace.appProperties.HELP_DATASET_ALIAS) {
      this.setState({ currentDataset: null, ready: true })
      return
    }

    await catalogAPI
      .get(
        getDatasetURI(
          this.props.match.params.workspaceId,
          workspace.appProperties.HELP_DATASET_ALIAS
        ),
        { errorHandler: false }
      )
      .then((resp: any) => {
        if (this.isThisMounted) {
          if (resp.data && this.isThisMounted) {
            const dataset = resp.data
            this.setState({ currentDataset: dataset, ready: true })
          } else {
            this.setState({ currentDataset: null, ready: true })
          }
        }
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 200)
      })
      .catch((error: any) => {
        // we might have search results or user link to deleted dataset
        if (this.isThisMounted && error.response && error.response.status === 404) {
          // not found
          this.setState({ currentDataset: null, ready: true })
        }
      })
  }

  public render() {
    return (
      <Workbench
        menu={this.props.menu}
        isExpandedSidebarActive={this.props.isExpandedSidebarActive}
        isSidebarLayout={this.props.isSidebarLayout}
        toggleSidebar={this.props.toggleSidebar}>
        {this.state.ready && <div className='rightside_pageContent' data-testid="helptext">
          {this.props.isSidebarLayout && 
          <PageTitle title={this.context.workspace.appProperties?.HELP_PAGE_TITLE || 'Help'} /> }
          {this.state.currentDataset != null && this.props.menu != 'lab' ?
            <ReactMarkdown
              renderers={markdownUtils.renderers}
              source={this.state.currentDataset.description}
              escapeHtml={false}
            /> : this.props.helpText}
        </div>}
      </Workbench>
    )
  }
}

export const HelpWithContext = bindContexts(withRouter(Help), [
  Auth0Context,
  APIContext,
  WorkspaceContext
])
export default HelpWithContext
import React, { FunctionComponent, PropsWithChildren, useContext, useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField } from '@material-ui/core'
import { APIContext } from 'src/contexts/api'
import { bindContexts } from 'src/utils/bindContexts'
import { IListDialogProps } from 'src/models/components/IListDialog'
import Spinner from 'src/components/Spinner'
import { useWorkspaceContext } from 'src/contexts/workspace'
import IChannel from 'src/models/IChannel'
import { AxiosResponse } from 'axios'

const ListDialog: FunctionComponent<IListDialogProps> = (props: PropsWithChildren<IListDialogProps>) => {

    const { initialiseChannel } = useWorkspaceContext()

    const [isCreateReady, setCreateReady] = useState(true)
    const [isUpdateReady, setUpdateReady] = useState(true)

    const [list, setList] = useState<any>({})

    const { catalogAPI } = useContext(APIContext)

    const handleCreateList = async () => {
        const channelRequestBody = {
            name: list.name,
            description: list.description,
            type: 'LIST',
            scope: 'PROFILE'
        }

        initialiseChannel().then((r: any) => {
            const newChannelLink = r._links['create channel']
            catalogAPI.put(newChannelLink.href, channelRequestBody).then((r: AxiosResponse<IChannel>) => props.addChannel!(r.data));
        })
        setCreateReady(true)
    }

    const handleUpdateList = async () => {
        props.channel!.name = list.name || props.channel!.name
        props.channel!.description = list.description || props.channel!.description

        const channelRequestBody = {
            name: props.channel!.name,
            description: props.channel!.description
        }

        const updateChannelLink = props.channel!._links['update channel']
        catalogAPI.put(updateChannelLink.href, channelRequestBody).then(() => props.updateChannel!(props.channel!));
        setUpdateReady(true)
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setList({ ...list, name: e.target.value })
        if (e.target.value == undefined || '') {
            setUpdateReady(false)
        }
        setUpdateReady(true)
    }

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setList({ ...list, description: e.target.value })
        if (list.name == undefined) {
            setList({ ...list, name: props.channel!.name })
        }
        setUpdateReady(true)
    }

    const handleCreate = () => {
        setCreateReady(false)
        handleCreateList()
        props.handleClose()
    }

    const handleUpdate = () => {
        setUpdateReady(false)
        handleUpdateList()
        props.handleClose()
    }

    return (
        <Dialog
            open
            onClose={props.handleClose}>
            <DialogTitle>{props.updateChannel ? 'Edit List' : 'New List'}</DialogTitle>
            <DialogContent>
                <TextField
                    id='new-list-name'
                    fullWidth
                    autoFocus
                    required
                    variant="outlined"
                    margin="dense"
                    label="Name"
                    placeholder="List Name"
                    defaultValue={props.channel?.name || ''}
                    onLoad={handleNameChange}
                    onChange={handleNameChange}
                />
                <TextField
                    id='new-list-description'
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Description"
                    placeholder="List Description"
                    defaultValue={props.channel?.description || ''}
                    onLoad={handleDescriptionChange}
                    onChange={handleDescriptionChange}
                />
                <DialogActions>
                    <Button
                        onClick={props.handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        disabled={props.updateChannel ? !isUpdateReady || !list.name : !isCreateReady || !list.name}
                        onClick={props.updateChannel ? handleUpdate : handleCreate}>
                        <Spinner active={props.updateChannel ? !isUpdateReady : !isCreateReady}>
                            {props.updateChannel ? 'Update' : 'Create'}
                        </Spinner>
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog >
    )

}

export const NewListDialogWithContext = bindContexts(ListDialog, [
    APIContext
])

export default ListDialog

import React from 'react'
import Col from 'react-bootstrap/Col'
import ContentLoader from 'react-content-loader'

const datasetPlaceholder = (
    <Col className={'feed pad_0'}>
        <div className="dataLoader_parent d-flex">
            <Col className="dataLoader">
                <ContentLoader className="placeholderList" viewBox="0 0 446 15" speed={2}>
                    <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                </ContentLoader>
                <ContentLoader className="placeholderDivider w-100" height={20} speed={2}>
                    <rect x="0" y="5" height="2" />
                </ContentLoader>

                <Col className="clearfix">
                    <div className="col-12 col-md-8 float-left">
                        <ContentLoader className="w-100" viewBox="0 0 400 290" speed={2}>
                            <circle cx="30" cy="238" r="30" />
                            <rect x="75" y="215" rx="4" ry="4" width="100" height="13" />
                            <rect x="75" y="235" rx="4" ry="4" width="50" height="8" />
                            <rect x="75" y="255" rx="5" ry="5" width="80" height="6" />
                            <rect x="0" y="0" rx="5" ry="5" width="400" height="200" />
                        </ContentLoader>
                        <ContentLoader
                            className="placeholderDivider w-100"
                            height={20}
                            speed={2}
                        >
                            <rect x="0" y="5" height="2" />
                        </ContentLoader>
                        <div className="clearfix">
                            <div className="col-2 float-left">
                                <ContentLoader
                                    className="w-100"
                                    viewBox="0 0 400 320"
                                    speed={2}
                                >
                                    <rect
                                        x="0"
                                        y="0"
                                        rx="5"
                                        ry="5"
                                        width="400"
                                        height="200"
                                    />
                                </ContentLoader>
                            </div>
                            <div className="col-2 float-left">
                                <ContentLoader
                                    className="w-100"
                                    viewBox="0 0 400 320"
                                    speed={2}
                                >
                                    <rect
                                        x="0"
                                        y="0"
                                        rx="5"
                                        ry="5"
                                        width="400"
                                        height="200"
                                    />
                                </ContentLoader>
                            </div>
                            <div className="col-2 float-left">
                                <ContentLoader
                                    className="w-100"
                                    viewBox="0 0 400 320"
                                    speed={2}
                                >
                                    <rect
                                        x="0"
                                        y="0"
                                        rx="5"
                                        ry="5"
                                        width="400"
                                        height="200"
                                    />
                                </ContentLoader>
                            </div>
                            <div className="col-2 float-left">
                                <ContentLoader
                                    className="w-100"
                                    viewBox="0 0 400 320"
                                    speed={2}
                                >
                                    <rect
                                        x="0"
                                        y="0"
                                        rx="5"
                                        ry="5"
                                        width="400"
                                        height="200"
                                    />
                                </ContentLoader>
                            </div>
                            <div className="col-2 float-left">
                                <ContentLoader
                                    className="w-100"
                                    viewBox="0 0 400 320"
                                    speed={2}
                                >
                                    <rect
                                        x="0"
                                        y="0"
                                        rx="5"
                                        ry="5"
                                        width="400"
                                        height="200"
                                    />
                                </ContentLoader>
                            </div>
                        </div>
                        <ContentLoader
                            className="placeholderDivider w-100"
                            height={20}
                            speed={2}
                        >
                            <rect x="0" y="5" height="2" />
                        </ContentLoader>
                        <ContentLoader className="w-100" viewBox="0 0 400 320" speed={2}>
                            <circle cx="30" cy="30" r="30" />
                            <rect x="75" y="15" rx="4" ry="4" width="100" height="13" />
                            <rect x="75" y="35" rx="4" ry="4" width="50" height="8" />
                        </ContentLoader>

                        <ContentLoader
                            className="placeholderDivider w-100"
                            height={20}
                            speed={2}
                        >
                            <rect x="0" y="5" height="2" />
                        </ContentLoader>
                    </div>
                    <div className="col-12 col-md-4 float-left">
                        <ContentLoader
                            className="placeholderList"
                            viewBox="0 0 446 60"
                            speed={2}
                        >
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="30" />
                            <rect x="56" y="9" rx="0" ry="0" width="240" height="3" />
                            <rect x="56" y="19" rx="0" ry="0" width="200" height="3" />
                            <rect x="56" y="29" rx="0" ry="0" width="100" height="2" />
                        </ContentLoader>
                        <ContentLoader
                            className="placeholderList"
                            viewBox="0 0 446 60"
                            speed={2}
                        >
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="30" />
                            <rect x="56" y="9" rx="0" ry="0" width="240" height="3" />
                            <rect x="56" y="19" rx="0" ry="0" width="200" height="3" />
                            <rect x="56" y="29" rx="0" ry="0" width="100" height="2" />
                        </ContentLoader>
                        <ContentLoader
                            className="placeholderList"
                            viewBox="0 0 446 60"
                            speed={2}
                        >
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="30" />
                            <rect x="56" y="9" rx="0" ry="0" width="240" height="3" />
                            <rect x="56" y="19" rx="0" ry="0" width="200" height="3" />
                            <rect x="56" y="29" rx="0" ry="0" width="100" height="2" />
                        </ContentLoader>
                        <ContentLoader
                            className="placeholderList"
                            viewBox="0 0 446 60"
                            speed={2}
                        >
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="30" />
                            <rect x="56" y="9" rx="0" ry="0" width="240" height="3" />
                            <rect x="56" y="19" rx="0" ry="0" width="200" height="3" />
                            <rect x="56" y="29" rx="0" ry="0" width="100" height="2" />
                        </ContentLoader>
                        <ContentLoader
                            className="placeholderList"
                            viewBox="0 0 446 60"
                            speed={2}
                        >
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="30" />
                            <rect x="56" y="9" rx="0" ry="0" width="240" height="3" />
                            <rect x="56" y="19" rx="0" ry="0" width="200" height="3" />
                            <rect x="56" y="29" rx="0" ry="0" width="100" height="2" />
                        </ContentLoader>
                        <ContentLoader
                            className="placeholderList"
                            viewBox="0 0 446 60"
                            speed={2}
                        >
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="30" />
                            <rect x="56" y="9" rx="0" ry="0" width="240" height="3" />
                            <rect x="56" y="19" rx="0" ry="0" width="200" height="3" />
                            <rect x="56" y="29" rx="0" ry="0" width="100" height="2" />
                        </ContentLoader>
                        <ContentLoader
                            className="placeholderList"
                            viewBox="0 0 446 60"
                            speed={2}
                        >
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="30" />
                            <rect x="56" y="9" rx="0" ry="0" width="240" height="3" />
                            <rect x="56" y="19" rx="0" ry="0" width="200" height="3" />
                            <rect x="56" y="29" rx="0" ry="0" width="100" height="2" />
                        </ContentLoader>
                        <ContentLoader
                            className="placeholderList"
                            viewBox="0 0 446 60"
                            speed={2}
                        >
                            <rect x="5" y="8" rx="0" ry="0" width="40" height="30" />
                            <rect x="56" y="9" rx="0" ry="0" width="240" height="3" />
                            <rect x="56" y="19" rx="0" ry="0" width="200" height="3" />
                            <rect x="56" y="29" rx="0" ry="0" width="100" height="2" />
                        </ContentLoader>
                    </div>
                </Col>
            </Col>
        </div>
    </Col>
)

export default datasetPlaceholder

/* eslint "react/display-name": "off" */
import * as assert from 'assert-plus'
import React, { Component } from 'react'
import { MergedContexts, mergedContextsValue } from '../contexts/merged.js'

const REACT_PORTAL_TYPE = Symbol.for('react.portal')
const REACT_FRAGMENT_TYPE = Symbol.for('react.fragment')
const REACT_STRICT_MODE_TYPE = Symbol.for('react.strict_mode')
const REACT_PROFILER_TYPE = Symbol.for('react.profiler')
const REACT_PROVIDER_TYPE = Symbol.for('react.provider')
const REACT_CONTEXT_TYPE = Symbol.for('react.context')
const REACT_FORWARD_REF_TYPE = Symbol.for('react.forward_ref')
const REACT_SUSPENSE_TYPE = Symbol.for('react.suspense')
const REACT_SUSPENSE_LIST_TYPE = Symbol.for('react.suspense_list')
const REACT_MEMO_TYPE = Symbol.for('react.memo')
const REACT_LAZY_TYPE = Symbol.for('react.lazy')
const REACT_BLOCK_TYPE = Symbol.for('react.block')

function getComponentName (type: any): any {
  if (type == null) return null
  if (typeof type === 'function') return type.displayName || type.name || null
  if (typeof type === 'string') return type
  switch (type) {
    case REACT_FRAGMENT_TYPE:
      return 'Fragment'
    case REACT_PORTAL_TYPE:
      return 'Portal'
    case REACT_PROFILER_TYPE:
      return 'Profiler'
    case REACT_STRICT_MODE_TYPE:
      return 'StrictMode'
    case REACT_SUSPENSE_TYPE:
      return 'Suspense'
    case REACT_SUSPENSE_LIST_TYPE:
      return 'SuspenseList'
  }
  if (typeof type === 'object') {
    switch (type.$$typeof) {
      case REACT_CONTEXT_TYPE:
        return (type.displayName || 'Context') + '.Consumer'
      case REACT_PROVIDER_TYPE:
        return (type._context.displayName || 'Context') + '.Provider'
      case REACT_FORWARD_REF_TYPE:
        let innerType = type.render
        innerType = innerType.displayName || innerType.name || ''
        return (
          type.displayName ||
                    (innerType !== '' ? 'ForwardRef(' + innerType + ')' : 'ForwardRef')
        )
      case REACT_MEMO_TYPE:
        return getComponentName(type.type)
      case REACT_BLOCK_TYPE:
        return getComponentName(type.render)
      case REACT_LAZY_TYPE:
        if ((type = type._status === 1 ? type._result : null)) { return getComponentName(type) }
    }
  }
  return null
}

const processContexts = (contexts: any[], ComponentToBeWrapped: any, props: any) => {
  if (
    typeof ComponentToBeWrapped.isThisMounted !== 'undefined' &&
        !ComponentToBeWrapped.isThisMounted
  ) { return null }

  let ChildContext = () => (
        <MergedContexts.Provider value={mergedContextsValue}>
            <ComponentToBeWrapped {...props} />
        </MergedContexts.Provider>
  )
  let i = contexts.length - 1
  for (; i >= 0; i--) {
    const TempContextItem = contexts[i]
    assert.object(
      TempContextItem._currentValue,
      'A (' +
                getComponentName(TempContextItem) +
                ') required by (' +
                getComponentName(ComponentToBeWrapped) +
                ') is not bound'
    )
    const OldChildContext = ChildContext()

    ChildContext = () => (
            <TempContextItem.Consumer>
                {(ctxItemValue: any) => {
                  mergedContextsValue.setValue({ ...mergedContextsValue, ...ctxItemValue })
                  return OldChildContext
                }}
            </TempContextItem.Consumer>
    )
  }
  return ChildContext()
}

/**
 * HOC (Higher Order Component) to merge values from multiple contexts
 * into New Context and then bind new Context's provider
 * to the given Component.
 *
 * In functional component, it is possile to use multiple contexts
 * at once, but not in Class Component.
 * This HOC helps in using values of multiple contexts in a "Class Component".
 *
 * Usually it can be used to merged static values from multiple contexts.
 * For examples,
 * - Global methods like Show/Hide Loading Indicators,
 *   Alert messages, Confirmation Boxes etc.,
 * - Application level configurations, Constants,
 *   Themes, Logged In user info and so on.
 *
 * @param { Component } ComponentToBeWrapped
 * @param { Array<Context> } contexts
 *
 * @returns { Component }
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const bindContexts = (ComponentToBeWrapped: any, contexts: any[]) => {
  return class WrappedComponent extends Component<any, any> {
    render () {
      mergedContextsValue.reset()
      const ProcessedContexts = processContexts(contexts, ComponentToBeWrapped, this.props)
      return ProcessedContexts
    }
  }
}

import React from 'react'
import { MergedContexts } from 'src/contexts/merged'
import { APIContext } from 'src/contexts/api'
import { WorkspaceContext } from 'src/contexts/workspace'
import { ChartProps } from 'src/models/charts/chart/Chart'
import { bindContexts } from 'src/utils/bindContexts'
import { parseJsonSwallowError } from 'src/utils/charts/chartUtils'
import history from 'src/utils/history'
import '../../assets/css/charts/Chart.css'
import ChartjsChart from './chartjs/ChartjsChart'
import GoogleChart from './google/GoogleChart'
import HandsontableTable from './handsontable/HandsontableTable'
import HtmlTable from './html/HtmlTable'
import HtmlMetric from './html/HtmlMetric'
import Metadata from './metadata/Metadata'
import MermaidChart from './mermaid/MermaidChart'
import { AxiosResponse } from 'axios'
import { logError } from 'src/utils/Error'
import CarouselChart from 'src/components/charts/Carousel'

class Chart extends React.Component<ChartProps> {
    static contextType = MergedContexts;

    openLink (link?: any) {
      const { workspace } = this.context
      if (link) {
        if (link.dataset) {
          history.push('/l/' + workspace.id + '/dataset/' + link.dataset)
        } else if (link.href) {
          window.open(link.href, link.target)
        }
      }
    }

    public render() {
      const { catalogAPI } = this.context
      const { dataset, showTable } = this.props

      if (!dataset)
        return null  // nothing to render for no dataset

      const _Metadata = new Metadata(dataset.metadata)

      const getData = ((dataHref: string | undefined) => async () => {
        if (!dataHref)
          return [];

        this.props.onGetData?.()

        const data: Record<string, unknown>[] = await catalogAPI.get(dataset._links.data.href, { errorHandler: false })
          .then((r: AxiosResponse) => r.data)
          .catch(logError);

        this.props.onGetData?.(data)

        return data ? data.map(_Metadata.applyPostProcessors) : [];
      })(dataset._links?.data?.href);

      const visualisation = showTable ? { "html-table": {} } : parseJsonSwallowError(dataset.visualisation)

      if (!visualisation)
        return null  // nothing to render for no visualisation

      const handleDrilldown = (event?: React.MouseEvent, selectedLink?: any) => {
        // return immediately if compact view, links disabled
        if (this.props.compact) return
        this.openLink(selectedLink)
      }

      if (_Metadata.getParseError())
        return <div className="chart-no-metadata">
          <span>Unable to parse &apos;metadata&apos; for this dataset -</span>
          <br />
          {_Metadata.getParseError()}
        </div>

      if (!_Metadata.getMetaDataObj() && (visualisation['google-chart'] || visualisation['chartjs-chart']))
        return <div className="chart-no-metadata">Chart has no metadata</div>  // assume charts require metadata

      if (visualisation['handsontable-table'])
        return <HandsontableTable
          options={visualisation.options}
          metadata={dataset.metadata}
          rawData={dataset.rawData}
          getData={getData}
        />

      if (visualisation['html-table'])
        return <HtmlTable
          options={{}}
          metadata={dataset.metadata}
          rawData={dataset.rawData}
          getData={getData}
          limit={!dataset.visualisation ? 10 : undefined}
        />

      if (visualisation['google-chart']) {
        const { chartType, options } = visualisation['google-chart']
        return <GoogleChart
          chartType={chartType}
          options={options}
          metadata={dataset.metadata}
          rawData={dataset.rawData}
          getData={getData}
          dataCallback={this.props.onCompleteCallback}
        />
      }

      if (visualisation['chartjs-chart']) {
        const { chartType, options } = visualisation['chartjs-chart']
        return <ChartjsChart
          chartType={chartType}
          canvasId={dataset.id}
          legend={this.props.compact ? { display: !this.props.compact } : null}
          drilldown={!this.props.compact}
          options={{
            ...options,
            animation: {
              ...options?.animation,
              onComplete: this.props.onCompleteCallback,
            }
          }}
          metadata={dataset.metadata}
          rawData={dataset.rawData}
          getData={getData}
          continuousPoll={dataset.continuousPoll}
          drilldownCallback={handleDrilldown}
          onClickCallback={this.props.onClickCallback}
          dataCallback={this.props.onCompleteCallback}
        />
      }

      if (visualisation.image)
        return <img
          src={visualisation.image.src}
          alt={dataset.title}
        />

      if (visualisation["mermaid"])
        return <MermaidChart
          rawData={dataset.rawData}
          compact={this.props.compact}
        />

      if (visualisation['html-metric'])
        return <HtmlMetric
          options={{}}
          metadata={dataset.metadata}
          rawData={dataset.rawData}
          getData={getData}
          limit={!dataset.visualisation ? 10 : undefined}
        />

      if (visualisation['carousel'])
        return <CarouselChart
          style={visualisation['carousel'].style}
          rawData={dataset.rawData}
          getData={getData} />

      return null  // nothing to render for unsupported visualisation
    }
  }

export const ChartWithContext = bindContexts(Chart, [
  APIContext,
  WorkspaceContext,
])

export default ChartWithContext

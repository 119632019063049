import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../assets/css/components/Workbench.css'
import WorkspaceMenu from 'src/components/WorkspaceMenu'
import NewQuestionDialog from 'src/components/NewQuestionDialog'
import { APIContext } from '../contexts/api'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { bindContexts } from '../utils/bindContexts'
import history from '../utils/history'
import { WorkspaceFeatures, isWorkspaceFeatureEnabled } from '../utils/appenv'
import { IWorkbenchProps, IWorkbenchState } from '../models/components/IWorkbench'
import IDataset from 'src/models/IDataset'

class Workbench extends React.Component<IWorkbenchProps, IWorkbenchState> {
    private previousContext: any;
    static contextType = MergedContexts;
    constructor (props: IWorkbenchProps) {
      super(props)
      this.state = {
        search: '',
        isQuestionDialogOpen: false,
      }
      this.handleSearch = this.handleSearch.bind(this)
    }

    public componentDidMount () {
      const { searchResults } = this.context
      if (searchResults.query) {
        this.setState({ search: searchResults.query })
      }

      const element = document.getElementsByClassName('toolbar')
      if (element && element.length > 0) {
        element[0].classList.remove('hide')
        element[0].classList.remove('show')
      }
      if (this.props.noHeader) {
        if (element && element.length > 0) {
          element[0].classList.add('noHeader')
        }
      } else {
        if (element && element.length > 0) {
          element[0].classList.remove('noHeader')
        }
      }

      // code added for Home view
      const rootEle = document.getElementsByClassName('App')
      if (this.props.isStickySearch) {
        if (rootEle && rootEle.length > 0) {
          rootEle[0].classList.add('toggleTopOnScroll')
        }
      } else {
        if (rootEle && rootEle.length > 0) {
          rootEle[0].classList.remove('toggleTopOnScroll')
        }
      }
      // end

      this.previousContext = this.context
    }

    public componentDidUpdate () {
      if (
        this.previousContext.searchResults !== this.context.searchResults &&
            (this.context.searchResults.query || this.state.search)
      ) {
        this.setState({ search: this.context.searchResults.query })
      }

      this.previousContext = this.context
    }

    private handleSearch (event: any) {
      event.preventDefault()
      const { workspace, search, searchResults, setSearchResults, handleSearchResults } =
            this.context
      history.push({
        pathname: '/l/' + workspace.id + '/feed',
        search: 'search="' + this.state.search + '"'
      })
      if (!searchResults.isLoading) {
        setSearchResults({ isLoading: true })
        search(workspace.id, handleSearchResults, null, null, this.state.search)
      }
    }

    public render () {
      const { workspace } = this.context
      const { isQuestionDialogOpen } = this.state
      const handleQuestionDialogOpen = () => this.setState({ isQuestionDialogOpen: true })
      const handleQuestionDialogClose = () => this.setState({ isQuestionDialogOpen: false })
      const handleAnswer = (dataset: IDataset) => {
        history.push(
          '/l/' + workspace.id + '/dataset/' + (dataset.alias ? dataset.alias : dataset.id)
        )
        isQuestionDialogOpen && handleQuestionDialogClose()
      }

      let sidebar = null
      let footer = null
      if (this.props.menu) {
        if (this.props.isSidebarLayout) {
          const sidebarClasses = this.props.isExpandedSidebarActive
            ? 'sidebar sidebar-expanded'
            : 'sidebar sidebar-narrow'
          sidebar = (
                    <Col className={sidebarClasses}>
                        {' '}
                        <WorkspaceMenu
                            isExpandedSidebarActive={this.props.isExpandedSidebarActive}
                            isSidebarLayout={this.props.isSidebarLayout}
                            toggleSidebar={this.props.toggleSidebar}
                            menu={this.props.menu} />
                    </Col>
          )
        } else {
          sidebar = null
          footer = (
                    <Row className="footer">
                        <WorkspaceMenu menu={this.props.menu} />
                    </Row>
          )
        }
      }

      return (
            <Container
                fluid={true}
                className={
                    'workbench-container ' +
                    (this.props.noHeader ? ' removeHeader' : '')
                }
            >
                {/* <Row className="workbench"> */}
                <Row
                    className={
                        this.props.isExpandedSidebarActive
                          ? 'workbench show_sidebar'
                          : 'workbench hide_sidebar'
                    }
                >
                    {sidebar != null ? sidebar : ''}
                    {this.props.children}
                </Row>
                <NewQuestionDialog
                    open={isQuestionDialogOpen}
                    handleClose={handleQuestionDialogClose}
                    handleAnswer={handleAnswer}
                />
                {isWorkspaceFeatureEnabled(workspace, WorkspaceFeatures.QUESTIONS) &&
                  <div className="button-wrapper app-action" onClick={handleQuestionDialogOpen}>
                    <i className='fas fa-plus' />
                  </div>}
                {footer != null ? footer : ''}
            </Container>
      )
    }
}

export const WorkbenchWithContext = bindContexts(Workbench, [APIContext, WorkspaceContext])
export default WorkbenchWithContext

import * as Sentry from '@sentry/react'
import React, { FunctionComponent } from 'react'
import connection_error_icon from 'src/assets/images/connection_error.png'
import { useAPIContext } from '../contexts/api'
import { useAuth0 } from '../contexts/auth0'
import AppEnv from 'src/utils/appenv'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function logError (error: any, user?:any) : void {
    Sentry.withScope((scope) => {
      scope.setTag("APP_VERSION", AppEnv.APP_VERSION)
      scope.setUser(user)
      scope.setExtras(error)
      if (error && error.stack && error.message) {
        // it's an application error, probably.  Log exception to Sentry
        Sentry.captureException(error)
      } else if (error && error.error === 'login_required') {
      } else if (error && error.error === 'consent_required') {
        // do not log
      } else if (error && error.request) {
        // api connection error
        // ignore, retry screen is shown to the user
      } else if (error && error.response && error.response.message) {
        // error caught from api error handler
        Sentry.captureMessage(error.response.message)
      } else if (error && error.message) {
        Sentry.captureMessage(error.message)
      } else {
        Sentry.captureMessage("Error w/ no message")
      }
    })
}

const Error: FunctionComponent<any> = () => {
  const { error, reintializeApi } = useAPIContext()
  const { user, logout } = useAuth0()
  logError(error, user)
  let message = null
  if (error && error.response) {
    message = error.response.message
  } else if (error && error.request) {
    message = 'We were unable to connect.'
  } else if (error && error.message) {
    message = error.message
  }
  if (!message) {
    message = 'An unexpected error occurred.'
  }

  const logoutWithRedirect = () => {
    logout({
      returnTo: window.location.origin
    })
  }

  return (
        <div className="fullScreenSize ">
            <div className="text-center RetryErrorBox">
                <img src={connection_error_icon} alt="Connection Error" />
                <h1>Uh oh!</h1>
                <h2 data-testid="error">{message}</h2>
                <button data-testid="retryButton" onClick={reintializeApi}>
                    RETRY
                </button>
                &nbsp;
                <button data-testid="logoutButton" onClick={logoutWithRedirect}>
                    LOGOUT
                </button>
            </div>
        </div>
  )
}

export default Error

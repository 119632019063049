import React from 'react'
import Col from 'react-bootstrap/Col'
import ContentLoader from 'react-content-loader'

const workspacePlaceholder = (
        <div className="dataLoader_parent d-flex" data-testid="loader">
            <Col className="dataLoader">
                <ContentLoader className="placeholderList" viewBox="0 0 446 15" speed={2}>
                    <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                </ContentLoader>
                <ContentLoader className="placeholderDivider w-100" height={10} speed={2}>
                    <rect x="0" y="5" height="2" />
                </ContentLoader>
                <ContentLoader viewBox="0 0 446 40" speed={2}>
                    <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                    <rect x="5" y="18" rx="0" ry="0" width="220" height="3" />
                    <rect x="5" y="26" rx="0" ry="0" width="220" height="3" />
                </ContentLoader>
                <ContentLoader viewBox="0 0 446 50" speed={2}>
                    <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                    <rect x="5" y="18" rx="0" ry="0" width="220" height="3" />
                    <rect x="5" y="26" rx="0" ry="0" width="220" height="3" />
                    <rect x="5" y="36" rx="0" ry="0" width="100" height="6" />
                </ContentLoader>
                <ContentLoader viewBox="0 0 446 50" speed={2}>
                    <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                    <rect x="5" y="18" rx="0" ry="0" width="220" height="3" />
                    <rect x="5" y="26" rx="0" ry="0" width="220" height="3" />
                    <rect x="5" y="36" rx="0" ry="0" width="100" height="6" />
                </ContentLoader>
            </Col>
        </div>
)

export default workspacePlaceholder;
import * as assert from 'assert-plus'
import { AxiosInstance } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { APIContext } from 'src/contexts/api'
import { Auth0Context } from 'src/contexts/auth0'
import { MergedContexts } from 'src/contexts/merged'
import { WorkspaceContext } from 'src/contexts/workspace'
import { WindowContext } from 'src/contexts/window';
import { bindContexts } from 'src/utils/bindContexts'
import { getSingleWorkspaceURI, createUpdateProfileURI } from 'src/contexts/api';
import { logError } from 'src/utils/Error';
import Back from 'src/components/Back';
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import AppEnv from '../utils/appenv';
import { waitUntilReady, AsyncLoader } from '../utils/async';
import WorkspaceForm from 'src/components/WorkspaceForm'
import HelpPanel from 'src/components/HelpPanel'
import { WorkspaceHelp } from 'src/assets/help/views/workspaceEdit';
import workspacePlaceholder from 'src/components/placeholders/WorkspacePlaceholder'
import { IWorkspaceEditProps, IWorkspaceEditState } from 'src/models/views/IWorkspaceEdit'
import 'src/assets/css/views/formViews.css';

class WorkspaceEdit extends React.Component<IWorkspaceEditProps, IWorkspaceEditState> implements AsyncLoader {
    static contextType = MergedContexts;
    public isThisMounted = false;

    constructor (props: IWorkspaceEditProps) {
      super(props)
      this.state = {
        isLoading: true,
        waited: 0,
        ready: false,
        workspace: null,
        copied: false,
        message: null,
      }

      this.handleBackToWorkspace = this.handleBackToWorkspace.bind(this)
      this.handleSaved = this.handleSaved.bind(this)
      this.handleDeleted = this.handleDeleted.bind(this)
      this.handleMessageClosed = this.handleMessageClosed.bind(this)
      this.copyText = this.copyText.bind(this)
    }

    // implements AsyncLoader
    public isLoading(): boolean {
        return this.state.isLoading;
    }

    public componentDidMount () {
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      assert.func(api, 'APIContext must be initialised before this component is used')
      const workspaceId = this.props.match.params.workspaceId;
      assert.string(workspaceId, ':workspaceId param must be supplied through router')

      this.isThisMounted = true
      this.loadWorkspace(workspaceId)
      // wait at least MINIMUM_LOAD, to show the placeholder smoothly on each load of this view
      waitUntilReady(
          parseInt(AppEnv.APP_PLACEHOLDER_MINIMUM_LOAD, 10),
          parseInt(AppEnv.APP_PLACEHOLDER_LOAD_TIMEOUT, 10),
          this.isThisMounted,
          this
      ).then(result => {
          if (this.isThisMounted) {
              this.setState({ ready: true, waited: result });
          }
      });
    }

    componentDidUpdate (prevProps: IWorkspaceEditProps) {
      if (this.props.match.params.workspaceId !== prevProps.match.params.workspaceId) {
          this.loadWorkspace(this.props.match.params.workspaceId)
      }
    }

    public componentWillUnmount () {
      // with thanks to - https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
      this.isThisMounted = false
    }

    private isWorkspaceAdmin (workspace:any) {
      return workspace && workspace._links && workspace._links['update delete workspace'] != null
    }

    private async loadWorkspace(workspaceId:string) {
      const { catalogAPI, isOwner } = this.context
      const api = catalogAPI as AxiosInstance
      if (workspaceId && workspaceId == 'new') {
          this.props.setViewName && this.props.setViewName("New Workspace")
          !isOwner() ? this.props.history.push('/signup') : this.isThisMounted && this.setState({ workspace: null, isLoading: false, ready: true })
      } else if (workspaceId) {
          this.props.setViewName && this.props.setViewName("Workspace Settings")
          await api
              .get(getSingleWorkspaceURI(workspaceId))
              .then((resp:any) => {
                const workspace = resp.data
                if (workspace && !this.isWorkspaceAdmin(workspace)) this.props.history.push('/')
                this.isThisMounted && this.setState({workspace: workspace, isLoading: false })
                return resp.data
              })
              .catch((e:any) => {
                this.setState({ isLoading: false })
                logError(e)
              })
      }
    }

    private async handleBackToWorkspace() {
        // route user to workspace home
        this.props.history.push('/');
    }

    private copyText = () => {
      this.setState({ copied: true })
    }

    private handleSaved = (savedWorkspace:any) => {
      const { updateWorkspace, catalogAPI, profile } = this.context;
      if (savedWorkspace) {
        const { name } = savedWorkspace
        const workspaceId = this.props.match.params.workspaceId;
        if (workspaceId && workspaceId == 'new') {
          this.setState({ message:'Workspace created successfully!' })
          // set the profile default workspace to this new workspace
          catalogAPI
            .patch(createUpdateProfileURI(profile.id), {
              defaultWorkspace: {
                id: savedWorkspace.id
              }
            })
          // a call to updateWorkspace for a new workspace will immediately stop the created message
          setTimeout(() => {
            updateWorkspace && updateWorkspace(savedWorkspace)
            this.props.history.push('/l/' + savedWorkspace.id + '/data/new')
          }, 1000)
        } else {
          this.setState({ message:`'${name}' Saved`, workspace: savedWorkspace })
          updateWorkspace(savedWorkspace)
        }
      }
    }

    private handleDeleted = (deletedWorkspace:any) => {
	  const { name } = deletedWorkspace
	  this.setState({ message:`'${name}' deleted` })
      setTimeout(() => {
        window.location.href = '/'
      }, 1000)
    }

    private handleMessageClosed = () => {
		this.setState({ message:null })
    };


    public render () {
        const { size } = this.context;
        const { workspace, ready, copied } = this.state
        const { isHelpMenuOpen } = this.state;

        const helpButtonClicked = () => this.setState({ isHelpMenuOpen: true})
        const closeHelpPanel = () => this.setState({ isHelpMenuOpen: false})
        const title = workspace ? "Workspace Settings" : "New Workspace"
        const workspaceId = this.props.match.params.workspaceId;

        const formContent = (
            <>
                <div className={`${size.smallScreen ? 'col-12 no-padding-left no-padding-right' : 'col-7 form no-padding-left no-padding-right'} ${isHelpMenuOpen ? 'no-display' : ''}`}>
                    <WorkspaceForm
                        title={title}
                        workspaceId={workspaceId}
                        workspace={workspace}
                        message={this.state.message}
                        savedCallback={this.handleSaved}
                        deletedCallback={this.handleDeleted}
                        handleMessageClosed={this.handleMessageClosed}
                        copyText={this.copyText}
                        helpButtonClicked={helpButtonClicked}
                    />
                    {!size.smallScreen && <div className="vl-help"></div>}
                </div>
                {!isHelpMenuOpen ?
                    <>{!size.smallScreen &&
                        <div className='col help no-padding-left'>
                            <HelpPanel title={title} overview={<WorkspaceHelp edit/>} />
                        </div>}
                    </>
                    :
                    <div className="help-info">
                        <HelpPanel title={title} overview={<WorkspaceHelp edit/>} closeHelpPanel={closeHelpPanel}/>
                    </div>}
            </>
      );

      const content = (
          <>
              <Snackbar
                  open={copied}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  autoHideDuration={3000}
                  onClose={() => this.setState({ copied: false })}>
                  <Alert severity='success'>Copied</Alert>
              </Snackbar>
              { this.props.app && (<Back workspace={this.context.workspace} />)}
              <div className="form-container">{formContent}</div>
          </>
      )
      return <>
          <div className={[
            "rightside_pageContent",
            "editForms",
            ...this.props.app ? ["p-0"] : []
          ].join(" ")}>
              {ready ? content : workspacePlaceholder}
          </div>
      </>

    }
}

export const WorkspaceEditWithContext = bindContexts(WorkspaceEdit, [
  APIContext,
  Auth0Context,
  WorkspaceContext,
  WindowContext
])
export default withRouter(WorkspaceEditWithContext)
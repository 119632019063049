import * as assert from 'assert-plus'
import { AxiosInstance } from 'axios'
import classNames from 'classnames'
import * as React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Toast from 'react-bootstrap/Toast'
import ContentLoader from 'react-content-loader'
import { withRouter } from 'react-router-dom'
import Workbench from 'src/components/Workbench'
import Back from 'src/components/Back';
import { logError } from 'src/utils/Error'
import validator from 'validator'
import { APIContext, createUpdateProfileURI, getProfileURI } from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { IProfileProps, IProfileState } from '../models/views/IProfile'
import 'src/styles.css'
import { bindContexts } from '../utils/bindContexts'

class Profile extends React.Component<IProfileProps, IProfileState> {
    static contextType = MergedContexts;

    public isThisMounted = false;
    constructor (props: IProfileProps) {
      super(props)
      this.state = {
        ready: false,
        name: { value: '', isValid: true, message: '' },
        email: { value: '', isValid: true, message: '' },
        loading: false,
        submitSuccess: false,
        profile: {},
        message: 'Profile updated !'
      }

      this.submitHandler = this.submitHandler.bind(this)
      this.handleInputChanges = this.handleInputChanges.bind(this)
      this.formIsValid = this.formIsValid.bind(this)
      this.resetValidationStates = this.resetValidationStates.bind(this)
    }

    public async componentDidMount () {
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      assert.func(api, 'APIContext must be initialise before this component is used')

      this.isThisMounted = true
      this.props.setViewName && this.props.setViewName("Profile")
      const { user } = this.context
      await api
        .get(getProfileURI(user.sub))
        .then(resp => {
          if (this.isThisMounted) {
            this.setState({
              name: { value: resp.data.name },
              email: { value: resp.data.email },
              ready: true
            })
          }
        })
        .catch(async e => {
          if (this.isThisMounted) {
            this.setState({ ready: true })
            logError(e)
          }
        })
    }

    public componentWillUnmount () {
      this.isThisMounted = false
    }

    private handleInputChanges = (e: any) => {
      e.preventDefault()
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      const state = {
        ...this.state,
        [name]: {
          ...this.state[name],
          value
        }
      }

      this.setState(state)
    };

    private async submitHandler (e: any) {
      e.preventDefault()
      // reset states before the validation procedure is run.
      this.resetValidationStates()
      // run the validation, and if it's good move on.

      if (await this.formIsValid()) {
        const { user, catalogAPI, setProfile } = this.context
        const api = catalogAPI as AxiosInstance
        const { name, email } = this.state
        this.setState({ submitSuccess: false, loading: true })
        const ProfileData = { id: user.sub, name: name.value, email: email.value }
        await api
          .put(createUpdateProfileURI(user.sub), ProfileData)
          .then(response => {
            setProfile(response.data)
            this.setState({
              submitSuccess: true,
              loading: false,
              message: 'Profile updated successfully!'
            })
          })
          .catch(e => {
            logError(e)
            this.setState({
              submitSuccess: true,
              loading: false,
              message: 'Error while updating. Please try again.'
            })
          })
      }
      return false
    }

    formIsValid = async () => {
      const name = { ...this.state.name }
      const email = { ...this.state.email }
      let isGood = true

      if (validator.isEmpty(name.value)) {
        name.isValid = false
        name.message = 'Enter your name'
        isGood = false
      }

      if (!validator.isEmail(email.value)) {
        email.isValid = false
        email.message = 'Enter valid email address'
        isGood = false
      }

      // perform addtion validation on password and confirmPassword here...

      if (!isGood) {
        this.setState({
          name,
          email
        })
      }

      return isGood
    };

    resetValidationStates = () => {
      const state = JSON.parse(JSON.stringify(this.state))
      Object.keys(state).forEach(key => {
        if (state[key].hasOwnProperty('isValid')) {
          state[key].isValid = true
          state[key].message = ''
        }
      })

      this.setState(state)
    };

    public render () {
      const { user } = this.context
      const { name, email, ready } = this.state
      const nameGroupClass = classNames('form-group', { 'has-error': !name.isValid })

      const emailGroupClass = classNames('form-group', { 'has-error': !email.isValid })

      const contentLoader = (
            <Col className={ready ? 'feed-hidden loaderfadeOut' : 'feed'}>
                <div className="dataLoader_parent d-flex" data-testid="loader">
                    <Col className="dataLoader">
                        <ContentLoader className="placeholderList" viewBox="0 0 446 15" speed={2}>
                            <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                        </ContentLoader>
                        <ContentLoader className="placeholderDivider w-100" height={10} speed={2}>
                            <rect x="0" y="5" height="2" />
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 446 40" speed={2}>
                            <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                            <rect x="5" y="18" rx="0" ry="0" width="220" height="3" />
                            <rect x="5" y="26" rx="0" ry="0" width="220" height="3" />
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 446 50" speed={2}>
                            <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                            <rect x="5" y="18" rx="0" ry="0" width="220" height="3" />
                            <rect x="5" y="26" rx="0" ry="0" width="220" height="3" />
                            <rect x="5" y="36" rx="0" ry="0" width="100" height="6" />
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 446 50" speed={2}>
                            <rect x="5" y="8" rx="0" ry="0" width="30" height="6" />
                            <rect x="5" y="18" rx="0" ry="0" width="220" height="3" />
                            <rect x="5" y="26" rx="0" ry="0" width="220" height="3" />
                            <rect x="5" y="36" rx="0" ry="0" width="100" height="6" />
                        </ContentLoader>
                    </Col>
                </div>
            </Col>
      )

      return (
            <Workbench>
                <div className="w-100" data-testid="resolved">
                    {ready ? (
                        <>
                            <Back />
                            <div className={ready ? 'full_pageContent pad_tb15 clearfix' : 'hidden'}>
                                <div className="profile_page">
                                    <Toast
                                        onClose={() => this.setState({ submitSuccess: false })}
                                        show={this.state.submitSuccess}
                                    >
                                        <Toast.Body>{this.state.message}</Toast.Body>
                                    </Toast>
                                    <ScrollAnimation animateIn="fadeIn" offset={10}>
                                        <h1>Profile View</h1>
                                    </ScrollAnimation>
                                    <Form onSubmit={this.submitHandler}>
                                        <ScrollAnimation animateIn="fadeIn" offset={10}>
                                            <Form.Group
                                                controlId="profileForm.name"
                                                className={nameGroupClass}
                                            >
                                                <Form.Label>Name</Form.Label>
                                                <Form.Text className="text-muted">
                                                    We&apos;ll use your name supplied here in all correspondence.
                                                </Form.Text>
                                                <Form.Control
                                                    type="text"
                                                    onChange={e => this.handleInputChanges(e)}
                                                    value={name.value}
                                                    name="name"
                                                    placeholder=""
                                                />
                                                <Form.Text
                                                    className="validation_msg"
                                                    data-testid="name_err"
                                                >
                                                    {name.message}
                                                </Form.Text>
                                            </Form.Group>
                                        </ScrollAnimation>

                                        { user.sub.startsWith('auth0') && (
                                        <ScrollAnimation animateIn="fadeIn" offset={10}>
                                            <Form.Group
                                                controlId="exampleForm.domain"
                                                className={emailGroupClass}
                                            >
                                                <Form.Label>Email</Form.Label>
                                                <Form.Text className="text-muted">
                                                    Changing your email address will also change your login
                                                    credentials.
                                                </Form.Text>
                                                <Form.Control
                                                    type="text"
                                                    onChange={e => this.handleInputChanges(e)}
                                                    value={email.value}
                                                    name="email"
                                                />
                                                <Form.Text
                                                    className="validation_msg"
                                                    data-testid="email_err"
                                                >
                                                    {email.message}
                                                </Form.Text>
                                            </Form.Group>
                                        </ScrollAnimation>
                                        )}

                                        <ScrollAnimation animateIn="fadeIn" offset={10}>
                                            <Button
                                                id="submit"
                                                variant="warning"
                                                type="submit"
                                                className="m_top10 float-left"
                                            >
                                                Save
                                            </Button>
                                        </ScrollAnimation>

                                        <div className="m_top10 d-inline-block float-left spinner_btnHolder">
                                            {this.state.loading && <Spinner animation="border" />}
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </>
                    ) : contentLoader}
                </div>
            </Workbench>
      )
    }
}

export const ProfileWithContext = bindContexts(Profile, [
  Auth0Context,
  APIContext,
  WorkspaceContext
])
export default withRouter(ProfileWithContext)

import { IDataPluginSettings } from 'src/models/components/IDataPluginSettings'
import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import DataPluginParameterField from 'src/components/DataPluginParameterField';
import GoogleAnalyticsViewsField from 'src/components/plugins/GoogleAnalyticsViewsField';
import GoogleAdsCustomerIdField from './plugins/GoogleAdsCustomerIdField';
import GoogleSheetsSheetIdField from './plugins/GoogleSheetsSheetIdField';
import GoogleDriveFileIdField from './plugins/GoogleDriveFilesAndFoldersField';
import DataPluginAuthorization from './DataPluginAuthorization';
import AppEnv from 'src/utils/appenv'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { SettingKind } from 'src/models/IDataPlugin'

const DataPluginSettings: FunctionComponent<IDataPluginSettings> = (props: PropsWithChildren<IDataPluginSettings>) => {
    const { handleSettingsChange, handleSettingsUpdate, selectedDataplugin, prefix, isEditEnabled, readOnlyProperties } = props;
    const properties = props.properties || {}
    const errors = props.errors || []
    // flag to control display of authorization vs settings
    const [consentRequired, setIsConsentRequired] = useState(true);
    const handleConsentRequired = (isRequired: boolean) => setIsConsentRequired(isRequired)

    const values = { ...selectedDataplugin.settings.filter(s => s.value != null).reduce((p, c) => ({ ...p, [`${selectedDataplugin.name}.${c.name}`]: c.value }), {}), ...properties }

    const pluginAuthorization = (
        handleSettingsUpdate &&
        <GoogleOAuthProvider clientId={AppEnv.APP_OAUTH_GOOGLE_CLIENT_ID}>
            <DataPluginAuthorization
                prefix={prefix}
                properties={values}
                errors={errors}
                handleSettingsUpdate={handleSettingsUpdate}
                consentRequiredCallback={handleConsentRequired}
            />
        </GoogleOAuthProvider>
    )

    return (
        <div data-testid="datastore-properties" className={`${isEditEnabled ? '' : 'datastore-properties'}`}>
            {pluginAuthorization}
            {(!consentRequired || !isEditEnabled) && selectedDataplugin && selectedDataplugin.settings &&
                selectedDataplugin.settings.map((setting: any, index: number) => {

                    if (setting.hidden || setting.kind === SettingKind.Hidden) return

                    const fieldId = `${selectedDataplugin.name}.${setting.name}`
                    const propertyName = prefix ? `${prefix}.${setting.name}` : setting.name
                    const error = errors?.find((err: any) => err.field === `properties.${propertyName}`)

                    let field = (
                        <DataPluginParameterField
                            isCopyEnabled={!isEditEnabled}
                            isLastpElement={selectedDataplugin.settings.length - 1 === index}
                            fieldId={fieldId}
                            setting={setting}
                            name={propertyName}
                            property={readOnlyProperties?.[setting.name]}
                            handleSettingsChange={handleSettingsChange}
                            currentValue={properties[propertyName]}
                            error={error}
                            handleCopyProp={props.handleCopyProp} />)

                    if (isEditEnabled) {
                        if (fieldId == 'tap-google-analytics.view_id') {
                            field = (
                                <GoogleAnalyticsViewsField
                                    properties={values}
                                    setting={setting}
                                    prefix={prefix}
                                    handleSettingsChange={handleSettingsChange}
                                    handleSettingsUpdate={handleSettingsUpdate}
                                    currentValue={properties[propertyName]}
                                    error={error} />)
                        } else if (fieldId == 'tap-googleads.customer_id') {
                            field = (
                                <GoogleAdsCustomerIdField
                                    properties={values}
                                    setting={setting}
                                    prefix={prefix}
                                    handleSettingsChange={handleSettingsChange}
                                    handleSettingsUpdate={handleSettingsUpdate}
                                    currentValue={properties[propertyName]}
                                    error={error} />)
                        } else if (fieldId == 'tap-google-sheets.sheet_id') {
                            field = (
                                <GoogleSheetsSheetIdField
                                    properties={values}
                                    setting={setting}
                                    prefix={prefix}
                                    handleSettingsChange={handleSettingsChange}
                                    handleSettingsUpdate={handleSettingsUpdate}
                                    currentValue={properties[propertyName]}
                                    error={error} />)
                        } else if (fieldId == 'gdrive.file_id') {
                            field = (
                                <GoogleDriveFileIdField
                                    properties={values}
                                    setting={setting}
                                    prefix={prefix}
                                    handleSettingsChange={handleSettingsChange}
                                    handleSettingsUpdate={handleSettingsUpdate}
                                    currentValue={properties[propertyName]}
                                    error={error} />)
                        }
                    }

                    return (
                        <div key={index} className={'dataPlugin-properties'}>
                            {field}
                        </div>)

                })}
        </div>
    );
};

export default DataPluginSettings
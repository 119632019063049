import * as assert from 'assert-plus'
import { AxiosInstance, AxiosResponse } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { APIContext } from 'src/contexts/api'
import { Auth0Context } from 'src/contexts/auth0'
import { MergedContexts } from 'src/contexts/merged'
import { WorkspaceContext } from 'src/contexts/workspace'
import { WindowContext } from 'src/contexts/window';
import { bindContexts } from 'src/utils/bindContexts'
import { createUpdateProfileURI, getSingleWorkspaceURI, createNewWorkspaceURI } from 'src/contexts/api';
import { logError } from 'src/utils/Error';
import Workbench from 'src/components/Workbench'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import AppEnv from '../utils/appenv';
import { waitUntilReady, AsyncLoader } from '../utils/async';
import WorkspaceForm from 'src/components/WorkspaceForm'
import HelpPanel from 'src/components/HelpPanel'
import { WorkspaceHelp } from 'src/assets/help/views/workspaceEdit';
import workspacePlaceholder from 'src/components/placeholders/WorkspacePlaceholder'
import 'src/assets/css/views/onboarding.css';
import 'src/assets/css/views/formViews.css';
import IDataPlugin from 'src/models/IDataPlugin'
import PipelineForm from 'src/components/PipelineForm';
import Tasks from 'src/components/Tasks'
import checkTick from '../assets/images/check.png'
import IPipeline from 'src/models/IPipeline'
import { DataPluginSearch } from 'src/components/Search'
import DataPluginRow from 'src/components/DataPluginRow'
import IPage from 'src/models/IPage'

class OnboardingView extends React.Component<any, any> implements AsyncLoader {
    static contextType = MergedContexts;
    public isThisMounted = false;
    private workspaceReadyIntervalId: null | ReturnType<typeof setTimeout> = null

    constructor (props: any) {
      super(props)
      this.state = {
        isLoading: true,
        waited: 0,
        ready: false,
        workspace: null,
        copied: false,
        pipeline: undefined,
        dataPlugins: undefined,
        dataPlugin: undefined,
        isHelpMenuOpen: false,
        step: 1
      }

      this.loadDataPlugins = this.loadDataPlugins.bind(this)
      this.handleSaved = this.handleSaved.bind(this)
      this.handleDeleted = this.handleDeleted.bind(this)
      this.copyText = this.copyText.bind(this)
      this.handleOpenHelpPanel = this.handleOpenHelpPanel.bind(this)
      this.handleCloseHelpPanel = this.handleCloseHelpPanel.bind(this)
      this.skipSteps = this.skipSteps.bind(this)
      this.goToLab = this.goToLab.bind(this)
      this.updateTheWorkspace = this.updateTheWorkspace.bind(this)
      this.handleAdd = this.handleAdd.bind(this)
      this.handleDataPluginDeleted = this.handleDataPluginDeleted.bind(this)
    }

    // implements AsyncLoader
    public isLoading(): boolean {
        return this.state.isLoading;
    }

    public componentDidMount () {
      this.isThisMounted = true
      const { catalogAPI } = this.context
      const api = catalogAPI as AxiosInstance
      assert.func(api, 'APIContext must be initialised before this component is used')
      const step = this.props.match.params.step;
      assert.string(step, ':step param must be supplied through router')
      const workspaceId = this.props.match.params.workspaceId;
      assert.string(workspaceId, ':workspaceId param must be supplied through router')
      this.props.setViewName && this.props.setViewName("")
      this.loadWorkspace(workspaceId).then(this.loadDataPlugins)

      // wait at least MINIMUM_LOAD, to show the placeholder smoothly on each load of this view
      waitUntilReady(
          parseInt(AppEnv.APP_PLACEHOLDER_MINIMUM_LOAD, 10),
          parseInt(AppEnv.APP_PLACEHOLDER_LOAD_TIMEOUT, 10),
          this.isThisMounted,
          this
      ).then(result => {
          if (this.isThisMounted) {
              this.setState({ ready: true, waited: result });
          }
      });
    }

    public componentWillUnmount () {
      this.workspaceReadyIntervalId && clearInterval(this.workspaceReadyIntervalId);
      // with thanks to - https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
      this.isThisMounted = false
    }

    public componentDidUpdate (prevProps: any, prevState: any) {
      const step = this.props.match.params.step;
      const workspaceId = this.props.match.params.workspaceId;
      const firstLoad = !prevState.workspace || this.state.workspace.id !== prevState.workspace.id

      if (this.state.step === 2 && !this.state.workspace.managed && this.state.workspace.status === 'READY')
        this.skipSteps()

      if (step !== prevProps.match.params.step && step == 'w') {
        // navigated back to workspace settings step
        this.restartOnboarding(workspaceId)
      } else if (step !== prevProps.match.params.step && step == 'imports') {
        // navigated back to imports step
        this.isThisMounted && this.setState({step: 2})
      } else if (step !== prevProps.match.params.step && step == 'tasks') {
        // navigated back to tasks step
        this.isThisMounted && this.setState({step: 3})
      } else if (workspaceId !== prevProps.match.params.workspaceId
          && prevProps.match.params.workspaceId && prevProps.match.params.workspaceId != 'new') {
        this.restartOnboarding(workspaceId)
      }
      if (this.state.workspace && firstLoad && step == 'w' && this.state.step != 1) {
        // navigated directly to workspace settings step
        this.restartOnboarding(workspaceId)
      } else if (this.state.workspace && firstLoad && step == 'imports' && this.state.step != 2) {
        // navigated directly to the import step
        this.isThisMounted && this.setState({step: 2})
      } else if (this.state.workspace && firstLoad && step == 'tasks' && this.state.step != 3) {
        // navigated directly to the tasks step
        this.isThisMounted && this.setState({step: 3})
      } else if (this.state.workspace && firstLoad) {
        // enable back after save workspace
        this.props.history.push('/setup/w/'+this.state.workspace.id)
      }

    }

    private restartOnboarding(workspaceId:any) {
      this.workspaceReadyIntervalId && clearInterval(this.workspaceReadyIntervalId)
      this.isThisMounted && this.setState({isLoading: true, ready: false})
      this.loadWorkspace(workspaceId)
      this.isThisMounted && this.setState({step: 1})
    }

    private isWorkspaceAdmin (workspace:any) {
      return workspace && workspace._links && workspace._links['update delete workspace'] != null
    }

    private async checkWorkspaceReady() {
     const workspaceId = this.props.match.params.workspaceId;
      const workspace = await this.loadWorkspace(workspaceId)
      if (workspace && workspace.status == 'READY') {
        this.workspaceReadyIntervalId && clearInterval(this.workspaceReadyIntervalId)
      }
    }

    private async loadWorkspace(workspaceId:string) {
      const { catalogAPI, isOwner } = this.context
      if (workspaceId && workspaceId == 'new') {
        if (!isOwner()) {
          this.props.history.push('/signup')
        } else {
          return await catalogAPI
            .post(createNewWorkspaceURI())
            .then((resp: any) => {
              const workspace = resp.data
              // initialise and setting workspace here has the side effect of pushing a workspace id into url
              this.isThisMounted && this.setState({ workspace: workspace, isLoading: false, ready: true })
              return workspace
            })
            .catch((e: any) => {
              this.isThisMounted && this.setState({ isLoading: false })
              logError(e)
            })
        }
      } else if (workspaceId) {
          return await catalogAPI
              .get(getSingleWorkspaceURI(workspaceId), { errorHandler: false })
              .then((resp:any) => {
                const workspace = resp.data
                if (workspace && !this.isWorkspaceAdmin(workspace)) this.props.history.push('/')
                this.isThisMounted && this.setState({workspace: workspace, isLoading: false, ready: true })
                this.props.setViewName && this.props.setViewName(workspace.name)
                return resp.data
              })
              .catch((e:any) => {
                this.props.history.push('/setup/w/new')
                logError(e)
              })
      }
      return null
    }

    private loadDataPlugins = (workspace: any) => {
      const { catalogAPI } = this.context
      const dataPluginsHref = workspace?._links.dataplugins?.href
      if (!dataPluginsHref) return

      catalogAPI.get('/dataplugins?sort=label&size=9999').then((r: AxiosResponse<IPage<IDataPlugin>>) => {
        const extractors = r.data._embedded?.dataplugins.filter(dp => dp.pluginType === 'EXTRACTOR')
        this.setState({ dataPlugins: extractors })
      })
    }

    private handleSaved = async (savedWorkspace:any) => {
      const { catalogAPI, profile } = this.context;
      if (savedWorkspace) {
        this.isThisMounted && this.setState({step: 2})
        // set the profile default workspace to this new workspace
        await catalogAPI
          .patch(createUpdateProfileURI(profile.id), {
            defaultWorkspace: {
              id: savedWorkspace.id
            }
          })
        this.isThisMounted && this.setState({workspace: savedWorkspace})
        this.props.history.push(`/setup/imports/${savedWorkspace.id}`)
        // start a check for workspace ready
        this.workspaceReadyIntervalId = setInterval(this.checkWorkspaceReady.bind(this), 5000)
        this.loadDataPlugins(savedWorkspace)
      }
    }

    private copyText = () => {
      this.isThisMounted && this.setState({ copied: true })
    }

    private handleDeleted = () => {
      setTimeout(() => {
        window.location.href = '/'
      }, 1000)
    }

    public handleOpenHelpPanel(){
      this.setState({ isHelpMenuOpen: true })
    }

    public handleCloseHelpPanel(){
      this.setState({ isHelpMenuOpen: false })
    }

    public skipSteps () {
      this.updateTheWorkspace()
      this.isThisMounted && this.setState({step: 3})
      this.props.history.push('/setup/tasks/'+this.state.workspace.id)
    }

    public updateTheWorkspace(){
      if (!this.state.workspace)
        return

      const { updateWorkspace, workspaceJobs, getAllWorkspaceJobs } = this.context
      updateWorkspace(this.state.workspace)

      if (!workspaceJobs)
        getAllWorkspaceJobs(this.state.workspace.id)
    }

    public goToLab(){
      this.updateTheWorkspace()
      this.state.workspace && this.props.history.push('/lab/' + this.state.workspace.id + '/pipelines')
    }

    public async handleAdd(dataPlugin: IDataPlugin) {
      const { catalogAPI } = this.context
      const { workspace } = this.state

      const newPipelineHref = workspace._links['new pipeline'].href

      this.setState({
        dataPlugin: dataPlugin,
        pipeline: await catalogAPI.post(newPipelineHref).then((r: AxiosResponse<IPipeline>) => r.data)
      })
    }

    public handleDataPluginDeleted(dataPlugin: IDataPlugin) {
      this.setState({ dataPlugins: this.state.dataPlugins.filter((dp: IDataPlugin) => dp.id !== dataPlugin.id)})
    }

    public render () {
      const { size } = this.context;
      const { workspace, ready, copied, pipeline, dataPlugins, dataPlugin, step } = this.state
      const { isHelpMenuOpen } = this.state;
      const workspaceId = this.props.match.params.workspaceId;
      const title = "New Workspace"

      const contentPlaceholder = workspacePlaceholder
      const savedPipelineCallback = (updatedPipeline:any, isDraft?:boolean) => {
        if (!isDraft) {
          this.isThisMounted && this.setState({pipeline: updatedPipeline, step: 3})
          this.props.history.push('/setup/tasks/'+this.state.workspace.id)
        }
      }

      const formContent = step == 1 ? (
        <>
          <div className={`${size.smallScreen ? 'col-12 no-padding-left no-padding-right' : 'col-7 form no-padding-left no-padding-right'} ${isHelpMenuOpen ? 'no-display' : ''}`}>
            <WorkspaceForm
                title={title}
                workspaceId={workspaceId}
                workspace={workspace}
                message={null}
                savedCallback={this.handleSaved}
                deletedCallback={this.handleDeleted}
                copyText={this.copyText}
                helpButtonClicked={this.handleOpenHelpPanel} />
            {!size.smallScreen && <div className="vl-help"></div>}
          </div>
          {!isHelpMenuOpen ?
            <>{!size.smallScreen &&
                <div className='col help no-padding-left'>
                    <HelpPanel
                      title={title}
                      overview={<WorkspaceHelp />} />
                </div>}
            </>
            :
            <div className="help-info">
                <HelpPanel
                  title={title}
                  overview={<WorkspaceHelp />}
                  closeHelpPanel={this.handleCloseHelpPanel} />
            </div>}
        </>
      ) : step == 2 && (!workspace || workspace.status != 'READY' || !dataPlugins) ? (
        <>
          <div className="onboarding-magic content-center">
              <div className='workspace-ready-icon'>
                <div className='onboarding-magic-icon'><div className='onboarding-magic-icon2'></div></div>
                <i className="fas fa-magic onboarding-magic-icon3" />
              </div>
              <h1>Please wait a moment while we do our magic.</h1>
              <div>
                <span>Preparing your workspace usually takes less than a minute.
                 <br/>- Creating private data store.
                 <br/>- Creating configuration repository.
                 <br/>- Configuring Meltano project.
                </span>
              </div>
          </div>
        </>
      ) : step == 2 ? (
          !dataPlugin && !pipeline ?
            <div className='data-page'>
              <DataPluginSearch
                entities={dataPlugins}
                row={e =>
                  <DataPluginRow
                      key={e.id}
                      dataPlugin={e}
                      handleAdd={this.handleAdd}
                      handleDeleted={this.handleDataPluginDeleted} />} />
            </div> :
            <>
              <div className={`${size.smallScreen ? 'col-12 no-padding-left no-padding-right' : 'col-7 form no-padding-left no-padding-right'} ${isHelpMenuOpen ? 'no-display' : ''}`}>
                <PipelineForm
                  workspace={workspace}
                  pipeline={pipeline}
                  dataPlugin={dataPlugin}
                  helpButtonClicked={this.handleOpenHelpPanel}
                  onSaved={savedPipelineCallback}
                  onClose={this.skipSteps} />
                {!size.smallScreen && <div className="vl-help"></div>}
              </div>
              {!isHelpMenuOpen ?
                !size.smallScreen &&
                  <div className='col help no-padding-left'>
                    <HelpPanel
                      title="Data Import"
                      dataPlugin={dataPlugin} />
                  </div> :
                <div className="help-info">
                  <HelpPanel
                    title="Data Import"
                    dataPlugin={dataPlugin}
                    closeHelpPanel={this.handleCloseHelpPanel} />
                </div>}
              </>
      ) : (
        <Tasks onboarding={true} updateTheWorkspace={this.updateTheWorkspace} workspace={workspace} />
      );
      const content = workspace && step == 1 ? (
          <>
              <Snackbar
                  open={copied}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  autoHideDuration={3000}
                  onClose={() => this.isThisMounted && this.setState({ copied: false })}>
                  <Alert severity='success'>Copied</Alert>
              </Snackbar>
              <Workbench>
                  <div className="w-100" data-testid="resolved">
                      <div className="onboarding editForms workspaceEdit">
                          <div className="form-steps">
                              <div className="step-circles">
                                <div className="tick-circle-workspace" style={{backgroundColor: '#454F66'}}></div>
                                <div className="tick-circle-workspace"></div>
                                <div className="tick-circle-workspace"></div>
                              </div>
                              <div className="step-circles-title"><h2 className="title-label">| Setup your workspace</h2></div>
                          </div>
                          <div className="form-container">{formContent}</div>
                      </div>
                  </div>
              </Workbench>
          </>
      ) : workspace && step == 2 ? (
        <Workbench>
            <div className="w-100" data-testid="resolved">
                <div className="onboarding editForms">
                    <div className="form-steps">
                        <div className="step-circles">
                            <div className="tick-circle-workspace" style={{border: '1px solid #449569'}}><img src={checkTick} /></div>
                            <div className="tick-circle-workspace" style={{backgroundColor: '#454F66'}}></div>
                            <div className="tick-circle-workspace"></div>
                        </div>
                        <div className="step-circles-title"><h2 className="title-label">| Import your data</h2></div>
                        <div className="skip-btn" onClick={this.skipSteps}><h2 className="title-label">Skip</h2></div>
                    </div>
                    <div className="form-container">{formContent}</div>
                </div>
            </div>
        </Workbench>
      ) : workspace ? (
        <Workbench>
            <div className="w-100" data-testid="resolved">
                <div className="onboarding editForms setup-tasks-mobile">
                    <div className="form-steps">
                        <div className="step-circles step-three-onboarding">
                            <div className="tick-circle-workspace" style={{border: '1px solid #449569'}}><img src={checkTick} /></div>
                            <div className="tick-circle-workspace" style={{border: '1px solid #449569'}}><img src={checkTick} /></div>
                            <div className="tick-circle-workspace" style={{backgroundColor: '#454F66'}}></div>
                        </div>
                        <div className="step-circles-title"><h2 className="title-label">| Nearly there...</h2></div>
                        <div className="skip-btn" onClick={this.goToLab}><h2 className="title-label">Go to Lab</h2></div>
                    </div>
                    <div className="form-container">{formContent}</div>
                </div>
            </div>
        </Workbench>
      ) : (
        <div>No workspace</div>
      );
      return ready ? content : contentPlaceholder;
    }
}

export const OnboardingViewWithContext = bindContexts(OnboardingView, [
  APIContext,
  Auth0Context,
  WorkspaceContext,
  WindowContext
])
export default withRouter(OnboardingViewWithContext)
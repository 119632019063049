import React, { FunctionComponent } from 'react'
import { useAuth0 } from '../contexts/auth0'
import 'src/assets/css/views/emailnotverified.css'

const EmailNotVerified: FunctionComponent<any> = () => {
  const { logout } = useAuth0()

  const logoutWithRedirect = () => {
    logout({
      returnTo: window.location.origin
    })
  }

  return (
        <div className="workbench">
            <div className="emailnotverified content-center">
                <h1>Please check your email and verify your account</h1>
                <div>
                    <a
                        href="/"
                        onClick={evt => {
                          evt.preventDefault()
                          logoutWithRedirect()
                        }}
                    >
                        Return to Login
                    </a>
                </div>
            </div>
        </div>
  )
}

export default EmailNotVerified

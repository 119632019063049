import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React, { CSSProperties, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'

type CarouselData = { name: string, type: string, url: string }

const CarouselChart: FunctionComponent<{
  style?: CSSProperties
  rawData?: string
  getData?: () => Promise<Record<string, unknown>[]>
}> = props => {
  const { rawData } = props

  const containerRef = useRef<HTMLDivElement>(null)

  const [chartData, setChartData] = useState<Record<string, unknown>[]>(rawData ? JSON.parse(rawData) : [])
  const [activeStep, setActiveStep] = useState(0)
  const [size, setSize] = useState(0)
  const [error, setError] = useState<ReactNode>(null)
  const [subjectRow, setSubjectRow] = useState<CarouselData | null>(null)

  const handleBack = () => setActiveStep(step => step - 1)
  const handleNext = () => setActiveStep(step => step + 1)

  const setSizeFromViewWidth = () => {
    const width = containerRef.current?.clientWidth

    if (!width)
      return

    setSize(Math.floor(width / 250) || 1)
  }

  useEffect(() => {
    setSizeFromViewWidth()
    window.addEventListener('resize', setSizeFromViewWidth)

    if (!chartData.length)
      props.getData?.().then(setChartData);

    return () => window.removeEventListener('resize', setSizeFromViewWidth)
  }, [])

  useEffect(() => {
    const expectedKeys = ['name', 'url']
    const invalidRows = chartData
      .map((row, i) => [row, i] as [Record<string, unknown>, number])
      .filter(([row]) => !(expectedKeys.every(k => k in row)))

    if (invalidRows.length)
      return setError(
        <>
          Row data does not conform to structure expected by visualisation
          <br />
          <br />
          Expected: {expectedKeys.join(', ')}
          <br />
          Actual:
          <ol>
            {invalidRows.map(([row, i]) =>
              <li
                key={i}
                value={i + 1}>
                {Object.keys(row).join(', ')}
              </li>)}
          </ol>
        </>
      )

    setError(null)
  }, [chartData])

  useEffect(() => {
    if (size < chartData.length)
      setActiveStep(0)
  }, [size])

  const handleClose = () => setSubjectRow(null)

  return <>
    <Dialog
      open={!!subjectRow}
      maxWidth={false}
      onClose={handleClose}>
      {subjectRow && <>
        <DialogTitle>{subjectRow.name}</DialogTitle>
        <DialogContent className='content'>
          <img
            src={subjectRow.url}
            alt={subjectRow.name} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </>}
    </Dialog>
    <div
      ref={containerRef}
      className='carousel'
      style={props.style}>
      <IconButton
        title="Back"
        disabled={activeStep === 0}
        onClick={handleBack}>
        <NavigateBeforeIcon />
      </IconButton>
      <div className='content'>
        {error
          ? <div className='chart-no-metadata'>{error}</div>
          : (chartData as CarouselData[]).slice(activeStep, activeStep + size).map((row, i) =>
            <img
              key={i}
              src={row.url}
              alt={row.name}
              onClick={() => setSubjectRow(row)} />
          )}
      </div>
      <IconButton
        title="Next"
        disabled={!!error || size <= 0 || activeStep >= chartData.length - size}
        onClick={handleNext}>
        <NavigateNextIcon />
      </IconButton>
    </div>
  </>


}

export default CarouselChart

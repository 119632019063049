import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import React, { useRef, useState } from 'react'
import Spinner from 'src/components/Spinner'
import ISplitButtonProps from 'src/models/components/ISplitButtonProps'

const SplitButton = (props: ISplitButtonProps): JSX.Element => {

    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(0)

    const anchorRef = useRef<HTMLDivElement>(null)

    const handleToggle = () => setOpen(o => !o)
    const handleClose = () => setOpen(false)

    const { disabled, label, icon, options, onClick, SpinnerProps, style } = { ...props, ...props.options[selected] }

    return <>
        <ButtonGroup
            style={style}
            variant='contained'
            color='primary'
            disableElevation
            disabled={disabled}
            ref={anchorRef}>
            <Button
                startIcon={icon}
                onClick={onClick}>
                {label}
            </Button>
            <Button
                size='small'
                onClick={handleToggle}
                aria-label='more-options'>
                <Spinner {...SpinnerProps}><ArrowDropDownIcon /></Spinner>
            </Button>
        </ButtonGroup>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement='bottom-end'>
            <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                        {options.map(({ label }, i) => {
                            if (i === selected) return

                            const onClick = () => {
                                setSelected(i)
                                handleClose()
                            }

                            return <MenuItem key={i} onClick={onClick}>{label}</MenuItem>
                        })}
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Popper>
    </>
}

export default SplitButton

import MDEditor from '@uiw/react-md-editor'
import { AxiosInstance } from 'axios'
import * as React from 'react'
import { Button, Modal, Toast } from 'react-bootstrap'
import { Button as MUIButton } from '@material-ui/core'
import CopyToClipboard from 'react-copy-to-clipboard'
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import DatasetChart from 'src/components/DatasetChart'
import { AppFeatures, isFeatureEnabled } from 'src/utils/appenv'
import { parseJsonSwallowError } from 'src/utils/charts/chartUtils'
import { logError } from 'src/utils/Error'
import * as markdownUtils from 'src/utils/markdownUtils'
import ScrollAnimation from 'src/utils/ScrollAnimation'
import { APIContext } from '../contexts/api'
import { WorkspaceContext } from '../contexts/workspace'
import { MergedContexts } from '../contexts/merged'
import { IDatasetProps, IDatasetState } from '../models/components/IDatasetDetails'
import '../styles.css'
import { bindContexts } from '../utils/bindContexts'
import closeIcon from './../assets/images/close.svg'
//import neveralert from './../assets/images/sharewith/neveralert.svg'
//import news from './../assets/images/sharewith/news.svg'
import alerts from './../assets/images/sharewith/alerts.svg'
import allactivity from './../assets/images/sharewith/all_activity.svg'
import email from './../assets/images/sharewith/email.svg'
import link from './../assets/images/sharewith/link.svg'
import message from './../assets/images/sharewith/message.svg'
import slack from './../assets/images/sharewith/slack.svg'
import teams from './../assets/images/sharewith/teams.svg'
import EntitySelectDialog from 'src/components/EntitySelectDialog'
import EntityRel from 'src/models/EntityRel'
import IChannel from 'src/models/IChannel'
import { INamedEntity } from 'src/models/IEntity'
import IPage from 'src/models/IPage'
import DatasetActions from 'src/components/DatasetActions'
import Spinner from 'src/components/Spinner'

class Dataset extends React.Component<IDatasetProps, IDatasetState> {
    private divElement: React.RefObject<HTMLDivElement>;
    static contextType = MergedContexts;

    constructor (props: IDatasetProps) {
      super(props)
      this.divElement = React.createRef()
      this.state = {
        showMore: false,
        showLess: false,
        showConfirmation: false,
        showShare: false,
        showSubscribe: false,
        copied: false,
        showTable: !!this.props.chartOnly && !this.props.dataset.visualisation,
        showSave: false,
        isAddedToList: false
      }
      this.handleSaveDataset = this.handleSaveDataset.bind(this)
      this.handleRemoveDataset = this.handleRemoveDataset.bind(this)
      this.handleDownloadDataset = this.handleDownloadDataset.bind(this)
    }

    public async handleSaveDataset () {
      const channel = this.state.channel
      const datasetId = this.props.dataset.id
      const { catalogAPI } = this.context

      if (channel && channel._links) {
        await catalogAPI.put(channel._links['datasets'].href + encodeURI("/" + datasetId)).catch(logError).then(() => {
            this.handleShowSave()
          })
      }
    }

    public async handleRemoveDataset () {
        const channel = this.state.channel
        const datasetId = this.props.dataset.id
        const { catalogAPI } = this.context

        if (channel && channel._links) {
          catalogAPI.delete(channel._links['datasets'].href + encodeURI("/" + datasetId)).catch(logError).then(() => {
            this.handleShowSave()
          })
        }
      }

    public toggleAddedToListAlert = () => {
        this.setState({ isAddedToList: !this.state.isAddedToList })
    }

    public onSelectEntity = (entity: INamedEntity) => {
        this.updateChannels(entity)
    }

    public async handleDownloadDataset () {
      const dataset = this.props.dataset
      const { catalogAPI } = this.context
      if (dataset.rawData) {
        const url = window.URL.createObjectURL(new Blob([dataset.rawData]))
        const downloadLink = document.createElement('a')
        downloadLink.href = url
        const fileName = `${dataset.alias}.txt`
        downloadLink.setAttribute('download', fileName)
        document.body.append(downloadLink)
        downloadLink.click()
        downloadLink.remove()
      } else if (dataset._links && dataset._links.data) {
        const dataUri = dataset._links.data.href
        const api = catalogAPI as AxiosInstance
        api.get(dataUri, {
          headers: {
            Accept: 'text/csv'
          }
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            const fileName = `${dataset.alias}.csv`
            downloadLink.setAttribute('download', fileName)
            document.body.appendChild(downloadLink)
            downloadLink.click()
            downloadLink.remove()
          })
          .catch(e => {
            logError(e)
          })
      } else {
        // download the markdown description
        const url = window.URL.createObjectURL(new Blob([dataset.description]))
        const downloadLink = document.createElement('a')
        downloadLink.href = url
        const fileName = `${dataset.alias}.md`
        downloadLink.setAttribute('download', fileName)
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.remove()
      }
    }

    private handleCloseShare = () => {
      this.setState({ showShare: false })
    };

    private handleShowShare = () => {
      this.setState({ showShare: true })
    };

    private handleCloseSubscribe = () => {
      this.setState({ showSubscribe: false })
    };

    private handleShowSubscribe = () => {
      this.setState({ showSubscribe: true })
    };

    private handleCloseSave = () => {
        this.setState({ showSave: false })
    };

    private updateChannels = (entity: INamedEntity) => {
        const channel = this.state.channelPage?._embedded?.[EntityRel.Channel.collection].find((c: IChannel) => c.id === entity.id)
        if (channel?.containsDataset) {
            this.setState({ channel: channel}, this.handleRemoveDataset)
        } else {
            this.setState({ channel: channel}, this.handleSaveDataset)
            this.toggleAddedToListAlert()
        }
    }

    private handleShowSave = () => {
        this.context.getChannels("LIST", this.props.dataset.id).then((p: IPage<IChannel>) => {
            this.setState({
            channelPage: {
                ...p,
                _embedded: p._embedded && {
                    ...p._embedded,
                    [EntityRel.Channel.collection]: p._embedded?.[EntityRel.Channel.collection].filter(c => c._links['update channel'] !== undefined)
                }
            },
            showSave: true
        })})
    };

    private handleSubscribe = (type: any) => {
      this.setState({ showSubscribe: false })
      this.props.handleSubscribeDataset(type)
    };

    private handleShareEmail = () => {
      const currentDatasetLink = window.location.href
      let title = ''
      if (this.props.dataset) {
        title = this.props.dataset.title
      }
      const mailToLink =
            'mailto:?subject=' +
            title +
            '&body=Hi, %0D' +
            'I thought this might interest you.%0D' +
            '%0D' +
            currentDatasetLink
      window.open(mailToLink, '_blank')
    };

    private handleCopyLink = () => {
      this.setState({ copied: true, showShare: false })
      setTimeout(() => {
        this.setState({ copied: false })
      }, 2000)
    };

    private handleToggleTable = () => {
      this.setState({ showTable: !this.state.showTable })
    };

    private handleContainsDataset = (channel: IChannel) => {
        const updatedChannel = this.state.channelPage?._embedded?.[EntityRel.Channel.collection].find((c: IChannel) => c.id === channel.id) || channel
        return updatedChannel.containsDataset
    }

    private renderListAddButton = (entity: IChannel): JSX.Element => {
        return (<MUIButton
                    style={{ transform: 'scale(0.85)' }}
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => this.onSelectEntity(entity)}>
                        {this.handleContainsDataset(entity) ? 'Remove' : 'Add'}
                </MUIButton>);
    }

    public render () {
      const { dataset } = this.props

      const settings = {
        className: 'slider_shareIcons',
        infinite: false,
        centerPadding: '60px',
        slidesToShow: 4,
        swipeToSlide: true,

        responsive: [
          {
            breakpoint: 767,
            settings: {
              variableWidth: true,
              slidesToShow: 7,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 500,
            settings: {
              variableWidth: true,
              slidesToShow: 4,
              slidesToScroll: 1
            }
          }
        ]
      }
      const settingsWatch = {
        className: 'slider_shareIcons',
        infinite: false,
        centerPadding: '60px',
        slidesToShow: 2,
        swipeToSlide: true,

        responsive: [
          {
            breakpoint: 767,
            settings: {
              variableWidth: true,
              slidesToShow: 3,
              slidesToScroll: 1
            }
          }
        ]
      }

      let channelIcon: any = null
      if (dataset._embedded.source.picture) {
        channelIcon = dataset._embedded.source.picture
      } else {
        channelIcon = require('./../assets/images/default_channel.png')
      }

      const channelIconStyle = {
        backgroundImage: `url(${channelIcon})`,
        backgroundSize: '42px',
        backgroundRepeat: 'no-repeat'
      }

      const { workspace, searchResults } = this.context
      const datasetDescription = !dataset.descriptionContentType || dataset.descriptionContentType === 'text/plain' 
        ? markdownUtils.processMarkdownWithTags(dataset.description, workspace) : dataset.description
      const textHighlighter = searchResults != null && searchResults.query != null && searchResults.query.length > 0 
        ? { text: markdownUtils.keywordHighligherTextRenderer([searchResults.query]) } : null
      const markdown = (
        <ReactMarkdown
            renderers={{...markdownUtils.renderers, ...textHighlighter}}
            escapeHtml={false}
            source={dataset.changedDescription != null ? dataset.changedDescription : datasetDescription}
        />
      )

      const visualisation = parseJsonSwallowError(dataset.visualisation)
      const chart = <DatasetChart dataset={dataset} showTable={this.state.showTable} />
      const { copied } = this.state
      const currentDatasetLink = window.location.href

      const shareTeams = (
            <div className="item">
                <Button variant="link">
                    <img src={teams} alt="icon" />
                    <div>Teams</div>
                </Button>
            </div>
      )
      const shareSlack = (
            <div className="item">
                <Button variant="link">
                    <img src={slack} alt="icon" />
                    <div>Slack</div>
                </Button>
            </div>
      )
      const shareMessage = (
            <div className="item">
                <Button variant="link">
                    <img src={message} alt="icon" />
                    <div>Message</div>
                </Button>
            </div>
      )

      const subscribed = dataset && dataset._links && dataset._links['delete subscription']
      const watchIcon = (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="22px"
                width="32px"
                viewBox="0 0 63.527 43.313"
            >
                <g id="visibility" transform="translate(0 -74.667)">
                    <g id="Group_497" data-name="Group 497" transform="translate(0 74.667)">
                        <g id="Group_496" data-name="Group 496" transform="translate(0 0)">
                            <path
                                id="Path_531"
                                data-name="Path 531"
                                d="M179.33,170.667a8.663,8.663,0,1,0,8.663,8.663A8.67,8.67,0,0,0,179.33,170.667Z"
                                transform="translate(-147.566 -157.673)"
                                fill="#000000"
                            />
                            <path
                                id="Path_532"
                                data-name="Path 532"
                                d="M31.763,74.667A34.151,34.151,0,0,0,0,96.324a34.121,34.121,0,0,0,63.527,0A34.136,34.136,0,0,0,31.763,74.667Zm0,36.095A14.438,14.438,0,1,1,46.2,96.324,14.443,14.443,0,0,1,31.763,110.762Z"
                                transform="translate(0 -74.667)"
                                fill="#000000"
                            />
                        </g>
                    </g>
                </g>
            </svg>
      )
      const watchedIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.527 56.74">
                <g id="visibility" transform="translate(0 -67.956)">
                    <g id="Group_510" data-name="Group 510">
                        <g id="Group_497" data-name="Group 497" transform="translate(0 74.667)">
                            <g id="Group_496" data-name="Group 496" transform="translate(0 0)">
                                <path
                                    id="Path_531"
                                    data-name="Path 531"
                                    d="M179.33,170.667a8.663,8.663,0,1,0,8.663,8.663A8.67,8.67,0,0,0,179.33,170.667Z"
                                    transform="translate(-147.566 -157.673)"
                                    fill="#FAA128"
                                />
                                <path
                                    id="Path_532"
                                    data-name="Path 532"
                                    d="M31.763,74.667A34.151,34.151,0,0,0,0,96.324a34.121,34.121,0,0,0,63.527,0A34.136,34.136,0,0,0,31.763,74.667Zm0,36.095A14.438,14.438,0,1,1,46.2,96.324,14.443,14.443,0,0,1,31.763,110.762Z"
                                    transform="translate(0 -74.667)"
                                    fill="#FAA128"
                                />
                            </g>
                        </g>
                        <rect
                            id="Rectangle_371"
                            data-name="Rectangle 371"
                            width="71"
                            height="5"
                            transform="translate(6.263 70.956) rotate(45)"
                            fill="#fff"
                        />
                        <rect
                            id="Rectangle_386"
                            data-name="Rectangle 386"
                            width="71"
                            height="5"
                            transform="translate(10.263 67.956) rotate(45)"
                            fill="#FAA128"
                        />
                    </g>
                </g>
            </svg>
      )

      const datasetStarAction = (
        <div key='star' className={'dataset-action ' +
            (this.props.likeLoading
                ? 'cursor-pointer text-center disable'
                : 'cursor-pointer text-center')
        }
            onClick={
                this.props.liked
                    ? this.props.handleDeleteDatasetLike
                    : this.props.handleRecordDatasetLike
            }
        >
            {this.props.liked
                ? (
                    <>
                        <i className="fas fa-star active" data-testid="datasetLiked" />
                        <span className="d-block active" data-testid="datasetLikeCnt">
                            {dataset.likeCount}
                        </span>
                    </>
                )
                : (
                    <>
                        <i className="far fa-star" data-testid="datasetLike" />
                        <span className="d-block" data-testid="datasetLikeCnt">
                            {dataset.likeCount}
                        </span>
                    </>
                )}
        </div>)

    const datasetShareAction = (
        <div key='share' className="dataset-action cursor-pointer text-center" onClick={this.handleShowShare}>
            <i className="fas fa-share" />
            <span className="d-block">Share</span>
        </div>)

    const datasetWatchAction = (
        <div
            key='watch'
            className={'dataset-action ' +
                (this.props.subscribeLoading
                    ? 'cursor-pointer text-center disable'
                    : 'cursor-pointer text-center')
            }
            onClick={
                subscribed
                    ? this.props.handleDeleteSubscribeDataset
                    : this.handleShowSubscribe
            }
        >
            {subscribed
                ? (
                    <>
                        {watchedIcon}
                        <span className="d-block active">Unwatch</span>
                    </>
                )
                : (
                    <>
                        {watchIcon}
                        <span className="d-block">Watch</span>
                    </>
                )}
        </div>)

    const datasetTableAction = (
        visualisation && !(visualisation['html-table'] || visualisation['handsontable-table'] || visualisation['mermaid']) &&
            (
                <div key='table' className="dataset-action cursor-pointer text-center" onClick={this.handleToggleTable}>
                    <i className={this.state.showTable ? "fas fa-chart-bar" : "fas fa-table"} />
                    <span className="d-block">{this.state.showTable ? "Chart" : "Table"}</span>
                </div>
            )
        )

    const datasetSaveAction = (
        isFeatureEnabled(AppFeatures.SAVE_LIST) &&
            <div key='save' className="dataset-action cursor-pointer text-center" onClick={this.handleShowSave}>
                <i className="fas fa-list" />
                <span className="d-block">Save</span>
            </div>
        )

    const datasetExportAction = (
        !workspace.appProperties?.HIDE_DATASET_EXPORT && <div key='export' className="dataset-action cursor-pointer text-center" onClick={this.handleDownloadDataset}>
            <i className="fas fa-download" />
            <span className="d-block">Export</span>
        </div>)

    let datasetActions: any[] = [datasetStarAction, datasetShareAction, datasetWatchAction, datasetTableAction, datasetSaveAction, datasetExportAction]

    if (workspace.appProperties?.DATASET_ACTIONS){
        datasetActions = []
        new Set<string>(workspace.appProperties.DATASET_ACTIONS.split(",")).forEach(action => {
            action = action.trim().toLowerCase()
            if (action == "star"){
                datasetActions.push(datasetStarAction)
            } else if (action == "share") {
                datasetActions.push(datasetShareAction)
            } else if (action == "watch") {
                datasetActions.push(datasetWatchAction)
            } else if (action == "table") {
                datasetActions.push(datasetTableAction)
            } else if (action == "save") {
                datasetActions.push(datasetSaveAction)
            } else if (action == "export") {
                datasetActions.push(datasetExportAction)
            }
        })
    }

      const handleUpdateDatasetDescription = () => this.props.handleUpdateDatasetDescription()
      const handleEditDescription = () => this.props.handleEditDescription(false)
      const handleCopied = () => this.setState({ copied: false })
//      const handleSubscribeNews = () => this.handleSubscribe('NONE')
      const handleSubscribeAlerts = () => this.handleSubscribe('ALERTS')
      const handleSubscribeAll = () => this.handleSubscribe('ALL')

      const style: React.CSSProperties = visualisation && (visualisation['chartjs-chart'] || visualisation['google-chart'] || visualisation['mermaid'])
        ? { minHeight: '75vh' }
        : {}

      return (
            this.props.chartOnly ?
        <><ScrollAnimation
            animateIn="fadeIn"
            offset={150}
            className="graph_holder"
            style={style}>
            {dataset.visualisation ? chart : ''}
            <div className="pad_tb15 pad_lr10">{markdown}</div>
        </ScrollAnimation></> :
            <>
                <ScrollAnimation
                    animateIn="fadeIn"
                    offset={10}
                    className="clearfix"
                >
                    <div style={channelIconStyle} className="graph_detail clearfix">
                        <div className='position-relative clearfix'>
                            <div
                                className='dataset-title-container d-flex justify-content-between'
                                title={dataset.title}>
                                <div>
                                    <h2>{dataset.title}</h2>
                                    <div className="text-muted">
                                        <p>{dataset._embedded.source.description}</p>
                                        <p>
                                            {`${dataset.viewCount} ${dataset.viewCount === 1 ? 'view' : 'views'}`}
                                            &nbsp;|&nbsp;
                                            {`${dataset.likeCount} ${dataset.likeCount === 1 ? 'star' : 'stars'}`}
                                            &nbsp;|&nbsp;
                                            <Moment fromNow utc>
                                                {dataset.published}
                                            </Moment>
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='action_list d-flex justify-content-between'>
                                        {datasetActions}
                                    </div>
                                    <DatasetActions 
                                        dataset={dataset} 
                                        handleDeleteDataset={this.props.handleDeleteDataset} 
                                        handleEditDescription={this.props.handleEditDescription} />
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>

                <ScrollAnimation
                    animateIn="fadeIn"
                    offset={150}
                    className="graph_holder"
                    style={style}>
                    {chart}
                </ScrollAnimation>

                {datasetDescription && (
                    <ScrollAnimation
                        afterAnimatedIn={this.props.afterDescriptionAnimatedIn}
                        animateIn="fadeIn"
                        offset={10}
                    >
                        {/* Start description */}
                        {dataset.isEditDescription
                          && (
                            <div className="dataset-editor w-100">
                                <div className="desceditor">
                                    <MDEditor
                                        value={dataset.changedDescription != null ? dataset.changedDescription : ""}
                                        preview={'edit'}
                                        autoFocus={true}
                                        height={400}
                                        onChange={this.props.handleChangeDescription}
                                        visiableDragbar={false}
                                    />
                                </div>
                                <div className="clearfix">
                                    <Spinner active={dataset.loadingSaveDescription}>
                                        <div className={'editorActionButtons'}>
                                            <button
                                                type="button"
                                                className={' btn btn-warning'}
                                                onClick={ handleUpdateDatasetDescription }
                                                disabled={dataset.loadingSaveDescription}
                                                data-testid="saveDescriptionBtn"
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className={' btn btn-secondary'}
                                                onClick={ handleEditDescription }
                                                disabled={dataset.loadingSaveDescription}
                                            >
                                                <img src={closeIcon} alt={''} />
                                            </button>
                                        </div>
                                    </Spinner>
                                </div>
                            </div>
                            )}
                            <div
                                data-testid="description-container"
                                className={
                                    this.state.showMore ? 'datasetDesc show-more' : 'datasetDesc'
                                }
                                ref={this.divElement}
                            >
                                <div className="markdown-content">
                                    {markdown}
                                </div>
                            </div>

                        {/* end description */}
                    </ScrollAnimation>
                )}

                <Modal
                    show={this.state.showShare}
                    onHide={this.handleCloseShare}
                    className="sharePopup"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Share</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="clearfix">
                            <Slider {...settings}>
                                <div className="item">
                                    <CopyToClipboard
                                        onCopy={this.handleCopyLink}
                                        text={currentDatasetLink}
                                    >
                                        <Button variant="link">
                                            <img src={link} alt="icon" />
                                            <div>Copy Link</div>
                                        </Button>
                                    </CopyToClipboard>
                                </div>
                                <div className="item">
                                    <Button variant="link" onClick={this.handleShareEmail}>
                                        <img src={email} alt="icon" />
                                        <div>Email</div>
                                    </Button>
                                </div>
                                {isFeatureEnabled(AppFeatures.SHARE_TEAMS) ? shareTeams : null}
                                {isFeatureEnabled(AppFeatures.SHARE_SLACK) ? shareSlack : null}
                                {isFeatureEnabled(AppFeatures.SHARE_MESSAGE) ? shareMessage : null}
                            </Slider>
                        </div>
                    </Modal.Body>
                </Modal>
                <Toast
                    className="tosterMessage"
                    onClose={ handleCopied }
                    show={copied}
                    delay={500}
                >
                    <Toast.Body>Copied</Toast.Body>
                </Toast>
                <Modal
                    show={this.state.showSubscribe}
                    onHide={this.handleCloseSubscribe}
                    className="sharePopup watchPopup"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Watch for...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="clearfix">
                            <Slider {...settingsWatch}>
                                <div className="item">
                                    <Button
                                        variant="link"
                                        onClick={ handleSubscribeAlerts }
                                    >
                                        <img src={alerts} alt="icon" />
                                        <div>{ workspace.appProperties && workspace.appProperties.DATASET_WATCH_ALERTS_ALT_TEXT ? workspace.appProperties.DATASET_WATCH_ALERTS_ALT_TEXT : 'Alerts'}</div>
                                    </Button>
                                </div>

                                <div className="item">
                                    <Button
                                        variant="link"
                                        onClick={ handleSubscribeAll }
                                    >
                                        <img src={allactivity} alt="icon" />
                                        <div>{ workspace.appProperties && workspace.appProperties.DATASET_WATCH_ALL_ACTIVITY_ALT_TEXT ? workspace.appProperties.DATASET_WATCH_ALL_ACTIVITY_ALT_TEXT : 'All Activity'}</div>
                                    </Button>
                                </div>
                            </Slider>
                        </div>
                    </Modal.Body>
                </Modal>
                {this.state.channelPage &&
                <EntitySelectDialog
                    open={this.state.showSave}
                    title="Add to list"
                    entityRel={EntityRel.Channel}
                    page={this.state.channelPage}
                    onClose={this.handleCloseSave}
                    onSelect={this.onSelectEntity}
                    disableCloseOnSelect
                    actionsButton={this.renderListAddButton}
                    imageHolderDisabled
                    customSearchPlaceholder={"Search lists..."}/>}
            </>
      )
    }
}

export const DatasetWithContext = bindContexts(Dataset, [APIContext, WorkspaceContext])
export default DatasetWithContext

export enum ImageHolderSize {
    Small = 'SMALL',
    Large = 'LARGE'
}

export interface IImageHolder {
    label?: string
    imageUrl: string
    size?: ImageHolderSize
    overlay?: React.ReactNode
    onClick?: () => void
}

import { AxiosInstance } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Feed from 'src/components/Feed'
import Workbench from 'src/components/Workbench'
import { logError } from 'src/utils/Error'
import { APIContext, getWorkspaceNewsChannelsURI } from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { INewsProps, INewsState } from '../models/views/INews'
import { bindContexts } from '../utils/bindContexts'
import 'src/assets/css/views/news.css'
import NewsChannelsFilter from 'src/components/NewsChannelsFilter'
import history from '../utils/history'
import PageTitle from 'src/components/PageTitle'

class News extends React.Component<INewsProps, INewsState> {
  private previousContext: any;
  static contextType = MergedContexts;
  public isThisMounted = false;
  public prev = 0;
  constructor(props: INewsProps) {
    super(props)
    this.state = {
      ready: false,
      showChannel: true
    }
    this.loadMore = this.loadMore.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.getQueryStringValue = this.getQueryStringValue.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.updateDatasets = this.updateDatasets.bind(this)
  }

  public componentDidMount() {
    const { searchResults } = this.context
    this.isThisMounted = true
    this.props.setViewName && this.props.setViewName("Home")
    if (
      this.isThisMounted &&
      (this.props.history.action === 'PUSH' || searchResults.hits === -1)
    ) {
      this.refreshData()
    } else {
      this.setState({ ready: true })
    }
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
    this.previousContext = this.context
    window.addEventListener('scroll', this.handleScroll, true)
  }

  private handleScroll(e: any) {
    const targetWindow = e.currentTarget
    const element = document.getElementsByClassName('toolbar')
    if (targetWindow.scrollY >= 100) {
      if (this.prev > targetWindow.scrollY) {
        element[0].classList.remove('hide')
        element[0].classList.add('show')
      } else if (this.prev < targetWindow.scrollY) {
        element[0].classList.remove('show')
        element[0].classList.add('hide')
      }
      this.prev = targetWindow.scrollY
    } else {
      element[0].classList.remove('hide')
      element[0].classList.add('show')
    }
  }

  public componentWillUnmount() {
    this.isThisMounted = false
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  private refreshData() {
    this.getChannelsData()
    this.handleChannelFilter(null)
  }

  public componentDidUpdate() {
    if (this.previousContext.workspace !== this.context.workspace) {
      if (this.isThisMounted) {
        this.refreshData()
      }
    }

    this.previousContext = this.context
  }

  getQueryStringValue(key: any) {
    const url = window !== undefined ? window.location.href : ''
    if (url) {
      const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)')
      const results = regex.exec(url)
      if (!results) return ''
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, ' '))
    } else {
      return ''
    }
  }

  private updateDatasets() {
    const { searchResults, handleSearchResults, updateDatasets } = this.context
    updateDatasets(searchResults, handleSearchResults)
  }

  loadMore(nextUrl: any) {
    const { searchResults, setSearchResults, search, workspace, handleSearchResults } =
      this.context
    setSearchResults({
      datasets: searchResults.datasets,
      fetchtime: searchResults.fetchtime,
      hits: searchResults.hits,
      nextPageUrl: searchResults.nextPageUrl,
      isLoading: searchResults.isLoading,
      isMoreLoading: true,
      query: searchResults.query
    })
    if (nextUrl) {
      search(workspace.id, handleSearchResults, searchResults, nextUrl, '')
    }
  }

  private async getChannelsData() {
    const { workspace, catalogAPI, setChannel } = this.context
    const api = catalogAPI as AxiosInstance
    if (workspace) {
      this.setState({ ready: false })
      await api
        .get(getWorkspaceNewsChannelsURI(workspace.id))
        .then(resp => {
          if (resp.data._embedded) {
            const query = this.getQueryStringValue('q')
            let selectedChannel = null
            if (query) {
              let channelName = ''
              const queryArray = query.split(':')
              if (queryArray[1] && queryArray[0] === 'in') {
                channelName = queryArray[1].replace('-', ' ')
              }

              resp.data._embedded.channels.forEach((channel: any) => {
                if (channel.name === channelName) {
                  selectedChannel = channel
                }
              })
            }

            setChannel({ channelList: resp.data._embedded.channels, selectedChannel })
          }
        })
        .catch(e => {
          logError(e)
        })
    }
    this.setState({ ready: true })
  }

  private handleChannelFilter(selectedChannel: any) {
    const {
      workspace,
      searchNews,
      searchResults,
      setSearchResults,
      handleSearchResults,
      channel,
      setChannel
    } = this.context
    if (!searchResults.isLoading) {
      setSearchResults({ isLoading: true })

      const channelList = channel.channelList
      let query = this.getQueryStringValue('q')

      if (selectedChannel) {
        if (channel.selectedChannel === selectedChannel) {
          setChannel({ channelList, selectedChannel: null })
          query = ''
        } else {
          setChannel({ channelList, selectedChannel })
          query = 'in:' + selectedChannel.name.replace(/\s/g, '-')
        }
      }

      if (query) {
        history.push({ pathname: '/l/' + workspace.id + '/news', search: 'q=' + query })
      } else {
        history.push({ pathname: '/l/' + workspace.id + '/news' })
      }

      searchNews(workspace.id, handleSearchResults, null, null, query)
    }
  }

  public render() {
    const { searchResults, showNewInsight, channel } = this.context
    const { isLoading, query, datasets, fetchtime, hits, nextPageUrl, isMoreLoading } =
      searchResults
    const { ready } = this.state
    const { channelList, selectedChannel } = channel
    const settings = {
      className: 'roundedListItemSlider position-relative channels-list',
      dots: false,
      infinite: false,
      centerMode: false,
      slidesToShow: 16,
      slidesToScroll: 1,
      hover: channel.description,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 13
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 9
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 9
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 7
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 5
          }
        }
      ]
    }

    let channelView = null
    if (channelList && channelList.length > 0) {
      const rows: any[] = []
      let channelIcon: any = null
      channelList.forEach((channelObj: any, index: any) => {
        if (channelObj.picture) {
          channelIcon = (
            <img className="item_icon" src={channelObj.picture} alt={channelObj.name} />
          )
        } else {
          channelIcon = (
            <img
              className="item_icon"
              src={require('./../assets/images/default_channel.png')}
              alt={channelObj.name}
            />
          )
        }

        let disableClass = ''
        if (selectedChannel && selectedChannel.id !== channelObj.id) {
          disableClass = 'disableChannel'
        }
        const handleChannel = () => this.handleChannelFilter(channelObj)
        rows.push(
          <div
            key={index}
            data-testid={channelObj.id}
            className={disableClass + ' roundedSliderItem cursor-pointer'}
            style={{ marginTop: '15px' }}
            onClick={handleChannel}
          >
            {channelIcon}
            <p className="channel_name text-muted">{channelObj.description}</p>
          </div>
        )
      })
      channelView = rows.length > 0 ? rows : ''
    }

    const noNewsContent = (
      <div className="no-news content-center">
        <i className="fas fa-rss" />
        <h1 className="content-center">No news. Watch some datasets to start receiving news.</h1>
      </div>
    )

    return (
      <Workbench
        menu='app'
        isExpandedSidebarActive={this.props.isExpandedSidebarActive}
        isSidebarLayout={this.props.isSidebarLayout}
        isStickySearch
        toggleSidebar={this.props.toggleSidebar}>
        <div className='rightside_pageContent' data-testid="resolved">
          {this.props.isSidebarLayout && 
          <PageTitle title={this.context.workspace.appProperties?.NEWS_PAGE_TITLE || 'News'} /> }
          {ready && channelList && channelList.length > 0 && (
            <NewsChannelsFilter
              channelList={channelList}
              channelView={channelView}
              showChannel={this.state.showChannel}
              settings={settings}
            />
          )}
          <Feed
            key={query}
            summaryMessage={null}
            noResultsMessage={noNewsContent}
            datasets={datasets}
            isLoading={isLoading}
            loadMore={this.loadMore}
            currentSearch={query}
            hits={hits}
            fetchtime={fetchtime}
            nextPageUrl={nextPageUrl}
            isMoreLoading={isMoreLoading}
            refreshData={this.refreshData}
            showNewInsight={showNewInsight}
            updateDatasets={this.updateDatasets}
            isNews={true}
          />
          {this.props.children}
        </div>
      </Workbench>
    )
  }
}

export const NewsWithContext = bindContexts(withRouter(News), [
  Auth0Context,
  APIContext,
  WorkspaceContext
])
export default NewsWithContext

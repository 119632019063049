import { AxiosInstance, AxiosResponse } from 'axios'
import React, { PropsWithChildren, ReactNode, useContext, useEffect, useState } from 'react'
import {
  APIContext,
  getDatasetURI,
} from 'src/contexts/api'
import { WorkspaceContext } from 'src/contexts/workspace'
import IDataset from 'src/models/IDataset'
import { logError } from 'src/utils/Error'


type Data = Record<string, unknown>[]

const DatasetData = (props: PropsWithChildren<{
  alias?: string
  dataset?: IDataset
  render: (dataset: IDataset, data: Data) => ReactNode,
}>): JSX.Element => {

  const { workspace } = useContext(WorkspaceContext)
  const { catalogAPI: api }: { catalogAPI: AxiosInstance } = useContext(APIContext)

  const [dataset, setDataset] = useState<IDataset | undefined>(props.dataset)
  const [data, setData] = useState<Data>([])

  useEffect(() => {
    if (dataset)
      return

    api.get(getDatasetURI(workspace.id, props.alias))
      .then((r: AxiosResponse<IDataset>) => setDataset(r.data))
      .catch(logError)
  }, [])

  useEffect(() => {
    if (!dataset)
      return

    if (dataset.rawData) {
      setData(JSON.parse(dataset.rawData))
      return
    }

    api.get(dataset._links.data.href)
      .then((r: AxiosResponse<Data>) => setData(r.data))
      .catch(logError)
  }, [dataset])

  return <>
    {dataset && props.render(dataset, data)}
  </>

}

export default DatasetData

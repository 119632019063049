import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Button } from '@material-ui/core'
import { MermaidProps } from 'src/models/charts/mermaid/MermaidProps'
import mermaid from 'mermaid'


mermaid.initialize({
  startOnLoad: true,
  theme: 'default',
  securityLevel: 'loose',
  fontFamily: 'monospace',
 });

const MermaidChart: FunctionComponent<MermaidProps> = (
  props: PropsWithChildren<MermaidProps>
) => {
  const {
    rawData,
    compact
  } = props


const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    mermaid.contentLoaded();
  })

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
      securityLevel: 'loose',
      fontFamily: 'monospace',
    });

    const delayedDisplay = () => {
      setIsRendered(true)
    };

    const delayInMillis = 250; // 0.25 seconds
    const timeoutId = setTimeout(delayedDisplay, delayInMillis);

    // Make sure to clear the timeout when the component is unmounted
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const mermaidDiv = <div style={{opacity: isRendered ? '100' : '0'}} className="mermaid">{rawData}</div>

  return (
    <>
      {compact ? mermaidDiv :
        <TransformWrapper centerOnInit>
          {({ zoomIn, zoomOut, resetTransform }) =>
            <>
              <div
                style={{ zIndex: 1 }}
                className='tools pad_10 position-absolute'>
                {Object.entries({
                  "+": zoomIn,
                  "-": zoomOut,
                  "Reset": resetTransform,
                }).map(([label, fn]) =>
                  <Button
                    style={{ backgroundColor: 'white' }}
                    key={label}
                    variant='outlined'
                    onClick={() => fn()}>
                    {label}
                  </Button>
                )}
              </div>
              <TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height: "inherit",
                  minHeight: "inherit",
                }}
                contentStyle={{ width: "100%", display: 'block' }}>
                {mermaidDiv}
              </TransformComponent>
            </>}
        </TransformWrapper>}
    </>
  )
}

export default MermaidChart
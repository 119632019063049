import React, { FunctionComponent, PropsWithChildren } from 'react'

const DataSourceImage : FunctionComponent<any> = (
    props: PropsWithChildren<any> 
    ) => {
        const { size, helpButtonClicked, questionMark, dataSourceImg, dataSourceLabel, imageClass } = props;

        return(
            <div className="image-holder datasource">
                {size && size.smallScreen && 
                <div title="Help" className={"help-button"} onClick={helpButtonClicked}>
                    <img src={questionMark} alt={questionMark} />
                </div>}
                <img src={dataSourceImg} alt={dataSourceLabel && dataSourceLabel} className={imageClass && imageClass} />
                <span>{dataSourceLabel}</span>
            </div>
        )
    }

export default DataSourceImage;
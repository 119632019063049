import React, { useEffect, FunctionComponent } from 'react'
import { useAuth0 } from '../contexts/auth0'
import Loading from '../utils/Loading'

const Logout: FunctionComponent<any> = () => {
  const { loading, isAuthenticated, logout, user } = useAuth0()

  const logoutWithRedirect = () => {
    logout({
      returnTo: window.location.origin
    })
  }

  useEffect(() => {
    if (loading || !isAuthenticated) {
      return
    }
    logoutWithRedirect()
  }, [loading, isAuthenticated, logout])

  if (loading) {
    return <Loading />
  }

  let message = null
  if (isAuthenticated) {
    message = 'Logging out ' + user.name
  } else {
    message = 'You are logged out.'
  }
  return (
        <div className="workbench-container">
            <div className="workbench">
                <h3>{message}</h3>
            </div>
        </div>
  )
}

export default Logout

import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core'
import { IApiKeyRowProps } from 'src/models/components/IApiKeyRow'
import copyIcon from 'src/assets/images/copyIcon.png'
import { ReactComponent as DeleteIcon } from 'src/assets/images/svgs/delete.svg'
import { ReactComponent as EnterIcon } from 'src/assets/images/svgs/enter.svg'
import { ReactComponent as ReloadIcon } from 'src/assets/images/svgs/reload.svg'
import Spinner from 'src/components/Spinner'
import IApiKey from 'src/models/IApiKey'

const ApiKeyRow: FunctionComponent<IApiKeyRowProps> = (props: PropsWithChildren<IApiKeyRowProps>) => {

    const [apiKey, setApiKey] = useState<IApiKey>(props.apiKey)
    const [isDeleteReady, setDeleteReady] = useState(true)

    const handleChangeNameTextField = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setApiKey({ ...apiKey, name: e.target.value })
    const handleBlurNameTextField = () => {
        if (apiKey.name) props.handleUpdateApiKey(apiKey)
    }
    const handleKeyPressNameTextField = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key !== 'Enter') return
        (e.target as HTMLDivElement).blur()
        handleBlurNameTextField()
    }
    const handleClickDeleteButton = () => setDeleteReady(false)

    useEffect(() => {
        if (!isDeleteReady) props.handleRemoveApiKey(apiKey)
    }, [isDeleteReady])

    const clientIdOrAccessToken = apiKey.clientId || apiKey.accessToken || ""
    const DeleteOrRefreshIcon = apiKey.name === 'default' ? ReloadIcon : DeleteIcon

    return (
        <Grid
            container
            spacing={2}
            className="apikey-list-row">
            <Grid item xs>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    label="Name"
                    defaultValue={apiKey.name}
                    placeholder="API key name"
                    error={!apiKey.name}
                    helperText={apiKey.name ? "" : "Name is mandatory"}
                    onChange={handleChangeNameTextField}
                    onBlur={handleBlurNameTextField}
                    onKeyPress={handleKeyPressNameTextField}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <EnterIcon width={20} height={20} />
                            </InputAdornment>
                    }} />
            </Grid>
            <Grid item xs>
                <CopyToClipboard
                    text={clientIdOrAccessToken}
                    onCopy={props.handleCopyClientIdOrAccessToken}>
                    <TextField
                        className="pointer"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label={apiKey.clientId ? "Client ID" : "Access token"}
                        value={clientIdOrAccessToken}
                        InputProps={{
                            readOnly: true,
                            endAdornment:
                                <InputAdornment position="end">
                                    <img src={copyIcon} width={20} height={20} />
                                </InputAdornment>
                        }} />
                </CopyToClipboard>
            </Grid>
            <Grid
                item
                xs
                container
                alignItems='center'
                justifyContent='flex-end'>
                <Button
                    variant='contained'
                    aria-label='delete'
                    disabled={!isDeleteReady}
                    onClick={handleClickDeleteButton}>
                    <Spinner active={!isDeleteReady}>
                        <DeleteOrRefreshIcon width={20} height={20} />
                    </Spinner>
                </Button>
            </Grid>
        </Grid>
    )

}

export default ApiKeyRow

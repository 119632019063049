import { AxiosInstance } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Feed from 'src/components/Feed'
import { logError } from 'src/utils/Error'
import { APIContext, getDatasetsInChannelURI } from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { ILibraryFeedProps, ILibraryFeedState } from '../models/views/ILibraryFeed'
import { bindContexts } from '../utils/bindContexts'
import 'src/assets/css/views/starred.css'
import 'src/styles.css'
import IChannel from 'src/models/IChannel'

class LibraryFeed extends React.Component<ILibraryFeedProps, ILibraryFeedState> {
  static contextType = MergedContexts;
  public isThisMounted = false;
  constructor(props: ILibraryFeedProps) {
    super(props)
    this.state = {
      ready: false,
      channel: this.props.channel
    }
    this.refreshData = this.refreshData.bind(this)
  }

  public componentDidMount() {
    const channel = this.props.channel
    this.setState({ channel: channel })
    this.props.setViewName && this.props.setViewName(channel.name)
    this.getDatasetsInChannel(channel)
    this.isThisMounted = true

    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
  }

  private refreshData() {
    this.getDatasetsInChannel(this.state.channel)
  }

  private async getDatasetsInChannel(channel: IChannel | undefined) {
    const { workspace, catalogAPI, handleSearchResults, setSearchResults } = this.context
    const api = catalogAPI as AxiosInstance
    if (workspace && channel) {
      setSearchResults({ isLoading: true })
      await api
        .get(getDatasetsInChannelURI(channel.id))
        .then(resp => {
          let results: any = []
          if (resp.data._embedded) {
            results = resp.data._embedded.datasets
          }
          handleSearchResults(results, 0, 0, null, null)
          this.setState({})
        })
        .catch(e => {
          logError(e)
          handleSearchResults([], 0, 0, null, null)
        })
    }
  }

  public render() {
    const { searchResults } = this.context
    const { isLoading, datasets, fetchtime, hits } = searchResults

    const noChannelContent = (
      <div className="no-starred content-center" data-testid="no-starred">
        <i className="fas fa-folder-open" />
        <h1>No datasets.</h1>
      </div>
    )

    return (
      <Feed
        key="library"
        summaryMessage={null}
        noResultsMessage={noChannelContent}
        datasets={datasets}
        isLoading={isLoading}
        hits={hits}
        fetchtime={fetchtime}
        refreshData={this.refreshData}
      />
    )
  }
}

export const LibraryFeedWithContext = bindContexts(withRouter(LibraryFeed), [
  Auth0Context,
  APIContext,
  WorkspaceContext
])
export default LibraryFeedWithContext

import React, { FunctionComponent, PropsWithChildren } from 'react';
import DataPluginParameterField from './DataPluginParameterField';

//
// A generic component to collect and validate the name of an instance of an entity.
//

const EntityName: FunctionComponent<any> = (props: PropsWithChildren<any>) => {
    const { handleNameChange, instanceName } = props;

    return (
        <DataPluginParameterField
            fieldId="entityName"
            className="entity-name-input"
            currentValue={instanceName}
            setting={{ label: 'Name', name: 'name', required: true }}
            handleSettingsChange={handleNameChange}
            error={props.error}
        />
    );
};

export default EntityName;

import { Tab, Tabs, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useWorkspaceContext } from 'src/contexts/workspace'
import { DataComponentSearch } from 'src/components/Search'
import DataComponentRow from 'src/components/DataComponentRow'
import IDataComponent from 'src/models/IDataComponent'
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

enum PluginTypes {
    Extractors = "Extractors",
    Mappers = "Mappers",
    Loaders = "Loaders",
//    Transforms = "Transforms",
    Transformers = "Transformers",
//    Files = "Files",
    Utilities = "Utilities"
}

const Project = (): JSX.Element => {

    const EXTRACTORS_TAB = 0
    const MAPPERS_TAB = 1
    const LOADERS_TAB = 2
    const TRANSFORMERS_TAB = 3
    const UTILITIES_TAB = 4

    const { workspace, getDataComponents } = useWorkspaceContext()

    const [activeTab, setActiveTab] = useState(0)
    const [copied, setCopied] = useState(false)
    const [dataComponents, setDataComponents] = useState<IDataComponent[]>([])

    const onTabChange = (event: React.ChangeEvent<unknown>, tab: number) => setActiveTab(tab)
    const handleSnackbarClose = () => setCopied(false)

    useEffect(() => {
      const type = activeTab == 0 ? "EXTRACTOR" : 
                    activeTab == 1 ? "MAPPER" : 
                    activeTab == 2 ? "LOADER" : 
                    activeTab == 3 ? "TRANSFORMER" : 
                    "UTILITY"
      workspace && getDataComponents(type).then(setDataComponents)
    }, [activeTab])

    return <>
        <div className='rightside_pageContent data-page'>
          <div>
            <TextField
                variant='standard'
                label="Active environment"
                defaultValue={workspace.defaultEnvironment}
                placeholder="No environment set"
                InputProps={{
                    readOnly: true,
                    disableUnderline: true
                }}
                InputLabelProps={{
                    shrink: true,
                    focused: false
                }} />
            <Tabs
                indicatorColor='primary'
                variant='scrollable'
                value={activeTab}
                onChange={onTabChange}>
                {Object.keys(PluginTypes).map(k =>
                    <Tab
                        key={k}
                        label={k} />)}
            </Tabs>
            {activeTab === EXTRACTORS_TAB && 
              <div className='projects-extractors'>
                <DataComponentSearch
                    entities={dataComponents}
                    row={e =>
                        <DataComponentRow
                            key={e.id}
                            datacomponent={e}
                        />}
                />
              </div>}
            {activeTab === MAPPERS_TAB && 
              <div className='projects-mappers'>
                <DataComponentSearch
                    entities={dataComponents}
                    row={e =>
                        <DataComponentRow
                            key={e.id}
                            datacomponent={e}
                        />}
                />
              </div>}
            {activeTab === LOADERS_TAB && 
              <div className='projects-loaders'>
                <DataComponentSearch
                    entities={dataComponents}
                    row={e =>
                        <DataComponentRow
                            key={e.id}
                            datacomponent={e}
                        />}
                />
              </div>}
            {activeTab === TRANSFORMERS_TAB && 
              <div className='projects-transformers'>
                <DataComponentSearch
                    entities={dataComponents}
                    row={e =>
                        <DataComponentRow
                            key={e.id}
                            datacomponent={e}
                        />}
                />
              </div>}
            {activeTab === UTILITIES_TAB && 
              <div className='projects-utilities'>
                <DataComponentSearch
                    entities={dataComponents}
                    row={e =>
                        <DataComponentRow
                            key={e.id}
                            datacomponent={e}
                        />}
                />
              </div>}
          </div>
        </div>
        <Snackbar
            open={copied}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}>
            <Alert severity='success'>Copied</Alert>
        </Snackbar>
    </>

}

export default Project

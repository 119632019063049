import * as assert from 'assert-plus';
import { AxiosInstance, AxiosResponse } from 'axios';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import DataRow from 'src/components/DataRow';
import LogModal from 'src/components/LogModal';
import { logError } from 'src/utils/Error';
import 'src/assets/css/views/data.css';
import dataIcon from '../assets/images/svgs/data.svg';
import pipelinesPlaceholder from '../components/placeholders/PipelinesPlaceholder';
import { APIContext, getAllWorkspaceDatasourcesURI } from '../contexts/api';
import { Auth0Context } from '../contexts/auth0';
import { MergedContexts } from '../contexts/merged';
import { WorkspaceContext } from '../contexts/workspace';
import { IDataImportsProps, IDataImportsState } from '../models/views/IDataImports';
import { AsyncLoader } from '../utils/async';
import history from 'src/utils/history';
import { bindContexts } from '../utils/bindContexts';
import ConfirmDialog from 'src/components/ConfirmDialog'
import IJob, { JobStatus } from 'src/models/IJob'
import IPage from 'src/models/IPage'
import IPipeline from 'src/models/IPipeline'
import IDataPlugin from 'src/models/IDataPlugin';
import { PipelineSearch } from 'src/components/Search'
import SplitButton from 'src/components/SplitButton'
import EntityRel from 'src/models/EntityRel'
import AddIcon from '@material-ui/icons/Add'
import ILog from 'src/models/ILog'

class DataImports extends React.Component<IDataImportsProps, IDataImportsState> implements AsyncLoader {
    public isThisMounted = false;
    static contextType = MergedContexts;
    constructor(props: IDataImportsProps) {
        super(props);
        this.state = {
            viewClass: 'listView',
            open: false,
            taskId: 0,
            logsBySequence: {},
            isConfirmDialogOpen: false,
        };

        this.deletePipeline = this.deletePipeline.bind(this);
        this.openLog = this.openLog.bind(this);
        this.renderLogs = this.renderLogs.bind(this);
        this.hideLog = this.hideLog.bind(this);
        this.downloadLog = this.downloadLog.bind(this);
        this.handleDeletePipeline = this.handleDeletePipeline.bind(this);
        this.handleOpenPipelineConfirmDialog = this.handleOpenPipelineConfirmDialog.bind(this)
        this.handleClosePipelineConfirmDialog = this.handleClosePipelineConfirmDialog.bind(this)

    }

    // implements AsyncLoader
    public isLoading(): boolean {
        return this.context.isLoadingPipelines;
    }

    public componentDidMount() {
        this.isThisMounted = true;
        this.props.setViewName && this.props.setViewName("Data Imports")
        const { catalogAPI, workspace, fetchPipelines } = this.context;
        const api = catalogAPI as AxiosInstance;
        assert.func(api, 'APIContext must be initialise before this component is used');

        const isWorkspaceAdmin = () => workspace && workspace._links && workspace._links['update delete workspace'] != null

        if (!isWorkspaceAdmin()) history.push("/")

        fetchPipelines()
        this.getDataPlugins()
    }

    public componentWillUnmount() {
        this.isThisMounted = false;
    }

    private async getDataPlugins() {
        const { workspace, catalogAPI } = this.context;

        await catalogAPI.get(getAllWorkspaceDatasourcesURI(workspace.id), { errorHandler: false })
            .then((r: AxiosResponse<IPage<IDataPlugin>>) => {
                this.setState({ dataPlugins: r.data._embedded?.dataplugins.filter(dp => !dp.matatikaHidden) || [] })
            }).catch(logError)

    }

    public deletePipeline(pipeline: IPipeline) {
        this.context.setPipelines((pipelines: IPipeline[]) => pipelines.filter(p => p.id !== pipeline.id))
    }

    public openLog(job: IJob) {
        this.setState({ open: true, job });
    }

    public renderLogs(logsArray: ILog[], taskId: any) {
        const logsBySequence = logsArray.reduce((obj, l) => ({ ...obj, [l.sequence]: l }), this.state.logsBySequence)
        this.setState({ logsBySequence, taskId });
    }

    public hideLog() {
        clearTimeout(this.state.taskId);
        this.setState({ open: false, logsBySequence: {}, taskId: null });
    }

    public async downloadLog(job: IJob) {
        if ([JobStatus.Queued, JobStatus.Running].includes(job.status)) return
        const { catalogAPI } = this.context;
        const b64EncodeUnicode = (str:string) => {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
                return String.fromCharCode(parseInt(p1, 16))
            }))
        }
        await catalogAPI
            .get(job._links.logs.href)
            .then((resp: any) => {
                const data = resp.data;
                const link = document.createElement('a');
                const b64Data = b64EncodeUnicode(data);
                link.href = 'data:text/plain;base64,' + b64Data;
                link.target = '_blank';
                link.download = `${job.id}.txt`;
                link.click();
            })
            .catch((e: any) => {
                logError(e);
            });
    }

    public async handleDeletePipeline() {
        const pipeline = this.state.pipeline
        if (!pipeline) return

        const { catalogAPI } = this.context;
        await catalogAPI.delete(pipeline._links['delete pipeline'].href)
            .then(() => this.deletePipeline(pipeline))
            .catch(logError)
    }

    private handleOpenPipelineConfirmDialog(pipeline: IPipeline) {
        this.setState({ isConfirmDialogOpen: true, pipeline: pipeline })
    }
    private handleClosePipelineConfirmDialog() {
        this.setState({ isConfirmDialogOpen: false })
    }

    public render() {
        const { open, logsBySequence, job, taskId } = this.state;
        const { workspace, profile } = this.context;
        assert.object(workspace, 'View must be used inside WorkspaceProvider');

        const onEdit = (pipeline: IPipeline) => history.push(`${location.pathname}/${pipeline.id}`)
        return (
            <>
                {this.state.pipeline &&
                    <ConfirmDialog
                        isOpen={this.state.isConfirmDialogOpen}
                        title={`Delete '${this.state.pipeline.name}'?`}
                        message="Remove this import from the workspace. This action cannot be undone."
                        confirmLabel="Delete"
                        handleClose={this.handleClosePipelineConfirmDialog}
                        handleConfirm={this.handleDeletePipeline} />}
                <div className='rightside_pageContent data-page'>
                    {job &&
                        <LogModal
                            show={open}
                            logArray={Object.values(logsBySequence)}
                            job={job}
                            hideLog={this.hideLog}
                            downloadLog={this.downloadLog}
                            taskId={taskId}
                        />}
                    {!this.context.isLoadingPipelines ?
                        <PipelineSearch
                            entities={this.context.pipelines}
                            new={<>{!profile?.featuresDisabled?.includes("MODIFY_PIPELINE") &&
                                <SplitButton
                                    icon={<AddIcon />}
                                    options={[{
                                        label: "Pipeline",
                                        onClick: () => history.push(`/lab/${workspace.id}/${EntityRel.Pipeline.collection}/new`)
                                    }, {
                                        label: "Import",
                                        onClick: this.props.onAdd
                                    }]} />
                                }</>}
                            row={e =>
                                <DataRow
                                    key={e.id}
                                    pipeline={e}
                                    renderLogs={this.renderLogs}
                                    openLog={this.openLog}
                                    downloadLog={this.downloadLog}
                                    handleOpenPipelineConfirmDialog={this.handleOpenPipelineConfirmDialog}
                                    onEdit={onEdit} />}
                            noEntities={
                                <div className="no-datasources content-center">
                                    <img
                                        alt="Data sources"
                                        src={dataIcon}
                                    />
                                    <h1>No data sources yet. Feed me some data!</h1>
                                </div>} /> :
                        pipelinesPlaceholder}
                </div>
            </>
        );
    }
}

export const DataImportsWithContext = bindContexts(DataImports, [Auth0Context, APIContext, WorkspaceContext]);

export default withRouter(DataImportsWithContext);

import React from 'react'

const content = {
	"Name": "Give your pipeline a memorable name.",
	"Settings": "Configure the runtime values for your pipeline. These will override any values defined on the workspace Project components.",
	"Actions": "By default, this pipeline will extract, load and transform your data. Define a custom Bash script to take complete control of this pipeline's actions.",
	"Schedule": "Automatically run this pipeline, or when set to 'Manual', the pipeline run will need to be triggered by a user."
}

export const DataImportEditOverview = <>
	{Object.entries(content).map((c, i) => ((title: string, description: string) =>
		<React.Fragment key={i}>
			<h3>{title}</h3>
			<div>{description}</div>
			<br />
		</React.Fragment>)(...c))}
</>

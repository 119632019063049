import * as assert from 'assert-plus'
//
// Async helpers
//

export async function delay (milliseconds: number) : Promise<number> {
  return new Promise<number>(resolve => {
    setTimeout(resolve, milliseconds)
    return milliseconds
  })
}

/*
 * This function returns a Promise to wait until either the component has signalled it is
 * loaded, and the minimum time has been waited or the maximum wait time has been reached.
 */
export async function waitUntilReady (
  minimumWaitMs: number,
  maximumWaitMs: number,
  isMounted: boolean,
  component: AsyncLoader
): Promise<number> {
  const WAIT_INTERVAL = 500
  let totalWait = 0
  assert.func(component.isLoading, 'Component must define an isLoading prop to waitUntilReady')
  // console.log("Should wait ["+component.isLoading()+"], waiting at least ["+minimumWaitMs+"], max wait ["+maximumWaitMs+"]");
  while (
    isMounted &&
        ((component.isLoading() && totalWait < maximumWaitMs) || totalWait < minimumWaitMs)
  ) {
    // console.log("Should still wait ["+component.isLoading()+"], waited ["+totalWait+"], waiting at least ["+minimumWaitMs+"], max wait ["+maximumWaitMs+"]");
    await delay(WAIT_INTERVAL)
    totalWait += WAIT_INTERVAL
  }
  return totalWait
}

export interface AsyncLoader {
  isLoading(): boolean
}
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import 'src/assets/css/charts/HtmlTable.css'
import Metadata from 'src/components/charts/metadata/Metadata'
import { useAPIContext } from 'src/contexts/api'
import { HtmlTableProps } from 'src/models/charts/html/HtmlTable'
import { parseJsonSwallowError } from 'src/utils/charts/chartUtils'
import * as markdownUtils from 'src/utils/markdownUtils'

const HtmlTable: FunctionComponent<HtmlTableProps> = props => {
  const { catalogAPI } = useAPIContext()
  const {
    getData,
    rawData,
    metadata,
  } = props
  const metadataObj = new Metadata(metadata)

  const [chartData, setChartData] = useState({
    ready: false,
    data: [] as Record<string, unknown>[],
  })

  // ensure component is still mounted before updating chart
  const componentIsMounted = useRef<boolean>(true)

  useEffect(() => {
    componentIsMounted.current = true
    if (rawData) {
      setChartData({ready: true, data: parseJsonSwallowError(rawData)})
    } else {
      getData?.().then(data => setChartData({ ready: true, data: data }));
    }

    // return a cleanup function
    return () => {
      componentIsMounted.current = false
    }
  }, [catalogAPI, rawData, metadata])

  const results = chartData.data.slice(0, props.limit)
  const columnNames = Object.keys(results?.[0] || {});
  const headers = columnNames.map(columnName => (
    <th key={columnName}>
        {metadataObj.getColumn(columnName)?.label || columnName}
    </th>));

  const rows = results.map((result, index) => {
    const rowData = columnNames.map(columnName => {
      const markdownLinkRegex = /\[.*\]\(.*\)/;
      const value = result[columnName];

      return (
        <td key={columnName}>
          {typeof value === "string" && markdownLinkRegex.test(value) ?
            <ReactMarkdown
              renderers={markdownUtils.renderers}
              source={value} /> :
            value as ReactNode}
        </td>
      );
    })

    return <tr key={index}>{rowData}</tr>;
  });

  return (
    chartData.ready ? <div data-testid="html-table" id="html-table" className="html-table">
      <table>
        <thead>
          <tr>
          {headers}
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
      {props.limit && chartData.data.length > props.limit &&
        `(showing ${props.limit} of ${chartData.data.length} rows)`}
    </div> : null
  )
}

export default HtmlTable

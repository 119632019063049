import * as assert from 'assert-plus'
import { Chart as ChartJS, registerables } from 'chart.js'
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Chart as ChartComponent } from 'react-chartjs-2'
import 'src/assets/css/charts/ChartjsChart.css'
import Metadata from 'src/components/charts/metadata/Metadata'
import { getRgbaString } from 'src/components/charts/metadata/palettes'
import { useAPIContext } from 'src/contexts/api'
import { ChartjsChartProps } from 'src/models/charts/chartjs/ChartJsChart'
import { buildChartOptions } from 'src/utils/charts/BuildChartOptions'
import { updateChartData } from 'src/utils/charts/UpdateChart'
import { parseJsonSwallowError } from 'src/utils/charts/chartUtils'
import { CircularProgress } from '@material-ui/core'

if (registerables) {
  ChartJS.register(...registerables)
}
if (TreemapController && TreemapElement) {
  ChartJS.register(TreemapController, TreemapElement);
}
if (ChartDataLabels) {
  ChartJS.register(ChartDataLabels);
}

function usePrevious (value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const ChartjsChart: FunctionComponent<ChartjsChartProps> = props => {
  const { catalogAPI } = useAPIContext()
  const {
    chartType,
    options,
    getData,
    continuousPoll,
    rawData,
    metadata,
    dataCallback,
    optionsCallback,
    onClickCallback,
    onClickAggregateCallback
  } = props
  const prevProps = usePrevious({
    chartType,
    options,
    getData,
    continuousPoll,
    rawData,
    metadata,
    dataCallback,
    optionsCallback,
    onClickCallback,
    onClickAggregateCallback
  })
  const metadataObj = new Metadata(metadata)
  const [chartData, setChartData] = useState({
    ready: false,
    aggregateIndex: [],
    labels: [],
    datasets: [
      {
        label: '',
        data: [] as Record<string, unknown>[],
        backgroundColor: getRgbaString(metadataObj.getPalette(), 0, 0.2),
        borderColor: getRgbaString(metadataObj.getPalette(), 0),
        borderWidth: 1
      }
    ]
  })

  // ensure component is still mounted before updating chart
  const componentIsMounted = useRef<boolean>(true)

  // the reference to the chartjs chart
  const chartReference = useRef<ChartJS | null>(null)

  // tooltip ref
  const tooltipReference = useRef<HTMLDivElement>(document.createElement('div'))

  // polling for data
  const pollingReference = useRef<NodeJS.Timeout | null>(null)

  const handleOnClick = (event: any, selectedLink?: any) => {
    if (event.persist) {
      event.persist()
    }
    if (chartReference.current !== null && props.onClickCallback) {
      const chartInstance = chartReference.current
      if (props.onClickCallback) {
        props.onClickCallback(chartInstance, event)
      }
    }
    // onClickCallback might throw the chart away / push new location
    if (chartReference.current) {
      const element: any = chartReference.current.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false)
      if (props.onClickAggregateCallback && element && element.length > 0) {
        let idx = element[0].datasetIndex
        if (props.chartType === 'pie' || props.chartType === 'doughnut') {
          idx = element[0].index
        }
        let aggregateKey = null
        if (chartData && chartData.aggregateIndex) {
          aggregateKey = chartData.aggregateIndex[idx]
        }
        props.onClickAggregateCallback(aggregateKey, element, event)
      }
    }
    if (
      selectedLink &&
            (selectedLink.dataset || selectedLink.href) &&
            props.drilldown &&
            props.drilldownCallback
    ) {
      props.drilldownCallback(event.nativeEvent, selectedLink)
    }
  }

  const fetchData = async (metaObj:any) => {
    getData?.().then(data => updateChartData(
      chartType,
      metaObj,
      data,
      componentIsMounted,
      setChartData,
      dataCallback
    ))
  }

  useEffect(() => {
    componentIsMounted.current = true
    const metaObj = new Metadata(metadata)
    if (rawData) {
      updateChartData(
        chartType,
        metaObj,
        parseJsonSwallowError(rawData),
        componentIsMounted,
        setChartData,
        dataCallback
      )
    } else {
      // terminate any existing polling
      if (!props.continuousPoll || pollingReference.current != null) {
        clearInterval(pollingReference.current as NodeJS.Timeout)
        pollingReference.current = null
      }
      // poll for data now and optionally at 5s interval
      fetchData(metaObj)
      if (props.continuousPoll) {
        pollingReference.current = setInterval(() => fetchData(metaObj), 5000)
      }
    }

    // return a cleanup function
    return () => {
      componentIsMounted.current = false
      if (pollingReference.current != null) {
        clearInterval(pollingReference.current as NodeJS.Timeout)
        pollingReference.current = null
      }
    }
  }, [catalogAPI, rawData, chartType, metadata, dataCallback, continuousPoll])

  const drilldownLinks =
        metadataObj && metadataObj.getDrilldownLinks ? metadataObj.getDrilldownLinks() : []

  let chart = null
  const chartOptions = buildChartOptions(
    metadataObj,
    props.chartType,
    props.options,
    props.legend,
    !!props.drilldown,
    chartReference,
    tooltipReference,
    handleOnClick
  )
  if (props.chartType && chartData && chartOptions) {
    const { labels, datasets } = chartData
    let theChartData = { labels, datasets }
    let redraw = false
    let theChartType = props.chartType
    if (props.chartType === 'area' || props.chartType === 'scatter') {
      theChartType = 'line' // we set fill to true, area is just a filled line chart
    }
    if (chartData.ready) {
      if (chartReference != null && chartReference.current != null) {
        const chartInstance: ChartJS = chartReference.current
        if (prevProps
            && JSON.stringify((prevProps as any).options) !== JSON.stringify(props.options)
        ) {
          // options changed, chart can be updated
          chartInstance.update()
        }
        if (prevProps
            && (prevProps as any).chartType !== chartType
        ) {
          // type changed, chart must be destroyed
          redraw = true
          theChartData = { labels: [], datasets: [] }
        }
      }

      if (
        theChartType &&
                ['treemap', 'pie', 'doughnut', 'bar', 'line', 'horizontalBar'].includes(
                  theChartType
                )
      ) {
        assert.object(ChartDataLabels, 'ChartDataLabels plugin loaded')
        if (props.optionsCallback) {
          props.optionsCallback(chartOptions)
        }
        // odd width and height?  https://github.com/jerairrest/react-chartjs-2/issues/368
        chart = (
                    <ChartComponent
                        id={props.canvasId}
                        ref={chartReference as any}
                        type={theChartType}
                        redraw={redraw}
                        data={theChartData}
                        height={250}
                        width={300}
                        options={{ ...chartOptions, maintainAspectRatio: false }}
                    />
        )
      }
    }
  }

  const hideTooltip = () => {
    const tooltipEl = tooltipReference.current

    if (!tooltipEl.matches(':hover')) {
      tooltipEl.style.opacity = '0'
    }
  }
  const drilldownLinksContent = () => {
    // no drilldown callback, links disabled
    if (!props.drilldown) {
      return <span />
    }
    return (
      drilldownLinks &&
            drilldownLinks.map((link: any, index: number) => (
                <Button
                    className="text-warning tooltip-btn-link"
                    variant="link"
                    data-testid="drill-down-link"
                    key={index}
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={e => handleOnClick(e, link)}
                >
                    {link.title ? link.title : 'Show more...'}
                </Button>
            ))
    )
  }

  return (
        <>
            <div
                data-testid={props.testId ? props.testId : 'chartjs-chart'}
                data-datasetidx="-1"
                className="chartjs-chart"
                onMouseOut={hideTooltip}
            >
              {chart ? chart : (<CircularProgress size='1em' />)}
            </div>
            <div
                data-testid="chartjs-tooltip"
                className="chartjs-tooltip"
                ref={tooltipReference}
                data-datasetidx="-1"
            >
                <table />
                {drilldownLinksContent()}
            </div>
        </>
  )
}

ChartjsChart.defaultProps = {
  legend: true,
  drilldown: true
}

export default ChartjsChart

import { AxiosResponse } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import 'src/assets/css/views/formViews.css'
import { DataImportEditOverview } from 'src/assets/help/views/dataImportEdit'
import PipelineForm from 'src/components/PipelineForm'
import HelpPanel from 'src/components/HelpPanel'
import workspacePlaceholder from 'src/components/placeholders/WorkspacePlaceholder'
import { APIContext, getDataPluginURI, getPipelineURI } from 'src/contexts/api'
import { MergedContexts } from 'src/contexts/merged'
import { WindowContext } from 'src/contexts/window'
import { WorkspaceContext } from 'src/contexts/workspace'
import IDataPlugin from 'src/models/IDataPlugin'
import IPipeline from 'src/models/IPipeline'
import { IDataImportEditProps, IDataImportEditState } from 'src/models/views/IDataImportEdit'
import { bindContexts } from 'src/utils/bindContexts'


class DataImportEdit extends React.Component<IDataImportEditProps, IDataImportEditState> {
    public isThisMounted = false
    public searchParams = new URLSearchParams(location.search)
    static contextType = MergedContexts
    constructor(props: IDataImportEditProps) {
        super(props)
        this.state = {
            dataComponents: [],
            isHelpMenuOpen: false
        }

        this.handleOpenHelpPanel = this.handleOpenHelpPanel.bind(this)
        this.handleCloseHelpPanel = this.handleCloseHelpPanel.bind(this)
        this.loadDataPlugin = this.loadDataPlugin.bind(this)
        this.onSettingsToggle = this.onSettingsToggle.bind(this)
        this.onSaved = this.onSaved.bind(this)
    }

    public componentDidMount() {
        this.props.setViewName?.("Data Imports")

        const pipelineId = this.props.match.params.pipelineId
        const dataPluginId = this.searchParams.get('dataplugin')

        this.loadPipeline(pipelineId).then(p => this.loadDataPlugin(p, dataPluginId))
    }

    public handleOpenHelpPanel() {
        this.setState({ isHelpMenuOpen: true })
    }

    public handleCloseHelpPanel() {
        this.setState({ isHelpMenuOpen: false })
    }

    private async loadDataPlugin(pipeline: IPipeline, id: string | null) {
        const { catalogAPI } = this.context

        const getDataPlugin = id ? (async () => catalogAPI.get(getDataPluginURI(id)).then(((r: AxiosResponse<IDataPlugin>) => r.data))) : undefined
        const dataPlugin = pipeline._embedded?.dataComponents[0]._embedded.dataplugin || await getDataPlugin?.() || null

        this.setState({ dataPlugin: dataPlugin })
    }

    private async loadPipeline(id: string | undefined) {
        const { catalogAPI, initialisePipeline } = this.context

        const getPipeline = async () => catalogAPI.get(getPipelineURI(id)).then((r: AxiosResponse<IPipeline>) => r.data)
        const pipeline: IPipeline = await (!id ? initialisePipeline() : getPipeline())
        this.setState({ pipeline: pipeline })

        return pipeline
    }

    private onSettingsToggle(index: number | undefined) {
        this.setState({ expandedSettingsIndex: index })
    }

    private onSaved(pipeline: IPipeline) {
        this.setState({ pipeline: pipeline})
        this.props.onSaved(pipeline)
    }

    public render() {
        const { pipeline, dataPlugin } = this.state
        const isSmallScreen = this.context.size.smallScreen
        const { isHelpMenuOpen } = this.state

        return <>
            <div className='rightside_pageContent editForms'>
                <div className="form-container">
                    {(!pipeline || dataPlugin === undefined) ?
                        workspacePlaceholder :
                        <>
                            <div className={`${isSmallScreen ? 'col-12 no-padding-left no-padding-right' : 'col-7 form no-padding-left no-padding-right'} ${isHelpMenuOpen ? 'no-display' : ''}`}>
                                <PipelineForm
                                    pipeline={pipeline}
                                    dataPlugin={dataPlugin}
                                    autoValidate={this.searchParams.get('validate')?.toLowerCase() === 'true'}
                                    helpButtonClicked={this.handleOpenHelpPanel}
                                    onSaved={this.onSaved}
                                    onClose={this.props.onClose}
                                    onSettingsToggle={this.onSettingsToggle}
                                    onDataComponentsChange={dataComponents => this.setState({
                                        dataComponents: dataComponents,
                                        expandedSettingsIndex: undefined
                                    })} />
                            </div>
                            {isHelpMenuOpen || !isSmallScreen &&
                                <div className={`${isSmallScreen ? 'help-info' : 'col help no-padding-left'}`}>
                                    {((index: number | undefined) => {
                                        const dataComponent = this.state.dataComponents.find((_, i) => i === index)

                                        if (!dataComponent)
                                            return <HelpPanel
                                                title="Data Import"
                                                overview={DataImportEditOverview} />
                                        
                                        return <HelpPanel
                                            title={dataComponent.name}
                                            dataPlugin={dataComponent._embedded?.dataplugin}
                                            closeHelpPanel={this.handleCloseHelpPanel} />
                                    })(this.state.expandedSettingsIndex)}
                                </div>}
                        </>}
                </div>
            </div>
        </>

    }
}

export const DataImportEditWithContext = bindContexts(DataImportEdit, [
    APIContext,
    WorkspaceContext,
    WindowContext
])

export default withRouter(DataImportEditWithContext)

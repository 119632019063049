/* eslint-disable */
import * as assert from 'assert-plus'
import React, { useEffect, useState } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Form from 'react-bootstrap/Form'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Route, Router, Switch } from 'react-router-dom'
import { useWorkspaceContext } from 'src/contexts/workspace'
import PrivateRoute from '../auth/PrivateRoute'
import { useAuth0 } from '../contexts/auth0'
import AppEnv from '../utils/appenv.js'
import history from '../utils/history'
import Loading from '../utils/Loading'
import './../styles.css'
import CatalogApi from './CatalogApi'
import Test from './Test'

const DebugApp = () => {
  const [token, setToken] = useState<any>('blah')
  const { isAuthenticated, loading, user, getTokenSilently } = useAuth0()
  const { workspace } = useWorkspaceContext()

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getTokenSilently()
      setToken(token)
      // console.log will also log to Sentry
      console.log("DEBUG", {token: token, appEnv: AppEnv,})
    }
    if (!loading) {
      fetchToken()
    }
  }, [loading, getTokenSilently, setToken])

  //
  // We are using assert-plus, which you disable with process.env.NODE_NDEBUG, but there's no way to set this in React
  //
  // assert.notEqual(process.env.NODE_ENV, 'production', 'Should never throw this assertion as assertions should be disabled in production');
  assert.notEqual(process.env.NODE_NDEBUG, 1)

  let userName = ''
  let userAuthenticated = false
  if (loading) {
    return <Loading />
  }
  userName = user ? user.name : ''
  userAuthenticated = isAuthenticated

  const vars = (
        <div>
            <Test name={userName}>
                <span>NODE_ENV: {process.env.NODE_ENV}</span>
                <br />
                <span>Logged in {userAuthenticated ? 'true' : 'false'}</span>
                <br />
                <span>User ID {user.sub}</span>
                <br />
                <span>Asserts: {process.env.NODE_NDEBUG ? 'disabled' : 'enabled'}</span>
                <br />
                <span>APP_VERSION: {AppEnv.APP_VERSION}</span>
                <br />
                <span>APP_IDENTITY_DOMAIN: {AppEnv.APP_IDENTITY_DOMAIN}</span>
                <br />
                <span>APP_IDENTITY_CLIENT_ID: {AppEnv.APP_IDENTITY_CLIENT_ID}</span>
                <br />
                <span>APP_SERVER_URI: {AppEnv.APP_SERVER_URI}</span>
                <br />
            </Test>
        </div>
  )
  return (
        <Router history={history}>
            <div className="App">
                <br />
                <br />

                <div className="pad_tb15 custom_container clearfix">
                    <ScrollAnimation animateIn="fadeIn" offset={10}>
                        <Form.Group controlId="profileForm.name" className="form-group">
                            <Form.Label>Workspace</Form.Label>
                            <Form.Text className="text-muted">
                                This is a href of your current workspace.
                            </Form.Text>
                            <Form.Control
                                type="text"
                                value={workspace && workspace._links ? workspace._links.self.href : null}
                                name="token"
                            />
                        </Form.Group>
                        <CopyToClipboard text={workspace && workspace._links ? workspace._links.self.href : null}>
                            <span style={{ fontSize: '2em' }}>
                                <i className="fas fa-clipboard" />
                            </span>
                        </CopyToClipboard>
                    </ScrollAnimation>
                </div>

                <div className="pad_tb15 custom_container clearfix">
                    <ScrollAnimation animateIn="fadeIn" offset={10}>
                        <Form.Group controlId="profileForm.name" className="form-group">
                            <Form.Label>API Token</Form.Label>
                            <Form.Text className="text-muted">
                                This is a short lived API token for testing and development.
                            </Form.Text>
                            <Form.Control type="text" value={token} name="token" />
                        </Form.Group>
                    </ScrollAnimation>

                    <CopyToClipboard text={token}>
                        <span style={{ fontSize: '2em' }}>
                            <i className="fas fa-clipboard" />
                        </span>
                    </CopyToClipboard>
                </div>

                <CatalogApi />
                {vars}
                <Switch>
                    <Route path="/debug" />
                    <PrivateRoute path="/" component={Test} exact={true} />
                    <PrivateRoute path="/profile">
                        <span>profile</span>
                    </PrivateRoute>
                </Switch>
            </div>
        </Router>
  )
}

export default DebugApp

import * as assert from 'assert-plus'
import * as React from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import { withRouter } from 'react-router-dom'
import Workbench from 'src/components/Workbench'
import Back from 'src/components/Back';
import { logError } from 'src/utils/Error'
import axios from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { IPasswordState, Props } from '../models/views/IChangePassword'
import AppEnv from '../utils/appenv'
import { bindContexts } from '../utils/bindContexts'
import Loading from '../utils/Loading'
import 'src/styles.css'

class ChangePasword extends React.Component<Props, IPasswordState> {
    static contextType = MergedContexts;

    constructor (props: Props) {
      super(props)
      this.state = {
        message: '',
        submitSuccess: false,
        calling: true
      }
    }

    public async componentDidMount () {
      const { user } = this.context
      assert.func(axios, 'http request object')
      this.props.setViewName && this.props.setViewName("Change Password")
      const data = {
        client_id: AppEnv.APP_IDENTITY_CLIENT_ID,
        email: user.email,
        connection: AppEnv.APP_IDENTITY_CONNECTION
      }
      const headers = {
        'Content-Type': 'application/json'
      }
      axios
        .post(
          'https://' + AppEnv.APP_IDENTITY_DOMAIN + '/dbconnections/change_password',
          data,
          {
            headers
          }
        )
        .then(response => {
          this.setState({ submitSuccess: true, calling: false, message: response.data })
        })
        .catch(error => {
          this.setState({
            submitSuccess: false,
            calling: false,
            message: 'Error ! Please try again'
          })
          logError(error)
        })
    }

    public render () {
      const { message, submitSuccess, calling } = this.state

      return (
            <Workbench>
                <div className="w-100" data-testid="resolved">
                    <Back />
                    {calling && (
                        <Col className={!calling ? 'feed-hidden loaderfadeOut' : 'feed'}>
                            <Loading />
                        </Col>
                    )}
                    <div className="full_pageContent pad_5 changepassword_page ">
                        <Col md={6} className="offset-md-3  align-self-center">
                            <div className="custom_alertbox text-center">
                                {submitSuccess
                                  ? (
                                    <Alert>{message}</Alert>
                                    )
                                  : (
                                    <Alert>{message}</Alert>
                                    )}
                            </div>
                        </Col>
                    </div>
                </div>
            </Workbench>
      )
    }
}

export const ChangePaswordWithContext = bindContexts(ChangePasword, [Auth0Context])
export default withRouter(ChangePaswordWithContext)

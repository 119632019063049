import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Tab, Tabs } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { ChangeEvent, useEffect, useState } from 'react'
import EntityRow from 'src/components/EntityRow'
import Search from 'src/components/Search'
import useEntityPage from 'src/hooks/useEntityPage'
import { IEntitySelectDialog } from 'src/models/components/IEntitySelectDialog'
import { ImageHolderSize } from 'src/models/components/IImageHolder'

const EntitySelectDialog = (props: IEntitySelectDialog): JSX.Element => {
	const { open, disableCloseOnSelect, title, tabs, onClose, onSelect } = props

	const [activeTabIndex, setActiveTabIndex] = useState(0)
	const changeTab = (event: React.ChangeEvent<unknown>, value: number) => setActiveTabIndex(value)

	const activeTab = Object.values({ ...tabs })[activeTabIndex]
	const { description, entityRel, page } = activeTab || props

	if (!entityRel) throw new Error("`entityRel` must be provided")
	if (!page) throw new Error("`page` must be provided")

	const entityPage = useEntityPage(entityRel, page)

	useEffect(() => {
		activeTab && activeTab.page && entityPage.setPage(activeTab.page)
	}, [activeTabIndex])

	const onPageChange = (e: ChangeEvent<unknown>, pageNumber: number) => {
		if (!entityPage.metadata) return

		const currentPage = entityPage.metadata.number + 1
		switch (pageNumber) {
			case currentPage:
				return
			case currentPage + 1:
				return entityPage.next()
			case currentPage - 1:
				return entityPage.prev()
			case 1:
				return entityPage.first()
			case entityPage.metadata.totalPages:
				return entityPage.last()
			default:
				return entityPage.read(pageNumber)
		}
	}
	return <>
		<Dialog
			open={open}
			fullWidth
			PaperProps={{ style: { height: '80vh' } }}
			onClose={onClose}>
			{title && <DialogTitle>{title}</DialogTitle>}
			{tabs &&
				<Tabs
					indicatorColor='primary'
					variant='fullWidth'
					value={activeTabIndex}
					onChange={changeTab}>
					{Object.keys(tabs).map(t =>
						<Tab
							key={t}
							label={t} />)}
				</Tabs>}
			<DialogContent>
				{description && <DialogContentText>{description}</DialogContentText>}
				<Search
					entityRel={entityRel}
					entities={entityPage.entities}
					customSearchPlaceholder={props.customSearchPlaceholder}
					row={e => {
						const onClick = () => {
							onSelect(e, entityRel)
							disableCloseOnSelect || onClose()
						}

						return <EntityRow
							key={e.id}
							entity={e}
							entityRel={entityRel}
							disableMenu
							description={activeTab?.getEntityDescription(e) || props.getEntityDescription?.(e)}
							actions={
								<>{props.actionsButton ? props.actionsButton(e) : <Button
									style={{ transform: 'scale(0.85)' }}
									color='primary'
									variant='contained'
									onClick={onClick}>
									Add
								</Button>}</>
							}
							ImageHolderProps={{
								imageUrl: activeTab?.getEntityLogoUrl(e) || props.getEntityLogoUrl?.(e),
								size: ImageHolderSize.Small
							}}
							disableImage={props.imageHolderDisabled || false} />
					}} />
			</DialogContent>
			<Pagination
				style={{ marginTop: 'auto' }}
				className='content-center pad_tb15'
				disabled={entityPage.isProcessing || !entityPage.entities}
				count={entityPage.metadata?.totalPages || 0}
				color='primary'
				showFirstButton
				showLastButton
				page={(entityPage.metadata?.number || 0) + 1}
				onChange={onPageChange} />
		</Dialog>
	</>

}

export default EntitySelectDialog

/*
 * exported stringStream
 * Creates a ReadableStream of characters from a string.
 */
"use strict";

function stringStream(s) {
  return new ReadableStream({
    start: function(controller) {
      var encoder = new TextEncoder();
      var pos = 0;
      var chunkSize = 1;

      function push() {
        if (pos >= s.length) {
          controller.close();
          return;
        }

        // Push some of the string, converting it into an Uint8Array of utf-8 data
        controller.enqueue(
          encoder.encode(s.slice(pos, pos + chunkSize))
        );

        pos += chunkSize;

        push();
      }

      push();
    },
    cancel: function() {

    }
  });
}

export default stringStream;


import { Button, Chip, CircularProgress, FormGroup, FormLabel, TextField } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import AddIcon from '@material-ui/icons/Add'
import { Alert, Autocomplete } from '@material-ui/lab'
import { AxiosError, AxiosResponse } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import 'src/assets/css/components/addDataForm.css'
import useStyles from 'src/assets/css/components/FormStyles'
import chainIcon from 'src/assets/images/svgs/chain-icon.svg'
import dataIcon from 'src/assets/images/svgs/data.svg'
import CustomAccordion from 'src/components/CustomAccordion'
import EntityName from 'src/components/EntityName'
import DataPluginSettings from 'src/components/DataPluginSettings'
import DataScheduleComponent from 'src/components/DataScheduleComponent'
import DataTransform from 'src/components/DataTransform'
import EntitySelectDialog from 'src/components/EntitySelectDialog'
import ImageHolder from 'src/components/ImageHolder'
import SplitButton from 'src/components/SplitButton'
import { useAPIContext } from 'src/contexts/api'
import { useWindowContext } from 'src/contexts/window'
import { useWorkspaceContext } from 'src/contexts/workspace'
import { useDataPlugin, usePipeline } from 'src/hooks/useCRUDEntity'
import { IPipelineFormProps } from 'src/models/components/IPipelineForm'
import { TransformType } from 'src/models/components/IDataTransform'
import { ImageHolderSize } from 'src/models/components/IImageHolder'
import EntityRel from 'src/models/EntityRel'
import IDataComponent from 'src/models/IDataComponent'
import IDataPlugin from 'src/models/IDataPlugin'
import { INamedEntity } from 'src/models/IEntity'
import IError from 'src/models/IError'
import IPage from 'src/models/IPage'
import IPipeline, { PipelineStatus } from 'src/models/IPipeline'
import ConfirmDialog from './ConfirmDialog'
import { PartialExcluding } from 'src/utils/types'
import IValidationError from 'src/models/IValidationError'

const PipelineForm = (props: IPipelineFormProps): JSX.Element => {

    const classes = useStyles()

    const { catalogAPI } = useAPIContext()
    const { size } = useWindowContext()

    const workspace = props.workspace || useWorkspaceContext().workspace
    const { profile, pipelines, fetchPipelines, isLoadingPipelines } = useWorkspaceContext()

    const pipeline = usePipeline(catalogAPI, props.pipeline, true)
    const dataPlugin = useDataPlugin(catalogAPI, props.dataPlugin)

    const [error, setError] = useState<{
        message: string | undefined,
        errors: IValidationError[],
    }>()
    const unsetError = () => setError(undefined)

    const [properties, setProperties] = useState(pipeline.properties)
    const [isSaved, setSaved] = useState(false)

    const [name, setName] = useState(pipeline.name || dataPlugin.label || dataPlugin.name)
    const userModifiedNameRef = useRef(!!name)

    const setNameFromDataPlugin = (dataPlugin: IDataPlugin | undefined) => {
        if (userModifiedNameRef.current)
            return
        
        setName(dataPlugin?.label || dataPlugin?.name || "")
    }

    const setInstanceName = (name: string, value: string) => {
        setName(value)
        userModifiedNameRef.current = true
    }

    const [actions, setActions] = useState(pipeline.actions)

    const [script, setScript] = useState(pipeline.script)
    const [transform, setTransform] = useState(script ? TransformType.Script : actions.length ? TransformType.Custom : TransformType.Default)

    const [schedule, setSchedule] = useState(pipeline.schedule)
    const [scheduleType, setScheduleType] = useState<string>()

    const [timeout, setTimeout] = useState(pipeline.timeout)
    const [maxRetries, setMaxRetries] = useState(pipeline.maxRetries)
    const [dataComponents, setDataComponents] = useState<PartialExcluding<IDataComponent, 'name'>[]>(pipeline._embedded?.dataComponents || [])
    const [triggeredBy, setTriggeredBy] = useState(pipeline.triggeredBy)

    const draftRef = useRef(false)
    const dataComponentRef = useRef<string>()

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
    const closeRemoveDialog = () => setRemoveDialogOpen(false)
    const openRemoveDialog = () => setRemoveDialogOpen(true)

    const [selectDialogOpen, setSelectDialogOpen] = useState(false)
    const closeSelectDialog = () => setSelectDialogOpen(false)
    const openSelectDialog = () => setSelectDialogOpen(true)

    const [dataComponentPage, setDataComponentPage] = useState<IPage<IDataComponent>>()
    const [dataPluginPage, setDataPluginPage] = useState<IPage<IDataPlugin>>()

    const handleAlertDismissed = () => setSaved(false)

    const handleResponse = async (response: Promise<AxiosResponse<IPipeline>>) => {

        const handleSuccess = () => setSaved(true)

        const handleError = (e: AxiosError<IError | IValidationError[]>) => {
            const data = e.response?.data

            if (!data)
                return

            setError({
                message: Array.isArray(data) ? data[0].defaultMessage : data.message,
                errors: Array.isArray(data) ? data : data.errors || [],
            })
        }

        await response.then(handleSuccess).catch(handleError)
    }

    const handleSave = async (options?: Partial<{ asDraft: boolean }>) => {
        const asDraft = options?.asDraft !== undefined ? options.asDraft : pipeline.status === PipelineStatus.Draft
        draftRef.current = asDraft

        // remove any properties for datacomponents that are no longer referenced by the pipeline
        const filteredProperties = properties && Object.entries(properties)
            .filter(([k]) => dataComponents.some(dc => k.startsWith(`${dc.name}.`)))
            .reduce((p, [k, v]) => ({ ...p, [k]: v }), {})

        setProperties(filteredProperties)

        const data = {
            name: name,
            properties: filteredProperties,
            schedule: schedule,
            dataComponents: dataComponents.map(dc => {
                const dp = dc._embedded?.dataplugin

                // we know whether to handle a datacomponent or dataplugin based on the presence of an id or embedded dataplugin
                if (dc.id || !dp)
                    return dc.name

                return `${dp.pluginType}/${dp.name}${dp.variant ? `--${dp.variant}` : ""}`
            }),
            actions: transform === TransformType.Default ? [] : actions,
            script: transform !== TransformType.Default && script?.length ? script : undefined,
            timeout: timeout,
            maxRetries: maxRetries,
            triggeredBy: triggeredBy,
        }

        const { draft, create, update } = pipeline
        const save = asDraft ? draft : pipeline.canCreate ? create : update

        unsetError()
        await handleResponse(save(data))
    }

    const handleSubmit = () => handleSave()
    const handleSubmitAsDraft = () => handleSave({ asDraft: true })
    const handleSubmitAsReady = () => handleSave({ asDraft: false })

    const onChangeActions = (actions: string[]) => setActions(actions)

    const handleChangeTransform = (transform: TransformType) => {
        setTransform(transform)

        if (transform === TransformType.Default) setScript(undefined)
    }

    const handleChangeSchedule = (e: React.ChangeEvent<HTMLInputElement>) => {
        const scheduleType = e.target.value
        setScheduleType(scheduleType)

        if (scheduleType === 'manual-schedule') {
            setSchedule(undefined);
        } else if (scheduleType !== 'custom-schedule') {
            // set hourly or daily schedule
            setSchedule(scheduleType)
        }
    }

    const onSelectEntity = (entity: INamedEntity, entityRel: EntityRel) => {
        if (entityRel == EntityRel.DataPlugin) {
            const plugin = entity as IDataPlugin
            setDataComponents([...dataComponents, {
                //  do not map id so we know that we are processing a dataplugin
                name: plugin.name,
                _embedded: { dataplugin: plugin }
            }])
            setNameFromDataPlugin(plugin)
        } else if (entityRel == EntityRel.DataComponent) {
            const component = entity as IDataComponent
            setDataComponents([...dataComponents, component])
            setNameFromDataPlugin(component._embedded.dataplugin)
        }
    }

    const onConfirmRemoveComponent = async () => {
        setDataComponents(dataComponents.filter((_dc, i) => i !== dataComponents.map(dc => dc.name).lastIndexOf(dataComponentRef.current!)))
    }

    useEffect(() => {
        if (props.autoValidate)
            handleSave({ asDraft: false })

        if (!pipelines.length && !isLoadingPipelines)
            fetchPipelines()

        if (!pipeline.canCreate || !dataPlugin.canRead) return

        setDataComponents([
            ...dataComponents,
            ...[
                { name: `extractors/${dataPlugin.name}--${dataPlugin.variant}` },
                workspace._embedded['default datastore'] as IDataComponent,
                { name: 'transformers/dbt' }
            ]
        ])
    }, [])

    useEffect(() => {
        props.onDataComponentsChange?.(dataComponents)
        setNameFromDataPlugin(dataComponents[0]?._embedded?.dataplugin)

        if (!pipeline.canCreate || !dataPlugin.canRead || !dataComponents.length) return

        handleSave({ asDraft: true })
    }, [dataComponents])

    useEffect(() => {
        isSaved && props.onSaved(pipeline, draftRef.current)
    }, [isSaved])

    useEffect(() => {
        if (!selectDialogOpen) return

        dataComponentPage || catalogAPI
            .get(workspace._links.datacomponents.href)
            .then((r: AxiosResponse<IPage<IDataComponent>>) => setDataComponentPage(r.data))

        dataPluginPage || catalogAPI
            .get(workspace._links.dataplugins.href)
            .then((r: AxiosResponse<IPage<IDataPlugin>>) => setDataPluginPage(r.data))
    }, [selectDialogOpen])

    useEffect(() => {
        if (!pipeline.dataComponents.length || error || !isSaved)
            return

        setDataComponents([...pipeline._embedded!.dataComponents])
    }, [pipeline.dataComponents, error, isSaved])

    useEffect(() => {
        if (name)
            return

        userModifiedNameRef.current = false
    }, [name])

    const tasks = ["Deploy"]

    return <>
        <ConfirmDialog
            isOpen={removeDialogOpen}
            title={`Remove '${dataComponentRef.current}'?`}
            message={`Remove this component from the pipeline '${name}'.`}
            confirmLabel="Remove"
            handleConfirm={onConfirmRemoveComponent}
            handleClose={closeRemoveDialog}
        />
        {dataComponentPage && dataPluginPage &&
            <EntitySelectDialog
                open={selectDialogOpen}
                tabs={{
                    "Existing": {
                        description: "Add an existing project component",
                        entityRel: EntityRel.DataComponent,
                        page: {
                            ...dataComponentPage,
                            _embedded: dataComponentPage._embedded && { [EntityRel.DataComponent.collection]: dataComponentPage._embedded[EntityRel.DataComponent.collection].filter(dc => !dc._embedded.dataplugin.pluginType?.match(/^(FILE)$/)) },
                        },
                        getEntityLogoUrl: (e: IDataComponent) => e._embedded.dataplugin.logoUrl,
                        getEntityDescription: (e: IDataComponent) => e._embedded.dataplugin.description
                    },
                    "New": {
                        description: "Add a new project component from an installed plugin",
                        entityRel: EntityRel.DataPlugin,
                        page: {
                            ...dataPluginPage,
                            _embedded: dataPluginPage._embedded && { [EntityRel.DataPlugin.collection]: dataPluginPage._embedded[EntityRel.DataPlugin.collection].filter(dp => !dp.pluginType?.match(/^(FILE)$/)) },
                        },
                        getEntityLogoUrl: (e: IDataPlugin) => e.logoUrl,
                        getEntityDescription: (e: IDataPlugin) => e.description
                    }
                }}
                onClose={closeSelectDialog}
                onSelect={onSelectEntity} />}
        <div className={!size.smallScreen ? classes.root_medium : classes.root}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSaved}
                autoHideDuration={6000}
                onClose={handleAlertDismissed}>
                <Alert
                    data-testid='saved-datastore'
                    variant='filled'
                    severity='success'>
                    {`'${pipeline.name}' saved${draftRef.current ? ' as draft' : ''}`}
                </Alert>
            </Snackbar>
            {error &&
                <Alert
                    severity='error'>
                    {error.message}
                </Alert>}
            <EntityName
                instanceName={name}
                handleNameChange={setInstanceName}
                error={error?.errors.find(e => e.field === 'name' )} />
            <div className='inline-vertical-center pipeline-row-data image-holders'>
                {dataComponents.map((dc, i) => {
                    const defaultIcon = dc._embedded?.dataplugin.pluginType === 'LOADER' ? dataIcon : chainIcon

                    const onRemoveDataComponent = () => {
                        dataComponentRef.current = dc.name
                        openRemoveDialog()
                    }

                    return <ImageHolder
                        key={dc.id || i}
                        label={dc.name}
                        imageUrl={dc._embedded?.dataplugin.logoUrl || defaultIcon}
                        size={ImageHolderSize.Small}
                        overlay={!profile?.featuresDisabled?.includes("MODIFY_PIPELINE") && "Remove"}
                        onClick={!profile?.featuresDisabled?.includes("MODIFY_PIPELINE") ? onRemoveDataComponent : () => void 0} />
                })}
                {!profile?.featuresDisabled?.includes("MODIFY_PIPELINE") &&
                <Button
                    className='image-holder-small'
                    aria-label='add-pipeline-component'
                    color='primary'
                    variant='outlined'
                    disabled={pipeline.isProcessing}
                    onClick={openSelectDialog}>
                    <AddIcon />
                </Button>
                }
            </div>
            <div className='properties-wrapper'>
                <CustomAccordion
                    step={1}
                    title="Settings"
                    expanded={dataComponents.some(dc => dc._embedded?.dataplugin)}>
                    {dataComponents.map((dc, i) => {
                        const dataplugin = dc._embedded?.dataplugin

                        if (!dataplugin)
                            return

                        const onToggle = (isExpanded: boolean) => props.onSettingsToggle?.(isExpanded && dataplugin.description ? i : undefined)
                        const errors = error?.errors?.filter(e => e.field.startsWith(`properties.${dc.name}`))

                        return (
                            <CustomAccordion
                                key={dc.id || i}
                                title={dc.name}
                                isLast={i === dataComponents.length - 1}
                                onToggle={onToggle}
                                expanded={!!errors?.length}>
                                <DataPluginSettings
                                    isEditEnabled
                                    selectedDataplugin={dataplugin}
                                    prefix={dc.name}
                                    properties={properties}
                                    readOnlyProperties={dc.properties}
                                    handleSettingsChange={(name: string, value: any) => setProperties({ ...properties, ...{ [name]: value || undefined } })}
                                    handleSettingsUpdate={setProperties}
                                    errors={errors} />
                            </CustomAccordion>)})}
                </CustomAccordion>
                <CustomAccordion
                    step={2}
                    title="Actions">
                    <DataTransform
                        dataComponents={dataComponents as IDataComponent[]}
                        actions={actions}
                        script={script}
                        transformType={transform}
                        handleCustomScriptChange={setScript}
                        onChangeActions={onChangeActions}
                        onChangeTransform={handleChangeTransform} />
                    <TextField
                        fullWidth
                        label="Timeout (seconds)"
                        id={`${pipeline.id}--timeout`}
                        onChange={e => setTimeout(Number(e.target.value))}
                        placeholder="300"
                        type='number'
                        value={timeout || ""}  // clear field when timeout is falsy (i.e. 0) to expose default value as placeholder
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            // endAdornment: <InputAdornment position="end">{`second${timeout !== 1 ? "s" : ""}`}</InputAdornment>,
                            inputProps: { min: 0 }
                        }}/>
                    <TextField
                        fullWidth
                        label="Max retries"
                        id={`${pipeline.id}--max-retries`}
                        onChange={e => setMaxRetries(Number(e.target.value))}
                        type='number'
                        value={maxRetries}
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputProps: { min: 0 } }}/>
                </CustomAccordion>
                <CustomAccordion
                    step={3}
                    title="Triggers"
                    isLast>
                    <FormGroup>
                        <FormLabel>Schedule</FormLabel>
                        <DataScheduleComponent
                            schedule={schedule}
                            selectedSchedule={scheduleType}
                            handleScheduleChange={setSchedule}
                            onChangeSchedule={handleChangeSchedule} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Pipelines and Tasks</FormLabel>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            fullWidth
                            autoHighlight
                            options={[
                                ...pipelines.length
                                    ? pipelines.map((p: IPipeline) => p.name)
                                    : [''],  // dummy option to display loading spinner
                                ...tasks,
                            ]}
                            getOptionDisabled={o => !o || o === pipeline.name}
                            getOptionSelected={(o, v) => isLoadingPipelines && !tasks.includes(o) || o === v}
                            groupBy={o => tasks.includes(o) ? "Tasks" : "Pipelines"}
                            value={triggeredBy}
                            onChange={(e, triggeredBy) => setTriggeredBy(triggeredBy)}
                            renderOption={o => o || <CircularProgress size='1em' />}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant='outlined'
                                />}
                            renderTags={(options, getTagProps) =>
                                options.map((o, i) =>
                                    <Chip
                                        {...getTagProps({ index: i })}
                                        key={o}
                                        label={o}
                                        disabled={isLoadingPipelines && !tasks.includes(o)}
                                    />
                                )}
                        />
                    </FormGroup>
                </CustomAccordion>
            </div>
            <form
                onSubmit={handleSubmit}
                id='create-pipeline'>
                <div className='form-buttons'>
                    <SplitButton
                        disabled={!name || pipeline.isProcessing}
                        options={[{
                            label: "Save",
                            onClick: handleSubmitAsReady
                        }, {
                            label: "Save as draft",
                            onClick: handleSubmitAsDraft
                        }]}
                        SpinnerProps={{ active: pipeline.isProcessing }} />
                    <Button onClick={props.onClose}>
                        Close
                    </Button>
                </div>
            </form>
        </div >
        {!size.smallScreen &&
            <div className='vl-help'></div>}
    </>

}

export default PipelineForm

import { AxiosResponse } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import 'src/assets/css/components/dataRow.css'
import 'src/assets/css/views/formViews.css'
import StoreForm from 'src/components/StoreForm'
import HelpPanel from 'src/components/HelpPanel'
import workspacePlaceholder from 'src/components/placeholders/WorkspacePlaceholder'
import { APIContext, getDataPluginURI, getDatastoreURI } from 'src/contexts/api'
import { MergedContexts } from 'src/contexts/merged'
import { WindowContext } from 'src/contexts/window'
import { WorkspaceContext } from 'src/contexts/workspace'
import IDataPlugin from 'src/models/IDataPlugin'
import IDataStore from 'src/models/IDataStore'
import { IDataStoreEditProps, IDataStoreEditState } from 'src/models/views/IDataStoreEdit'
import { bindContexts } from 'src/utils/bindContexts'

class DataStoreEdit extends React.Component<IDataStoreEditProps, IDataStoreEditState> {
    static contextType = MergedContexts

    public searchParams = new URLSearchParams(location.search)

    constructor(props: IDataStoreEditProps) {
        super(props)
        this.state = {
            isHelpMenuOpen: false
        }

        this.handleOpenHelpPanel = this.handleOpenHelpPanel.bind(this)
        this.handleCloseHelpPanel = this.handleCloseHelpPanel.bind(this)
        this.loadDataPlugin = this.loadDataPlugin.bind(this)
    }

    public componentDidMount() {
        this.props.setViewName?.("Data Stores")
        this.loadDataStore().then(this.loadDataPlugin)
    }

    public handleOpenHelpPanel() {
        this.setState({ isHelpMenuOpen: true })
    }

    public handleCloseHelpPanel() {
        this.setState({ isHelpMenuOpen: false })
    }

    private async loadDataPlugin(dataStore: IDataStore) {
        const { catalogAPI } = this.context

        const dataPluginId = new URLSearchParams(location.search).get('dataplugin')
        const dataPluginHref = dataStore._links.dataplugin?.href || getDataPluginURI(dataPluginId)

        const getDataPlugin = async () => catalogAPI.get(dataPluginHref).then((r: AxiosResponse<IDataPlugin>) => r.data)
        this.setState({ dataPlugin: await getDataPlugin() })
    }

    private async loadDataStore() {
        const { catalogAPI, initialiseDataStore } = this.context
        const dataStoreId = this.props.match.params.datastoreId

        const getDataStore = async () => catalogAPI.get(getDatastoreURI(dataStoreId)).then((r: AxiosResponse<IDataStore>) => r.data)
        const dataStore: IDataStore = await (dataStoreId === undefined ? initialiseDataStore() : getDataStore())
        this.setState({ dataStore: dataStore })

        return dataStore
    }

    public render() {
        const { dataStore, dataPlugin } = this.state
        const isSmallScreen = this.context.size.smallScreen
        const { isHelpMenuOpen } = this.state

        return <>
            <div className='rightside_pageContent editForms'>
                <div className="form-container">
                    {!(dataStore && dataPlugin) ?
                        workspacePlaceholder :
                        <>
                            <div className={`${isSmallScreen ? 'col-12 no-padding-left no-padding-right' : 'col-7 form no-padding-left no-padding-right'} ${isHelpMenuOpen ? 'no-display' : ''}`}>
                                <StoreForm
                                    dataStore={dataStore}
                                    dataPlugin={dataPlugin}
                                    autoValidate={this.searchParams.get('validate')?.toLowerCase() === 'true'}
                                    helpButtonClicked={this.handleOpenHelpPanel}
                                    onSaved={this.props.onSaved}
                                    onClose={this.props.onClose} />
                            </div>
                            {!isHelpMenuOpen ?
                                <>{!isSmallScreen &&
                                    <div className='col help no-padding-left'>
                                        <HelpPanel title="Store" dataPlugin={dataPlugin} />
                                    </div>}
                                </>
                                :
                                <div className="help-info">
                                    <HelpPanel title="Store" dataPlugin={dataPlugin} closeHelpPanel={this.handleCloseHelpPanel}/>
                                </div>}
                        </>}
                </div>
            </div>
        </>

    }

}

export const DataStoreEditWithContext = bindContexts(DataStoreEdit, [
    APIContext,
    WorkspaceContext,
    WindowContext
])

export default withRouter(DataStoreEditWithContext)

import { INamedEntity } from 'src/models/IEntity'

//
// Describes the data plugins from our catalog (e.g. Extractors and Loaders)
//

interface IDataPlugin extends INamedEntity {
    namespace?: string;
    pipUrl?: string;
    capabilities?: string[];

    //
    // These properties have some affect on our UI
    //

    // type (extractor, loader, etc)
    pluginType?: string;

    // display name
    label?: string;

    // logo url
    logoUrl?: string;

    // overview description of plugin
    description: string;

    // description of plugin + descriptions of all settings
    fullDescription: string;

    // display variant
    variant?: string;

    // Matatika or Community supported
    repositoryUrl?: string;

    // link to docs
    docs?: string | null;

    // intelligent and adaptive rendering of fields
    settings: any[];

    // do not display in results
    matatikaHidden?: boolean;

    commands?: { [name: string]: IDataPluginCommand }

}

export default IDataPlugin

export enum SettingKind {
    OAuth = 'OAUTH',
    Hidden = 'HIDDEN',
    Password = 'PASSWORD',
    Date = 'DATE_ISO8601',
    FILE = 'FILE',
    Email = 'EMAIL',
    Integer = 'INTEGER',
    Options = 'OPTIONS',
    Object = 'OBJECT',
    Array = 'ARRAY',
    Boolean = 'BOOLEAN',
    String = 'STRING'
}

export enum SettingValueProcessor {
    UpcaseString = 'UPCASE_STRING',
    NestObject = 'NEST_OBJECT',
    Stringify = 'STRINGIFY'
}

export interface ISettingOAuth {
    provider: string
}

export interface ISettingOptions {
    label: string
    value: string
}

export interface ISetting extends INamedEntity {
    aliases?: string[]
    label?: string
    value?: string
    placeholder?: string
    kind?: SettingKind
    description?: string
    tooltip?: string
    documentation?: string
    protected?: boolean
    hidden: boolean
    sensitive: boolean
    value_processor?: SettingValueProcessor
    value_post_processor?: SettingValueProcessor
    oauth?: ISettingOAuth
    options?: ISettingOptions
    docs?: string
}

export interface IDataPluginCommand {
    args: string
    description?: string
}

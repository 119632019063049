import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { IDataPluginAuthorization } from 'src/models/components/IDataPluginAuthorization'
import OAuth2Login from 'react-simple-oauth2-login';
import { APIContext } from 'src/contexts/api';
import { logError } from 'src/utils/Error';
import { useGoogleLogin } from '@react-oauth/google'
import { Button } from '@material-ui/core';

/*
 * Manages 'authorization_code' or 'implicit' (a.k.a. 'token' flow) grant flows.
 */
const DataPluginAuthorization: FunctionComponent<IDataPluginAuthorization> = (props: PropsWithChildren<IDataPluginAuthorization>) => {
    const {
      prefix,
      properties,
      errors,
      handleSettingsUpdate,
      consentRequiredCallback
    } = props;
    const { catalogAPI } = useContext(APIContext);

    // flag indicating authorization required and consent given
    const [authorizationState, setAuthorizationState] = useState({
        isAuthorizationRequired: false,
        isAuthorized: false,
    });

    // the URL of the identity providers authorization endpoint
    const authorizationUrl = properties[prefix ? `${prefix}.oauth_credentials.authorization_url` : 'oauth_credentials.authorization_url']
    // 'code' - from 'authorization_code' identity provider response
    const [authoriztionCode, setAuthorizationCode] = useState("")
    // 'access_token' - from 'implicit' identity provider response
    const [accessToken, setAccessToken] = useState(null)

    const connectToGoogle = useGoogleLogin({
      flow: 'auth-code',
      onSuccess: r => setAuthorizationCode(r.code),
      onError: logError,
      scope: properties[prefix ? `${prefix}.oauth_credentials.scope` : 'oauth_credentials.scope']
    })

    const connectDisconnect = () => {
      if (!authorizationState.isAuthorized) {
        connectToGoogle()
        return
      }

      setAuthorizationState({
        ...authorizationState,
        isAuthorized: false
      })

      // remove any associated properties
      const filtered = Object.entries(properties)
        .filter(([k]) => !!prefix && !k.startsWith(prefix))
        .reduce((obj, [k, v]) => ({...obj, [k]: v}), {})

      handleSettingsUpdate(filtered)
    }
    /*
     * OAuth 'implicit' response
     */
    const handleImplicitResponse = (response:any) => {
      if (response.access_token) {
        setAccessToken(response.access_token)
      } else {
        logError(response)
      }
    }

    /*
     * Exchange the authorization_code, received following user consent, for a
     * access_token / refresh_token to be used to call the request API. 
     */
    const exchangeCode = async (code:any) => {
      const exchangeBody = {
        grant_type: 'authorization_code',
        code: code,
      }
      await catalogAPI
          .post(encodeURI(`/tokens/oauth2-google/token`), exchangeBody, { errorHandler: false })
          .then((resp: any) => {
            setAuthorizationCode("")
            const data = resp.data;
            properties[prefix ? `${prefix}.oauth_credentials.access_token` : 'oauth_credentials.access_token'] = data.access_token
            properties[prefix ? `${prefix}.oauth_credentials.refresh_token` : 'oauth_credentials.refresh_token'] = data.refresh_token
            handleSettingsUpdate(properties)
          })
          .catch((e: any) => {
            logError(e);
          });
    }

    let authorizeButton = null

    if (authorizationUrl === 'https://oauth2.googleapis.com/token') {
      authorizeButton =
        <Button
          onClick={connectDisconnect}>
          {!authorizationState.isAuthorized ? "Connect to Google" : "Disconnect"}
        </Button>

    } else if (properties[prefix ? `${prefix}.oauth_credentials.grant_type` : 'oauth_credentials.grant_type'] == 'implicit') {
      authorizeButton = (
        <OAuth2Login
          authorizationUrl={authorizationUrl}
          responseType="token"
          clientId={properties[prefix ? `${prefix}.oauth_credentials.client_id` : 'oauth_credentials.client_id']}
          scope={properties[prefix ? `${prefix}.oauth_credentials.scope` : 'oauth_credentials.scope']}
          redirectUri={properties[prefix ? `${prefix}.oauth_credentials.redirect_uri` : 'oauth_credentials.redirect_uri']}
          onSuccess={handleImplicitResponse}
          onFailure={handleImplicitResponse}/>
      )
    } else {
      authorizeButton = (<div>Authorization url not supported: {authorizationUrl}</div>)
    }

    const content = (
      <>
        { authorizationState.isAuthorizationRequired ? authorizeButton : null}
        { errors && errors.length
            && errors.find((error) => error.field == (prefix ? `properties.${prefix}.oauth_credentials.access_token` : 'properties.oauth_credentials.access_token')
                                        || error.field == (prefix ? `properties.${prefix}.oauth_credentials.refresh_token` : 'properties.oauth_credentials.refresh_token')) 
            ? (
            <p className="validation_msg">
                Data source must be authorized
            </p>
        ) : null}
      </>
    )

    useEffect(() => {
        if (authoriztionCode) {
          if (authorizationUrl === 'https://oauth2.googleapis.com/token') {
            // we have a code, but have not exchanged it for a refresh_token yet
            exchangeCode(authoriztionCode)
          }
        }
    }, [authoriztionCode]);

    useEffect(() => {
        if (accessToken) {
          if (authorizationUrl === 'https://auth.zappforms.com/connect/authorize') {
            // we have a Solarvista access_token, but have not exchanged this for clientId and code yet
            setAccessToken(null)
            properties[prefix ? `${prefix}.clientId` : 'clientId'] = 'todo'
            properties[prefix ? `${prefix}.code` : 'code'] = 'todo'
            handleSettingsUpdate(properties)
          }
        }
    }, [accessToken]);

    useEffect(() => {
      // the scope to be requested for this grant request
      const hasScope = !!properties[prefix ? `${prefix}.oauth_credentials.scope` : 'oauth_credentials.scope']
      // the token to request an access token
      const hasRefreshToken = !!properties[prefix ? `${prefix}.oauth_credentials.refresh_token` : 'oauth_credentials.refresh_token']

      const isRequired = !!authorizationUrl && hasScope
      setAuthorizationState({isAuthorizationRequired: isRequired, isAuthorized: hasRefreshToken})
      consentRequiredCallback(isRequired && !hasRefreshToken)
    }, [properties]);

    return content;
};

export default DataPluginAuthorization;

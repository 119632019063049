import React from 'react'
import Col from 'react-bootstrap/Col'
import ContentLoader from 'react-content-loader'

const pipelinesPlaceholder = (
    <>
        <div className="dataLoader_parent d-flex" data-testid="pipelines-placeholder">
            <Col className="dataLoader">
                <ContentLoader className="boxload" height="10%" width={'100%'} viewBox="0 0 1000 50" speed={2}>
                    <rect x="0" y="20" width="85%" height="30" />
                    <rect x="90%" y="20" width="100" height="30" />
                </ContentLoader>
                <ContentLoader
                    speed={2}
                    width={'100%'}
                    height={150}
                    viewBox="0 0 1000 90"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="7" y="2" rx="0" ry="0" width="80" height="80" />
                    <rect x="105" y="2" rx="0" ry="0" width="80" height="80" />
                    <rect x="198" y="20" rx="0" ry="0" width="40%" height="20" />
                    <rect x="300" y="50" rx="0" ry="0" width="40" height="16" />
                    <rect x="88%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="92%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="96%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="206" y="50" rx="0" ry="0" width="40" height="16" />
                    <rect x="255" y="50" rx="0" ry="0" width="40" height="16" />
                </ContentLoader>
                <ContentLoader
                    speed={2}
                    width={'100%'}
                    height={150}
                    viewBox="0 0 1000 90"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="7" y="2" rx="0" ry="0" width="80" height="80" />
                    <rect x="105" y="2" rx="0" ry="0" width="80" height="80" />
                    <rect x="198" y="20" rx="0" ry="0" width="40%" height="20" />
                    <rect x="300" y="50" rx="0" ry="0" width="40" height="16" />
                    <rect x="88%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="92%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="96%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="206" y="50" rx="0" ry="0" width="40" height="16" />
                    <rect x="255" y="50" rx="0" ry="0" width="40" height="16" />
                </ContentLoader>
                <ContentLoader
                    speed={2}
                    width={'100%'}
                    height={150}
                    viewBox="0 0 1000 90"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="7" y="2" rx="0" ry="0" width="80" height="80" />
                    <rect x="105" y="2" rx="0" ry="0" width="80" height="80" />
                    <rect x="198" y="20" rx="0" ry="0" width="40%" height="20" />
                    <rect x="300" y="50" rx="0" ry="0" width="40" height="16" />
                    <rect x="88%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="92%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="96%" y="20" rx="0" ry="0" width="20" height="20" />
                    <rect x="206" y="50" rx="0" ry="0" width="40" height="16" />
                    <rect x="255" y="50" rx="0" ry="0" width="40" height="16" />
                </ContentLoader>
            </Col>
        </div>
    </>
)

export default pipelinesPlaceholder

import { Chart } from 'chart.js';
import Metadata from 'src/components/charts/metadata/Metadata'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const buildChartOptions = (
  metadata: Metadata,
  chartType: any,
  newOptions: any,
  legend: boolean,
  drilldown: boolean,
  chartReference?: any,
  tooltipReference?: any,
  handleOnClick?: (event: React.MouseEvent<HTMLElement>) => void
): any => {

  // Setup default options if none supplied
  if (!newOptions) {
    newOptions = {}
  }
  // Setup default plugins if none supplied
  if (!newOptions.plugins) {
    newOptions.plugins = {}
  }

  // bind on click handling
  newOptions.onClick = handleOnClick

  // bind onHover if drilldown is enabled
  newOptions.onHover = (e: any, elements: any) => {
    const tooltipEl = tooltipReference.current
    const tableRoot = tooltipEl.querySelector('table')

    const drilldownLinks =
      metadata && metadata.getDrilldownLinks ? metadata.getDrilldownLinks() : []

    if (elements.length === 0 && !tooltipEl.matches(':hover')) {
      tableRoot.innerHTML = ''
      tableRoot.id = 'chartjs-tooltip'
      tooltipEl.style.visibility = drilldownLinks.length > 0 ? 'visible' : 'hidden'
      tooltipEl.style.opacity = 1
      tooltipEl.style.left = e.x + 30 + 'px'
      tooltipEl.style.top = e.y + 40 + 'px'
      tooltipEl.style.padding = 12
      tooltipEl.classList.add('open')
      tooltipEl.classList.remove('closed')
      tooltipEl.dataset.datasetidx = '-1'
    }

    const element = chartReference.current?.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false)
    if (element && element.length > 0) {
      const idx = element[0].index
      tooltipEl.dataset.datasetidx = idx
      tooltipEl.style.visibility = 'visible'
    }
  }

  const externalTooltipHandler = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = tooltipReference.current

    // Hide if no tooltip
    if (tooltipEl.dataset.datasetidx < 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const tableHead = document.createElement('thead');

      titleLines.forEach((title: any) => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = "0";

        const th = document.createElement('th');
        th.style.borderWidth = "0";
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body: any, i: number) => {
        const colors = tooltip.labelColors[i];

        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';

        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = "0";

        const td = document.createElement('td');
        td.style.borderWidth = "0";

        const text = document.createTextNode(body);

        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  }

  // Custom tooltip
  newOptions.plugins.tooltip = {
    enabled: false,
    external: externalTooltipHandler,
  }

  // enable datalabels for pie and doughnut charts by default, disable by default for all other charts
  if (chartType === 'pie' || chartType === 'doughnut') {
    newOptions.plugins.datalabels = { display: true }
  } else if (!newOptions.plugins.datalabels) {
    newOptions.plugins.datalabels = { display: false }
  }

  // never display the legend on treemap, uses a single dataset so legend is pointless
  if (chartType === 'treemap') {
    newOptions.plugins.legend = { display: false }
    newOptions.plugins.tooltip.callbacks = {
      title: (items: any) => items.map((item: any) => item.dataset.label),
      label: (tooltipCtx: any) => tooltipCtx.raw.s
    }
    newOptions.maintainAspectRatio = false

    const noDataPlugin = {
      id: "noDataPlugin",
      afterDraw: (chart: any) => {
        const datasets = chart.data.datasets
        if (datasets.length > 0) {
          if (datasets[0].data.every((item: any) => item == null || item.s === 0)) {
            const ctx = chart.ctx
            const width = chart.width
            const height = chart.height

            chart.clear()
            ctx.save()
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fontSize = 20
            ctx.fillStyle = '#191919'
            ctx.fillText('No data to display', width / 2, height / 2)
            ctx.restore()
          }
        }
      }
    }
    Chart.register(noDataPlugin)
  } else {
    // push legend option into all charts to ensure related datasets are compact
    if (legend)
      newOptions.plugins.legend = legend
  }
  return newOptions
}

import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { AxiosInstance, AxiosResponse } from 'axios'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, InputAdornment, TextField } from '@material-ui/core'
import copyIcon from 'src/assets/images/copyIcon.png'
import { APIContext, getAllApiKeysURI } from 'src/contexts/api'
import { bindContexts } from 'src/utils/bindContexts'
import { INewApiKeyDialogProps } from 'src/models/components/INewApiKeyDialog'
import { logError } from 'src/utils/Error'
import Spinner from 'src/components/Spinner'
import IApiKey from 'src/models/IApiKey'

const NewApiKeyDialog: FunctionComponent<INewApiKeyDialogProps> = (props: PropsWithChildren<INewApiKeyDialogProps>) => {

    const isMounted = useRef(true)

    const [isCreateReady, setCreateReady] = useState(false)
    const [apiKey, setApiKey] = useState<IApiKey>({} as any)

    const { catalogAPI } = useContext(APIContext)
    const api = catalogAPI as AxiosInstance

    const initialiseNewApiKey = async () => {
        await api.post(getAllApiKeysURI())
            .then((r: AxiosResponse<IApiKey>) => {
                if (isMounted.current) {
                    setCreateReady(true)
                    setApiKey(r.data)
                }
            })
            .catch(logError)
    }

    const createApiKey = async () => {
        await api.put(apiKey._links['create apikey'].href, apiKey)
            .then((r: AxiosResponse<IApiKey>)  => {
                setApiKey(r.data)
                setCreateReady(true)
            })
            .catch(logError)
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setApiKey({ ...apiKey, name: e.target.value })
    }

    const handleCreate = () => {
        setCreateReady(false)
        createApiKey()
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') return
        const target = (e.target as HTMLInputElement)
        target.blur()
        if (target.value) handleCreate()
    }

    useEffect(() => {
        initialiseNewApiKey()
        return () => { isMounted.current = false }
    }, [])

    useEffect(() => {
        if (apiKey.clientId) props.handleAddApiKey(apiKey)
    }, [apiKey])

    return (
        <Dialog
            open
            onClose={props.handleClose}>
            <DialogTitle>New Personal Access Token</DialogTitle>
            <DialogContent>
                <TextField
                    id='new-apikey-name'
                    fullWidth
                    autoFocus
                    required
                    variant="outlined"
                    margin="dense"
                    label="Name"
                    placeholder="API key name"
                    onKeyPress={handleKeyPress}
                    onChange={handleNameChange}
                    InputProps={{ readOnly: !!apiKey.clientId }}
                />
                <CopyToClipboard
                    text={apiKey.clientId!}
                    onCopy={props.handleCopyClientCredentials}>
                    <TextField
                        id='new-apikey-client-id'
                        className="pointer"
                        fullWidth
                        disabled={!apiKey.clientId}
                        variant="outlined"
                        margin="dense"
                        value={apiKey.clientId || ''}
                        label="Client ID"
                        InputProps={{
                            readOnly: true,
                            endAdornment:
                                <>
                                    {apiKey.clientId &&
                                        <InputAdornment position="end">
                                            <img src={copyIcon} width={20} height={20} />
                                        </InputAdornment>
                                    }
                                </>
                        }} />
                </CopyToClipboard>
                <CopyToClipboard
                    text={apiKey.clientSecret!}
                    onCopy={props.handleCopyClientCredentials}>
                    <TextField
                        id='new-apikey-client-secret'
                        className="pointer"
                        fullWidth
                        disabled={!apiKey.clientSecret}
                        variant="outlined"
                        margin="dense"
                        value={apiKey.clientSecret || ''}
                        label="Client secret"
                        helperText={apiKey.clientSecret ? "Your client secret will not be shown again" : ' '}
                        InputProps={{
                            readOnly: true,
                            endAdornment:
                                <>
                                    {apiKey.clientSecret &&
                                        <InputAdornment position="end">
                                            <img src={copyIcon} width={20} height={20} />
                                        </InputAdornment>
                                    }
                                </>
                        }}
                        FormHelperTextProps={{
                            style: { color: '#f29048' }
                        }} />
                </CopyToClipboard>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={!isCreateReady || !apiKey.name || !!apiKey.clientId}
                        onClick={handleCreate}>
                        <Spinner active={!isCreateReady}>
                            Create
                        </Spinner>
                    </Button>
                    <Button
                        onClick={props.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog >
    )

}

export const NewApiKeyDialogWithContext = bindContexts(NewApiKeyDialog, [
    APIContext
])

export default NewApiKeyDialog

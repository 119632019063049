import { AxiosInstance } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Feed from 'src/components/Feed'
import Workbench from 'src/components/Workbench'
import { logError } from 'src/utils/Error'
import { APIContext, getWorkspaceTagsURI } from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { IExploreProps, IExploreState } from '../models/views/IExplore'
import { bindContexts } from '../utils/bindContexts'
import history from '../utils/history'
import PageTitle from 'src/components/PageTitle'

class Explore extends React.Component<IExploreProps, IExploreState> {
    static contextType = MergedContexts;
    public isThisMounted = false;
    public prev = 0;
    constructor (props: IExploreProps) {
      super(props)
      this.state = {
        ready: false,
        showTag: true
      }
      this.loadMore = this.loadMore.bind(this)
      this.handleScroll = this.handleScroll.bind(this)
      this.handleTagFilter = this.handleTagFilter.bind(this)
      this.getQueryStringValue = this.getQueryStringValue.bind(this)
      this.refreshData = this.refreshData.bind(this)
      this.updateDatasets = this.updateDatasets.bind(this)
    }

    public componentDidMount () {
      const { searchResults, fetchFeeds, workspace, handleSearchResults } = this.context
      this.isThisMounted = true
      this.props.setViewName && this.props.setViewName(this.props.viewName ? this.props.viewName : "Explore")
      if (
        this.isThisMounted &&
            (this.props.history.action === 'PUSH' || searchResults.hits === -1)
      ) {
        this.refreshData()
      } else {
        this.setState({ ready: true })
        fetchFeeds(workspace.id, searchResults, handleSearchResults)
      }
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 200)
      window.addEventListener('scroll', this.handleScroll, true)
    }

    private handleScroll (e: any) {
      const targetWindow = e.currentTarget
      const element = document.getElementsByClassName('toolbar')
      if (targetWindow.scrollY >= 100) {
        if (this.prev > targetWindow.scrollY) {
          element[0].classList.remove('hide')
          element[0].classList.add('show')
          if (!this.state.showTag) this.setState({ showTag: true })
        } else if (this.prev < targetWindow.scrollY) {
          element[0].classList.remove('show')
          element[0].classList.add('hide')
          if (this.state.showTag) this.setState({ showTag: false })
        }
        this.prev = targetWindow.scrollY
      } else {
        element[0].classList.remove('hide')
        element[0].classList.add('show')
        if (!this.state.showTag) this.setState({ showTag: true })
      }
    }

    public componentWillUnmount () {
      this.isThisMounted = false
      window.removeEventListener('scroll', this.handleScroll, true)
    }

    private refreshData () {
      this.getTagsData()
      this.handleTagFilter(null)
    }

    private async getTagsData () {
      const { workspace, catalogAPI, setTag } = this.context
      const api = catalogAPI as AxiosInstance
      // component has loaded, refresh tags
      if (workspace) {
        this.setState({ ready: false })
        await api
          .get(getWorkspaceTagsURI(workspace.id))
          .then(resp => {
            if (resp.data._embedded) {
              const query = this.getQueryStringValue('search', this.props)
              let selectedTag = null
              if (query) {
                let tag_name = ''
                const query_array = query.replace(/"/g, '').split(':')
                if (query_array[1] && query_array[0] === 'tag') {
                  tag_name = query_array[1].replace('-', ' ')
                }

                resp.data._embedded.tags.forEach(function (tag: any) {
                  if (tag.name === tag_name) {
                    selectedTag = tag
                  }
                })
              }
              setTag({ tagList: resp.data._embedded.tags, selectedTag: selectedTag })
            }
          })
          .catch(e => logError(e))
        this.setState({ ready: true })
      }
    }

    public componentDidUpdate (prevProps: IExploreProps) {
      if (this.props.viewName != prevProps.viewName) {
        this.props.setViewName && this.props.setViewName(this.props.viewName ? this.props.viewName : "Explore")
      }
    }

    public shouldComponentUpdate (nextProps: IExploreProps, nextState: IExploreState): boolean {
      this.props.match.params.q
      if (JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
            JSON.stringify(nextState) !== JSON.stringify(this.state)
      ) { return true }

      return false
    }

    getQueryStringValue (key: any, theProps: any) {
      const url = theProps && theProps.location.search ? theProps.location.search : ''
      if (url) {
        const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)')
        const results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return null
        return decodeURIComponent(results[2].replace(/\+/g, ' ')).toLocaleLowerCase()
      } else {
        return null
      }
    }

    private updateDatasets () {
      const { searchResults, handleSearchResults, updateDatasets } = this.context
      updateDatasets(searchResults, handleSearchResults)
    }

    loadMore (nextUrl: any) {
      const { searchResults, setSearchResults, search, workspace, handleSearchResults } =
            this.context
      setSearchResults({
        datasets: searchResults.datasets,
        fetchtime: searchResults.fetchtime,
        hits: searchResults.hits,
        nextPageUrl: searchResults.nextPageUrl,
        isLoading: searchResults.isLoading,
        isMoreLoading: true,
        query: searchResults.query
      })
      if (nextUrl) {
        search(workspace.id, handleSearchResults, searchResults, nextUrl, '')
      }
    }

    private handleTagFilter (selectedTag: any) {
      const {
        workspace,
        search,
        searchResults,
        setSearchResults,
        handleSearchResults,
        tag,
        setTag
      } = this.context
      if (!searchResults.isLoading) {
        setSearchResults({ isLoading: true })
        const tagList = tag.tagList
        let query = this.getQueryStringValue('search', this.props)

        if (selectedTag) {
          if (tag.selectedTag === selectedTag) {
            // toggle, deselect tag
            setTag({ tagList: tagList, selectedTag: null })
            query = ''
            history.push({ pathname: '/l/' + workspace.id + '/explore' })
          } else {
            setTag({ tagList: tagList, selectedTag: selectedTag })
            const query_str = selectedTag.name.replace(/\s/g, '-')
            query = 'tag:' + query_str
            history.push({ pathname: '/l/' + workspace.id + '/explore', search: 'search="' + query + '"'})            
          }
        } else {
            if (query) {
                const query_array = query.replace(/"/g, '').split(':')
                if (query_array[1] && query_array[0] === 'tag') {
                  const tagName = query_array[1].replace('-', ' ')
                  setTag({ tagList: tagList, selectedTag: { name: tagName } })
                  query = 'tag:' + tagName
                }
            }
          
        }

        search(workspace.id, handleSearchResults, null, null, query)
      }
    }

    public render () {
      const { searchResults, tag, showNewInsight } = this.context
      const { isLoading, query, datasets, fetchtime, hits, nextPageUrl, isMoreLoading } =
            searchResults
      const { ready } = this.state
      const { tagList, selectedTag } = tag
      const settings = {
        className: 'buttonListSlider position-relative',
        dots: false,
        infinite: false,
        centerMode: false,
        variableWidth: true,
        swipeToSlide: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }

      let tagView = null
      if (tagList && tagList.length > 0) {
        const rows: any[] = []
        tagList.forEach((tag: any, index: any) => {
          let disableClass = ''
          if (selectedTag && selectedTag.name === tag.name) {
            disableClass = 'active'
          }

          rows.push(
                    <button
                        key={index}
                        data-testid={tag.id}
                        className={disableClass + ' buttonListItem cursor-pointer'}
                        onClick={() => this.handleTagFilter(tag)}
                    >
                        {tag.name}
                    </button>
          )
        })
        tagView = rows.length > 0 ? rows : ''
      }

      return (
            <Workbench
                menu='app'
                isExpandedSidebarActive={this.props.isExpandedSidebarActive}
                isSidebarLayout={this.props.isSidebarLayout}
                toggleSidebar={this.props.toggleSidebar}
                isStickySearch>
                <div className={'rightside_pageContent'}>
                    {this.props.isSidebarLayout && 
                    <PageTitle title={this.context.workspace.appProperties?.EXPLORE_PAGE_TITLE || 'Explore'} /> }
                    {ready && tagList && tagList.length > 0 && (
                        <>
                            {/* <ScrollAnimation animateIn='fadeIn' offset ={10}> */}
                            <div
                                className={
                                    'clearfix' +
                                    (this.state.showTag ? ' show' : ' hide')
                                }
                            >
                                <div className="roundedListItemSlider_holder  position-relative">
                                    {' '}
                                    {/* to add vertical dot at right 'SliderWith_rightSide_verticalDots' */}
                                    <Slider {...settings}>{tagView}</Slider>
                                </div>
                            </div>
                            {/* </ScrollAnimation> */}
                        </>
                    )}
                    <Feed
                        key={query}
                        datasets={datasets}
                        isLoading={isLoading}
                        loadMore={this.loadMore}
                        currentSearch={query}
                        hits={hits}
                        fetchtime={fetchtime}
                        nextPageUrl={nextPageUrl}
                        isMoreLoading={isMoreLoading}
                        refreshData={this.refreshData}
                        showNewInsight={showNewInsight}
                        updateDatasets={this.updateDatasets}
                    />
                    {this.props.children}
                </div>
            </Workbench>
      )
    }
}

export const ExploreWithContext = bindContexts(withRouter(Explore), [
  Auth0Context,
  APIContext,
  WorkspaceContext
])
export default ExploreWithContext

import React, { useState, FunctionComponent } from 'react'
import closeIcon from '../assets/images/close.svg'
import share from '../assets/images/shareIcon.png'
import image from '../assets/images/logo.svg'

export const InstallPWA: FunctionComponent<any> = () => {
  const [modalOpen, setModalOpen] = useState(true)
  const setCloseModalOpen = () => {
    localStorage.setItem('installPrompt', 'true')
    setModalOpen(false)
  }
  return (
        <div className={modalOpen ? 'notification-card-parent' : 'd-none'}>
            <div className="notification-card position-relative">
                <button
                    onClick={setCloseModalOpen}
                    className="close-notification position-absolute"
                >
                    <img src={closeIcon} alt="close" />
                </button>
                <div className="d-flex ">
                    <div className="col-auto ">
                        <img src={image} className="notification-logo" alt="Matatika" />
                    </div>

                    <div className="col pad_l0 pad_r30 align-self-center">
                        <span>Install MATATIKA App on your phone by tapping the </span>
                        <img
                            src={share}
                            className="d-inline-block shareIcon"
                            alt="Add to homescreen"
                        />
                        <span> icon and then tapping Add to Home Screen</span>
                    </div>
                </div>
                <div className="popper-triangle-bottom" />
            </div>
        </div>
  )
}

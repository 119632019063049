import React, { FunctionComponent, PropsWithChildren, ReactNode, useContext, useState } from 'react'
import ICustomDataPluginDialogProps from 'src/models/components/ICustomDataPluginDialog'
import IPage from 'src/models/IPage'
import IDataPlugin from 'src/models/IDataPlugin'
import { APIContext } from 'src/contexts/api'
import { AxiosError, AxiosResponse } from 'axios'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import Spinner from 'src/components/Spinner'
import { logError } from 'src/utils/Error'
import { WorkspaceContext } from 'src/contexts/workspace'

const supportedUploadFormats = ['yaml', 'yml']

const CustomDataPluginDialog: FunctionComponent<ICustomDataPluginDialogProps> = (props: PropsWithChildren<ICustomDataPluginDialogProps>) => {

    const { isOpen, handleUpdateDataPlugins, handleSelectDataPlugin, handleClose } = props

    const { catalogAPI } = useContext(APIContext)
    const { workspace } = useContext(WorkspaceContext)

    const [fileName, setFileName] = useState("")
    const [discoveryContent, setDiscoveryContent] = useState("")
    const [errorMessage, setErrorMessage] = useState<ReactNode>(' ')
    const [isNextReady, setNextReady] = useState(true)

    const reader = new FileReader()
    reader.onload = e => setDiscoveryContent(e.target?.result as string)

    const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0] as File
        reader.readAsText(selectedFile)
        setFileName(selectedFile.name)
    }

    const UploadButton = () => (
        <Button
            variant='contained'
            component='label'>
            <FolderIcon />
            <input
                type='file'
                hidden
                accept={supportedUploadFormats.map(e => '.' + e).join(',')}
                onChange={handleSelectFile} />
        </Button>
    )

    const createDataplugins = () => {

        const config = {
            headers: {
                'Content-Type': 'text/x-yaml'
            },
            errorHandler: false
        }

        catalogAPI.post(workspace._links['publish discovery.yml'].href, discoveryContent, config)
            .then((r: AxiosResponse<IPage<IDataPlugin>>) => {

                if (r.status == 204) return setErrorMessage("No Meltano plugins found")

                const dataplugins = r.data._embedded?.dataplugins.filter(dp => !dp.matatikaHidden)
                const extractors = dataplugins?.filter(dp => dp.pluginType === 'EXTRACTOR')

                if (extractors?.length == 1) {
                    handleSelectDataPlugin(extractors[0])
                } else {
                    handleUpdateDataPlugins(...(extractors || []))
                }
                handleClose()

            })
            .catch((e: AxiosError) => {
                const response = e.response as AxiosResponse<any>
                if (response.status != 400) logError(e)

                const errors = response.data.errors as any[]
                if (!errors) {
                    setErrorMessage(response.data.message)
                } else {
                    const errorLines = errors.map((v, i) => `${v.objectName}[${i}].${v.field} - ${v.defaultMessage}`)
                    setErrorMessage(
                        <>
                            {errorLines.map((l, i) =>
                                <React.Fragment key={i} >
                                    {l}
                                    < br />
                                </React.Fragment>
                            )}
                        </>
                    )
                }

            })
            .finally(() => setNextReady(true))

    }

    const handleChangeContent = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMessage(' ')
        setDiscoveryContent(e.target.value)
    }
    const handleClickReset = () => {
        setFileName("")
        setDiscoveryContent("")
        setErrorMessage(' ')
    }
    const handleClickNext = () => {
        setNextReady(false)
        setErrorMessage(' ')
        createDataplugins()
    }

    return (
        <Dialog
            open={isOpen || false}
            fullWidth
            onClose={handleClose}>
            <DialogTitle>Add custom plugin(s)</DialogTitle>
            <DialogContent>
                <DialogContentText>Add custom plugin definitions from a Meltano discovery.yml file</DialogContentText>
                <Box marginBottom={2}>
                    <Grid container>
                        <Grid
                            item
                            xs={10}
                            container
                            alignItems='center'>
                            <TextField
                                fullWidth
                                placeholder="Select a file..."
                                value={fileName}
                                InputProps={{ readOnly: true }}>
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            xs
                            container
                            alignItems='center'
                            justifyContent='flex-end'>
                            <UploadButton />
                        </Grid>
                    </Grid>
                </Box>
                <TextField
                    id='discovery-content'
                    fullWidth
                    multiline
                    rows={16}
                    label="discovery.yml"
                    variant='outlined'
                    value={discoveryContent}
                    error={typeof errorMessage === 'string' ? !!errorMessage.trim() : true}
                    helperText={errorMessage}
                    onChange={handleChangeContent}>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    onClick={handleClickReset}
                    disabled={!fileName && !discoveryContent || !isNextReady}>
                    Reset
                </Button>
                <Button
                    variant='contained'
                    disabled={!discoveryContent || !isNextReady}
                    onClick={handleClickNext}>
                    <Spinner active={!isNextReady}>
                        Next
                    </Spinner>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomDataPluginDialog

import React, { useEffect, useRef, useState } from 'react'
import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import { Tab, Tabs } from '@material-ui/core'
import DataPluginRow, { CustomDataPluginRow } from 'src/components/DataPluginRow'
import { DataPluginSearch } from 'src/components/Search'
import { useAPIContext } from 'src/contexts/api'
import { useWorkspaceContext } from 'src/contexts/workspace'
import EntityRel from 'src/models/EntityRel'
import IDataPlugin from 'src/models/IDataPlugin'
import IPage from 'src/models/IPage';
import { logError } from 'src/utils/Error';
import history from 'src/utils/history'

const Plugins = (): JSX.Element => {

    const INSTALLED_TAB = 0
    const AVAILABLE_TAB = 1
    const CUSTOM_TAB = 2

    const totalPlugins = useRef<number>()
    const cancelTokenSourceRef = useRef<CancelTokenSource>()

    const { catalogAPI } = useAPIContext()
    const { workspace } = useWorkspaceContext()

    const [dataPlugins, setDataPlugins] = useState<IDataPlugin[]>([])
    const [activeTab, setActiveTab] = useState(Number(new URLSearchParams(location.search).get('tab')) || 0)
    const [searchPluginName, setSearchPluginName] = useState('')

    const onTabChange = (event: React.ChangeEvent<unknown>, tab: number) => setActiveTab(tab)

    const getDataPlugins = async (type: string | null, name: string) => {
      cancelTokenSourceRef.current?.cancel()
      cancelTokenSourceRef.current = axios.CancelToken.source()

      return workspace._links && workspace._links['availabledataplugins'] &&
        catalogAPI.get(workspace._links['availabledataplugins'].href + encodeURI('?sort=label&size=20&name='+name + (type ? '&type='+type : '')), { cancelToken: cancelTokenSourceRef.current.token })
          .then((r: AxiosResponse<IPage<IDataPlugin>>) => {
            totalPlugins.current = r.data.page.totalElements
            return r.data._embedded?.dataplugins.filter(dp => !dp.matatikaHidden)
          })
          .catch(logError)
    }
  
    const getWorkspaceDataPlugins = async (type:string | null) =>
      workspace._links && workspace._links['dataplugins'] && 
        catalogAPI.get(workspace._links['dataplugins'].href + '?sort=label&size=9999')
          .then((r: AxiosResponse<IPage<IDataPlugin>>) => {
            totalPlugins.current = r.data.page.totalElements
            return r.data._embedded?.dataplugins.filter(dp => !dp.matatikaHidden && (!type || type === dp.pluginType))
          })
          .catch(logError)

    useEffect(() => {
        const type = new URLSearchParams(location.search).get('type')
        activeTab == AVAILABLE_TAB && getDataPlugins(type, searchPluginName).then(setDataPlugins)
    }, [activeTab, searchPluginName])

    useEffect(() => {
        const type = new URLSearchParams(location.search).get('type')
        activeTab == INSTALLED_TAB && getWorkspaceDataPlugins(type).then(setDataPlugins)
    }, [activeTab])

    const handleUpdated = (...newDataPlugins: IDataPlugin[]) => setDataPlugins([...dataPlugins, ...newDataPlugins])
    const handleDeleted = (dataPlugin: IDataPlugin) => setDataPlugins(dataPlugins.filter(dp => dp.id !== dataPlugin.id))
    
    const handleInstall = async (dataPlugin: IDataPlugin) => {
      dataPlugin && dataPlugin._links['add dataplugin']
        && await catalogAPI.put(dataPlugin._links['add dataplugin'].href).then((r: AxiosResponse<IDataPlugin>) => r.data) 
        && setActiveTab(INSTALLED_TAB)
    }
    
    const handleConnect = (dataPlugin: IDataPlugin) => {
      const params = new URLSearchParams({ [EntityRel.DataPlugin.item]: dataPlugin.id })
  
      const entityRel = (() => {
          switch (dataPlugin.pluginType) {
              case 'EXTRACTOR':
                  return EntityRel.Pipeline
  
              case 'LOADER':
                  return EntityRel.DataStore
  
              default:
                  return
          }
      })()
  
      entityRel ? history.push(`/lab/${workspace.id}/${entityRel?.collection}/new?${params}`) 
        : alert(`Add component not supported.  \rPlease 'meltano add ${new String(dataPlugin.pluginType).toLowerCase()} ${dataPlugin.name}'`)
  
    }

    return <>
        <div className='rightside_pageContent data-page'>
            <Tabs
                indicatorColor='primary'
                variant='scrollable'
                value={activeTab}
                onChange={onTabChange}
            >
                <Tab key="installed" label="Installed" />
                <Tab key="available" label="Available" />
                <Tab key="custom" label="Custom" />
            </Tabs>
            {activeTab === INSTALLED_TAB &&
                <DataPluginSearch
                    entities={dataPlugins}
                    customSearchPlaceholder={`Search ${totalPlugins.current} plugins...`}
                    row={e =>
                        <DataPluginRow
                            key={e.id}
                            dataPlugin={e}
                            handleAdd={handleConnect}
                            handleDeleted={handleDeleted} />} />}
            {activeTab === AVAILABLE_TAB &&
                <DataPluginSearch
                    disableSearch
                    onValueChanged={(v) => setSearchPluginName(v)}
                    entities={dataPlugins}
                    customSearchPlaceholder={`Search ${totalPlugins.current} plugins...`}
                    row={e =>
                        <DataPluginRow
                            key={e.id}
                            dataPlugin={e}
                            handleInstall={handleInstall}
                            handleDeleted={handleDeleted} />} />}
            {activeTab === CUSTOM_TAB &&
                <CustomDataPluginRow
                    isExpanded={true}
                    handleUpdateDataPlugins={handleUpdated}
                    handleSelectDataPlugin={handleConnect} />}
        </div>
    </>

}

export default Plugins

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import 'src/assets/css/charts/HtmlMetric.css'
import Metadata from 'src/components/charts/metadata/Metadata'
import { getRgbaString } from 'src/components/charts/metadata/palettes'
import { useAPIContext } from 'src/contexts/api'
import { HtmlMetricProps } from 'src/models/charts/html/HtmlMetric'
import { parseJsonSwallowError } from 'src/utils/charts/chartUtils'

const HtmlMetric: FunctionComponent<HtmlMetricProps> = props => {
  const { catalogAPI } = useAPIContext()
  const {
    getData,
    rawData,
    metadata,
  } = props
  const metadataObj = new Metadata(metadata)

  const [chartData, setChartData] = useState({
    ready: false,
    data: [] as Record<string, unknown>[],
  })

  // ensure component is still mounted before updating chart
  const componentIsMounted = useRef<boolean>(true)

  useEffect(() => {
    componentIsMounted.current = true
    if (rawData) {
      setChartData({ready: true, data: parseJsonSwallowError(rawData)})
    } else {
      getData?.().then(data => setChartData({ ready: true, data: data }));
    }

    // return a cleanup function
    return () => {
      componentIsMounted.current = false
    }
  }, [catalogAPI, rawData, metadata])

  const backgroundColor = getRgbaString(metadataObj.getPalette(), 0, 0.2)
  const hasCustomColorPalette = metadataObj.hasCustomPalette()

  const results = chartData.data.slice(0, props.limit)
  const fields = results && results.length > 0 && Object.keys(results[0])

  let values:any = []
  let error_message = false
  results && results.map((result: any) => {
    const data = fields && fields.map((column: any) => {
      if (column == 'error') {
        error_message = true;
      }
      return (result[column])
    })
    values = data
  })

  let html = <>Nothing here</>

  if (values) {
    html = <>
    <div style={{ textAlign: 'center' }}>
      {values.map((item: any, index: any) => {
        if (index === 0) {
          return (
            <p 
              key={index}
              className="first-paragraph"
              style={{
                color: hasCustomColorPalette ? getRgbaString(metadataObj.getPalette(), index + 1, 0.5) : 'black',
                ...(error_message && { fontSize: '16px' })
               }}>
                {item}
            </p>
          );
        } else {
          return (
            <p key={index}
              className="other-paragraphs"
              style={{ color: hasCustomColorPalette && index + 2 <= metadataObj.getPalette().length ? getRgbaString(metadataObj.getPalette(), index + 1, 0.5) : 'black'}}>
                {item}
            </p>
          );
        }
      })}
    </div>
    </>
  }

  return (
    <div data-testid="html-metric" id="html-metric" className="html-metric" style={{ backgroundColor: hasCustomColorPalette ? backgroundColor : 'white' }}>
        {chartData.ready ? html : null}
    </div>
  )
}

export default HtmlMetric

import React, { FunctionComponent, PropsWithChildren } from 'react'
import { withRouter } from 'react-router-dom'
import '../assets/css/components/WorkspaceMenu.css'
import { useWorkspaceContext } from '../contexts/workspace'
import { alpha, Menu,  MenuItem, styled } from '@material-ui/core'
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import leftAngleGrey from './../assets/images/left_angle_grey.png'
import logo from 'src/assets/images/logo.svg'


const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      top: '40px !important',
      marginTop: theme.spacing(1),
      minWidth: 180,
      maxWidth: 300,
      color: theme.palette.grey[600],
      boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        background: "#f1f1f1",
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      },
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        padding: '10px 16px 10px 16px',
        '& .MuiListItemIcon-root': {
          minWidth: '40px', 
          justifyContent: 'center',
          color: 'rgba(0, 0, 0, 0.7)',
        },
        '& .MuiListItemText-root': {
          padding: '0 0 0 10px',
          color: 'rgb(59, 68, 88)',
        },
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  const StyledMenuMobile = styled((props) => (
    <SwipeableDrawer
      anchor='left'
      open={props.open}
      onClose={props.onClose}
      onOpen={() => { return }}
    >
        <Box
          sx={{ width: '90vw' }}
          role="presentation"
          onClick={props.handleClose}
          onKeyDown={props.handleClose}
          {...props}
        />
    </SwipeableDrawer>
  ))(({ theme }) => ({
    '& .MuiListItem-root': {
      '& .MuiListItemIcon-root': {
        minWidth: '40px', 
        justifyContent: 'center',
        color: 'rgba(0, 0, 0, 0.7)',
      },
      '& .MuiListItemText-root': {
        padding: '0 0 0 10px',
        color: 'rgb(59, 68, 88)',
      },
    },
    '& .MuiPaper-root': {
      overflowY: 'scroll',
      marginTop: theme.spacing(1),
      color: theme.palette.grey[600],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    },
  }));


const SwitchMenu: FunctionComponent<any> = (props: PropsWithChildren<any>) => {
  
  const { workspace, isOwner, workspaces, switchToWorkspace, profile } = useWorkspaceContext();
  const [switchWorkspaceOpen, setSwitchWorkspaceOpen] = React.useState(false);
  const { history, menu } = props

  const handleSwitchWorkspaceMenuClick = () => {
    setSwitchWorkspaceOpen(!switchWorkspaceOpen);
  };

  const isWorkspaceAdmin = () => workspace && workspace._links && workspace._links['update delete workspace'] != null

  let workspaceList = null
  if (workspaces && workspaces.length > 0 && workspaces !== null) {
    workspaceList = workspaces.map((workspaceItem: any, i: any) => {
      const switchWorkspaceFn = () => {
          switchToWorkspace(workspaceItem.id)
          props.handleClose()
      }
      const imageUrl = workspaceItem && workspaceItem.imageUrl ? workspaceItem.imageUrl : logo
      return (
          <MenuItem
            disableRipple
            key={i}
            className={'nav-link dropdown-item dropdown-subitem' + (workspaceItem.id === workspace.id ? ' dropdown-item-active' : '')}
            onClick={switchWorkspaceFn} 
          >
            <ListItemIcon>
                <img className='workspace-url-image' src={imageUrl} />
            </ListItemIcon>
            <ListItemText>{workspaceItem.name}</ListItemText>
        </MenuItem>
      )
    })
  }

  const settingLink = (
      <MenuItem
        disableRipple
        onClick={() => {
            props.handleClose()
            history.push('/w/' + workspace.id)
        }}
        data-rb-event-key="settings"
        data-testid="settingsLink"
      >
          <ListItemIcon>
              <svg fill="#3b4458" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.875 31">
                  <g
                      id="Group_381"
                      data-name="Group 381"
                      transform="translate(-457 -415)"
                  >
                      <path
                          id="Path_210"
                          data-name="Path 210"
                          d="M32.689,17.315l-1.276-.737a5.771,5.771,0,0,0,0-2.1l1.276-.737a.362.362,0,0,0,.165-.419,7.462,7.462,0,0,0-1.639-2.834.361.361,0,0,0-.443-.069l-1.276.737A5.655,5.655,0,0,0,27.673,10.1V8.629a.359.359,0,0,0-.282-.351,7.532,7.532,0,0,0-3.272,0,.359.359,0,0,0-.282.351V10.1a5.833,5.833,0,0,0-1.822,1.052l-1.273-.737a.356.356,0,0,0-.443.069,7.417,7.417,0,0,0-1.639,2.834.358.358,0,0,0,.165.419l1.276.737a5.771,5.771,0,0,0,0,2.1l-1.276.737a.362.362,0,0,0-.165.419A7.462,7.462,0,0,0,20.3,20.572a.361.361,0,0,0,.443.069l1.276-.737a5.655,5.655,0,0,0,1.822,1.052V22.43a.359.359,0,0,0,.282.351,7.532,7.532,0,0,0,3.272,0,.359.359,0,0,0,.282-.351V20.956A5.833,5.833,0,0,0,29.5,19.9l1.276.737a.356.356,0,0,0,.443-.069,7.417,7.417,0,0,0,1.639-2.834A.37.37,0,0,0,32.689,17.315Zm-6.933.608a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,25.756,17.924Z"
                          transform="translate(449.357 412.204)"
                      />
                      <path
                          id="Path_209"
                          data-name="Path 209"
                          d="M31.969,0H2.906A2.907,2.907,0,0,0,0,2.906V22.281a2.907,2.907,0,0,0,2.906,2.906H14.531l-.969,2.906H9.2A1.453,1.453,0,0,0,9.2,31H25.672a1.453,1.453,0,0,0,0-2.906H21.313l-.969-2.906H31.969a2.907,2.907,0,0,0,2.906-2.906V2.906A2.907,2.907,0,0,0,31.969,0ZM31,21.313H3.875V3.875H31Z"
                          transform="translate(457 415)"
                      />
                  </g>
              </svg>
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
      </MenuItem>
  )
  
  const appLab = (
    <MenuItem 
      disableRipple
      onClick={() => {
          props.handleClose()
          history.push(menu !== 'lab' ? '/lab' : `/l/${workspace.id}/${workspace.appProperties?.HOME_PAGE || 'feed'}`)
      }}
    >
        <ListItemIcon>
          <i className={`fas fa-${menu !== 'lab' ? 'magic' : 'door-open'}`} />
        </ListItemIcon>
        <ListItemText>{`${menu !== 'lab' ? "Lab" : "App"}`}</ListItemText>
    </MenuItem>
  )
  
  const newWorkspace = (
      <MenuItem
        disableRipple
        onClick={() => {
            props.handleClose()
            history.push('/setup/w/new')
        }}
        data-rb-event-key="new-workspace"
        data-testid="newWorkspaceLink"
      >
          <ListItemIcon>
              <svg fill="#3b4458" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.875 31">
                  <g
                      id="Group_382"
                      data-name="Group 382"
                      transform="translate(-457 -465)"
                  >
                      <path
                          id="Path_212"
                          data-name="Path 212"
                          d="M31.969,0H2.906A2.907,2.907,0,0,0,0,2.906V22.281a2.907,2.907,0,0,0,2.906,2.906H14.531l-.969,2.906H9.2A1.453,1.453,0,0,0,9.2,31H25.672a1.453,1.453,0,0,0,0-2.906H21.313l-.969-2.906H31.969a2.907,2.907,0,0,0,2.906-2.906V2.906A2.907,2.907,0,0,0,31.969,0ZM31,21.313H3.875V3.875H31Z"
                          transform="translate(457 465)"
                      />
                      <path
                          id="Path_213"
                          data-name="Path 213"
                          d="M7.455-8.755h3.878v2.8H7.455v4.383H4.5V-5.951H.612v-2.8H4.5v-4.2H7.455Z"
                          transform="translate(468 485)"
                      />
                  </g>
              </svg>
          </ListItemIcon>
          <ListItemText>New Workspace</ListItemText>
      </MenuItem>
  )

  const invitationsLink = (
      <MenuItem
        disableRipple
        onClick={() => {
            props.handleClose()
            history.push('/l/' + workspace.id + '/invitations')
        }}
        data-rb-event-key="invitations"
        data-testid="invitationsLink"
      >
          <ListItemIcon>
              <svg fill="#3b4458" width="20" height="20" viewBox="0 0 48.278 38.623">
                  <path
                      d="M339.571,249.69h-4.828v-4.828a1.211,1.211,0,0,0-1.207-1.207h-2.414a1.211,1.211,0,0,0-1.207,1.207v4.828h-4.828a1.211,1.211,0,0,0-1.207,1.207v2.414a1.211,1.211,0,0,0,1.207,1.207h4.828v4.828a1.211,1.211,0,0,0,1.207,1.207h2.414a1.211,1.211,0,0,0,1.207-1.207v-4.828h4.828a1.211,1.211,0,0,0,1.207-1.207V250.9A1.211,1.211,0,0,0,339.571,249.69ZM309.4,253.311a9.656,9.656,0,1,0-9.656-9.656A9.655,9.655,0,0,0,309.4,253.311Zm6.759,2.414H314.9a13.132,13.132,0,0,1-11,0h-1.26A10.141,10.141,0,0,0,292.5,265.864V269a3.622,3.622,0,0,0,3.621,3.621h26.553A3.622,3.622,0,0,0,326.295,269v-3.138A10.141,10.141,0,0,0,316.156,255.725Z"
                      transform="translate(-292.5 -234)"
                  />
              </svg>
          </ListItemIcon>
          <ListItemText>Invitations</ListItemText>
      </MenuItem>
  )

  const switchWorkspacesLink = (
      <MenuItem
        disableRipple
        onClick={handleSwitchWorkspaceMenuClick}
        data-rb-event-key="switch-workspace"
        data-testid="switchWorkspaceLink"
        className="workspace_dropdown"
      >
          <ListItemIcon>
              <svg fill="#3b4458" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.875 31.188">
                  <g id="Group_394" data-name="Group 394" transform="translate(-48 -591)">
                      <g id="Group_391" data-name="Group 391" transform="translate(5 -51)">
                          <g id="Group_388" data-name="Group 388" transform="translate(-408 177)">
                              <path
                                  id="Path_212"
                                  data-name="Path 212"
                                  d="M31.969,0H2.906A2.907,2.907,0,0,0,0,2.906V22.281a2.907,2.907,0,0,0,2.906,2.906H14.531l-.969,2.906H9.2A1.453,1.453,0,0,0,9.2,31H25.672a1.453,1.453,0,0,0,0-2.906H21.313l-.969-2.906H31.969a2.907,2.907,0,0,0,2.906-2.906V2.906A2.907,2.907,0,0,0,31.969,0ZM31,21.313H3.875V3.875H31Z"
                                  transform="translate(457 465)"
                              />
                          </g>
                      </g>
                      <g id="Group_392" data-name="Group 392" transform="translate(-1 -52)">
                          <g id="Group_388-2" data-name="Group 388" transform="translate(49 645)">
                              <path
                                  id="Path_212-2"
                                  data-name="Path 212"
                                  d="M2.906,3A2.907,2.907,0,0,0,0,5.906V29.281a2.907,2.907,0,0,0,2.906,2.906Zm.969,25.313v0Z"
                                  transform="translate(0 -3)"
                              />
                          </g>
                      </g>
                      <g
                          id="Group_393"
                          data-name="Group 393"
                          transform="translate(-607.156 671.031) rotate(-90)"
                      >
                          <g id="Group_388-3" data-name="Group 388" transform="translate(-408 177)">
                              <path
                                  id="Path_212-3"
                                  data-name="Path 212"
                                  d="M2.906,14A2.907,2.907,0,0,0,0,16.906v5.375a2.907,2.907,0,0,0,2.906,2.906Zm.969,7.313v0Z"
                                  transform="translate(457 465)"
                              />
                          </g>
                      </g>
                  </g>
              </svg>
          </ListItemIcon>
          <ListItemText>Switch Workspace</ListItemText>
          <img
              className={switchWorkspaceOpen ? 'workspaces-arrow-open' : 'workspaces-arrow'}
              src={leftAngleGrey}
              alt="dropdown icon"
          />
      </MenuItem>
  )
  const switchWorkspacesSubMenu = (
      <Collapse in={switchWorkspaceOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
              {workspaceList}
          </List>
      </Collapse>
  )

  const imageUrl = workspace && workspace.imageUrl ? workspace.imageUrl : logo

  const navigateHome = () => {
      props.handleClose()
      history.push(menu === 'lab' ? '/lab' : `/l/${workspace.id}/${workspace.appProperties?.HOME_PAGE || 'feed'}`)  
  }

  return (
      <>
         {props.isSidebarLayout ? <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={props.anchorEl}
              open={props.open}
              onClose={props.handleClose}
          >
              <div className="workspace-menu-header" onClick={navigateHome}>
                  <div className="linkIcon_holder navigate-brand" style={{ minWidth: '40px', maxWidth: '40px' }}>
                      <img className='workspace-url-image' src={imageUrl} />
                  </div>
                  <div className="workspace-menu-name">{workspace.name}</div>
              </div>
              {!profile?.featuresDisabled?.includes("INVITATIONS") && invitationsLink}
              {menu === 'app' && isWorkspaceAdmin() && settingLink}
              { (isOwner() || workspaceList) && (
                  <List component="div" disablePadding>
                      <Divider />
                      {isOwner() && newWorkspace}
                      {workspaceList && workspaceList.length > 1 && switchWorkspacesLink }
                      {workspaceList && workspaceList.length > 1 && switchWorkspacesSubMenu }
                  </List>
              )}
          </StyledMenu>
          :
          <StyledMenuMobile
              open={props.open}
              onClose={props.handleClose}
          >
              <div className="workspace-menu-header" onClick={navigateHome}>
                  <div className="linkIcon_holder navigate-brand" style={{ minWidth: '40px', maxWidth: '40px' }}>
                      <img className='workspace-url-image' src={imageUrl} />
                  </div>
                  <div className="workspace-menu-name" >{workspace && workspace.name}</div>
              </div>
              {!profile?.featuresDisabled?.includes("INVITATIONS") && invitationsLink}
              {menu === 'app' && isWorkspaceAdmin() && settingLink}
              {isWorkspaceAdmin() && appLab}
              { (isOwner() || workspaceList) && (
                  <>
                      <Divider />
                      <div className="workspaces-menu-header">Workspaces</div>
                      <List component="div" disablePadding>
                          {isOwner() && newWorkspace}
                          {workspaceList}
                      </List>
                  </>
              )}
          </StyledMenuMobile>}
      </>
  )
}

export default withRouter(SwitchMenu)

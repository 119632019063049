export enum PostProcess {
    JsonParse = "json_parse"
}

export class PostProcessor {

    static readonly JsonParse = new PostProcessor({
        string: JSON.parse
    })

    static readonly fromExpression = (expression: string): PostProcessor => new PostProcessor({
        default: value => new Function(`return ${expression}`)()(value)
    });

    static readonly get = (postProcess: PostProcess): PostProcessor | undefined => {
        const [key] = Object.entries(PostProcess).find(([, v]) => v === postProcess) || [];
        return key && PostProcessor[key];
    }

    private static defaultApplicator = (value: unknown) => value;

    constructor(private applicators: {
        string?: (value: string) => unknown
        object?: (value: Record<string, unknown>) => unknown
        default?: (value: unknown) => unknown
    }) { }

    readonly apply = (value: unknown): unknown => {
        const applicator = this.applicators[typeof value]
            || this.applicators.default
            || PostProcessor.defaultApplicator;

        return applicator(value);
    }
}

interface RelatedTable {
    columns?: Column[];
    aggregates?: Aggregate[];
}
interface Link {
    title: string;
    dataset: string;
}

export interface Aggregate {
    name: string;
    label: string;
    description: string;
    post_process_expr?: string;
    post_process?: PostProcess;
    links?: Link[];
}

export interface Column {
    name: string;
    label?: string;
    description?: string;
    post_process_expr?: string;
    post_process?: PostProcess;
}

export interface IMetaDataProps {
    version?: number;
    name?: string;
    label?: string;
    related_table?: RelatedTable;
    links?: Link[];
    palette?: number[][];
}

import React, { FunctionComponent, useEffect, useState } from 'react';
import arrowIcon from 'src/assets/images/svgs/arrow_left.svg';
import ICustomAccordian from 'src/models/components/ICustomAccordian';

const CustomAccordion: FunctionComponent<ICustomAccordian> = props => {
    const { step, title, defaultExpanded, isLast, children, onToggle } = props

    const [expanded, setExpanded] = useState(!!defaultExpanded)

    const handleToggle = () => React.Children.toArray(children).filter(Boolean).length && setExpanded(!expanded)

    useEffect(() => {
        onToggle?.(expanded)
    }, [expanded])

    useEffect(() => {
        props.expanded !== undefined && setExpanded(props.expanded)
    }, [props.expanded])

    return <>
        <div title={title}>
            <div className="step-header" onClick={handleToggle}>
                {step &&
                    <span className="step-identicator">{step}</span>}
                <img src={arrowIcon} className={`expand-arrow ${expanded ? 'expanded' : ''}`} />
                <span className="step-title">{title}</span>
            </div>
            {expanded && <div className="step-data-wrapper">
                <div className={`step-data ${isLast && !expanded ? 'clear-border' : ''}`}>
                    {children}
                </div>
            </div>}
        </div>
    </>

}

export default CustomAccordion

import React, { ReactNode } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'
import DatasetChart from 'src/components/DatasetChart'
import 'src/styles.css'
import * as markdownUtils from 'src/utils/markdownUtils'
import { IDatasetCardProps } from '../models/components/IRelatedDatasetCard'

class RelatedDatasetCard extends React.Component<IDatasetCardProps> {
  public render () : ReactNode {
    const dataset = this.props.dataset
    const channelIcon = dataset._embedded.source.picture 
                          ? dataset._embedded.source.picture : require('./../assets/images/default_channel.png')
    const channelIconStyle = {
      backgroundImage: `url(${channelIcon})`
    }
    const chart = <DatasetChart dataset={dataset} compact={true} />
    const datasetDescription = markdownUtils.processMarkdown(dataset.description)
    const markdown = (
            <ReactMarkdown
                renderers={markdownUtils.renderers}
                escapeHtml={false}
                source={datasetDescription}
            />
    )
    const content = dataset.visualisation == null ? markdown : chart
    const handleViewDataset = () => this.props.handleDatasetView(dataset)
    return (
            <ScrollAnimation animateIn="fadeIn" offset={10} className="gridbox">
                <div
                    className="cursor-pointer related-dataset-card"
                    data-testid={dataset.id}
                    onClick={handleViewDataset}
                >
                    <div className="graph_detail position-relative" style={channelIconStyle}>
                        <div className="relatedDetails">
                            <h2>{dataset.title}</h2>
                            <div>{content}</div>
                            <div className="text-muted">
                                <span>{dataset._embedded.source.description}</span>
                                <br />
                                <span>
                                    {dataset.viewCount} views | {dataset.likeCount} stars{' '}
                                    {dataset.published && (
                                        <>
                                            | Updated{' '}
                                            <Moment fromNow={true} utc={true} local={true}>
                                                {dataset.published}
                                            </Moment>
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
    )
  }
}

export default RelatedDatasetCard

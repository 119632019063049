import React from 'react';

const watchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="22px"
    width="32px"
    viewBox="0 0 63.527 43.313"
  >
    <g id="visibility" transform="translate(0 -74.667)">
      <g id="Group_497" data-name="Group 497" transform="translate(0 74.667)">
        <g id="Group_496" data-name="Group 496" transform="translate(0 0)">
          <path
            id="Path_531"
            data-name="Path 531"
            d="M179.33,170.667a8.663,8.663,0,1,0,8.663,8.663A8.67,8.67,0,0,0,179.33,170.667Z"
            transform="translate(-147.566 -157.673)"
            fill="#a5a5a5"
          />
          <path
            id="Path_532"
            data-name="Path 532"
            d="M31.763,74.667A34.151,34.151,0,0,0,0,96.324a34.121,34.121,0,0,0,63.527,0A34.136,34.136,0,0,0,31.763,74.667Zm0,36.095A14.438,14.438,0,1,1,46.2,96.324,14.443,14.443,0,0,1,31.763,110.762Z"
            transform="translate(0 -74.667)"
            fill="#a5a5a5"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const DefaultAppHelp = (
  <>
    <div className="help-field">
      <div className="pad_bot15">
        <h2>Using your workspace</h2>
      </div>

      <h3>The Main Menu</h3>
      <div className="pad_bot15">
        The main menu on the left hand side is how you get around the workspace.
      </div>
      <table className='help-table' cellSpacing="0" cellPadding="0">
        <tr>
          <td align="center">
            <div className='linkIcon_holder'>
              <i className={`fas fa-home fa-2x`} />
            </div>
          </td>
          <td>
            Home is where you can find updates and information about datasets you have followed.
          </td>
        </tr>
        <tr>
          <td align="center">
            <div className='linkIcon_holder'>
              <i className={`fas fa-hashtag fa-2x`} />
            </div>
          </td>
          <td>
            Explore is where you can find new and interesting datasets in your workspace.
          </td>
        </tr>
        <tr>
          <td align="center">
            <div className='align-center'>
              <i className={`fas fa-database fa-2x`} />
            </div>
          </td>
          <td>
            Channels is where you can see the channels that datasets have been grouped into.
          </td>
        </tr>
        <tr>
          <td align="center">
            <div className='linkIcon_holder float-left'>
              <i className={`fas fa-star fa-2x`} />
            </div>
          </td>
          <td>
            Starred is where you can find all of your starred datasets.
          </td>
        </tr>
        <tr>
          <td align="center">
            <div className='linkIcon_holder float-left'>
              <i className={`fas fa-question-circle fa-2x`} />
            </div>
          </td>
          <td>
            Help - You are here!
          </td>
        </tr>
      </table>

      <hr></hr>

      <h3>The User Menu</h3>
      <div className="pad_bot15">In the top right of the screen there is your user image alongside a few other buttons. From left to right:</div>
      <table className='help-table'>
        <tr>
          <td>
            <b>Notification Bell</b> which will show if you have any new notifications or alerts.
          </td>
        </tr>
        <tr>
          <td>
            <b>Invite Button</b> that you can click on to invite new users with their email address.
          </td>
        </tr>
        <tr>
          <td>
            <b>User Image</b> which if you click on, will open a menu where you can: edit your profile, change your password, get an API key, or logout.
          </td>
        </tr>
      </table>
    </div>

    <hr></hr>

    <div className="help-field">
      <div className="help-title">
        <h2>Interacting with your data</h2>
      </div>
      <div className="pad_bot15">
        When you find a dataset you are interested in, you can interact with it.
      </div>
      <div className="pad_bot15">
        At the bottom of each dataset you will see the buttons listed below, as well a comments.
      </div>
      <table className='help-table'>
        <tr>
          <td align="center">
            <div className='linkIcon_holder text-muted'>
              <i className={`fas fa-star fa-2x`} />
            </div>
          </td>
          <td>
            Star datasets so you can quickly find them again under starred on the menu.
          </td>
        </tr>
        <tr>
          <td align="center">
            <div className='linkIcon_holder text-muted'>
              <i className={`fas fa-share fa-2x`} />
            </div>
          </td>
          <td>
            Share datasets by getting a sharable link, or by emailing the dataset.
          </td>
        </tr>
        <tr>
          <td align="center">
            <div className='align-center'>
              {watchIcon}
            </div>
          </td>
          <td>
            Watch the dataset and get notifications on activity.
          </td>
        </tr>
        <tr>
          <td align="center">
            <div className='linkIcon_holder text-muted'>
              <i className={`fas fa-folder-plus fa-2x`} />
            </div>
          </td>
          <td>
            Save the dataset and get the data behind the chart or table.
          </td>
        </tr>
      </table>
    </div>
  </>
)


/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useAPIContext } from 'src/contexts/api'
import { useWorkspaceContext } from 'src/contexts/workspace'
import EntityRel from 'src/models/EntityRel'
import { INamedEntity } from 'src/models/IEntity'
import IPage from 'src/models/IPage'

const useEntityPage = <E extends INamedEntity>(rel: EntityRel, e?: IPage<E>) => {

    const { catalogAPI: api }: { catalogAPI: AxiosInstance } = useAPIContext()
    const { workspace } = useWorkspaceContext()

    const [page, setPage] = useState(e)
    const [isProcessing, setProcessing] = useState(false)

    const successResponseCallback = useCallback((response: AxiosResponse<IPage<E>>) => {
        if (response.data) setPage(response.data)
        setProcessing(false)
        return response
    }, [])

    const errorResponseCallback = useCallback((error: AxiosError) => {
        setProcessing(false)
        return Promise.reject(error)
    }, [])

    const request = async (config: AxiosRequestConfig) => {
        setProcessing(true)

        return await api.request(config)
            .then(successResponseCallback)
            .catch(errorResponseCallback)

    }

    const rest = {
        get: (url: string) => request({
            method: 'GET',
            url: url
        })
    }

    const readLink = page?._links.self || workspace._links[rel.collection]
    const firstLink = page?._links.first
    const lastLink = page?._links.last
    const nextLink = page?._links.next
    const prevLink = page?._links.prev

    const read = (pageNumber?: number) => {
        if (!readLink) throw Error(`'${rel.collection}' page cannot be read`)

        const url = new URL(readLink.href)
        pageNumber && url.searchParams.set('page', `${pageNumber}`)

        rest.get(`${url}`)
    }

    const first = () => {
        if (!firstLink) throw Error(`No first '${rel.collection}' page`)
        rest.get(firstLink.href)
    }

    const last = () => {
        if (!lastLink) throw Error(`No last '${rel.collection}' page`)
        rest.get(lastLink.href)
    }

    const next = () => {
        if (!nextLink) throw Error(`No next '${rel.collection}' page`)
        rest.get(nextLink.href)
    }

    const prev = () => {
        if (!prevLink) throw Error(`No previous '${rel.collection}' page`)
        rest.get(prevLink.href)
    }

    useEffect(() => {
        page || read()
    }, [])

    return {
        metadata: page?.page,
        entities: page?._embedded?.[rel.collection],
        isProcessing,
        read,
        next,
        prev,
        first,
        last,
        setPage
    }

}

export default useEntityPage

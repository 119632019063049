import { AxiosInstance } from 'axios'
import * as React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Col from 'react-bootstrap/Col'
import ContentLoader from 'react-content-loader'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Feed from 'src/components/Feed'
import Workbench from 'src/components/Workbench'
import { logError } from 'src/utils/Error'
import { APIContext, getWorkspaceChannelsURI } from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { IChannelProps, IChannelState } from '../models/views/IChannels'
import { bindContexts } from '../utils/bindContexts'
import PageTitle from 'src/components/PageTitle'
import 'src/styles.css'

class Channels extends React.Component<IChannelProps, IChannelState> {
    private previousContext: any;
    static contextType = MergedContexts;
    public isThisMounted = false;
    constructor (props: IChannelProps) {
      super(props)
      this.state = {
        ready: false
      }
      this.loadMore = this.loadMore.bind(this)
      this.handleChannelFilter = this.handleChannelFilter.bind(this)
      this.refreshData = this.refreshData.bind(this)
      this.updateDatasets = this.updateDatasets.bind(this)
    }

    public componentDidMount () {
      this.previousContext = this.context
      const { searchResults, fetchFeeds, handleSearchResults, workspace } = this.context
      this.isThisMounted = true
      this.props.setViewName && this.props.setViewName("Channels")
      if (
        this.isThisMounted &&
            (this.props.history.action === 'PUSH' || searchResults.hits === -1)
      ) {
        this.refreshData()
      } else {
        this.setState({ ready: true })
        fetchFeeds(workspace.id, searchResults, handleSearchResults)
      }

      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 200)
    }

    private refreshData () {
      this.getChannelsData()
      this.handleChannelFilter(null)
    }

    public componentDidUpdate () {
      if (this.previousContext.workspace !== this.context.workspace) {
//        this.getChannelsData()
//        this.handleChannelFilter(null)
      }
      this.previousContext = this.context
    }

    private async getChannelsData () {
      const { workspace, catalogAPI, setChannel } = this.context
      const api = catalogAPI as AxiosInstance
      if (workspace) {
        this.setState({ ready: false })
        await api
          .get(getWorkspaceChannelsURI(workspace.id))
          .then(resp => {
            if (resp.data._embedded) {
              const query = this.getQueryStringValue('search', this.props)
              let selectedChannel = null
              if (query) {
                let channelName = ''
                const queryArray = query.replace(/"/g, '').split(':')
                if (queryArray[1] && queryArray[0] === 'in') {
                  channelName = queryArray[1]
                }

                resp.data._embedded.channels.forEach((channel: any) => {
                  if (channel.name === channelName) {
                    selectedChannel = channel
                  }
                })
              }

              setChannel({ channelList: resp.data._embedded.channels, selectedChannel })
            }
          })
          .catch(e => {
            logError(e)
          })
      }
      this.setState({ ready: true })
    }

    private handleChannelFilter (selectedChannel: any) {
      const {
        workspace,
        search,
        searchResults,
        setSearchResults,
        handleSearchResults,
        channel,
        setChannel
      } = this.context
      if (!searchResults.isLoading) {
        setSearchResults({ isLoading: true })

        const channelList = channel.channelList
        let query = this.getQueryStringValue('search', this.props)

        if (selectedChannel) {
          if (channel.selectedChannel === selectedChannel) {
            setChannel({ channelList, selectedChannel: null })
            query = ''
            this.props.history.push({ pathname: '/l/' + workspace.id + '/channels' })
          } else {
            setChannel({ channelList, selectedChannel })
            query = 'in:' + selectedChannel.name
            this.props.history.push({ pathname: '/l/' + workspace.id + '/channels', search: 'search="' + query + '"' })
          }
        } else {
            if (query) {
                const query_array = query.replace(/"/g, '').split(':')
                if (query_array[1] && query_array[0] === 'in') {
                  const channelName = query_array[1]
                  setChannel({ channelList: channelList, selectedChannel: { name: channelName } })
                  query = query.replace(/"/g, '')
                }
            }
          
        }

        search(workspace.id, handleSearchResults, null, null, query)
      }
    }

    private updateDatasets () {
      const { searchResults, handleSearchResults, updateDatasets } = this.context
      updateDatasets(searchResults, handleSearchResults)
    }

    loadMore (nextUrl: any) {
      const { searchResults, setSearchResults, search, workspace, handleSearchResults } =
            this.context
      setSearchResults({
        datasets: searchResults.datasets,
        fetchtime: searchResults.fetchtime,
        hits: searchResults.hits,
        nextPageUrl: searchResults.nextPageUrl,
        isLoading: searchResults.isLoading,
        isMoreLoading: true,
        query: searchResults.query
      })
      if (nextUrl) {
        search(workspace.id, handleSearchResults, searchResults, nextUrl, '')
      }
    }

    getQueryStringValue (key: any, theProps: any) {
      const url = theProps && theProps.location.search ? theProps.location.search : ''
      if (url) {
        const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)')
        const results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return null
        return decodeURIComponent(results[2].replace(/\+/g, ' ')).toLocaleLowerCase()
      } else {
        return null
      }
    }

    public render () {
      const { searchResults, channel, showNewInsight } = this.context
      const { isLoading, datasets, fetchtime, hits, nextPageUrl, isMoreLoading, query } =
            searchResults
      const { ready } = this.state
      const { channelList, selectedChannel } = channel
      const contentLoader = (
            <div className="d-flex">
                <Col className="dataLoader">
                    <ContentLoader className="placeholderList" viewBox="0 0 446 25" speed={2}>
                        <circle cx="15" cy="12" r="10" />
                        <circle cx="45" cy="12" r="10" />
                        <circle cx="75" cy="12" r="10" />
                        <circle cx="105" cy="12" r="10" />
                        <circle cx="135" cy="12" r="10" />
                        <circle cx="165" cy="12" r="10" />
                        <circle cx="195" cy="12" r="10" />
                        <circle cx="225" cy="12" r="10" />
                        <circle cx="255" cy="12" r="10" />
                    </ContentLoader>
                </Col>
            </div>
      )

      const settings = {
        className: 'roundedListItemSlider position-relative channels-list',
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: 16,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 13
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 9
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 9
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 7
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 5
            }
          }
        ]
      }

      let channelView = null
      if (channelList && channelList.length > 0) {
        const rows: any[] = []
        let channelIcon: any = null
        channelList.forEach((channelObj: any, index: any) => {
          if (channelObj.picture) {
            channelIcon = (
                        <img className="item_icon" src={channelObj.picture} alt={channelObj.name} />
            )
          } else {
            channelIcon = (
                        <img
                            className="item_icon"
                            src={require('./../assets/images/default_channel.png')}
                            alt={channelObj.name}
                        />
            )
          }

          let disableClass = ''
          if (selectedChannel && selectedChannel.name !== channelObj.name) {
            disableClass = 'disableChannel'
          }
          const handleChannel = () => this.handleChannelFilter(channelObj)
          rows.push(
                    <div
                        key={index}
                        data-testid={channelObj.id}
                        className={disableClass + ' roundedSliderItem cursor-pointer'}
                        style={{marginTop: '15px'}}
                        onClick={handleChannel}
                    >
                        {channelIcon}
                        <p className="channel_name text-muted">{channelObj.description}</p>
                    </div>
          )
        })
        channelView = rows.length > 0 ? rows : ''
      }

      return (
            <Workbench
                menu='app'
                isExpandedSidebarActive={this.props.isExpandedSidebarActive}
                isSidebarLayout={this.props.isSidebarLayout}
                toggleSidebar={this.props.toggleSidebar}>
                <div className="rightside_pageContent">
                    {this.props.isSidebarLayout && 
                    <PageTitle title={this.context.workspace.appProperties?.CHANNELS_PAGE_TITLE || 'Channels'} /> }
                    {ready ? null : contentLoader}
                    {ready && channelList && channelList.length > 0 && (
                        <ScrollAnimation
                            animateIn="fadeIn"
                            offset={10}
                            className="clearfix"
                        >
                            <div className="roundedListItemSlider_holder SliderWith_rightSide_verticalDots position-relative">
                                <Slider {...settings}>{channelView}</Slider>
                            </div>
                        </ScrollAnimation>
                    )}
                    <Feed
                        key={query}
                        datasets={datasets}
                        isLoading={isLoading}
                        loadMore={this.loadMore}
                        currentSearch={query}
                        hits={hits}
                        fetchtime={fetchtime}
                        nextPageUrl={nextPageUrl}
                        isMoreLoading={isMoreLoading}
                        refreshData={this.refreshData}
                        showNewInsight={showNewInsight}
                        updateDatasets={this.updateDatasets}
                    />
                    {this.props.children}
                </div>
            </Workbench>
      )
    }
}

export const ChannelsWithContext = bindContexts(withRouter(Channels), [
  Auth0Context,
  APIContext,
  WorkspaceContext
])
export default ChannelsWithContext

import React from 'react';

export const LabHelp = (
    <>
        <br></br>
        <div className="help-field">
            <div className="help-title">
                <h2>Join our community on the <a href="https://join.slack.com/t/matatika/shared_invite/zt-19n1bfokx-F31DNitTpSxWCFO2aFlgxg" target="_blank" rel="noreferrer">Matatika Slack</a> to get help and updates.</h2>
            </div>
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        <div className="help-field">
            <div className="help-title">
                <h2>Further Reading & Links</h2>
            </div>
            <div className="pad_bot10">
                <a href="https://www.matatika.com/docs/" target="_blank" rel="noreferrer">Matatika Documentation</a>
            </div>
            <div className="pad_bot10">
                <a href="https://github.com/Matatika/matatika-examples" target="_blank" rel="noreferrer">Matatika Examples</a>
            </div>
        </div>
    </>
)


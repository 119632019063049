import { Pagination } from '@material-ui/lab'
import React, { ChangeEvent } from 'react'
import dataIcon from 'src/assets/images/svgs/data.svg'
import EntityRow from 'src/components/EntityRow'
import pipelinesPlaceholder from '../components/placeholders/PipelinesPlaceholder';
import Search from 'src/components/Search'
import useEntityPage from 'src/hooks/useEntityPage'
import { IImageHolder } from 'src/models/components/IImageHolder'
import EntityRel from 'src/models/EntityRel'
import IEntity from 'src/models/IEntity'

interface ISearchEntitiesProps {
    entityRel: EntityRel
    getExpandContext?: <E extends IEntity>(entity: E) => React.ReactNode
    getImageHolderProps?: (entity: any) => Partial<IImageHolder>
}

const SearchEntities = (props: ISearchEntitiesProps): JSX.Element => {

    const page = useEntityPage(props.entityRel)

    const onPageChange = (e: ChangeEvent<unknown>, pageNumber: number) => {
        if (!page.metadata) return

        const currentPage = page.metadata.number + 1
        switch (pageNumber) {
            case currentPage:
                return
            case currentPage + 1:
                return page.next()
            case currentPage - 1:
                return page.prev()
            case 1:
                return page.first()
            case page.metadata.totalPages:
                return page.last()
            default:
                return page.read(pageNumber)
        }
    }

    return <>
        <div className='rightside_pageContent data-page'>
            {page.isProcessing ? pipelinesPlaceholder :
                <Search
                    {...props}
                    entities={page.entities}
                    row={e =>
                        <EntityRow
                            key={e.id}
                            entity={e}
                            entityRel={props.entityRel}
                            onClosed={page.read}
                            expandContent={props.getExpandContext?.(e)}
                            ImageHolderProps={{ ...props.getImageHolderProps?.(e) }} />}
                    noEntities={<>
                        <div className="no-datasources content-center">
                            <img
                                alt={`No ${props.entityRel.collectionLabel}`}
                                src={dataIcon} />
                            <h1>{`No ${props.entityRel.collectionLabel}`}</h1>
                        </div>
                    </>} />}
            <Pagination
                style={{ marginTop: 'auto' }}
                className='content-center pad_tb15'
                disabled={page.isProcessing || !page.entities}
                count={page.metadata?.totalPages || 0}
                color='primary'
                showFirstButton
                showLastButton
                page={(page.metadata?.number || 0) + 1}
                onChange={onPageChange} />
        </div>
    </>

}

export default SearchEntities

/* eslint-disable */
import { Column, IMetaDataProps, PostProcessor } from 'src/models/charts/metadata/metadata';
import { parseJsonSwallowError } from 'src/utils/charts/chartUtils'
import defaultPalette from './palettes'

class Metadata {
    private metadataObj: IMetaDataProps;
    private parseError: Error = null as any;

    constructor (metadata: IMetaDataProps | string, metadataErrorCallback?: (e: any) => void) {
      if (typeof metadata === 'string') {
        this.metadataObj = parseJsonSwallowError(metadata, (e: any) => {
          this.parseError = e
          if (metadataErrorCallback) {
            metadataErrorCallback(e)
          }
        })
      } else {
        this.metadataObj = metadata
      }
    }

    getParseError () {
      return this.parseError
    }

    getGlobalLinks () {
      if (this.metadataObj) {
        return this.metadataObj.links
      }

      return null
    }

    chartLabel = (id: any) => {
      const agg = this.getAggregate(id)
      return agg ? agg.label : id
    };

    getAggregate = (id: any) => {
      if (!this.metadataObj) return null
      let resultAggregates: any[] = []
      if (
        this.metadataObj &&
            this.metadataObj.related_table &&
            this.metadataObj.related_table.aggregates
      ) {
        resultAggregates = this.metadataObj.related_table.aggregates
      }
      const agg = resultAggregates.find(
        aggregate => this.resolveColumnName(aggregate) === id
      )
      return agg
    };

    getMetaDataObj () {
      return this.metadataObj
    }

    getMetaDataObjStringify () {
      if (this.metadataObj !== null) {
        return JSON.stringify(this.metadataObj)
      }

      return null
    }

    getAggregateKeys = () => {
      if (this.metadataObj) {
        let resultAggregates: any[] = []
        if (
          this.metadataObj &&
                this.metadataObj.related_table &&
                this.metadataObj.related_table.aggregates
        ) {
          resultAggregates = this.metadataObj.related_table.aggregates
        }
        return resultAggregates.map(this.resolveColumnName)
      }
      return null
    };

    getColumnKeys = () => {
      if (this.metadataObj) {
        let resultColumns: any[] = []
        if (
          this.metadataObj &&
                this.metadataObj.related_table &&
                this.metadataObj.related_table.columns
        ) {
          resultColumns = this.metadataObj.related_table.columns
        }
        return resultColumns.map(this.resolveColumnName)
      }
      return null
    };

    getDrilldownLinks = () => {
      const aggregates = this.metadataObj?.related_table?.aggregates
      const aggregateLinks = aggregates?.[0]?.links

      const globalLinks = this.metadataObj?.links

      const getFirstNonEmpty = (...arrays: (any[] | undefined)[]) => arrays.find(a => !!a?.length)

      return getFirstNonEmpty(aggregateLinks, globalLinks) || []
    };

    getType = (type: any) => {
      // convert our type to google chart type
      let gtype = null
      switch (type) {
        case 'sum':
          gtype = 'number'
          break
        default:
          gtype = 'string'
          break
      }
      return gtype
    };

    // get column metadata (type, label) by id
    getColumn = (name: string) => {
      const columns = this.metadataObj?.related_table?.columns || [];
      const aggregates = this.metadataObj?.related_table?.aggregates || [];

      const column = columns.find(c => this.resolveColumnName(c) === name)
          || aggregates.find(a => this.resolveColumnName(a) === name);

      if (!column)
        return;

      return {
        type: this.getType(column["type"]),
        label: column.label,
      };
    };

    getPalette = (): number[][] => {
      if (this.metadataObj && this.metadataObj.palette) {
        return this.metadataObj.palette
      }

      return defaultPalette
    };


    hasCustomPalette = (): boolean => {
      if (this.metadataObj && this.metadataObj.palette) {
        return true
      }
      return false
    };

    applyPostProcessors = (row: Record<string, unknown>) => {
      const columns = [
        ...this.metadataObj?.related_table?.columns || [],
        ...this.metadataObj?.related_table?.aggregates || [],
      ];

      const postProcessors = columns.reduce((obj, column) => {
          const postProcessor = (() => {
            if (column.post_process_expr)
              return PostProcessor.fromExpression(column.post_process_expr);
            
            if (column.post_process)
              return PostProcessor.get(column.post_process);

            return;
          })();

          return { ...obj, ...postProcessor && { [this.resolveColumnName(column)]: postProcessor } };
        }, {} as { [columnName: string]: PostProcessor });

      const postProcessedResult = Object.entries(postProcessors)
        .reduce((newRow, [columnName, postProcessor]) => {
          const value = newRow[columnName];

          let result;

          try {
            result = postProcessor.apply(value);
          } catch (e) {
            console.warn(e);
            return newRow;
          }

          if (typeof result === "object" && result !== null) {
            // remove the old value
            delete newRow[columnName];

            // unpack the result
            Object.entries(result).forEach(([k, v]) => newRow[k] = v);
          } else {
            // set the result
            newRow[columnName] = result;
          }

          return newRow;
        }, row);

      return postProcessedResult;
    }

    resolveName = (name: string) => {
      const metadataName = this.metadataObj.name;
      return metadataName ? `${metadataName}.${name}` : name;
    }

    resolveColumnName = (column: Column) => this.resolveName(column.name);

}

export default Metadata

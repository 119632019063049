import axios from 'axios'
import axiosRetry from 'axios-retry'
import React, { useEffect, useState, useRef } from 'react'
import { logError } from 'src/utils/Error'
import { useAuth0 } from '../contexts/auth0'
import AppEnv from 'src/utils/appenv'
export default axios.create({
  headers: {
    'Content-type': 'application/json'
  }
})

//
// API context, to handle retry and initialisation
//

const APIContext = React.createContext()
APIContext.displayName = 'APIContext'

function APIProvider ({ children }) {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  const [catalogAPI, setCatalogAPI] = useState()
  const [error, setError] = useState()
  const [showNewInsight, setShowNewInsight] = useState(false)
  const [newDatasets, setNewDatasets] = useState([])
  const { getTokenSilently, loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const rootRef = useRef()

  const clearErrors = () => setError(null)

  /*
   * Create error view on errors
   *
   * This can be disabled for specific requests with:
   *      axios.get('/example', {errorHandler: false}).then()
   */
  const errorHandler = error => {
    if (!isMounted.current) { return }

    // is our error handler disabled for this request
    const isErrorHandleDisabled = error.config?.errorHandler === false

    if (axios.isCancel(error) || isErrorHandleDisabled) {
      return Promise.reject({ ...error })
    }

    if (error.response && error.response.data && error.response.data.message) {
      // API returned a well formed error response (5xx, 4xx)
      setError({ status: error.response.status, response: error.response.data })
    } else if (error.error === 'login_required') {
      loginWithRedirect();
    } else if (error.error === 'consent_required') {
      loginWithRedirect();
    } else if (error.response && error.response.status === 401) {
      loginWithRedirect();
    } else if (error.response) {
      // API returned a generic response (5xx, 4xx)
      setError({ message: '[' + error.response.status + '] ' + JSON.stringify(error.response.data) })
    } else if (error.request) {
      // client never received a response, or request never left
      setError({ request: error.request, config: error.config, message: error.message })
    } else {
      // anything else
      setError(error)
    }
    return Promise.reject({ ...error })
  }

  const requestHandler = async request => {
    const token = await getTokenSilently()
    request.headers.Authorization = `Bearer ${token}`
    return request
  }

  const successHandler = response => {
    // Handle responses
    return response
  }

  const search = (workspaceId, handleResults, searchResults, nextPageUrl, query) => {
    const beginFetch = Date.now()
    let searchLink = `/workspaces/${workspaceId}/feed`
    if (nextPageUrl) {
      searchLink = nextPageUrl
    } else if (query) {
      const cleanQuery = query.replace(/"/g, '')
      searchLink = `/workspaces/${workspaceId}/search?q=${cleanQuery}`
    }
    catalogAPI
      .get(encodeURI(searchLink))
      .then(resp => {
        const endFetch = Date.now()
        let total_count = 0
        let newDatasets = []
        let nextPage = null
        if (resp.data) {
          if (resp.data._embedded && resp.data._embedded.datasets) {
            newDatasets = resp.data._embedded.datasets
            newDatasets = newDatasets.map(dataset => {
              return dataset
            })
          }
          if (resp.data._links.next) {
            nextPage = resp.data._links.next.href
          }
          if (nextPageUrl) {
            newDatasets = searchResults.datasets.concat(newDatasets)
          }
          if (resp.data.page) {
            total_count = resp.data.page.totalElements
          } else {
            total_count = newDatasets.length
          }
        }
        handleResults(newDatasets, endFetch - beginFetch, total_count, nextPage, query)
      })
      .catch(e => {
        logError(e)
        const endFetch = Date.now()
        handleResults([], endFetch - beginFetch, 0)
      })
  }

  const searchNews = (workspaceId, handleResults, searchResults, nextPageUrl, query) => {
    const beginFetch = Date.now()
    let searchLink = `/workspaces/${workspaceId}/news`
    if (nextPageUrl) {
      searchLink = nextPageUrl
    } else if (query) {
      searchLink = `/workspaces/${workspaceId}/news?q=${query}`
    }
    catalogAPI
      .get(encodeURI(searchLink))
      .then(resp => {
        const endFetch = Date.now()
        const newNotifications = resp.data._embedded?.notifications || []
        const newDatasets = newNotifications
          .filter(notification => {
            const hasDataset = !!notification._embedded?.dataset

            if (!hasDataset)
              logError({ message: `News item should be associated with a dataset:\n\n${JSON.stringify(notification, null, 2)}` })

            return hasDataset
          })
          .map(notification => {
            const dataset = notification._embedded.dataset
            dataset._embedded = {
              notification: notification,
              source: notification._embedded.channel,
              datasetComment: notification._embedded.datasetComment,
              subscriberProfile: notification._embedded.subscriberProfile
            }
            dataset._links = { data: notification._links.data }
            return dataset
          })

        const nextPage = resp.data._links.next?.href

        if (nextPageUrl) {
          newDatasets = searchResults.datasets.concat(newDatasets)
        }

        const totalCount = resp.data.page?.totalElements ?? newDatasets.length

        handleResults(newDatasets, endFetch - beginFetch, totalCount, nextPage, query)
      })
      .catch(e => {
        logError(e)
        const endFetch = Date.now()
        handleResults([], endFetch - beginFetch, 0)
      })
  }

  const fetchFeeds = (workspaceId, searchResults) => {
    let feedLink = `/workspaces/${workspaceId}/feed?page=0&size=10`
    if (searchResults.query) {
      feedLink = `/workspaces/${workspaceId}/search?page=0&size=10&q=${searchResults.query}`
    }
    catalogAPI
      .get(encodeURI(feedLink))
      .then(resp => {
        if (resp.data._embedded) {
          const { datasets } = searchResults
          if (datasets) {
            const feed = resp.data._embedded.datasets
            var difference = feed.filter(function (obj1) {
              return !datasets.some(function (obj2) {
                return obj1.id === obj2.id
              })
            })
          }

          if (difference && difference.length > 0) {
            setShowNewInsight(true)
            setNewDatasets(difference)
          }
        }
      })
      .catch(e => {
        logError(e)
      })
  }

  const updateDatasets = (searchResults, handleSearchResults) => {
    const { datasets } = searchResults
    const updatedDatasets = newDatasets.concat(datasets)
    handleSearchResults(
      updatedDatasets,
      searchResults.fetchtime,
      searchResults.hits,
      searchResults.nextPageUrl,
      searchResults.query
    )
    setShowNewInsight(false)
  }

  const removeDataset = (searchResults, handleSearchResults, removedDataset) => {
    const { datasets } = searchResults
    const updatedDatasets = datasets.filter(item => item.id !== removedDataset.id)
    handleSearchResults(
      updatedDatasets,
      searchResults.fetchtime,
      searchResults.hits,
      searchResults.nextPageUrl,
      searchResults.query
    )
  }

  const getRoot = () => catalogAPI.get('/').then(r => {
    rootRef.current = r.data
    return rootRef.current
  })

  useEffect(() => {
    if (!loading) {
      const api = axios.create({
        baseURL: AppEnv.APP_SERVER_URI
      })
      // 3x retry, exponential back-off retry delay between requests
      axiosRetry(api, {
        retries: 3,
        retryDelay: axiosRetry.exponentialDelay
      })
      api.interceptors.request.use(request => requestHandler(request))
      api.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
      )
      setCatalogAPI(() => api)
    }
  }, [getTokenSilently, isAuthenticated, loading])

  /*
   * This api error handling implementation will simply rerender the App.
   * We should instead move the success processing inside a dedicated api instance
   * for each request.  Using this technique, we could then retry using the use the
   * axios.request object from the error.config and instance that was created for this
   * request would handler the success of the original request.
   */
  const reintializeApi = () => {
    clearErrors()
    if (!loading) {
      const api = axios.create({
        baseURL: AppEnv.APP_SERVER_URI
      })
      // 3x retry, exponential back-off retry delay between requests
      axiosRetry(api, {
        retries: 3,
        retryDelay: axiosRetry.exponentialDelay
      })
      api.interceptors.request.use(request => requestHandler(request))
      api.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
      )
      setCatalogAPI(() => api)
    }
  }

  return (
        <APIContext.Provider
            value={{
              catalogAPI,
              rootRef,
              getRoot,
              error,
              clearErrors,
              reintializeApi,
              search,
              fetchFeeds,
              showNewInsight,
              updateDatasets,
              removeDataset,
              searchNews
            }}
        >
            {children}
        </APIContext.Provider>
  )
}

function APIConsumer ({ children }) {
  return (
        <APIContext.Consumer>
            {context => {
              if (context === undefined) {
                throw new Error('APIConsumer must be used within a APIProvider')
              }
              return children(context)
            }}
        </APIContext.Consumer>
  )
}

function useAPIContext () {
  const context = React.useContext(APIContext)
  if (context === undefined) {
    throw new Error('useAPIContext must be used within a APIProvider')
  }
  return context
}

const getProfileURI = id => encodeURI(`/profiles/${id}`)
const createUpdateProfileURI = id => encodeURI(`/profiles/${id}`)
const createNewWorkspaceURI = () => encodeURI('/workspaces')
const updateWorkspaceURI = id => encodeURI(`/workspaces/${id}`)
const getWorkspaceURI = () => encodeURI('/workspaces?size=99')
const getSingleWorkspaceURI = id => encodeURI(`/workspaces/${id}`)
const getUserInvitationsURI = email => {
  email = encodeURIComponent(email)
  return `/invitations?email=${email}`
}
const getWorkspaceChannelsURI = id => encodeURI(`/workspaces/${id}/channels`)
const getChannelURI = id => encodeURI(`/channels/${id}`)
const getDatasetURI = (workspaceId, datasetIdOrAlias) =>
  encodeURI(`/workspaces/${workspaceId}/datasets/${datasetIdOrAlias}`)
const recordViewDatasetURI = id => encodeURI(`/datasets/${id}/view`)
const recordLikeDatasetURI = id => encodeURI(`/datasets/${id}/like`)
const deleteLikeDatasetURI = id => encodeURI(`/datasets/${id}/like`)
const getWorkspaceLikedURI = id => encodeURI(`/workspaces/${id}/liked`)
const viewAllDatasetCommentURI = id => encodeURI(`/datasets/${id}/comments?size=6`)
const intializeDatasetCommentURI = id => encodeURI(`/datasets/${id}/comments`)
const createDatasetCommentURI = (datasetId, id) =>
  encodeURI(`/datasets/${datasetId}/comments/${id}`)
const intializeCommentReplyURI = id => encodeURI(`/comments/${id}`)
const recordLikeCommentURI = id => encodeURI(`/comments/${id}/like`)
const deleteLikeCommentURI = id => encodeURI(`/comments/${id}/like`)
const viewAllCommentReplyURI = (datasetId, id) =>
  encodeURI(`/datasets/${datasetId}/comments?parentId=${id}`)
const deleteWorkspaceURI = id => encodeURI(`/workspaces/${id}`)
const getWorkspaceTagsURI = id => encodeURI(`/workspaces/${id}/tags`)
const deleteDatasetCommentURI = id => encodeURI(`/comments/${id}`)
const deleteDatasetURI = id => encodeURI(`/datasets/${id}`)
const updateDatasetDescriptionURI = id => encodeURI(`/datasets/${id}`)
const subscribeDatasetURI = id => encodeURI(`datasets/${id}/subscriptions`)
const deleteSubscribeDatasetURI = id => encodeURI(`subscriptions/${id}`)
const getWorkspaceMembersURI = id => encodeURI(`/workspaces/${id}/members`)
const getNotificationURI = (all, before, since, markAsResolved) =>
  encodeURI(
        `/notifications?all=${all}&before=${before}&since=${since}&markAsResolved=${markAsResolved}`
  )
const deleteNotificationsURI = id => encodeURI(`notifications/${id}`)
const getWorkspaceNewsTagsURI = id => encodeURI(`/workspaces/${id}/news/tags`)
const getWorkspaceNewsChannelsURI = id => encodeURI(`/workspaces/${id}/news/channels`)
const getPipelineURI = (pipelineId) => encodeURI(`/pipelines/${pipelineId}`)
const pipelineInitialisationURI = id => encodeURI(`/workspaces/${id}/pipelines`)
const getAllWorkspaceDatasourcesURI = id => encodeURI(`/workspaces/${id}/dataplugins?sort=label&size=9999`)
const getDataPluginURI = id => encodeURI(`/dataplugins/${id}`)
const getAllApiKeysURI = () => encodeURI(`/apikeys`)
const getDatastoreURI = (datastoreId) => encodeURI(`/datastores/${datastoreId}`)
const datastoreInitialisationURI = id => encodeURI(`/workspaces/${id}/datastores`)
const getAllJobs = id => encodeURI(`/workspaces/${id}/jobs`)
const deleteWorkspaceJobURI = (jobId) => encodeURI(`/jobs/${jobId}`)
const getDatasetsInChannelURI = id => encodeURI(`/channels/${id}/datasets`)

export {
  APIContext,
  APIProvider,
  APIConsumer,
  useAPIContext,
  getProfileURI,
  createUpdateProfileURI,
  createNewWorkspaceURI,
  updateWorkspaceURI,
  getWorkspaceURI,
  getSingleWorkspaceURI,
  getUserInvitationsURI,
  getWorkspaceChannelsURI,
  getDatasetURI,
  recordViewDatasetURI,
  recordLikeDatasetURI,
  deleteLikeDatasetURI,
  viewAllDatasetCommentURI,
  intializeDatasetCommentURI,
  createDatasetCommentURI,
  intializeCommentReplyURI,
  recordLikeCommentURI,
  deleteLikeCommentURI,
  getWorkspaceLikedURI,
  viewAllCommentReplyURI,
  deleteWorkspaceURI,
  getWorkspaceTagsURI,
  deleteDatasetCommentURI,
  deleteDatasetURI,
  updateDatasetDescriptionURI,
  subscribeDatasetURI,
  deleteSubscribeDatasetURI,
  getWorkspaceMembersURI,
  getNotificationURI,
  deleteNotificationsURI,
  getWorkspaceNewsTagsURI,
  getWorkspaceNewsChannelsURI,
  getPipelineURI,
  pipelineInitialisationURI,
  getAllWorkspaceDatasourcesURI,
  getDataPluginURI,
  getAllApiKeysURI,
  getDatastoreURI,
  datastoreInitialisationURI,
  getAllJobs,
  deleteWorkspaceJobURI,
  getDatasetsInChannelURI,
  getChannelURI
}

// Merged Context
import { createContext } from 'react'

export let mergedContextsValue

const setValue = newValues => {
  for (const key in newValues) {
    if (['reset', 'setValue'].indexOf(key) === -1 && newValues.hasOwnProperty(key)) {
      mergedContextsValue[key] = newValues[key]
    }
  }
}

const reset = () => {
  // Keep the setValue and reset methods attached always
  // to update the values of contexts to be merged
  mergedContextsValue = {
    setValue,
    reset
  }
}

reset()

const MergedContexts = createContext(mergedContextsValue)
MergedContexts.displayName = 'MergedContexts'

export { MergedContexts }

import React, { FunctionComponent, PropsWithChildren } from 'react'

const DataStoreImage : FunctionComponent<any> = (
    props: PropsWithChildren<any> 
    ) => {
        const { size, helpButtonClicked, questionMark, dataStoreImg, dataStoreLabel, imageClass, helpClass } = props;

        return(
            <div className="image-holder datastore">
                {size && size.smallScreen && 
                <div title="Help" className={"help-button"} onClick={helpButtonClicked}>
                    <img className={helpClass && helpClass} src={questionMark} alt={questionMark} />
                </div>}
                <img src={dataStoreImg} alt={dataStoreLabel && dataStoreLabel} className={imageClass && imageClass} />
                <span>{dataStoreLabel}</span>
            </div>
        )
    }

export default DataStoreImage;
/* eslint-disable */
import React, { useState } from 'react'
import { useAuth0 } from '../contexts/auth0'
import AppEnv from '../utils/appenv'

const CatalogApi = () => {
  const [showResult, setShowResult] = useState(false)
  const [apiMessage, setApiMessage] = useState('')
  const { getTokenSilently } = useAuth0()

  const callApi = async () => {
    try {
      const serverUri = AppEnv.APP_SERVER_URI
      const requestUrl = serverUri + '/workspaces'
      const token = await getTokenSilently()

      const response = await fetch(requestUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const responseData = await response.json()

      setShowResult(true)
      setApiMessage(responseData)
    } catch (error) {
      console.error(error)
    }
  }

  return (
        <>
            <h1>Catalog API</h1>
            <button onClick={callApi}>Ping API</button>
            {showResult && <code>{JSON.stringify(apiMessage, null, 2)}</code>}
        </>
  )
}

export default CatalogApi

import React, { useState } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import searchIcon from 'src/assets/images/search_icon.svg'
import addIcon from 'src/assets/images/svgs/add.svg'
import { ISearchProps } from 'src/models/components/ISearch'
import EntityRel from 'src/models/EntityRel'
import IDataPlugin from 'src/models/IDataPlugin'
import IDataStore from 'src/models/IDataStore'
import IDataComponent from 'src/models/IDataComponent'
import { INamedEntity } from 'src/models/IEntity'
import IChannel from 'src/models/IChannel'
import IPipeline from 'src/models/IPipeline'

export const Search = <E extends INamedEntity>(props: ISearchProps<E>): JSX.Element => {

    const [searchText, setSearchText] = useState("")

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value)
        props.onValueChanged && props.onValueChanged(e.target.value)
    }
    const handleSearchClear = () => {
        setSearchText("")
        props.onValueChanged && props.onValueChanged("")
    }

    const searchableFields = [
        'alias',
        'name',
        'label',
        'description',
        'source'
    ]

    const searchResults = props.disableSearch ? props.entities : props.entities?.filter(e => searchableFields.find(f => e[f]?.toLowerCase().includes(searchText.toLowerCase())))

    return <>
        <ScrollAnimation
            animateIn='fadeIn'
            offset={10}
            className='clearfix d-md-block'>
            <div className='data-search-container'>
                <form className='data-search'>
                    <span
                        className={`dataPlugin-search data-search-clear d-${searchText ? 'block' : 'none'}`}
                        onClick={handleSearchClear}>
                        <img
                            src={addIcon}
                            alt="Clear search"
                            title="Clear search" />
                    </span>
                    <input
                        type='search'
                        className='data-search-bar'
                        onChange={handleSearchChange}
                        placeholder={props.customSearchPlaceholder || `Search ${props.entityRel.collectionLabel}...`}
                        value={searchText} />
                    <img
                        className='data-search-icon'
                        src={searchIcon}
                        alt="Search"
                        title="Search" />
                </form>
                {props.new}
            </div>
        </ScrollAnimation>
        <div className='clearfix flex-wrap fullWidth gridview'>
            {props.beforeRows}
            {searchResults?.length
                ? searchResults.map(props.row)
                : props.noEntities}
        </div>
    </>

}

export default Search

export const DataPluginSearch = (props: Omit<ISearchProps<IDataPlugin>, 'entityRel'>): JSX.Element =>
    <Search
        entityRel={EntityRel.DataPlugin}
        {...props} />

export const DataStoreSearch = (props: Omit<ISearchProps<IDataStore>, 'entityRel'>): JSX.Element =>
    <Search
        entityRel={EntityRel.DataStore}
        {...props} />

export const DataComponentSearch = (props: Omit<ISearchProps<IDataComponent>, 'entityRel'>): JSX.Element =>
    <Search
        entityRel={EntityRel.DataComponent}
        {...props} />

export const LibraryChannelSearch = (props: Omit<ISearchProps<IChannel>, 'entityRel'>): JSX.Element =>
    <Search
        entityRel={EntityRel.Channel}
        {...props} />

export const LibraryListSearch = (props: Omit<ISearchProps<IChannel>, 'entityRel'>): JSX.Element =>
    <Search
        entityRel={EntityRel.Channel}
        customSearchPlaceholder={'Search lists...'}
        {...props} />

export const PipelineSearch = (props: Omit<ISearchProps<IPipeline>, 'entityRel'>): JSX.Element =>
    <Search
        entityRel={EntityRel.Pipeline}
        {...props} />

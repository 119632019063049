import React, { useRef, useState, FunctionComponent, PropsWithChildren } from 'react';
import useRowStyles from 'src/assets/css/components/RowComponentsStyles';
import 'src/styles.css'
import { ICommentActionsProps } from 'src/models/components/ICommentActions'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { MoreVert } from '@material-ui/icons'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import Spinner from 'src/components/Spinner'

const CommentActions: FunctionComponent<ICommentActionsProps> = (props: PropsWithChildren<ICommentActionsProps>) => {

    const classes = useRowStyles();
    const menuAnchorEl = useRef<EventTarget & HTMLButtonElement>()
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [isMenuOpen, setMenuOpen] = useState(false)

    const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMenuOpen(true)
        menuAnchorEl.current = e.currentTarget
    }
    const handleCloseMenu = () => setMenuOpen(false)

    const handleDelete = async () => {
      setConfirmDialogOpen(false)
      setMenuOpen(false)
      await props.handleDelete()
    }

    const handleEdit = () => {
      setMenuOpen(false)
      props.handleEdit()
    }

    const { comment } = props

    const iconButton = (
        <div>
            <IconButton
                className={classes.button}
                onClick={handleOpenMenu}
                data-testid='actions-menu'
                aria-label='More'
                title="More">
                <MoreVert className={classes.largeIcon} />
            </IconButton>
        </div>
    );
    
    const buttons = (
        <div className='inline-vertical-center'>
            {iconButton}
            <Menu
                className={classes.dropdownMenu}
                open={isMenuOpen}
                anchorEl={menuAnchorEl.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseMenu}
            >
                {comment._links && comment._links['update comment'] != null && (
                  <MenuItem
                    className={classes.dropdownItem}
                    dense
                    onClick={handleEdit}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.947 383.947">
                        <g>
                            <polygon points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893" />
                            <path d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04 C386.027,77.92,386.027,64.373,377.707,56.053z" />
                        </g>
                    </svg>
                    <span>Edit</span>
                  </MenuItem>)}
                {comment._links && comment._links['delete comment'] != null && (
                  <MenuItem
                    className={classes.dropdownItem}
                    dense
                    onClick={ () => setConfirmDialogOpen(true) }
                  >
                    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path d="m424 64h-88v-16c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16h-88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zm-216-16c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96z" />
                            <path d="m78.364 184c-2.855 0-5.13 2.386-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042c.136-2.852-2.139-5.238-4.994-5.238zm241.636 40c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z" />
                        </g>
                    </svg>
                    <span>Delete</span>
                  </MenuItem>)}
            </Menu>
        </div>
    )
    
    const contextMenu = (
        <div className="rightSide_verticalDots">
            {(comment._links && comment._links['delete comment'] != null) ||
            (comment._links && comment._links['update comment'] != null)
              ? (
                <Spinner active={props.isLoading}>
                    {buttons}
                </Spinner>
                )
              : null}
        </div>
    )

    return (
        <>
            {contextMenu}
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                title={`Delete comment?`}
                message="Remove this comment. This action cannot be undone."
                cancelLabel="Close"
                confirmLabel="Delete"
                handleClose={ () => setConfirmDialogOpen(false) }
                handleConfirm={ handleDelete } />
        </>
    )
}

export default CommentActions

import React, { useContext, FunctionComponent, useEffect, useRef, useState } from 'react'
import { ICustomDataPluginRowProps, IDataPluginRowProps } from 'src/models/components/IDataPluginRow'
import { useAPIContext } from 'src/contexts/api'
import { WindowContext } from 'src/contexts/window';
import { Button, IconButton, Link, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import DataSourceImage from 'src/components/DataSourceImage'
import chainIcon from 'src/assets/images/svgs/chain-icon.svg'
import ReactMarkdown from 'react-markdown'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { useDataPlugin } from 'src/hooks/useCRUDEntity'
import CustomDataPluginDialog from 'src/components/CustomDataPluginDialog'
import AddIcon from '@material-ui/icons/Add'
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import useRowStyles from 'src/assets/css/components/RowComponentsStyles';

interface IRow {
    label: string
    description: {
        content: string
        isExpanded?: boolean
        handleToggleExpanded?: () => void
    }
    imageUrl?: string
    menu?: {
        isOpen: boolean
        anchorEl: Element | undefined
        options: {
            label: string
            isDisabled: boolean
            handleClick: (e: React.MouseEvent<HTMLLIElement>) => void
        }[]
        handleOpen: (e: React.MouseEvent<HTMLButtonElement>) => void
        handleClose: () => void
    }
    select?: {
        label?: React.ReactNode
        icon?: React.ReactNode
        isDisabled?: boolean
        onClick?: () => void
    }
    labels?: JSX.Element
}

export const Row: FunctionComponent<IRow> = props => {
    const classes = useRowStyles();
    const { size } = useContext(WindowContext);

    const iconButton = props.menu && (
        <div className={`${(size.smallScreen || size.mediumScreen) ? 'actions-menu-mobile' : ''}`}>
            <IconButton
                className={classes.button}
                aria-label='more'
                onClick={props.menu.handleOpen}
                title="More">
                <MoreVert className={classes.largeIcon} />
            </IconButton>
        </div>
    );

    const buttons = props.menu && (
        <>
            {iconButton}
            <Menu
                aria-label='menu'
                open={props.menu.isOpen}
                anchorEl={props.menu.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={props.menu.handleClose}>
                {props.menu.options.map((o, i) =>
                    <MenuItem
                        key={i}
                        aria-label={o.label}
                        dense
                        disabled={o.isDisabled}
                        onClick={o.handleClick}>
                        {o.label}
                    </MenuItem>
                )}
            </Menu>
        </>
    )

    return <>
        <div
            aria-label={props.label}
            className='dataRow-container'
            style={{ backgroundColor: 'white' }}
            data-testid='row'>
            <div className={`${props.description?.isExpanded ? 'row-info-container source-search' : 'row-info-container source-search flex-wrap collapse-pipeline-datastore'}`}>
                <div className='row-data'>
                    <DataSourceImage
                        dataSourceImg={props.imageUrl || chainIcon}
                        imageClass='plugin-img'
                        dataSourceLabel={props.label}
                    />
                    <div className="row-content">
                        <div className='pipeline-datastore-info'>
                            <div>
                                <h3>{props.label}</h3>
                                {props.description.content && <>
                                    <ReactMarkdown
                                        className={[
                                            'description',
                                            props.description.isExpanded && 'more'
                                        ].filter(Boolean).join(' ')} >
                                        {props.description.content}
                                    </ReactMarkdown>
                                    <Link
                                        className='more'
                                        component='button'
                                        underline='always'
                                        onClick={props.description.handleToggleExpanded}>
                                        {props.description.isExpanded ? "Less": "More"}
                                    </Link>
                                </>}
                            </div>
                            {buttons}
                        </div>
                        <div className='inline-vertical-center row-labels'>
                            {props.labels && props.labels}
                            {props.select && <Button
                                color='primary'
                                variant='contained'
                                disabled={props.select.isDisabled}
                                onClick={props.select.onClick}
                                startIcon={props.select.icon}
                                style={{
                                    marginLeft: 'auto',
                                    transform: 'scale(0.85)',
                                    zIndex: 2
                                }}>
                                {props.select.label || "Add"}
                            </Button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}


const DataPluginRow: FunctionComponent<IDataPluginRowProps> = props => {

    const { catalogAPI } = useAPIContext()
    const dataPlugin = useDataPlugin(catalogAPI, props.dataPlugin)

    const [isExpanded, setExpanded] = useState(false)
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [isInstalling, setInstalling] = useState(false)

    const menuAnchorElRef = useRef<Element>()

    useEffect(() => {
        if (isMenuOpen) return
        menuAnchorElRef.current = undefined
    }, [isMenuOpen])

    const handleRowAction = () => {
        setInstalling(true)
        props.handleAdd?.(dataPlugin)
        props.handleInstall?.(dataPlugin)
    }

    const handleToggleExpanded = () => setExpanded(!isExpanded)

    const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        menuAnchorElRef.current = e.currentTarget
        setMenuOpen(true)
    }

    const handleCloseMenu = () => setMenuOpen(false)

    const handleMenuDelete = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation()
        setDeleteDialogOpen(true)
        handleCloseMenu()
    }

    const handleDeleteDialogClose = () => setDeleteDialogOpen(false)
    const handleDeleteDialogClosed = () => props.handleDeleted(dataPlugin)
    return <>
        {!dataPlugin.isDeleted &&
            <Row
                label={dataPlugin.label ? `${dataPlugin.label}` : dataPlugin.name}
                imageUrl={dataPlugin.logoUrl}
                menu={{
                    isOpen: isMenuOpen,
                    anchorEl: menuAnchorElRef.current,
                    options: [
                        {
                            label: "Delete",
                            isDisabled: !dataPlugin.canDelete,
                            handleClick: handleMenuDelete
                        }
                    ],
                    handleOpen: handleOpenMenu,
                    handleClose: handleCloseMenu
                }}
                description={{
                    content: dataPlugin.description,
                    isExpanded: isExpanded,
                    handleToggleExpanded: handleToggleExpanded
                }}
                select={{
                    label: props.handleAdd ? dataPlugin.pluginType === 'EXTRACTOR' ? "Pipeline" : "Add" : "Install",
                    icon: props.handleAdd && dataPlugin.pluginType === 'EXTRACTOR' && <AddIcon />,
                    isDisabled: !props.handleAdd && (isInstalling || !dataPlugin._links['add dataplugin']),
                    onClick: handleRowAction
                }}
                labels={
                    <>
                        {dataPlugin.pluginType &&
                            <span className='inline-vertical-center datastore-date'>
                                <CodeRoundedIcon style={{ fontSize: 12 }} />
                                {dataPlugin.pluginType}
                            </span>}
                        {dataPlugin.variant !== 'matatika' &&
                            <span className='inline-vertical-center datastore-date'>
                                <PeopleRoundedIcon style={{ fontSize: 12 }} />
                                Community Supported
                            </span>}
                        {dataPlugin.variant !== 'matatika' &&
                            <span className='inline-vertical-center datastore-date'>
                                <PeopleRoundedIcon style={{ fontSize: 12 }} />
                                {dataPlugin.variant}
                            </span>}
                    </>
                }
                />
        }
        <ConfirmDialog
            isOpen={isDeleteDialogOpen}
            title={`Delete '${dataPlugin.label || dataPlugin.name}'?`}
            message="Remove this plugin from the workspace. This action cannot be undone."
            confirmLabel="Delete"
            handleClose={handleDeleteDialogClose}
            handleConfirm={dataPlugin.delete_}
            handleClosed={handleDeleteDialogClosed} />
    </>

}

export default DataPluginRow

export const CustomDataPluginRow: FunctionComponent<ICustomDataPluginRowProps> = props => {

    const [isExpanded, setExpanded] = useState(!!props.isExpanded)
    const [isAddCustomDialogOpen, setAddCustomDialogOpen] = useState(false)

    const handleToggleExpanded = () => setExpanded(!isExpanded)
    const handleToggleAddCustomDialogOpen = () => setAddCustomDialogOpen(!isAddCustomDialogOpen)

    return <>
        <Row
            label="Custom"
            description={{
                content: "Custom data sources can be added to your project by uploading a Meltano `discovery.yml`\n\n" +
                    "Your discovery file describes all the Singer.io taps, DBT transforms, and Matatika datasets used to import, transform and visualise your data.\n\n" +
                    "We have lots of examples in our [Documentation](https://www.matatika.com/docs/) and feel free to [get in touch](mailto:support@matatika.com) if you get stuck.",
                isExpanded: isExpanded,
                handleToggleExpanded: handleToggleExpanded

            }}
            select={{ onClick: handleToggleAddCustomDialogOpen }} />
        <CustomDataPluginDialog
            isOpen={isAddCustomDialogOpen}
            handleClose={handleToggleAddCustomDialogOpen}
            handleUpdateDataPlugins={props.handleUpdateDataPlugins}
            handleSelectDataPlugin={props.handleSelectDataPlugin}
        />
    </>

}

import { AxiosInstance } from 'axios'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { logError } from 'src/utils/Error'
import DatasetCard from '../components/DatasetCard'
import newsPlaceholder from '../components/placeholders/NewsPlaceholder';
import datasetsFeedPlaceholder from '../components/placeholders/DatasetsFeedPlaceholder';
import { APIContext, deleteDatasetURI } from '../contexts/api'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { IFeedProps, IFeedState } from '../models/components/IFeed'
import searchIcon from 'src/assets/images/search_icon.svg'
import 'src/assets/css/components/feed.css';
import 'src/styles.css'
import AppEnv from '../utils/appenv'
import { waitUntilReady, AsyncLoader } from '../utils/async'
import { bindContexts } from '../utils/bindContexts'

class Feed extends React.Component<IFeedProps, IFeedState> implements AsyncLoader {
    static contextType = MergedContexts;
    public isThisMounted = false;

    constructor (props: IFeedProps, context: any) {
      super(props)
      const { workspace } = context
      let view = null
      if (this.props.isNews) {
        view = 'largCardView singleView'
      } else {
        view = localStorage.getItem(workspace.id + '-feed-view') ? localStorage.getItem(workspace.id + '-feed-view') :
                  workspace && workspace.appProperties && workspace.appProperties.FEED_VIEW_DEFAULT ? workspace.appProperties.FEED_VIEW_DEFAULT :
                  'gridview'
      }

      this.state = {
        ready: false,
        waited: 0,
        viewClass: view
      }

      this.handleDeleteDataset = this.handleDeleteDataset.bind(this)
      this.handleEditDescription = this.handleEditDescription.bind(this)
      this.handleImportData = this.handleImportData.bind(this)
    }

    // implements AsyncLoader
    public isLoading() : boolean {
      return this.props.isLoading;
    }

    public componentDidMount () {
      this.isThisMounted = true
      // wait at least MINIMUM_LOAD, to show the placeholder smoothly on every request
      waitUntilReady(
        parseInt(AppEnv.APP_PLACEHOLDER_MINIMUM_LOAD, 10),
        parseInt(AppEnv.APP_PLACEHOLDER_LOAD_TIMEOUT, 10),
        this.isThisMounted,
        this
      ).then(result => {
        // console.log("DONE, WAITED TOTAL " + result + " " + this.state.ready);
        if (this.isThisMounted) {
          this.setState({ ready: true, waited: result })
        }
      })
    }

    public componentWillUnmount () {
      // with thanks to - https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
      this.isThisMounted = false
    }

    public shouldComponentUpdate (nextProps: IFeedProps, nextState: IFeedState): boolean {
      if (nextProps.location.key !== this.props.location.key) {
        // force reload if location has changed by user clicking on a Link
        this.props.refreshData()
      }
      if (nextProps.isLoading && nextState.ready) {
        // timeout condition
        return true
      }

      return (
        nextProps.isLoading !== this.props.isLoading ||
            nextState.ready !== this.state.ready ||
            nextProps.datasets !== this.props.datasets ||
            nextProps.currentSearch !== this.props.currentSearch ||
            nextState.viewClass !== this.state.viewClass ||
            nextProps.showNewInsight !== this.props.showNewInsight
      )
    }

    public handleChangeView (view: any) {
      const { workspace } = this.context
      localStorage.setItem(workspace.id + '-feed-view', view)
      this.setState({ viewClass: view })
    }

    private async handleDeleteDataset (dataset: any) {
      dataset.deleteLoading = true
      const { catalogAPI, removeDataset, searchResults, handleSearchResults } = this.context
      const api = catalogAPI as AxiosInstance
      await api
        .delete(deleteDatasetURI(dataset.id))
        .then(() => {
          removeDataset(searchResults, handleSearchResults, dataset)
        })
        .catch(e => {
          logError(e)
        })
      dataset.deleteLoading = false
    }

    private async handleEditDescription (dataset: any) {
      const { workspace, setCurrentDataset } = this.context
      const currentDataset = dataset
      currentDataset.changedDescription = currentDataset.description
      currentDataset.isEditDescription = true
      setCurrentDataset(currentDataset)
      this.props.history.push(
        '/l/' + workspace.id + '/dataset/' + (dataset.alias ? dataset.alias : dataset.id)
      )
    }

    public async handleImportData(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault()
        const { workspace } = this.context
        this.props.history.push(`/lab/${workspace.id}/pipelines`)
    }

    public render () {

      let datasets = null
      let noDatasets = null
      let summary = null
      const { isWorkspaceAdmin } = this.context
      if (
        this.state.ready &&
            this.state.waited >= parseInt(AppEnv.APP_PLACEHOLDER_LOAD_TIMEOUT, 10)
      ) {
        noDatasets = (
                <div key="no-datasets" className="no-datasets text-center">
                    Hang on, it&apos;s taking longer than normal to populate your feed.
                </div>
        )
      } else {
        if (this.props.noResultsMessage) {
          noDatasets = this.props.noResultsMessage
        } else {
          noDatasets = (
            <div key="no-datasets" className="no-datasets content-center">
                <img alt="Datasets" src={searchIcon}></img>
                <h1>There were no datasets matching your query.</h1>
                { isWorkspaceAdmin ?
                  <p>Please refine your search or <a data-testid="import-data" href="#" onClick={this.handleImportData}>Import more data</a>.</p>
                : 
                  <p>Please refine your search.</p>
                }
            </div>
          )
        }
      }
      if (!this.props.isLoading) {
        if (this.props.datasets && this.props.datasets.length > 0) {
          const rows: any[] = []
          this.props.datasets.forEach((dataset, index) => {
            rows.push(
                        <DatasetCard
                            isNews={this.props.isNews}
                            dataset={dataset}
                            key={index}
                            handleDeleteDataset={this.handleDeleteDataset}
                            handleEditDescription={this.handleEditDescription}
                            viewClass={this.state.viewClass}
                        />
            )
          })
          datasets = rows.length > 0 ? rows : ''
        }

        const queryContent = this.props.currentSearch
          ? `Found these results for "${this.props.currentSearch}"`
          : 'Found these results'
        const waitedContent =
                this.props.fetchtime <= 1000 ? 'less than one second' : `${this.props.fetchtime}ms`
        summary = typeof this.props.summaryMessage !== 'undefined' 
          ? (this.props.summaryMessage)
          : (
	                <span id="search-summary" className="align-self-center">
	                    {queryContent} ({waitedContent})
	                </span>
	            )
      }

      const handleLargeCardView = () => this.handleChangeView('largCardView')
      const handleListView = () => this.handleChangeView('listView')
      const handleGridView = () => this.handleChangeView('gridview')
      const handleRefreshView = () => this.props.refreshData()
      const handleLoadMore = () => this.props.loadMore(this.props.nextPageUrl)
      const handleUpdateDatasets = () => this.props.updateDatasets()

      return (
            <>
                {(this.state.ready === false || this.props.isLoading) && (
                    this.props.isNews
                      ? newsPlaceholder
                      : datasetsFeedPlaceholder
                )}

                {this.state.ready && !this.props.isLoading && (
                    <>
                        {!this.props.isNews && (
                            <div
                                className="custom_container clearfix d-block"
                                data-testid="resolved"
                            >
                                <div
                                    className={
                                        (this.state.viewClass === 'largCardView'
                                          ? ' pl-0 pr-0'
                                          : '') + 'col-8 pad_0 float-left d-flex subtitle'
                                    }
                                >
                                    <span className="align-self-center" data-testid="summaryMessage">{summary}</span>
                                </div>
                                {!this.props.isNews && (
                                        <div className="selectViewButton text-right">
                                            <div
                                                className={
                                                    this.state.viewClass === 'largCardView'
                                                      ? 'view_icon active'
                                                      : 'view_icon'
                                                }
                                                onClick={handleLargeCardView}
                                                data-testid={'largCardView-btn'}
                                                aria-label='Large'
                                            >
                                                <svg viewBox="0 0 170 170">
                                                    <rect
                                                        x="0"
                                                        y="0"
                                                        rx="15"
                                                        ry="15"
                                                        width="170"
                                                        height="108"
                                                    />
                                                    <rect
                                                        x="0"
                                                        y="130"
                                                        rx="15"
                                                        ry="15"
                                                        width="170"
                                                        height="40"
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                className={
                                                    this.state.viewClass === 'listView'
                                                      ? 'view_icon active'
                                                      : 'view_icon'
                                                }
                                                onClick={handleListView}
                                                data-testid={'listview-btn'}
                                                aria-label='List'
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 47.855 38.07"
                                                >
                                                    <g
                                                        id="Group_234"
                                                        data-name="Group 234"
                                                        transform="translate(0 -52)"
                                                    >
                                                        <path
                                                            id="Path_137"
                                                            data-name="Path 137"
                                                            d="M0,52H10.589V62.589H0Z"
                                                        />
                                                        <path
                                                            id="Path_138"
                                                            data-name="Path 138"
                                                            d="M149.3,52h33.9V62.589H149.3Z"
                                                            transform="translate(-135.343)"
                                                        />
                                                        <path
                                                            id="Path_139"
                                                            data-name="Path 139"
                                                            d="M0,199.008H10.589V209.6H0Z"
                                                            transform="translate(0 -133.267)"
                                                        />
                                                        <path
                                                            id="Path_140"
                                                            data-name="Path 140"
                                                            d="M149.3,199.008h33.9V209.6H149.3Z"
                                                            transform="translate(-135.343 -133.267)"
                                                        />
                                                        <path
                                                            id="Path_141"
                                                            data-name="Path 141"
                                                            d="M0,346.012H10.589V356.6H0Z"
                                                            transform="translate(0 -266.531)"
                                                        />
                                                        <path
                                                            id="Path_142"
                                                            data-name="Path 142"
                                                            d="M149.3,346.012h33.9V356.6H149.3Z"
                                                            transform="translate(-135.343 -266.531)"
                                                        />
                                                    </g>
                                                </svg>
                                            </div>
                                            <div
                                                className={
                                                    (this.state.viewClass === 'gridview'
                                                      ? 'view_icon active'
                                                      : 'view_icon') + ' d-none d-md-inline-block'
                                                }
                                                onClick={handleGridView}
                                                data-testid={'gridview-btn'}
                                                aria-label='Grid'
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 38.589 38.07"
                                                >
                                                    <g
                                                        id="Group_235"
                                                        data-name="Group 235"
                                                        transform="translate(-1 -52)"
                                                    >
                                                        <path
                                                            id="Path_137"
                                                            data-name="Path 137"
                                                            d="M0,52H10.589V62.589H0Z"
                                                            transform="translate(1)"
                                                        />
                                                        <path
                                                            id="Path_139"
                                                            data-name="Path 139"
                                                            d="M0,199.008H10.589V209.6H0Z"
                                                            transform="translate(1 -133.267)"
                                                        />
                                                        <path
                                                            id="Path_141"
                                                            data-name="Path 141"
                                                            d="M0,346.012H10.589V356.6H0Z"
                                                            transform="translate(1 -266.531)"
                                                        />
                                                        <path
                                                            id="Path_145"
                                                            data-name="Path 145"
                                                            d="M0,52H10.589V62.589H0Z"
                                                            transform="translate(15)"
                                                        />
                                                        <path
                                                            id="Path_146"
                                                            data-name="Path 146"
                                                            d="M0,199.008H10.589V209.6H0Z"
                                                            transform="translate(15 -133.268)"
                                                        />
                                                        <path
                                                            id="Path_147"
                                                            data-name="Path 147"
                                                            d="M0,346.012H10.589V356.6H0Z"
                                                            transform="translate(15 -266.531)"
                                                        />
                                                        <path
                                                            id="Path_148"
                                                            data-name="Path 148"
                                                            d="M0,52H10.589V62.589H0Z"
                                                            transform="translate(29)"
                                                        />
                                                        <path
                                                            id="Path_149"
                                                            data-name="Path 149"
                                                            d="M0,199.008H10.589V209.6H0Z"
                                                            transform="translate(29 -133.268)"
                                                        />
                                                        <path
                                                            id="Path_150"
                                                            data-name="Path 150"
                                                            d="M0,346.012H10.589V356.6H0Z"
                                                            transform="translate(29 -266.531)"
                                                        />
                                                    </g>
                                                </svg>
                                            </div>
                                            <div
                                                className="view_icon"
                                                onClick={handleRefreshView}
                                                data-testid={'reload-btn'}
                                            >
                                                <svg viewBox="0 0 438.529 438.528">
                                                    <g>
                                                        <g>
                                                            <path
                                                                d="M433.109,23.694c-3.614-3.612-7.898-5.424-12.848-5.424c-4.948,0-9.226,1.812-12.847,5.424l-37.113,36.835
														c-20.365-19.226-43.684-34.123-69.948-44.684C274.091,5.283,247.056,0.003,219.266,0.003c-52.344,0-98.022,15.843-137.042,47.536
														C43.203,79.228,17.509,120.574,5.137,171.587v1.997c0,2.474,0.903,4.617,2.712,6.423c1.809,1.809,3.949,2.712,6.423,2.712h56.814
														c4.189,0,7.042-2.19,8.566-6.565c7.993-19.032,13.035-30.166,15.131-33.403c13.322-21.698,31.023-38.734,53.103-51.106
														c22.082-12.371,45.873-18.559,71.376-18.559c38.261,0,71.473,13.039,99.645,39.115l-39.406,39.397
														c-3.607,3.617-5.421,7.902-5.421,12.851c0,4.948,1.813,9.231,5.421,12.847c3.621,3.617,7.905,5.424,12.854,5.424h127.906
														c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.42-7.898,5.42-12.847V36.542C438.529,31.593,436.733,27.312,433.109,23.694z"
                                                            />
                                                            <path
                                                                d="M422.253,255.813h-54.816c-4.188,0-7.043,2.187-8.562,6.566c-7.99,19.034-13.038,30.163-15.129,33.4
														c-13.326,21.693-31.028,38.735-53.102,51.106c-22.083,12.375-45.874,18.556-71.378,18.556c-18.461,0-36.259-3.423-53.387-10.273
														c-17.13-6.858-32.454-16.567-45.966-29.13l39.115-39.112c3.615-3.613,5.424-7.901,5.424-12.847c0-4.948-1.809-9.236-5.424-12.847
														c-3.617-3.62-7.898-5.431-12.847-5.431H18.274c-4.952,0-9.235,1.811-12.851,5.431C1.807,264.844,0,269.132,0,274.08v127.907
														c0,4.945,1.807,9.232,5.424,12.847c3.619,3.61,7.902,5.428,12.851,5.428c4.948,0,9.229-1.817,12.847-5.428l36.829-36.833
														c20.367,19.41,43.542,34.355,69.523,44.823c25.981,10.472,52.866,15.701,80.653,15.701c52.155,0,97.643-15.845,136.471-47.534
														c38.828-31.688,64.333-73.042,76.52-124.05c0.191-0.38,0.281-1.047,0.281-1.995c0-2.478-0.907-4.612-2.715-6.427
														C426.874,256.72,424.731,255.813,422.253,255.813z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                )}
                            </div>
                        )}

                        {datasets ? (
                        <div
                            id="datasets"
                            data-testid={'viewContainer'}
                            className={
                                'custom_container fullWidth d-flex flex-wrap ' +
                                this.state.viewClass
                            }
                        >
                            {/* toggle "listView gridview" classes for list and list and grid view----default is grid */}
                            {datasets}
                        </div>
                        ) : (
                            <>
                                {noDatasets}
                            </>
                        )}
                        {this.props.nextPageUrl && datasets && (
                            <div className={'loadMoreDatasetButton '}>
                                <button
                                    type="button"
                                    className={
                                        'float-center btn btn-warning' +
                                        (this.props.isMoreLoading ? ' spinner-border' : '')
                                    }
                                    onClick={handleLoadMore}
                                    disabled={this.props.isMoreLoading}
                                >
                                    {this.props.isMoreLoading ? ' ' : ' Load More'}
                                </button>
                            </div>
                        )}
                        {this.props.showNewInsight && (
                            <div
                                className="cursor-pointer newInsightBox text-center"
                                onClick={handleUpdateDatasets}
                            >
                                New Insights
                            </div>
                        )}
                    </>
                )}
            </>
      )
    }
}

export const FeedWithContext = bindContexts(Feed, [APIContext, WorkspaceContext])
export default withRouter(FeedWithContext)

import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { bindContexts } from '../utils/bindContexts'
import Workbench from 'src/components/Workbench'
import Tasks from 'src/components/Tasks'
import 'src/assets/css/views/setupTasks.css'

class SetupTasks extends React.Component<any, any> {
    static contextType = MergedContexts;
    public isThisMounted = false;

    constructor (props: any) {
      super(props)
    }

    public componentDidMount () {
      this.props.setViewName && this.props.setViewName("Setup Tasks");
    }

    public render () {
      const { workspace, workspaceJobs } = this.context
      return (
        <Workbench
            menu={this.props.isLab ? 'lab' : 'app'}
            isExpandedSidebarActive={this.props.isExpandedSidebarActive}
            isSidebarLayout={this.props.isSidebarLayout}
            toggleSidebar={this.props.toggleSidebar}>
            <div className="rightside_pageContent" data-testid="resolved">
                <div className="setup-tasks-view">
                    <Tasks workspace={workspace} workspaceJobs={workspaceJobs} />
                </div>
            </div>
        </Workbench>
      )
    }
}

export const SetupTasksWithContext = bindContexts(withRouter(SetupTasks), [
  Auth0Context,
  WorkspaceContext
])
export default SetupTasksWithContext
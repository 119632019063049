import * as React from 'react'
import Button from '@material-ui/core/Button'
import ReactCrop from 'react-image-crop'
import 'src/assets/css/components/workspaceForm.css'
import logo from 'src/assets/images/logo.svg'


class WorkspaceImage extends React.Component<any, any> {

    imageRef: any;
    pictureFileInput: any;
    // eslint-disable-next-line
    constructor(props: any) {
        super(props)
        this.state = {
            croppedImageUrl: null,
            showMe: "none",
            showModal: false,
            src: undefined,
            crop: null,
            imageUrl: this.props.workspace && this.props.workspace.imageUrl
        }
        this.pictureFileInput = React.createRef();
        this.onSelectFile = this.onSelectFile.bind(this)
        this.addOrChangeWorkspaceImage = this.addOrChangeWorkspaceImage.bind(this)
        this.onImageLoaded = this.onImageLoaded.bind(this)
    }

    // eslint-disable-next-line
    public showModal = () => {
        this.setState({ showModal: true });
    }

    // eslint-disable-next-line
    public onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            // eslint-disable-next-line
            reader.addEventListener('load', (x) => {
                this.setState({ src: reader.result });
                this.setState({ showMe: "block" });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // eslint-disable-next-line
    public addOrChangeWorkspaceImage = () => {
        const myInput = document.getElementById('workspace-picture') as HTMLInputElement;
        myInput && myInput.click();
    }

    // eslint-disable-next-line
    public onImageLoaded = (image: any) => {
        this.imageRef = image;
        const heightInPx = 100;
        this.setState({
            crop: {
                unit: 'px',
                height: heightInPx,
                x: 0,
                y: 0,
                aspect: 1 / 1,
            }
        });
        const cropForInitialPreview = {
            width: heightInPx,
            height: heightInPx,
            x: 0,
            y: 0
            
        };
        this.makeClientCrop(cropForInitialPreview);
        return false;
    };

    // eslint-disable-next-line
    public onCropComplete = (crop: any) => {
        this.makeClientCrop(crop);
    };

    // eslint-disable-next-line
    public onCropChange = (cropSelection: any) => {
        this.setState({ crop: cropSelection })
    };

    // eslint-disable-next-line
    public makeClientCrop = (crop: any) => {
        if (this.imageRef && crop.width && crop.height) {
            const imageType = this.imageRef.src.match(/png|svg|jpg|jpeg/);
            this.getCroppedImg(
                this.imageRef,
                crop,
                imageType[0],
                (e: any) => {
                    this.setState({ croppedImageUrl: e })
                }
            );
        }
    }

    // eslint-disable-next-line
    public getCroppedImg = (image: any, crop: any, imageType: string, resolve: any) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        //fixed dest size - 512x512
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx: any = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );
       
        const dataURL = canvas.toDataURL("image/"+ imageType)
        this.setState({ croppedImageUrl: dataURL})
    }

    // eslint-disable-next-line
    public render() {
        const { crop, croppedImageUrl, imageUrl } = this.state;
        const selectAllFn = (e:any) => {
          e.preventDefault()
          const cropArea = {
            unit: 'px',
            height: this.imageRef.height,
            width: this.imageRef.width,
            x: 0,
            y: 0,
          }
          this.onCropChange(cropArea)
          this.onCropComplete(cropArea)
        }

        return (
                <>
                {imageUrl === "" ?
                    <div className="upload-img">
                        <button
                            style={{
                                "backgroundColor": "transparent",
                                "border": "none",
                                "marginTop": "5rem"
                            }}>
                            <input
                                id="workspace-picture"
                                type="file"
                                onClick={(event: any) => {
                                    event.target.value = null
                                }}
                                accept="image/jpeg,image/svg,image/png"
                                onChange={this.onSelectFile}
                            />
                            <br />
                            <h4 className="upload-img-label">
                                Add logo
                            </h4>
                        </button>
                    </div>
                    :
                    <div>
                    {this.state.showMe === 'none' &&
                        <div className="workspace-form-image-container" onClick={() => this.pictureFileInput.current.click()}>
                            <img className='workspace-form-image' src={croppedImageUrl ? croppedImageUrl : 
                                                                        imageUrl ? imageUrl : logo } alt={"Logo"} />
                            <div className="workspace-form-change-logo-button">
                              <div className="workspace-form-change-logo-text">Change logo</div>
                            </div>
                        </div>
                    }
                        <div className="centered-image-buttons">
                        <input
                            id="workspace-picture"
                            ref={this.pictureFileInput}
                            style={{ "display": 'none' }}
                            type="file"
                            onClick={(event: any) => {
                                event.target.value = null
                            }}
                            accept="image/jpeg,image/svg,image/png"
                            onChange={this.onSelectFile}
                        />
                        </div>
                    </div>}
                    <div style={{ "display": this.state.showMe , width: '100%', position: 'relative'}}>
                        {this.state.src &&
                        <div className="p-2 w-50 d-table-cell">
                            <div>Select an area to use as logo or <a href="" onClick={selectAllFn}>select all</a></div>
                            <ReactCrop
                                src={this.state.src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        </div>}
                        <div className="footer-buttons">
                            <Button onClick={(e) => {
                                    e.preventDefault()
                                    this.props.onChangeImageUrl(imageUrl);
                                    this.setState({ croppedImageUrl: imageUrl, showMe: "none" })
                                    }}>Cancel</Button>
                            <Button
                                disabled={crop && crop.width < 10}
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.props.onChangeImageUrl(croppedImageUrl);
                                    this.setState({ showMe: "none" });
                                }}>OK</Button>
                        </div>
                    </div>
                </>
        );
    }
}


export default WorkspaceImage;
import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import Moment from 'react-moment'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import TaskCard from 'src/components/TaskCard'
import { useAPIContext, deleteWorkspaceJobURI } from '../contexts/api'
import { useWorkspaceContext } from '../contexts/workspace'
import 'src/assets/css/views/setupTasks.css'
import { logError } from 'src/utils/Error'
import { ITasksProps } from 'src/models/components/ITasks'
import EntityRel from 'src/models/EntityRel'

//
// The tasks component - used in Onboarding and SetupTasks views.
//

const Tasks: FunctionComponent<ITasksProps> = (props: PropsWithChildren<ITasksProps>) => {
  const [jobs, setJobs] = useState<[]>(props.workspaceJobs)
  
  const { catalogAPI } = useAPIContext();
  const { workspaceJobs, getAllWorkspaceJobs } = useWorkspaceContext();
  const history = useHistory()
  const { onboarding, workspace } = props;

  useEffect(() => {
    if (!props.workspaceJobs) {
      getAllWorkspaceJobs(workspace.id)
    }
  }, [workspace])  

  useEffect(() => {
    if (props.workspaceJobs) {
      setJobs(props.workspaceJobs)
    } else {
      setJobs(workspaceJobs)
    }
  }, [workspaceJobs, props.workspaceJobs])  
  
  const deleteWorkspaceJob = async (job: any) => {
    await catalogAPI
    .delete(deleteWorkspaceJobURI(job.id))
    .then(() => {
        getAllWorkspaceJobs(workspace.id)
    })
    .catch((e: any) => {
        logError(e);
    });
  }

  const handleAppLetsGo = async (path: string) => {
    props.updateTheWorkspace && props.updateTheWorkspace()
    history.push('/l/' + props.workspace.id + path)
  }

  const handleLabLetsGo = async (path: string) => {
    props.updateTheWorkspace && props.updateTheWorkspace()
    history.push('/lab/' + props.workspace.id + path)
  }

  const createCard = (index:number, workspace:any, job:any, completedJob:boolean, runningJobsLength: number, completedJobsLength: number) => {

    if (job.type == 'PROFILE_IMPORT') {
      return (
        <TaskCard 
          workspace={workspace}
          runningJobsLength={runningJobsLength}
          completedJobsLength={completedJobsLength}
          key={index}
          index={index}
          lineBefore={completedJob}
          job={job}
          handleDismiss={!completedJob ? () => deleteWorkspaceJob(job) : null}
          handleLetsGo={!completedJob ? () => handleLabLetsGo('/pipelines') : null}
          title="Import data"
          message="Setup a pipeline to import data from a supported or custom data source."
        />
      )
    } else if(job.type == 'PROFILE_COLLABORATE') {
      return (
        <TaskCard 
          workspace={workspace}
          runningJobsLength={runningJobsLength}
          completedJobsLength={completedJobsLength}
          key={index}
          index={index}
          lineBefore={completedJob}
          job={job}
          handleDismiss={!completedJob ? () => deleteWorkspaceJob(job) : null}
          handleLetsGo={!completedJob ? () => handleAppLetsGo('/invitations') : null}
          title="Collaborate"
          message="Invite others to collaborate in your workspace."
        />
      )
    } else if(job.type == 'PROFILE_PUBLISH') {
      return (
        <TaskCard
          workspace={workspace}
          runningJobsLength={runningJobsLength}
          completedJobsLength={completedJobsLength} 
          key={index}
          index={index}
          lineBefore={completedJob}
          job={job}
          handleDismiss={!completedJob ? () => deleteWorkspaceJob(job) : null}
          handleLetsGo={!completedJob ? () => history.push('https://www.matatika.com/docs') : null}
          title="Publish insights"
          message="Publish real-time insights to all members of your workspace."
        />
      )
    } else if(job.type == 'PROFILE_CONFIGURE_PIPELINE') {
      return (
        <TaskCard
          workspace={workspace}
          runningJobsLength={runningJobsLength}
          completedJobsLength={completedJobsLength}
          key={index}
          index={index}
          lineBefore={completedJob}
          job={job}
          handleDismiss={!completedJob ? () => deleteWorkspaceJob(job) : null}
          handleLetsGo={!completedJob ? () => history.push(`/lab/${workspace.id}/${EntityRel.Pipeline.collection}/${job._embedded.pipeline.id}?validate=true`) : null}
          title={`Complete your '${job._embedded.pipeline.name}' pipeline configuration`}
          message={`The pipeline '${job._embedded.pipeline.name}' is missing some required settings. Please complete the configuration.`}
        />
      )
    } else if(job.type == 'PROFILE_CONFIGURE_DATASTORE') {
      return (
        <TaskCard
          workspace={workspace}
          runningJobsLength={runningJobsLength}
          completedJobsLength={completedJobsLength}
          key={index}
          index={index}
          lineBefore={completedJob}
          job={job}
          handleDismiss={!completedJob ? () => deleteWorkspaceJob(job) : null}
          handleLetsGo={!completedJob ? () => history.push(`/lab/${workspace.id}/${EntityRel.DataStore.collection}/${job._embedded.datastore.id}?validate=true`) : null}
          title={`Complete your '${job._embedded.datastore.name}' store configuration`}
          message={`The store '${job._embedded.datastore.name}' is missing some required settings. Please complete the configuration.`}
        />
      )
    } else if(job.type == 'PIPELINE_CONFIG') {
      return (
        <TaskCard
          workspace={workspace}
          runningJobsLength={runningJobsLength}
          completedJobsLength={completedJobsLength} 
          key={index}
          index={index}
          lineBefore={completedJob}
          job={job}
          title="Preparing your pipeline"
          message={(
            <>
              { job.endTime ? (
                <>
                  Completed&nbsp;
                  <Moment fromNow={true} ago={true} utc={true} local={true}>
                    {job.endTime}
                  </Moment>
                  &nbsp;ago.
                </>
              )
                : (<>Once complete, you can run your pipeline manually or configure an automated schedule.</>)
              }
              <br/>
              <a href='#' onClick={(e:any) => {
                e.preventDefault()
                handleLabLetsGo('/pipelines')
              }}>View pipelines</a>
            </>
          )}
        />
      )
    } else if(job.type == 'WORKSPACE_INIT'){
      return (
        <TaskCard 
          workspace={workspace}
          runningJobsLength={runningJobsLength}
          completedJobsLength={completedJobsLength} 
          key={index}
          index={index}
          lineBefore={completedJob}
          job={job}
          title="Configuring your workspace"
          message={job.endTime && (
            <>
            Completed&nbsp;
            <Moment fromNow={true} ago={true} utc={true} local={true}>
              {job.endTime}
            </Moment>
            &nbsp;ago.
            <br/>
            <a target="_blank" rel='noreferrer' href={workspace.repositoryUrl} >{workspace.repositoryUrl}</a>
            </>
          )}
        />
      )
    } else {
      return (
        <TaskCard 
          workspace={workspace}
          runningJobsLength={runningJobsLength}
          completedJobsLength={completedJobsLength} 
          key={index}
          index={index}
          lineBefore={completedJob}
          job={job}
          handleDismiss={() => deleteWorkspaceJob(job)}
          title="Other job"
          message={job.endTime ? (
            <>
            ({job.type}) Completed&nbsp;
            <Moment fromNow={true} ago={true} utc={true} local={true}>
              {job.endTime}
            </Moment>
            &nbsp;ago.
            </>
          )
          : (
            <>
            ({job.type}) Started&nbsp;
            <Moment fromNow={true} ago={true} utc={true} local={true}>
              {job.startTime}
            </Moment>
            &nbsp;ago.
            </>
          )}
        />
      )
    }
  }

  const completedJobs = jobs && jobs.filter((job: any) => (job.status != 'RUNNING' && job.status != 'QUEUED'))
  const runningJobs = jobs && jobs.filter((job: any) => (job.status == 'RUNNING' || job.status == 'QUEUED'))
  return (
    <>
          <div className="onboarding-tasks">
            <div className="onboarding-icon onboarding-tasks-icon">
              <div className='circle-border circle-border-tasks' style={(runningJobs && runningJobs.length == 0) ? {background: 'linear-gradient(180deg, #009579 50%, transparent 50%), linear-gradient(360deg, #009579 50%, lightgray 50%)'} : {}}>
                <div className='tasks-in-progress-icon tasks-icon' data-testid="inprogress-jobs">{runningJobs && runningJobs.length}</div>
              </div>
            </div>
            <div className="onboarding-title">
              <span className="onboarding-text">{ onboarding ? (
                  <>Complete these tasks to get the most out of your workspace.</>
                ):(
                  <>Tasks in progress.</>
                )}
              </span>
            </div>
          </div>
          <div className="setup-tasks-mobile onboarding-setup-tasks">
            <div className="setup-tasks">
              {runningJobs && runningJobs.length > 0 && <div className="remaining-tasks">
                <h3 className="remaining-tasks-label">{ onboarding ? "Next steps" : "In progress" }</h3>
                  {runningJobs && runningJobs.map((job: any, index: number) => {
                      return createCard(index, workspace, job, false, runningJobs.length, completedJobs && completedJobs.length)
                    })
                  }
              </div>}
              <div className="completed-tasks">
                <h3 className="completed-tasks-label">Completed</h3>
                  {completedJobs && completedJobs.map((job: any, index: number) => {
                      return createCard(index, workspace, job, true, runningJobs && runningJobs.length, completedJobs.length)
                    })
                  }
              </div>
            </div>
          </div>
    </>
  )
}

export default Tasks
import { createTheme } from '@material-ui/core/styles'

const matatikaBlue = '#455066'

const theme = createTheme({
    palette: {
        primary: {
            main: matatikaBlue
        },
    },
    props: {
        MuiButton: {
            disableElevation: true
        }
    }
})

export default theme

import { HotTable } from "@handsontable/react"
import { registerAllModules } from 'handsontable/registry'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import Metadata from 'src/components/charts/metadata/Metadata'
import { useAPIContext } from 'src/contexts/api'
import { HandsontableTableProps } from 'src/models/charts/handsontable/HandsontableTable'
import { parseJsonSwallowError } from 'src/utils/charts/chartUtils'

// register Handsontable's modules
registerAllModules();

const HandsontableTable: FunctionComponent<HandsontableTableProps> = props => {
  const { catalogAPI } = useAPIContext()
  const {
    options,
    getData,
    rawData,
    metadata,
  } = props
  const metadataObj = new Metadata(metadata)

  const [chartData, setChartData] = useState({
    ready: false,
    data: [] as Record<string, unknown>[],
  })

  // ensure component is still mounted before updating chart
  const componentIsMounted = useRef<boolean>(true)

  useEffect(() => {
    componentIsMounted.current = true
    if (rawData) {
      setChartData({ready: true, data: parseJsonSwallowError(rawData)})
    } else {
      getData?.().then(data => setChartData({ ready: true, data: data }));
    }

    // return a cleanup function
    return () => {
      componentIsMounted.current = false
    }
  }, [catalogAPI, rawData, metadata])

  const settings = options ? options : {
    data: chartData.data,
    colHeaders(index:any) {
      const colKeys = metadataObj.getColumnKeys()
      const aggKeys = metadataObj.getAggregateKeys()
      const column = colKeys && metadataObj.getColumn(colKeys[index])
        || aggKeys && metadataObj.getColumn(aggKeys[colKeys && colKeys.length > 0 ? index - 1 : index])
      return column && column.label ? column.label : column;
    },
    rowHeaders: true,
    height: 'auto',
    width: '100%',
    stretchH: 'all',
    contextMenu: {
      items: {
        'copy': {},
      }
    },
    readOnly: true,
    licenseKey: 'non-commercial-and-evaluation'
  }
  return (
    chartData.ready ? <div id="handsontable-table">
      <HotTable
        settings={settings}
      />
    </div> : null
  )
}

export default HandsontableTable

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            width: '100%',
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '5px',
            border: '1px solid #ddd',
            '& .MuiStepIcon-root.MuiStepIcon-active': {
                color: '#455066',
            },
            '& .MuiStepIcon-completed': {
                color: '#455066',
            },
            '& .MuiButton-containedPrimary': {
                backgroundColor: '#455066',
            },
            '& .MuiButton-containedPrimary.Mui-disabled': {
                backgroundColor: '#9e9e9e',
                color: '#fff',
            },
            '& .MuiRadio-colorSecondary.Mui-checked': {
                color: '#455066',
            },
            '& .MuiAccordionDetails-root': {
                flexDirection: 'column',
            },
            '& .save-button': {
                backgroundColor: '#454f66',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px 10px',
            },
            '& .MuiSnackbar-anchorOriginTopCenter': {
                top: '54px',
                width: '100%',
            },
            '& .MuiAlert-filledSuccess': {
                justifyContent: 'center',
                width: '100%',
            },
        },
        root_medium: {
            height: '100%',
            width: '100%',
            padding: '20px',
            backgroundColor: '#fff',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            border: '1px solid #ddd',
            borderRight: 'none',
            position: 'relative',
            minHeight: '64vh',
            '& .MuiStepIcon-root.MuiStepIcon-active': {
                color: '#455066',
            },
            '& .MuiStepIcon-completed': {
                color: '#455066',
            },
            '& .MuiButton-containedPrimary': {
                backgroundColor: '#455066',
            },
            '& .MuiButton-containedPrimary.Mui-disabled': {
                backgroundColor: '#9e9e9e',
                color: '#fff',
            },
            '& .MuiRadio-colorSecondary.Mui-checked': {
                color: '#455066',
            },
            '& .MuiAccordionDetails-root': {
                flexDirection: 'column',
            },
            '& .save-button': {
                backgroundColor: '#454f66',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px 10px',
            },
            '& .MuiSnackbar-anchorOriginTopCenter': {
                top: '54px',
                width: '100%',
            },
            '& .MuiAlert-filledSuccess': {
                justifyContent: 'center',
                width: '100%',
            },
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    })
);

export default useStyles;